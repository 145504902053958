import apiClient from "../apiClient";

export const sendProjectTransferPayment = async (
    project_id,
    payment_method,
    purchased,
    invoiceInfo,
    unitPrice,
    totalPrice,
    vat,
    saleContract,
    discountRate = 0,
) => {
    const url = `${process.env.REACT_APP_API_URL}/api/project-transfer-payment/`;

    let bodyDict = {
        method: payment_method,
        purchased: purchased,
        project_id: project_id,
        invoice_type: invoiceInfo.type,
        client_name: invoiceInfo.data.name,
        client_surname: invoiceInfo.data.surname,
        client_tr_id: invoiceInfo.data.tr_identity_number,
        client_telephone_country_code: invoiceInfo.data.telephone_country_code,
        client_telephone: invoiceInfo.data.telephone,
        client_taxno: invoiceInfo.data.taxno,
        client_tax_office: invoiceInfo.data.tax_office,
        client_company_name: invoiceInfo.data.company_name,
        client_address: invoiceInfo.data.address,

        country: invoiceInfo.data.country,
        province: invoiceInfo.data.province,
        county: invoiceInfo.data.county,
        street: invoiceInfo.data.street,
        zipcode: invoiceInfo.data.zipcode,
        address_line1: invoiceInfo.data.address_line1,
        address_line2: invoiceInfo.data.address_line2,

        unit_price: unitPrice,
        total_price: totalPrice,
        vat: vat,
        sale_contract: saleContract,
        discount_rate: discountRate,
    }

    const response = await apiClient(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyDict),
    });

    return response;

}