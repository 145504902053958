import i18n from "../i18n";

export const checkIfUserActive = async (email, password = null) => {
    const url = `${process.env.REACT_APP_API_URL}/api/is-user-active/`;

    let body = {
        login: email,
    }
    if (password !== null) {
        body.password = password;
    }

    let ipAddress

    try {
        const ipResponse = await fetch('https://api.ipify.org?format=json');
        if (ipResponse.ok) {
            const data = await ipResponse.json();
            ipAddress = data.ip
        }
        else {
            ipAddress = "0.0.0.0"
        }
    }
    catch {
        ipAddress = "0.0.0.0"
    }

    body.ip_address = ipAddress;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language || 'tr',
        },
        body: JSON.stringify(body),
    });

    return response;

}