import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getUserData } from '../../services/userService';
import { getAssignedUsers } from '../../services/delegateServices/getAssignedUsers';
import { getAssignedUsersPayments } from '../../services/delegateServices/getAssignedUsersPayments';

import { useTranslation } from 'react-i18next';

const useDelegatePanel = () => {
    const navigate = useNavigate();

    const { i18n, t } = useTranslation();

    const tableKeys = [
        {
            accessorKey: 'order_id', //access nested data with dot notation
            header: t('Sipariş No'),
            size: 120,
        },
        {
            accessorFn: (row) => {
                let method = row.method
                if (method === "transfer") return t("Havale")
                else return t("K. Kartı")
            },
            header: t('Metod'),
            size: 120,
        },
        {
            accessorKey: 'user', 
            header: t('Kullanıcı'),
            size: 30,
        },
        {
            accessorFn: (row) => {
                let status = row.status
                if (status === "success") return t("Başarılı")
                else if (status === "waiting") return t("Bekliyor")
                else if (status === "failed") return t("Başarısız")
                else if (status === "in_progress") return t("Devam Ediyor")
                else return t("HATA")
            },
            header: t('Durum'),
            size: 150,
            Cell: ({ renderedCellValue, row }) => {
                let bg_variant, text_variant, message
                if (renderedCellValue === t("Bekliyor")) {
                    bg_variant = "bg-warning"
                    text_variant = "text-dark"
                }
                else if (renderedCellValue === t("Başarılı")) {
                    bg_variant = "bg-success"
                    text_variant = "text-white"
                }
                else if (renderedCellValue === t("Başarısız")) {
                    bg_variant = "bg-danger"
                    text_variant = "text-white"
                }
                else if (renderedCellValue === t("Devam Ediyor")) {
                    bg_variant = "bg-info"
                    text_variant = "text-dark"
                }
                else {
                    bg_variant = "bg-dark"
                    text_variant = "text-white"
                }
                return <p className={`text-center border ${text_variant} ${bg_variant}`}>{renderedCellValue}</p>
            }
        },
        {
            accessorFn: (row) => `₺${row.payment_detail.total_price}`,
            header: t('Tutar'),
            size: 120,
            Cell: ({ renderedCellValue, row }) => {
                return <p className={`fw-bold`} style={{ fontSize: "20px" }}>{renderedCellValue}</p>
            }
        },
        {
            accessorFn: (row) => `₺${
                Math.floor(row.payment_detail.delegation_cut_unit_price * row.payment_detail.project_weight * 1e-3)
            }`,
            header: t('Temsilci Payı'),
            size: 120,
            Cell: ({ renderedCellValue, row }) => {
                return <p className={`fw-bold`} style={{ fontSize: "20px" }}>{renderedCellValue}</p>
            }
        },
        {
            accessorFn: (row) => `${row.payment_detail.project_name} (${row.payment_detail.project})`,
            header: t('Proje İsmi'),
            size: 150,
        },
        {
            accessorFn: (row) => `${(row.payment_detail.project_weight / 1000).toFixed(3)}`,
            header: t('Ağırlık (ton)'),
            size: 100,
        },
        {
            accessorFn: (row) => `${(new Date(row.payment_detail.date_created)).toLocaleDateString('tr-TR')}`,
            header: t('Tarih'),
            sortingFn: 'datetime',
            size: 150,
        },
    ]

    const [activeTab, setActiveTab] = useState("first")
    const [assignedUsers, setAssignedUsers] = useState([])
    const [assignedUsersPayments, setAssignedUsersPayments] = useState([])
    const [isPageLoading, setIsPageLoading] = useState(true)


    useEffect(() => {
        async function fetchData() {

            let user_data
            try {
                user_data = await getUserData()
            }
            catch {
                // getUserError
            }
            if (!(user_data.user_type === 3 && user_data.delegation_active)) {
                navigate(`/${i18n.language}`)
            }

            let getAssignedUsersResponse;

            try {
                getAssignedUsersResponse = await getAssignedUsers();
            }
            catch {
                //
            }

            if (getAssignedUsersResponse.ok) {
                const assignedUsersData = await getAssignedUsersResponse.json()
                setAssignedUsers(assignedUsersData)
            }

            let getAssignedUsersPaymentsResponse

            try {
                getAssignedUsersPaymentsResponse = await getAssignedUsersPayments();
            }
            catch {
                //
            }

            if (getAssignedUsersPaymentsResponse.ok) {
                const getAssignedUsersPaymentsResponseData = await getAssignedUsersPaymentsResponse.json()
                setAssignedUsersPayments(getAssignedUsersPaymentsResponseData)
            }

            setIsPageLoading(false)

        }

        fetchData()
    }, [])

    return {
        activeTab,
        setActiveTab,
        isPageLoading,
        assignedUsers,

        tableKeys,

        assignedUsersPayments,
    }
}

export default useDelegatePanel