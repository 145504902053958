import React from 'react';
import i18n from '../../i18n';
import UserManualEn from './UserManualEn';
import UserManualTr from './UserManualTr';
const UserManual = () => {
    if (i18n.language === 'tr') {
        return <UserManualTr />;
    }
    else{
        return <UserManualEn />;
    }
}

export default UserManual;