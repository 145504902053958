import React, {useState} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import styles from './CpInfo.module.css';

import Configuration from './TabPages/Configuration';
import Summary from './TabPages/Summary'
import Patterns from './TabPages/Patterns';

import Substitutions from './TabPages/Substitutions';

import { useTranslation } from 'react-i18next';
import SolutionOptions from './TabPages/SolutionOptions';

const ActiveCpInfo = ({
    showLowerFloorCheck,

    result,

    diameters,
    floors,
    checkedDiameter,
    checkedFloors,
    currentPatterns,
    showedPatterns,

    currentSubstitutes,
    currentWastes,

    resultOptions,
    currentResultSummary,

    handleDiametersChange,
    handleFloorsChange,
    handleShowLowerFloorPatterns,

}) => {

    const { i18n, t } = useTranslation();

    const floorAvailable = true

    const [activeTab, setActiveTab] = useState('second');

    return (
        <Container fluid>

            <Row>
                <Col lg={2} className='mb-3'>
                    <Configuration
                        floors={floors}
                        diameters={diameters}
                        onDiametersChange={handleDiametersChange}
                        onFloorsChange={handleFloorsChange}
                    />
                </Col>
                <Col lg={10}>
                    <Tab.Container fluid="md" id="left-tabs-example" defaultActiveKey="second">

                        <Nav className="nav-tabs nav-fill flex-row">
                            <Col xs={3}>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="second"
                                        className={activeTab === 'second' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('second')}
                                    >
                                        {t("Özet")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={3}>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="third"
                                        className={activeTab === 'third' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('third')}
                                    >
                                        {t("Kesim")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={3} className='d-none d-md-block'>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="fourth"
                                        className={activeTab === 'fourth' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('fourth')}
                                    >
                                        {t("Y. Kul. & Fire Lis.")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={3} className='d-block d-md-none'>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="fourth"
                                        className={activeTab === 'fourth' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('fourth')}
                                    >
                                        {t("Diğer")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={3}>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="fifth"
                                        className={activeTab === 'fifth' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('fifth')}
                                    >
                                        {t("Bilgi")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="second" transition={false}>
                                <Summary
                                    floorAvailable={floorAvailable}
                                    checkedDiameter={checkedDiameter}
                                    currentResultSummary={currentResultSummary}
                                    result={result}
                                    floors={floors}
                                />

                            </Tab.Pane>
                            <Tab.Pane eventKey="third" transition={false}>
                                <Patterns
                                    diameter={checkedDiameter}
                                    floorAvailable={floorAvailable}
                                    currentPatterns={currentPatterns}
                                    showedPatterns={showedPatterns}
                                    allFloors={result.allFloors}
                                    donatiDict={result.donatiDict}
                                    checkedFloors={checkedFloors}
                                    bindirmeOrani={result.bindirmeOrani}
                                    handleShowLowerFloorPatterns={handleShowLowerFloorPatterns}
                                    showLowerFloorCheck={showLowerFloorCheck}
                                    version={result.version}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth" transition={false}>
                                <Substitutions
                                    floorAvailable={floorAvailable}

                                    currentSubstitutes={currentSubstitutes}
                                    currentWastes={currentWastes}

                                    allFloors={result.allFloors}
                                    donatiDict={result.donatiDict}
                                    minOutageLength={resultOptions.minOutageLength}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth" transition={false}>
                                <SolutionOptions
                                    resultOptions={resultOptions}
                                />
                            </Tab.Pane>
                        </Tab.Content>

                    </Tab.Container>
                </Col>
            </Row>

        </Container>
    );
};

export default ActiveCpInfo;
