import React from 'react';
import styles from './LongRebarTable.module.css';
import { useTranslation } from 'react-i18next';

const LongRebarTable = (props) => {

    const data = props.data;
    const bindirmeOrani = props.bindirmeOrani;
    let floorAvailable = false;

    const { t } = useTranslation();

    if (data.length > 0) {
        floorAvailable = "floor" in data[0]
    }

    return (
        <div className={`mb-3 border ${styles.tableWrap}`}>
            <table className={`table ${styles.table} text-center align-middle`}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th>#</th>
                        <th>{t("Adet")}</th>
                        <th>{t("Uzunluk")}</th>
                        <th>{t("Çap")}</th>
                        <th>{t("Bindirme Boyu")}</th>
                        {floorAvailable ?
                            <>
                                <th>{t("Kat")}</th>
                                <th>{t("Eleman")}</th>
                                <th>{t("Eleman Kodu")}</th>
                                <th>{t("Açıklama")}</th>
                            </>
                            : <></>}
                        <th>{t("Donatı Kodları")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, i) => {
                        let span = row.rebarCodes.length
                        let stripeClass = i % 2 === 0 ? styles.stripeEven : styles.stripeOdd;
                        return <React.Fragment key={i}>
                            <tr className={stripeClass}>
                                <td rowSpan={span}>{i + 1}</td>
                                <td rowSpan={span}>{row.number}</td>
                                <td rowSpan={span}>{row.totalLength}</td>
                                <td rowSpan={span}>{row.diameter}</td>
                                <td rowSpan={span}>{(4 * row.diameter * bindirmeOrani).toFixed(0)}</td>
                                {floorAvailable ?
                                    <>
                                        <td rowSpan={span}>{row.floor}</td>
                                        <td rowSpan={span}>{row.element}</td>
                                        <td rowSpan={span}>{row.elementCode}</td>
                                        <td rowSpan={span}>{row.explanation}</td>
                                    </>
                                    : <></>}
                                <td>{row.rebarCodes[0]}</td>
                            </tr>
                            {row.rebarCodes.slice(1,).map((code, j) => (
                                <tr className={stripeClass} key={`${i}-${j}`}><td>{code}</td></tr>
                            ))}
                        </React.Fragment>;
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default LongRebarTable;