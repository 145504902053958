import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import CheckboxList from '../../../../components/CheckboxList/CheckboxList';

import styles from '../CpInfo.module.css';

import { useTranslation } from 'react-i18next';

const Configuration = ({
    profiles,
    onProfilesChange,
    showPieceInfo,
    setShowPieceInfo
}) => {

    const { t } = useTranslation();

    return (
        <Container fluid className='border p-3' style={{ backgroundColor: 'white' }}>
            <h4 className='text-center mb-3'>{t("Seçim")}</h4>

            <Container className="border shadow-sm mb-3">
            <Form.Check // prettier-ignore
                type="switch"
                label={t("Kesim planında parça bilgilerini göster.")}
                className='py-2'
                checked={showPieceInfo}
                onChange={(e)=>setShowPieceInfo(e.target.checked)}
            />
                <h5 className="text-center py-2">{t("Profiller")}</h5>
                <div className={`${styles.checkboxListContainer} mb-3`}>
                    <CheckboxList items={profiles} onCheckedItemsChange={onProfilesChange} />
                </div>
            </Container>

        </Container>
    )
}

export default Configuration;