import apiClient from "../apiClient";

export const getAssignedUsers = async (id=null) => {
    
    let url;
    if(id == null){
        url = `${process.env.REACT_APP_API_URL}/api/get-assigned-users`;
    }
    else{
        url = `${process.env.REACT_APP_API_URL}/api/get-assigned-users?id=${id}`;
    }

    const response = await apiClient(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response;
}