import apiClient from "./apiClient";

export const getAdditionalUserInfo = async (id = -1) => {
    let url
    if (id === -1) {
        url = `${process.env.REACT_APP_API_URL}/api/additional-user-info/`;
    }
    else {
        url = `${process.env.REACT_APP_API_URL}/api/additional-user-info/?id=${id}/`;
    }

    const response = await apiClient(url, {
        method: 'GET',
    })
    return response;

}

export const setAdditionalUserInfo = async (body, id = -1) => {
    let url
    if (id === -1) {
        url = `${process.env.REACT_APP_API_URL}/api/additional-user-info/`;
    }
    else {
        url = `${process.env.REACT_APP_API_URL}/api/additional-user-info/?id=${id}/`;
    }

    const response = await apiClient(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })
    return response;

}