import apiClient from "./apiClient";

export const getProjectPrice = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/api/project-price/${id}`;

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;


}