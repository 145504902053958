import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { useTranslation } from 'react-i18next';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

const DetailedProfileTable = ({data}) => {

    const { i18n, t } = useTranslation();

    const localization = i18n.language === 'tr' ? MRT_Localization_TR : MRT_Localization_EN;

    const columns = useMemo(
        () => [
            {
                header: t('Poz'),
                accessorKey: 'piece_code',
                enableGrouping: false, //do not let this column be grouped
                size: 90
            },
            {
                header: t('Profil'),
                accessorKey: 'profile',
                size: 125
            },
            {
                header: t('Malzeme'),
                accessorKey: 'grade',
                size: 125
            },
            {
                header: t('Adet'),
                accessorFn: (row) => Number(row.quantity),
                aggregateFn: 'sum',
                AggregatedCell: ({ cell, row }) => {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box fontWeight="bold">Toplam:</Box>
                            <Box>{cell.getValue()?.toFixed()}</Box>
                        </Stack>
                    )
                },
                size: 90
            },
            {
                header: t('Uzunluk'),
                accessorFn: (row) => (Number(row.length).toFixed(2)),
                size: 100
            },
            {
                header: t('Parça Ağırlığı'),
                accessorFn: (row) => (Number(row.weight_of_one_piece).toFixed(2)),
                size: 100
            },
            {
                header: t('Toplam Ağırlık (kg)'),
                accessorFn: (row) => ((Number(row.quantity) * Number(row.weight_of_one_piece))),
                Cell: ({ cell, row }) => {
                    return (
                        <Box className="fw-bold">{cell.getValue()?.toFixed(2)}</Box>
                    )
                },
                enableGrouping: false,
                aggregateFn: 'sum',
                AggregatedCell: ({ cell, table }) => {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box fontWeight="bold">Toplam:</Box>
                            <Box>{cell.getValue()?.toFixed(2)}</Box>
                        </Stack>
                    )
                },
                size: 150
            },
            {
                header: t('Açıklama'),
                accessorKey: 'description',
                size: 100
            },
        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnResizing
            enableGrouping
            enableStickyHeader
            enableStickyFooter
            initialState={{
                density: 'compact',
                expanded: true, //expand all groups by default
                pagination: { pageIndex: 0, pageSize: 100 },
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            localization={localization}
        />
    )
}

export default DetailedProfileTable;