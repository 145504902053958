import React from 'react';
import { iso31661_country_codes } from '../../other/variables';
import { iso31662_state_codes } from '../../other/variables';

import { useTranslation } from 'react-i18next';
import DelegationContractTextTr from './locales/DelegationContractTextTr';
import DelegationContractTextEn from './locales/DelegationContractTextEn';

const homeURL = process.env.REACT_APP_HOME_URL

const DelegationContractText = ({ props }) => {

    const { i18n, t } = useTranslation();

    const address_line2 = props.address_line2 !== null ? props.address_line2 : "";

    const addressParts = [props.street, props.address_line1, address_line2, props.county, iso31662_state_codes[props.province], iso31661_country_codes[props.country]];
    const address = addressParts.filter(part => part !== null && part !== undefined).join(' ');

    if(i18n.language === "en"){
        return <DelegationContractTextEn props={{...props, address:address}}/>
    }
    else{
        return <DelegationContractTextTr props={{...props, address:address}}/>
    }
}

export default DelegationContractText