import React from 'react';
import Container from 'react-bootstrap/Container';

import SubstitutionTable from '../../../../components/CpInfoTables/SubstitututionTable/SubstitutionTable';
import WasteTable from '../../../../components/CpInfoTables/WasteTable/WasteTable';

import { useTranslation } from 'react-i18next';

const Substitutions = (props) => {

    const { t } = useTranslation();

    const floorAvailable = props.floorAvailable
    const substitutes = props.currentSubstitutes
    const wastes = props.currentWastes
    const donatiDict = props.donatiDict
    const minOutageLength = props.minOutageLength

    let subAvailable;
    if(substitutes){
        subAvailable = substitutes.length !== 0
    }
    else{
        subAvailable = false;
    }

    let wasteAvailable;
    if(wastes){
        wasteAvailable = Object.keys(wastes).length !== 0
    }
    else{
        wasteAvailable = false;
    }

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            <Container fluid style={{ "display": "flex", "flexDirection": "column", "maxHeight": "100vh" }}>
                <h5 className="text-center py-2">{t("Yerine Kullanımlar")}</h5>
                {subAvailable ?
                    <SubstitutionTable substitutes={substitutes} donatiDict={donatiDict} floorAvailable={floorAvailable} ratio={1}/>
                    : <p className="text-center">{t("Yerine kullanım mevcut değil.")}</p>}
            </Container>
            <Container fluid style={{ "display": "flex", "flexDirection": "column", "maxHeight": "100vh" }}>
                <h5 className="text-center py-2">{t("Fire Listesi")}</h5>
                {wasteAvailable ?
                    <WasteTable wastes={wastes} minOutageLength={minOutageLength} ratio={1}/>
                    : <p className="text-center">{t("Fire listesi mevcut değil.")}</p>}
            </Container>
        </Container>
    )
}

export default Substitutions;