import React, { useEffect, useState } from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

import { useLocation } from 'react-router-dom';
const AboutUsTr = () => {

    document.title = "Hakkımızda"

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

    const [show, setShow] = useState(false);
    const [modalImg, setModalImg] = useState(null)

    const handleClose = () => setShow(false);
    const handleShow = (img) => { setShow(true); setModalImg(img) };

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
      
        if (hash) {
          const id = hash.replace('#', '');

          setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }, 1000);
        }
      }, [location]);


    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>Hakkımızda</h2>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="what-is-tasnifer">Tasnifer Nedir? Kim Tarafından Geliştirilmektedir?</h4>

                    <p><span className='fw-bold'>Tasnifer</span>, inşaat donatılarının kesimi sırasında oluşan fireleri minimize etmek amacıyla, matematiksel optimizasyon metodlarını kullarak donatı optimizasyonu yapan ve kullanıcılarına kesim planları oluşturan web tabanlı bir programdır.</p>

                    <p>Tasnifer, <span className='fw-bold'>Teber Soft Yazılım İnşaat Sanayi ve Ticaret Ltd. Şti.</span> (VKN:8331165876) tarafından T.C. Sanayi ve Teknoloji Bakanlığı proje kodu 89116 olan <span className='fw-bold'>"Donatı Kesim Planı Oluşturma"</span> isimli proje kapsamında Gaziantep Teknopark bünyesinde geliştirilmektedir. Bu proje, T.C. Sanayi ve Teknoloji Bakanlığı tarafından KDV Muafiyeti kapsamına alınmıştır.</p>

                    <p>Tasnifer web tabanlı çalışır, kurulum gerektirmez. Sistem internet sitesi üzerinden üye olunarak kullanılır.</p>

                    <p>Tasnifer'in nasıl kullanılacağı hakkında daha detaylı bilgi için <a href="user-manual">Kullanım Rehberi</a>mizi ziyaret edebilirsiniz.</p>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="what-is-optimization">Donatı Kesim Optimizasyonu Nedir?</h4>
                    <p><span className='fw-bold'>Donatı Kesim Optimizasyonu</span>, matematiksel modeller ve optimizasyon algoritmaları kullanılarak inşaat donatılarının kesimi sırasındaki fire miktarını en düşük seviyeye getirme işlemidir. Bunun sağlanabilmesi için donatılar belli bir plan dahilinde kesilmelidir.</p>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="why-optimization">Neden Donatı Kesim Optimizasyonu?</h4>

                    <p>Optimizasyon yapılmadan yapılan mevcut donatı kesim işlemleri kendi içerisinde birçok problemi barındırmaktadır. Mevcut donatı kesimleri ile ilgili problemlerden bazıları şunlardır:</p>

                    <ol>
                        <li>Demir ve işçilik maliyetlerinin artması</li>
                        <li>Fire miktarının fazla olması - Verimden kayıp</li>
                        <li>Ne kadar fire çıkacağının önceden bilinmemesi</li>
                        <li>Projelere tam uyumun sağlanamaması</li>
                        <li>Firelerin iş sahibinin eline geçmemesi</li>
                    </ol>

                    <p>Firelerin iş sahibinin eline geçmemesinin sebeplerinden birkaçı ise şunlardır:</p>
                    <ol>
                        <li>Uzunlukların değiştirilerek yerleştirilmesi</li>
                        <li>Firelerin çalışanlar tarafından gizlice satılması</li>
                        <li>Firelerin betona atılması</li>
                        <li>Firelerin toprağa gömülmesi</li>
                    </ol>

                    <p>Donatı kesimleri ve fire yönetimi ile ilgili tüm bu problemler bilimsel yöntemlerle optimize edilmiş ve planlı bir kesim işlemini zorunlu kılmaktadır.</p>


                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="gains">Tasnifer ile Elde Edilecek Kazançlar Nelerdir?</h4>

                    <p>Tasnifer ile proje sahiplerinin elde edebileceği başlıca kazançlar şunlardır:</p>
                    <ul>
                        <li><u className='fw-bold'>Verim artışı ile maliyetin azalması</u>: Plansız ve optimize edilmeyen kesimlerde fire miktarları çok fazla olmaktadır. Tasnifer ile en optimum kesim planı elde edilerek fireler mümkün olan en az miktara indirilecektir.</li>
                        <li><u className='fw-bold'>Fire miktarının projeye başlamadan önce bilinmesi</u>: Tasnifer ile projeye başlanmadan önce kesim sonunda elde kalacak fire miktarı net bir şekilde bilinebilecektir.</li>
                        <li><u className='fw-bold'>Demirciye ödenen ücretin azalması</u>: Kesim yapan demirci daha az demir işleyeceği için işçilik maliyetlerinde azalma olacaktır.</li>
                        <li><u className='fw-bold'>Projeye tam uyumun sağlanması</u>: Projede var olan donatıların planlı bir şekilde eksiksiz kesilmesi ile projeye tam uyum sağlanmış olacak, daha güvenli yapılar inşa edilecektir.</li>
                    </ul>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="pages">Tasnifer'in Özellikleri</h4>

                    <ul>
                        <li>Web tabanlı çalışır, kurulum gerektirmez.</li>
                        <li>İnşaat donatılarının kesimi sırasında oluşan fireleri minimize ederek kesim planları hazırlar.</li>
                        <li>Elle veri girişine gerek kalmadan mevcut betonarme paket programlarından direkt donatı metraj verisi alabilir.</li>
                        <li>Kullanıcı tarafından hazırlanan Excel dosyalarından da metraj verisi alabilir.</li>
                        <li>Projenin analizi sonucunda paket programlardan alınan detaylı metrajların Excel formatında indirilmesine izin verir.</li>
                        <li>Böylelikle statikerler tarafından analiz bittikten sonra çizim editöründe yapılan değişikliklerin hesaba katılmasına imkan tanımış olur.</li>
                        <li>Metraj dosyasından aldığı verileri sınıflandırarak donatı bilgilerinin ve metrajların kat ve eleman bazında interaktif olarak incelenmesini sağlar. Demirciler, şantiye şefleri, yapı denetim elemanları için eleman bazında kontrol imkanı tanır.</li>
                        <li>Projede yer alan 12 metreden uzun donatıların bindirme boylarını kesim planında dikkate alır.</li>
                        <li>12 metreden farklı uzunluklarda üretilen donatılar için de kesim planı hazırlayabilir.</li>
                        <li>Farklı kat ve çap konfigürasyonları ile kesim planları hazırlayabilir.</li>
                        <li>Kesim planı hazırlarken bir çaptan artan fireleri bir alt çapta uygun donatıların yerine kullanabilir.</li>
                        <li>Kesim sonunda elde kalacak fire için dikkate alınacak en kısa boyun ayarlanmasına izin verir.</li>
                        <li>Kesim planında her bir parçanın ait olduğu yeri gösterir.</li>
                        <li>İnşaat başlamadan kat bazında ve tüm yapı için hangi donatıdan ne kadar sipariş vermeniz gerektiğini hesaplayabilir.</li>
                        <li>Kat ve çap bazında elde edeceğiniz verimi hesaplayabilir.</li>
                        <li>Kesim sonunda elde kalacak firelerin listesini verir.</li>
                        <li>Oluşturulan kesim planlarının PDF ve Excel formatında indirilmesine izin verir.</li>
                        <li>Programı satın almadan proje başına ücret ödenerek programın kullanılmasına izin verir.</li>
                        <li>Bir projede sadece kesim planları oluşturma hakkı için ücret alır.</li>
                        <li>Kesim planı oluşturma hakkını satın almadan önce ücret ödemeden ne kadar verim elde edeceğinizi öğrenmenize imkan tanır.</li>
                    </ul>


                    <hr className='my-4' />

                    <p>Her türlü soru, görüş ve öneriniz için bizimle <span className='fw-bold'>{process.env.REACT_APP_CONTACT_EMAIL}</span> e-posta adresi üzerinden iletişime geçebilirsiniz.</p>

                </Container>
            </div>

            <Modal show={show} fullscreen="lg-down" onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='d-flex justify-content-center'><img className='img-fluid img-thumbnail' style={{ maxHeight: "80vh" }} src={modalImg} /></Modal.Body>
            </Modal>
            <Footer />
        </div>
    );
};

export default AboutUsTr;
