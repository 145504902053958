import React from 'react';

import Pattern from '../../../components/Pattern/MetalProfilePattern';
import WasteTable from '../../../components/CpInfoTables/WasteTable/MetalProfileWasteTable';

import styles from './PrintableCpInfo.module.css'

import watermark1 from '../../../img/watermark-1.png'
import watermark2 from '../../../img/watermark-2.png'

import { useTranslation } from 'react-i18next';
import { AllSummaryTable, StockStatsTable, OrderStatsTable } from '../MetalProfileCpInfo/TabPages/Summary';

const PrintableCpInfo = React.forwardRef(({
    cuttingPlanInfo,
    currentResultSummary,
    patternGroups,
    currentWastes,
    allStatistics,
    allProfilesInfo,
    availableStocks,
    ratio,
    showPieceInfo=true,
}, ref) => {

    const { t } = useTranslation();

    let unusedStocks = availableStocks.filter(stock => !('patterns' in stock))

    let wasteAvailable;
    if (currentWastes) {
        wasteAvailable = Object.keys(currentWastes).length !== 0
    }
    else {
        wasteAvailable = false;
    }

    return (
        <div ref={ref}>
            <div className={`${styles.centerContent}`} style={{ pageBreakAfter: 'always' }}>
                <img src={watermark1} style={{ height: '40px', marginBottom: '10pt' }}></img>
                <h5>{t("Sonuç Özeti")}</h5>
                <table className={`table table-sm table-striped-columns border my-3`}>
                    <tbody>
                        <tr>
                            <th scope="row">{t("Proje")}</th>
                            <td>{cuttingPlanInfo.projectName} ({cuttingPlanInfo.projectId})</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Kesim Planı")}</th>
                            <td>{cuttingPlanInfo.cpName} ({cuttingPlanInfo.cpId})</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Seçilen Profiller")}</th>
                            <td>{currentResultSummary.selectedProfiles.toString()}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Sipariş Boy Sayısı")}</th>
                            <td>{currentResultSummary.totalLogs}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Toplam Ağırlık (kg)")}</th>
                            <td>{currentResultSummary.totalWeight}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Verim Yüzdesi")}</th>
                            <td>{currentResultSummary.yieldPercentage}%</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Fire Yüzdesi")}</th>
                            <td>{currentResultSummary.wastePercentage}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div className="row justify-content-end align-items-center">
                <div className="col-4">
                    <h5 className="text-center">{t("Kesimler")}</h5>
                </div>
                <div className="col-4 text-end">
                    <img src={watermark2} style={{ height: '25px', marginBottom: '10pt' }}></img>
                </div>
            </div>
            {Object.values(patternGroups).map((patternGroup, i) => (
                <React.Fragment key={i}>
                    {i !== 0 && <hr className='my-3' />}
                    <div className='my-2'>
                        <h5 className='fw-bold'>{patternGroup.profileName}</h5>
                        <p>
                            <span className='fw-bold'>{t('Kesim Uzunluğu')}:</span> {patternGroup.logLength} mm &nbsp;|&nbsp; 
                            <span className='fw-bold'>{t('Testere Kalınlığı')}:</span> {patternGroup.bladeThickness} mm
                        </p>
                    </div>
                    {patternGroup.patterns.map((pattern, j) =>
                        <Pattern
                            key={`${i}-${j}`}
                            pattern={pattern}
                            profileLogLength={patternGroup.logLength}
                            showPieceInfo={showPieceInfo}
                            bladeThickness={patternGroup.bladeThickness}
                            ratio={ratio}
                            print={true}
                        />
                    )}
                </React.Fragment>

            ))}

            <div className="row justify-content-end align-items-center">
                <div className="col-4">
                    <h5 className="text-center py-2">{t("Fire Listesi")}</h5>
                </div>
                <div className="col-4 text-end">
                    <img src={watermark2} style={{ height: '25px', marginBottom: '10pt' }}></img>
                </div>
            </div>
            {wasteAvailable ?
                <WasteTable wastes={currentWastes} unusedStocks={unusedStocks} ratio={.8} />
                :
                <p className="text-center">{t("Fire listesi mevcut değil.")}</p>
            }

            <div className="row justify-content-end align-items-center">
                <div className="col-4">
                    <h5 className="text-center py-2">{t("Tüm Sonuç Özeti")}</h5>
                </div>
                <div className="col-4 text-end">
                    <img src={watermark2} style={{ height: '25px', marginBottom: '10pt' }}></img>
                </div>
            </div>

            <p className='fw-bold' style={{fontSize:'14pt'}}>{t("Toplam Sonuç İstatistikleri")}</p>
            <AllSummaryTable allStatistics={allStatistics} fontSize='6pt'/>
            <p className='fw-bold' style={{fontSize:'14pt'}}>{t("Stok İstatistikleri")}</p>
            <StockStatsTable allStatistics={allStatistics} fontSize='6pt'/>
            <p className='fw-bold' style={{fontSize:'14pt'}}>{t("Sipariş İstatistikleri")}</p>
            <OrderStatsTable allStatistics={allStatistics} fontSize='6pt'/>
        </div>
    );
});

export default PrintableCpInfo;