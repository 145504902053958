import React from 'react';
import Container from 'react-bootstrap/Container';

// import lines from './lines.png';
import cross from './cross.png';
import lines from './lines.png'

import { useTranslation } from 'react-i18next';

function generateRGB(num1, num2) {
    // convert inputs to a string
    let str = num1.toString() + num2.toString();

    // hash the input
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // convert the hash to an RGB color
    let color = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color[i] = value;
    }

    // increase contrast if necessary
    let luma = 0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2]; // per ITU-R BT.709
    if (luma < 128) {
        color = color.map(value => value + (128 - luma) / 2);
    }

    // convert color array to CSS color
    let cssColor = 'rgba(' + color.join(',') + ',0.6)';

    return cssColor;
}

function generatePrintGray(num1, num2) {
    // convert inputs to a string
    let str = num1.toString() + num2.toString();

    // hash the input
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // convert the hash to a gray value
    let value = Math.abs(hash) % 256; // This generates a number between 0 and 255

    // invert the color to make it lighter (closer to white)
    value = 255 - value;

    // ensure the color is not too light for printing and has high contrast with black
    if (value > 220) {
        value = 220; // This caps the maximum gray value to 220, preventing very light colors
    }

    // enforce a minimum gray value for high contrast with black
    if (value < 180) {
        value = 180; // This sets the minimum gray value to 180, ensuring the colors will contrast with black
    }

    // create a gray color with the same value for all channels
    let color = [value, value, value];

    // convert color array to CSS color
    let cssColor = 'rgba(' + color.join(',') + ',0.6)';

    return cssColor;
}



const Pattern = ({ floorAvailable, pattern, allFloors, donatiDict, checkedFloors, bindirmeOrani, ratio = 1, print = false, version }) => {
    const { t } = useTranslation();
    
    const cuts = pattern.cuts

    let totalLength = cuts.reduce((sum, cut) => sum + cut.nCuts * cut.cutLength, 0) + pattern.waste;

    const cutLengths = cuts.map((cut) => cut.cutLength)

    let waste = pattern.waste
    let displayWaste;
    if (version === 1){
        displayWaste = pattern.nLogs * pattern.waste
    }
    else{
        displayWaste = pattern.waste
    }

    const isWasted = waste !== 0

    const isOverproduced = 'overproductions' in pattern

    const isSubstituted = 'substitutions' in pattern

    let overproductions = []

    if (isOverproduced) {
        totalLength += pattern.overproductions.reduce((sum, cut) => sum + cut.nCuts * Number(cut.cutLength), 0)
        overproductions = pattern.overproductions
        if(version === 1){
            displayWaste += pattern.overproductions.reduce((sum, cut) => sum + pattern.nLogs * cut.nCuts * Number(cut.cutLength), 0)
        }
    }

    let listOfCuts = cuts.map((item, i) => (
        {
            "cutLength": item.cutLength,
            "nCuts": item.nCuts,
        }
    ))

    if (isSubstituted) {
        const subs = pattern.substitutions

        if (version === 1){
            displayWaste -= subs.reduce((sum, sub) => sum + sub.number * Number(sub.subs_by), 0)
        }

        let listOfSubs = []
        for (let i = 0; i < subs.length; i++) {
            let sub = subs[i]
            if (sub.type === 1) {
                listOfSubs.push({
                    "cutLength": pattern.waste,
                    "nCuts": 1,
                })
                break
            }
        }

        if (isOverproduced) {
            for (let j = 0; j < pattern.overproductions.length; j++) {
                let op = pattern.overproductions[j]
                let list = subs.filter((sub) => (
                    sub.type === 2 && sub.subs_by === op.cutLength
                ))
                if (list.length !== 0) {
                    listOfSubs.push({
                        "cutLength": op.cutLength,
                        "nCuts": op.nCuts,
                    })
                }
            }
        }

        listOfCuts = listOfCuts.concat(listOfSubs)
    }

    let listOfElements = []
    let longElements = []

    if (floorAvailable) {
        let elements = Object.keys(pattern.elements)

        listOfElements = elements.map((element, i) => {
            return [
                pattern.elements[element],
                cutLengths.indexOf(Number(element.split("-")[1])),
                allFloors.indexOf(donatiDict[element]["kat"]),
                donatiDict[element]["yapiElemani"],
                donatiDict[element]["yapiElemaniKodu"],
                donatiDict[element]["aciklama"],
                donatiDict[element]["donatiKodu"],
                donatiDict[element]["poz"],
            ]
        })

        listOfElements.sort((a, b) => ((a[1] - b[1]) || (a[2] - b[2]))) // or ((a[2]-b[2]) || (a[1]-b[1]))) 

        for (let i = 0; i < elements.length; i++) {
            let element = elements[i]
            let info = donatiDict[element]
            if ("kesik" in info) {
                info["mevcutEleman"] = element
                longElements.push(info)
            }
        }
    }

    let fontSize1 = `${12 * ratio}pt`
    let fontSize2 = `${11 * ratio}pt`
    let fontSize3 = `${10 * ratio}pt`
    let fontSize4 = `${9 * ratio}pt`

    let marginSize1 = `${6 * ratio}pt`
    let marginSize2 = `${2 * ratio}pt`
    let marginSize3 = `${1 * ratio}pt`

    let patternBorderBlack = `${marginSize3} solid black`
    let patternBorderGray = `${marginSize3} solid gray`


    if (pattern.nLogs === 0) {
        return <></>
    }

    let floorActive = true
    let minCheckedFloor = Infinity
    if (floorAvailable) {

        let patternFloors = cuts.reduce((acc, cut) => [...acc, ...cut.floors], [])

        const floorsOfPattern = Array.from(new Set(patternFloors)).map(Number);
        const minFloorOfPattern = Math.min(...floorsOfPattern)
        minCheckedFloor = Math.min(...checkedFloors)

        if (minFloorOfPattern < minCheckedFloor) {
            floorActive = false
        }
    }

    let bgColor = "white"
    if (!print) {
        bgColor = floorActive ? 'rgba(248, 249, 250)' : 'rgba(248, 249, 250,0.5)'
    }

    return (
        <div className="rounded" style={{
            backgroundColor: `${bgColor}`,
            marginBottom: `${marginSize1}`,
            padding: `${marginSize2} ${marginSize1}`,
            border: print ? `${marginSize3} solid #808080` : `${marginSize3} solid #dee2e6`
        }}>

            {print ?
                <table
                    className="table table-sm"
                    style={{ marginTop: `${marginSize3}`, width: "100%" }}
                >
                    <tbody>
                        <tr style={{ verticalAlign: "middle", }}>
                            <th style={{ width: '6%', fontSize: `${fontSize1}` }}>{pattern.nLogs}</th>
                            <th style={{ width: '19%', fontWeight: "normal", fontSize: `${fontSize1}` }}>{t("Adet")}</th>
                            <th style={{ width: '50%', fontWeight: "normal", fontSize: `${fontSize1}` }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", alignItems: "center" }}>
                                    {listOfCuts.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#40;</div>
                                                <div style={{ fontWeight: '400', fontSize: `${fontSize1}` }}>{item.nCuts}</div>
                                                <div style={{ fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>x</div>
                                                <div style={{ fontWeight: '500', fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>{item.cutLength}</div>
                                                <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#41;</div>
                                                {index !== listOfCuts.length - 1 ?
                                                    <div style={{ fontSize: `${fontSize1}`, margin: `0pt ${marginSize2}` }}> + </div>
                                                    : <></>}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </th>
                            <th style={{ width: '8%', fontSize: `${fontSize3}` }}>{t("Fire")}:</th>
                            <th style={{ width: '3%', fontSize: `${fontSize2}`, }}>{displayWaste}</th>
                            <td style={{ width: '14%', fontSize: `${fontSize4}`, textAlign: 'end' }}><div>{t("Kesim No:")} {pattern.patternNo}</div></td>
                        </tr>
                    </tbody>
                </table> :
                <div>
                    <div className='table-responsive d-none d-md-block'>
                        <table
                            className={`table table-sm`}
                            style={{ marginTop: `${marginSize3}`, width: "100%" }}
                        >
                            <tbody>
                                <tr style={{ verticalAlign: "middle", }}>
                                    <th style={{ width: '6%', fontSize: `${fontSize1}` }}>{pattern.nLogs}</th>
                                    <th style={{ width: '19%', fontWeight: "normal", fontSize: `${fontSize1}` }}>{t("Adet")}</th>
                                    <th style={{ width: '50%', fontWeight: "normal", fontSize: `${fontSize1}` }}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", alignItems: "center" }}>
                                            {listOfCuts.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#40;</div>
                                                        <div style={{ fontWeight: '400', fontSize: `${fontSize1}` }}>{item.nCuts}</div>
                                                        <div style={{ fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>x</div>
                                                        <div style={{ fontWeight: '500', fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>{item.cutLength}</div>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#41;</div>
                                                        {index !== listOfCuts.length - 1 ?
                                                            <div style={{ fontSize: `${fontSize1}`, margin: `0pt ${marginSize2}` }}> + </div>
                                                            : <></>}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </th>
                                    <th style={{ width: '8%', fontSize: `${fontSize3}` }}>{t("Fire")}:</th>
                                    <th style={{ width: '3%', fontSize: `${fontSize2}`, }}>{displayWaste}</th>
                                    <td style={{ width: '14%', fontSize: `${fontSize4}`, textAlign: 'end' }}><div>{t("Kesim No:")} {pattern.patternNo}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='d-md-none'>

                        <div className='text-center' style={{ marginTop: `${marginSize3}` }}>
                            <span style={{ fontWeight: "bold", fontSize: `${fontSize1}` }}>{pattern.nLogs}</span>&nbsp;&nbsp;&nbsp;
                            <span style={{ fontWeight: "normal", fontSize: `${fontSize1}` }}>{t("Adet")}</span>
                        </div>

                        <table className="table table-sm m-0 table-borderless" >
                            <tbody>
                                <tr style={{ verticalAlign: "middle", textAlign: "center" }}>
                                    <th style={{ fontWeight: "normal", fontSize: `${fontSize1}`, textAlign: 'center' }}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", alignItems: "center" }}>
                                            {listOfCuts.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#40;</div>
                                                        <div style={{ fontWeight: '400', fontSize: `${fontSize1}` }}>{item.nCuts}</div>
                                                        <div style={{ fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>x</div>
                                                        <div style={{ fontWeight: '500', fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>{item.cutLength}</div>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#41;</div>
                                                        {index !== listOfCuts.length - 1 ?
                                                            <div style={{ fontSize: `${fontSize1}`, margin: `0pt ${marginSize2}` }}> + </div>
                                                            : <></>}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>


                        <table className="table table-sm" >
                            <tbody>
                                <tr style={{ verticalAlign: "middle", }}>
                                    <th><div><span style={{ fontSize: `${fontSize3}` }}>{t("Fire")}:&nbsp;</span><span style={{ fontSize: `${fontSize2}`, }}>{displayWaste}</span></div></th>
                                    <td style={{ fontSize: `${fontSize4}`, textAlign: 'end' }}><div>{t("Kesim No:")} {pattern.patternNo}</div></td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </div>

            }


            <div className='table-responsive'>
                <table style={{ width: "100%", marginBottom: "10px" }}>
                    <tbody>
                        <tr>
                            {cuts.map((cut, i) => (
                                Array(cut.nCuts).fill().map((_, j) => {
                                    const length = cut.cutLength
                                    let floor;
                                    let fazlalik = false;
                                    let floorActiveInCut = true;
                                    if (floorAvailable) {
                                        floor = Number(cut.floors[j])
                                        floorActiveInCut = floor >= minCheckedFloor
                                    }
                                    const total = totalLength
                                    let width = (length / total) * 100;

                                    let patternBgColor = floorActiveInCut ? generatePrintGray(width, i) : "white"

                                    if (!print) {
                                        patternBgColor = floorActiveInCut ? generateRGB(width, i) : '#f8f9fa'
                                    }

                                    return (
                                        <th key={`${i}-${j}`} style={{
                                            borderLeft: floorActiveInCut ? `${patternBorderBlack}` : `${patternBorderGray}`,
                                            borderTop: floorActiveInCut ? `${patternBorderBlack}` : `${patternBorderGray}`,
                                            borderBottom: floorActiveInCut ? `${patternBorderBlack}` : `${patternBorderGray}`,
                                            textAlign: 'center',
                                            verticalAlign: 'top',
                                            backgroundColor: patternBgColor,
                                            // backgroundImage: (print && !floorActiveInCut) ? `url('${lines}')` : "",
                                            backgroundPosition: 'center',
                                            backgroundSize: '300pt',
                                            // backgroundRepeat: 'no-repeat',
                                            width: `${width}%`,
                                            fontSize: `${fontSize4}`,
                                            padding: 0,
                                            position: 'relative',
                                            opacity: floorActiveInCut ? 1 : 0.6,
                                            zIndex: floorActiveInCut ? 2 : 1,
                                        }}>
                                            <div> {length} </div>
                                        </th>
                                    )
                                })
                            ))}
                            {overproductions.map((cut, i) => (
                                Array(cut.nCuts).fill().map((_, j) => {
                                    const length = cut.cutLength
                                    const total = totalLength
                                    let width = (length / total) * 100;

                                    let color, bgColor, bgImage = "", shadow = ""
                                    if (print){
                                        color = "black"
                                        bgColor = ""
                                        bgImage = `url('${lines}')`
                                    }
                                    else{
                                        if(version === 1){
                                            color = "white"
                                            bgColor = "red"
                                        }
                                        else{
                                            color = "black"
                                            bgColor = generateRGB(width, i)
                                            bgImage = `url('${lines}')`
                                            shadow = '1px 1px 1px white'
                                        }
                                    }

                                    return (
                                        <th key={`${i}-${j}`} style={{
                                            borderLeft: `${patternBorderBlack}`,
                                            borderTop: `${patternBorderBlack}`,
                                            borderBottom: `${patternBorderBlack}`,
                                            textAlign: 'center',
                                            verticalAlign: 'top',
                                            color: `${color}`,
                                            backgroundColor: `${bgColor}`,
                                            backgroundImage: `${bgImage}`,
                                            backgroundPosition: 'center',
                                            backgroundSize: '150pt',
                                            width: `${width}%`,
                                            fontSize: `${fontSize4}`,
                                            padding: 0,
                                            position: 'relative',
                                        }}>
                                            <div style={{
                                                backgroundColor: print ? "white" : "",
                                                display: 'inline-block',
                                                textShadow: `${shadow}`,
                                            }}> {length} </div>
                                        </th>
                                    )
                                })
                            ))}
                            {isWasted ?
                                <th style={{
                                    border: `${patternBorderBlack}`,
                                    textAlign: 'center',
                                    verticalAlign: 'top',
                                    color: print ? 'black' : 'white',
                                    backgroundColor: print ? "" : 'red',
                                    backgroundImage: print ? `url('${cross}')` : "",
                                    backgroundPosition: 'center',
                                    backgroundSize: '150pt',
                                    width: `${(waste / totalLength) * 100}%`,
                                    fontSize: `${fontSize4}`,
                                    padding: 0,
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        backgroundColor: print ? "white" : "",
                                        display: print ? 'inline-block' : "",
                                    }}> {waste} </div>
                                </th>
                                : <th style={{
                                    width: "0",
                                    borderLeft: `${patternBorderBlack}`,
                                }}></th>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

            {floorAvailable ?
                <div className='table-responsive'>
                    <table className="table table-sm"
                        style={{
                            fontSize: `${fontSize4}`,
                            marginTop: `${marginSize1}`
                        }}>
                        <tbody>
                            <tr>
                                <th scope='col' width='7%'>{t("Adet")}</th>
                                <th scope='col' width='10%'>{t("Uzunluk")}</th>
                                <th scope='col' width='10%'>{t("Poz")}</th>
                                <th scope='col' width='15%'>{t("Donatı Kodu")}</th>
                                <th scope='col' width='15%'>{t("Kat")}</th>
                                <th scope='col' width='18%'>{t("Yapı Elemanı")}</th>
                                <th scope='col' width='12.5%'>{t("Eleman İsmi")}</th>
                                <th scope='col' width='12.5%'>{t("Açıklama")}</th>
                            </tr>
                            {listOfElements.map((element, i) => {
                                let floorActiveInCut = element[2] >= minCheckedFloor
                                if (element[0] !== 0) {
                                    return (
                                        <tr key={i} style={{ opacity: floorActiveInCut ? 1 : 0.5, }}>
                                            <td>{element[0]}</td>
                                            <td>{cutLengths[element[1]]}</td>
                                            <td>{element[7]}</td>
                                            <td>{element[6]}</td>
                                            <td>{allFloors[element[2]]}</td>
                                            <td>{element[3]}</td>
                                            <td>{element[4]}</td>
                                            <td>{element[5]}</td>
                                        </tr>)
                                }

                            })}
                        </tbody>
                    </table>
                </div>
                : <></>}

            {longElements.length > 0 && floorAvailable ?
                <>
                    <div className="border rounded" style={{ margin: `${marginSize1}`, padding: `${marginSize2} ${marginSize1}`}}>
                        <div style={{ fontWeight: "bold", fontSize: `${fontSize1}` }}>{t("Uzun Donatı Parçaları")}</div>
                        <div className='table-responsive'>
                            <table className={`table table-sm`}
                                style={{
                                    fontSize: `${fontSize4}`,
                                    marginTop: { marginSize3 },
                                    verticalAlign: 'middle'
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <th>{t("Kesilen Donatı")}</th>
                                        <th>{t("Bindirme Uzunluğu")}</th>
                                        <th>{t("Asıl Uzunluk")}</th>
                                        <th>{t("Diğer Parçalar")}</th>
                                    </tr>
                                    {longElements.map((element, i) => {
                                        if (element.number !== 0) {
                                            let span = element.kesikParcaKodlari.length
                                            let diameter = Number(element.kesikParcaKodlari[0].split("-")[0])
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr>
                                                        <td rowSpan={span}>{element.mevcutEleman}</td>
                                                        <td rowSpan={span}>{diameter * bindirmeOrani * 4}</td>
                                                        <td rowSpan={span}>{element.toplamUzunluk}</td>
                                                        <td>{element.kesikParcaKodlari[0]}</td>
                                                    </tr>
                                                    {element.kesikParcaKodlari.slice(1,).map((kod, index) => (
                                                        <tr key={`${i}-${index}`}>
                                                            <td>{kod}</td>
                                                        </tr>
                                                    ))}
                                                </React.Fragment>
                                            )
                                        } else {
                                            return <></>
                                        }
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
                : <></>}

            {isSubstituted ?
                <>
                    <div className="border rounded" style={{ margin: `${marginSize1}`, padding:`${marginSize2} ${marginSize1}` }}>
                        <div style={{ fontWeight: "bold", fontSize: `${fontSize1}` }}>{t("Yerine Kullanımlar")}</div>
                        <div className='table-responsive'>
                            <table className={`table table-sm`}
                                style={{
                                    fontSize: `${fontSize4}`,
                                    marginTop: `${marginSize3}`,
                                    verticalAlign: 'middle'
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <th>{t("Adet")}</th>
                                        <th>{t("Uzunluk")}</th>
                                        <th>{t("Yerine Kullanılan")}</th>
                                        {floorAvailable ?
                                            <>
                                                <th>{t("Kat")}</th>
                                                <th>{t("Yapı Elemanı")}</th>
                                                <th>{t("Eleman İsmi")}</th>
                                                <th>{t("Açıklama")}</th>
                                            </> : <></>
                                        }
                                    </tr>
                                    {pattern.substitutions.map((element, i) => {
                                        if (element.number !== 0) {
                                            return (
                                                <tr key={i}>
                                                    <td>{element.number}</td>
                                                    <td>{element.subs_by}</td>
                                                    <td>{element.actual}</td>
                                                    {floorAvailable ?
                                                        <>
                                                            <td>{donatiDict[element.actual].kat}</td>
                                                            <td>{donatiDict[element.actual].yapiElemani}</td>
                                                            <td>{donatiDict[element.actual].yapiElemaniKodu}</td>
                                                            <td>{donatiDict[element.actual].aciklama}</td>
                                                        </>
                                                        : <></>}
                                                </tr>)
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
                : <></>}
        </div>
    );
};

export default Pattern;