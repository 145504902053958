import React from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import styles from './AppNavbar.module.css';

import useUserData from '../../hooks/useUserDataForNavbar';
import { useNavigate } from 'react-router-dom';

import { logoutUser } from '../../services/authService';

import tasnifer1 from '../../img/tasnifer1.png'

import Offcanvas from 'react-bootstrap/Offcanvas';

import { GoProjectRoadmap } from 'react-icons/go'
import { VscAccount } from 'react-icons/vsc'
import { FiLogOut } from 'react-icons/fi'
import { MdOutlineCreate } from 'react-icons/md'
import { GiEclipseSaw } from "react-icons/gi";

import { useLocation } from 'react-router-dom'

import { useTranslation } from "react-i18next";

import { supportedLanguages, defaultLanguage } from '../../i18n';

const AppNavbar = ({ projectId = 0 }) => {
    const { i18n, t } = useTranslation();

    const navigate = useNavigate();

    const location = useLocation();
    const pathname = location.pathname;

    function disabledLink(link) {
        return pathname === link;
    }

    const handleLogout = () => {
        logoutUser(navigate);
    };


    const { email, id, userType, name, isLoading } = useUserData();

    // const langPrefix = i18n.language === defaultLanguage ? "" : `/${i18n.language}`;
    const langPrefix = `/${i18n.language}`

    const handleLanguageChange = (newLang) => {

        const pathLang = window.location.pathname.split('/')[1];
        let pathWithoutLang;

        if (supportedLanguages.includes(pathLang)) {
            pathWithoutLang = window.location.pathname.split('/').slice(2).join('/');
        }
        else {
            pathWithoutLang = window.location.pathname.split('/').slice(1).join('/');
        }

        if (newLang === defaultLanguage) {
            window.location.href = `/${pathWithoutLang}`;
        } else {
            window.location.href = `/${newLang}/${pathWithoutLang}`;
        }

        setTimeout(() => {
            i18n.changeLanguage(newLang);
        }, 1500);

    };

    const changeLanguageDropdown = (
        <NavDropdown
            title={i18n.language.toUpperCase()}
            id="collapsible-nav-dropdown"
            className="my-1"
            drop='down-centered'
            align={"end"}
        >
            <NavDropdown.Header>{t("Dili Değiştirin")}</NavDropdown.Header>
            <NavDropdown.Divider />
            <NavDropdown.Item
                onClick={() => handleLanguageChange("tr")}
            >
                TR-Türkçe
            </NavDropdown.Item>
            <NavDropdown.Item
                onClick={() => handleLanguageChange("en")}
            >
                EN-English
            </NavDropdown.Item>
        </NavDropdown>
    )

    const unauthorizedLinks = (
        <>
            <Nav.Link href={`${langPrefix}/login`} className={`my-1 ${disabledLink(`${langPrefix}/login`) ? styles.disabledLink : ""}`}>{t("Giris Yap")}</Nav.Link>
            <Nav.Link href={`${langPrefix}/signup`} className={`my-1 ${disabledLink(`${langPrefix}/signup`) ? styles.disabledLink : ""}`}>{t("Üye Ol")}</Nav.Link>
            {changeLanguageDropdown}
        </>
    )

    const userExpandedLinks = (
        <>
            {projectId !== 0 ?
                <Nav.Link className={`my-1 text-center ${disabledLink(`${langPrefix}/create-cp/${projectId}`) ? styles.disabledLink : ""}`} href={`${langPrefix}/create-cp/${projectId}`}><GiEclipseSaw /> &nbsp;{t("Kesim Planı Oluştur")}</Nav.Link>
                : <></>}
            <Nav.Link className={`my-1 text-center ${disabledLink(`${langPrefix}/create-project`) ? styles.disabledLink : ""}`} href={`${langPrefix}/create-project`}><MdOutlineCreate /> &nbsp;{t("Proje Oluştur")}</Nav.Link>
            <Nav.Link className={`my-1 text-center ${disabledLink(`${langPrefix}/projects`) ? styles.disabledLink : ""}`} href={`${langPrefix}/projects`}><GoProjectRoadmap /> &nbsp;{t("Projelerim")}</Nav.Link>
            <Nav.Link className={`my-1 text-center ${disabledLink(`${langPrefix}/account`) ? styles.disabledLink : ""}`} href={`${langPrefix}/account`}><VscAccount /> &nbsp;{t("Hesabım")}</Nav.Link>
            <Nav.Link className="my-1 text-center" onClick={handleLogout}>
                <FiLogOut /> &nbsp;{t("Çıkış Yap")}
            </Nav.Link>
            {changeLanguageDropdown}

        </>
    )

    const userShrinkedLinks = (
        <>
            {userType === 3 ? <Nav.Item className='my-1'>{t("Sn. ")}{name.toUpperCase()}, {t("hoş geldiniz.")}</Nav.Item> : <></>}
            <Nav.Item className="my-1 "><u>{email}</u></Nav.Item>
            {projectId !== 0 ?
                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/create-cp/${projectId}`) ? styles.disabledLink : ""}`} href={`${langPrefix}/create-cp/${projectId}`}><GiEclipseSaw /> &nbsp;{t("Kesim Planı Oluştur")}</Nav.Link>
                : <></>}
            <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/create-project`) ? styles.disabledLink : ""}`} href={`${langPrefix}/create-project`}><MdOutlineCreate /> &nbsp;{t("Proje Oluştur")}</Nav.Link>
            <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/projects`) ? styles.disabledLink : ""}`} href={`${langPrefix}/projects`}><GoProjectRoadmap /> &nbsp;{t("Projelerim")}</Nav.Link>
            <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/account`) ? styles.disabledLink : ""}`} href={`${langPrefix}/account`}><VscAccount /> &nbsp;{t("Hesabım")}</Nav.Link>
            <Nav.Link onClick={handleLogout}>
                <FiLogOut /> &nbsp;{t("Çıkış Yap")}
            </Nav.Link>
            {changeLanguageDropdown}
        </>
    )

    const expand = 'lg'

    const userLinks = (
        <>
            <span className={`d-none d-${expand}-flex`}>
                {userExpandedLinks}
            </span>
            <section className={`d-${expand}-none`}>
                {userShrinkedLinks}
            </section>
        </>
    )



    const navLinks = email ? userLinks : unauthorizedLinks

    const loadedLinks = isLoading ? (<></>) : navLinks

    const brandLink = i18n.language === defaultLanguage ? "/" : `/${i18n.language}`;

    return (
        <>
            <Navbar expand={expand} className={`border ${styles.navbarCustom}`} >
                <Container fluid className='mx-4'>
                    <Navbar.Brand href={brandLink} className={`${styles.logoText} ${disabledLink(brandLink) ? styles.disabledLink : ""}`} >
                        <img
                            src={tasnifer1}
                            width="27"
                            height="27"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />{' '}
                        &nbsp;Tasnifer
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Tasnifer
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className={`justify-content-start flex-grow-1 pe-3 d-none d-${expand}-flex`}>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/about-us`) ? styles.disabledLink : ""}`} href={`${langPrefix}/about-us`}>{t("Hakkımızda")}</Nav.Link>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/user-manual`) ? styles.disabledLink : ""}`} href={`${langPrefix}/user-manual`}>{t("Kullanım Rehberi")}</Nav.Link>
                            </Nav>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                {loadedLinks}
                            </Nav>
                            <Nav className={`d-${expand}-none`}>
                                <hr />
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/about-us`) ? styles.disabledLink : ""}`} href={`${langPrefix}/about-us`}>{t("Hakkımızda")}</Nav.Link>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/user-manual`) ? styles.disabledLink : ""}`} href={`${langPrefix}/user-manual`}>{t("Kullanım Rehberi")}</Nav.Link>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/terms-and-conditions`) ? styles.disabledLink : ""}`} href={`${langPrefix}/terms-and-conditions`}>{t("Üyelik Sözleşmesi")}</Nav.Link>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/privacy-policy`) ? styles.disabledLink : ""}`} href={`${langPrefix}/privacy-policy`}>{t("Gizlilik Politikası")}</Nav.Link>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/sale-contract`) ? styles.disabledLink : ""}`} href={`${langPrefix}/sale-contract`}>{t("Satış Sözleşmesi")}</Nav.Link>
                                {/* <Nav.Link className={`my-1 ${disabledLink("/return-contract") ? styles.disabledLink : ""}`} href="/return-contract`}>İade Sözleşmesi</Nav.Link> */}
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/prices`) ? styles.disabledLink : ""}`} href={`${langPrefix}/prices`}>{t("Fiyatlandırma")}</Nav.Link>
                                <Nav.Link className={`my-1 ${disabledLink(`${langPrefix}/contact`) ? styles.disabledLink : ""}`} href={`${langPrefix}/contact`}>{t("İletişim")}</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
            {userType === 3 ?
                <Nav className='justify-content-end d-none d-lg-flex'>
                    <Nav.Item>
                        <Nav.Link className='disabled text-primary'>{t("Sn. Temsilci ")}{name.toUpperCase()}, {t("hoş geldiniz.")}</Nav.Link>
                    </Nav.Item>
                </Nav>
                : <></>}
            {/* {[4, 5].includes(userType) ?
                <Nav className='justify-content-end d-none d-lg-flex'>
                    <Nav.Item>
                        <Nav.Link className='disabled text-primary'>Sn. Yönetici {name.toUpperCase()}, hoş geldiniz.</Nav.Link>
                    </Nav.Item>
                </Nav>
                : <></>} */}
        </>
    )
};

export default AppNavbar;