import { useState } from 'react';

import { sendResetPasswordLink } from '../../services/sendResetPwLinkService';
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const useSendResetPwLink = () => {

    const { i18n, t } = useTranslation();

    const navigate = useNavigate();

    const [errorToast, setErrorToast] = useState({ show: false, message: "" });

    const [isLoading, setIsLoading] = useState(false);

    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === "") {
            setErrorToast({ show: true, message: t("E-posta boş bırakılamaz.") });
        }
        else {
            setIsLoading(true);
            try {
                const response = await sendResetPasswordLink(email)
                if (response.ok) {
                    window.location = `/${i18n.language}/reset-link-sent`
                }
                else {
                    setIsLoading(false)
                    const data = await response.json();
                    if (data.detail === "User not found") {
                        setErrorToast({ show: true, message: t("Bu email adresi ile kayıtlı kullanıcı bulunamadı.") });
                    }
                    else {
                        setErrorToast({ show: true, message: `${t("Sıfırlama maili gönderilemedi. Hata mesajı:")} ${data.detail}` });
                    }
                }
            }
            catch {
                var props = {
                    error_code: "87KZCI",
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }
                
                navigate(`/${i18n.location}/error`, { state: { props: props } })
            }
        }
    };

    return {
        email,
        setEmail,
        handleSubmit,

        errorToast,
        setErrorToast,

        isLoading,
    };
};

export default useSendResetPwLink;
