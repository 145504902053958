import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import styles from './Verification.module.css';
import useVerification from '../../hooks/useVerification/useVerification';

import { FaCheckCircle } from 'react-icons/fa'; // use react-icons for this
import { HiXCircle } from 'react-icons/hi'; // use react-icons for this

import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import { useTranslation } from 'react-i18next';

const AfterVerification = () => {

    const {
        isLoading,
        verifyNote,
    } = useVerification();

    const { t } = useTranslation();

    document.title = t("E-posta Doğrulama")

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>

                {isLoading ? LoadingContainer :
                    <>
                        {"state" in verifyNote ?
                            <Container className={`bg-white mt-5 p-5 shadow-sm ${styles.verifyContainer}`}>
                                {verifyNote.state === "danger" ?
                                    <HiXCircle size={70} color="red" className={styles.checkIcon} /> :
                                    <FaCheckCircle size={70} color="green" className={styles.checkIcon} />
                                }
                                {verifyNote.state === "danger" ?
                                    <h2 className={styles.title}>{t("Hata")}</h2> :
                                    <h2 className={styles.title}>{t("E-posta Doğrulama Başarılı")}</h2>
                                }
                                <p className={styles.message}>{verifyNote.note}</p>
                            </Container>
                            : <></>}
                    </>
                }
            </div>
            <Footer />
        </div>
    );
};

export default AfterVerification;
