import apiClient from "./apiClient";

export const metalProfileInfoUpdate = async (
    id,
    updatedProfiles,
) => {
    const url = `${process.env.REACT_APP_API_URL}/api/update-metal-profiles/${id}`;

    const response = await apiClient(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "updated_profiles" : updatedProfiles
        }),
    });

    return response;

}