import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

const BackgroundCpInfo = ({
    userType,
    ownCp,
    owner,
    result,
}) => {

    const { i18n, t } = useTranslation();

    const cpId = result.cpId
    const cpName = result.cpName
    const projectId = result.projectId
    const projectName = result.projectName
    const minOutageLength = result.minOutageLength
    const useSubstitutes = result.useSubstitutes
    const diameters = result.diameters
    let status = result.status
    if(status === 'pending'){
        status = t('Sıraya alındı')
    }
    else if(status === 'processing'){
        status = t('Hesaplanıyor...')
    }
    else{
        status = t('Tamamlandı')
    }
    const floorAvailable = "floors" in result
    let floors;
    if (floorAvailable) {
        floors = result.floors;
    }

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    return (
        <>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Container>

                <Row>
                    <Col md={4}>
                        <table className="table table-striped-columns border my-3 align-middle bg-white">
                            <tbody>
                                <tr>
                                    <th style={{ width: "75%" }}>{t("Kesim Planı Numarası")}</th>
                                    <td style={{ width: "25%" }}>{cpId}</td>
                                </tr>
                                <tr>
                                    <th>{t("Elde kalacak en kısa fire")}</th>
                                    <td>{minOutageLength}</td>
                                </tr>
                                <tr>
                                    <th>{t("Yerine kullanım")}</th>
                                    <td>{useSubstitutes ? t('Evet') : t('Hayır')}</td>
                                </tr>
                            </tbody>
                        </table>

                    </Col>
                    <Col md={8}>
                        <table className="table table-striped-columns border my-3 align-middle bg-white">
                            <tbody>
                                <tr>
                                    <th>{t("Çözüme dahil olan çaplar")}</th>
                                    <td>{diameters.map((d, i) => {
                                        if (diameters.indexOf(d) === diameters.length - 1) {
                                            return <span key={i}>Φ{d}</span>
                                        } else {
                                            return <span key={i}>Φ{d}, </span>
                                        }
                                    })}</td>
                                </tr>
                                <tr>
                                    <th>{t("Çözüme dahil olan katlar")}</th>
                                    <td>{floors.map((d, i) => {
                                        if (floors.indexOf(d) === floors.length - 1) {
                                            return <span key={i}>{d}</span>
                                        } else {
                                            return <span key={i}>{d}, </span>
                                        }
                                    })}</td>
                                </tr>
                                <tr>
                                    <th style={{ width: "75%" }}>{t("Durum")}</th>
                                    <td style={{ width: "25%" }}>{status}</td>
                                </tr>
                            </tbody>
                        </table>

                    </Col>
                </Row>

                <Container
                    style={{
                        marginTop: "2rem",
                        padding: "1rem",
                        fontSize: "1.5rem",
                        textAlign: "center",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        border: "1px solid #e0e0e0",
                    }}
                >

                    <p className='p-0 m-0'>
                        {t("Bu kesim planı çok sayıda ve tipte parça içerdiği için")} <span className='fw-bold'>{t("çözüm arka planda yapılıyor.")}</span>
                    </p>
                    <p className='p-0 m-0'>
                        {t("Çözüm tamamlandığında")} <span className='fw-bold'>{t("bu sayfayı yenileyerek")}</span> {t("sonucu görebilirsiniz.")}
                    </p>

                </Container>

            </Container>
        </>
    )
}

export default BackgroundCpInfo;
