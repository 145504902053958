import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import styles from './CpInfo.module.css';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';

const InactiveCpInfo = ({
    result,
    cuttingPlanInfo,
    allProfilesInfo,
    projectUnitPrice,
    unitDiscount,
    purchasable,
    ownCp,
}) => {

    const nFormat = new Intl.NumberFormat();

    function format(item) {
        return nFormat.format(item);
    }

    const [open, setOpen] = useState(false)

    const cpId = cuttingPlanInfo.cpId
    const cpName = cuttingPlanInfo.cpName
    const projectId = cuttingPlanInfo.projectId
    const projectName = cuttingPlanInfo.projectName
    const creationTime = cuttingPlanInfo.creationTime
    const projectTheoreticalWeight = cuttingPlanInfo.projectTheoreticalWeight
    const projectTheoreticalLength = cuttingPlanInfo.projectTheoreticalLength
    // const allLogWeight = cuttingPlanInfo.allLogWeight
    // const allWastedWeight = cuttingPlanInfo.allWastedWeight
    // const percentYield = cuttingPlanInfo.percentYield

    const { i18n, t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col md={{ offset: 1, span: 10 }}>
                    <table className="table table-striped-columns border my-3 align-middle bg-white">
                        <tbody>
                            <tr>
                                <th style={{ width: "75%" }}>{t("Kesim Planı Numarası")}</th>
                                <td style={{ width: "25%" }}>{cpId}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "45%" }}>{t("Toplam Ağırlık")} (kg)</th>
                                <td style={{ width: "65%" }} rowSpan={3} className='text-center'>{t("Bu istatistikleri ve profil bazında diğer detaylı istatistikleri öğrenmek için ödeme yapınız.")}</td>
                                {/* <td style={{ width: "65%" }}>{format(allLogWeight)}</td> */}
                            </tr>
                            <tr>
                                <th>{t("Fire Ağırlığı")} (kg)</th>
                                {/* <td>{format(allWastedWeight)}</td> */}
                            </tr>
                            <tr>
                                <th>{t("Verim Yüzdesi")}</th>
                                {/* <td>{percentYield}%</td> */}
                            </tr>
                        </tbody>
                    </table>

                </Col>
            </Row>
            <Row><Col md={{ offset: 1, span: 10 }}><Container className='p-2 mb-3' style={{
                border: "1px solid #ced4db",
                backgroundColor: 'white'
            }}>
                <Row className="align-items-center" onClick={() => setOpen(!open)}>
                    <Col lg={11} xs={10} className='m-0 ps-4'>
                        <span
                            style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold"
                            }}
                        >
                            {t("Profil bilgileri")}
                        </span>
                    </Col>
                    <Col lg={1} xs={2}>
                        {open ? (
                            <FiChevronUp
                                style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
                            />
                        ) : (
                            <FiChevronDown
                                style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
                            />
                        )}
                    </Col>
                </Row>
                <Collapse in={open}>
                    <div className="p-2 table-responsive">
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: '20%' }}>{t("Profil")}</th>
                                    <th style={{ width: '15%' }}>{t("Malzeme")}</th>
                                    <th style={{ width: '15%' }}>{t("Optimizasyona Dahil")}</th>
                                    <th style={{ width: '20%' }}>{t("Kesim Yapılan Boy")} (mm)</th>
                                    <th style={{ width: '15%' }}>{t("Testere Kalınlığı")} (mm)</th>
                                    <th style={{ width: '15%' }}>{t("Birim Ağırlık")} (kg/m)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allProfilesInfo.map((profile, index) => {
                                    let include = t('Hayır')
                                    const trueValues = [true, 'true','True', 1, '1']
                                    if (trueValues.includes(profile.include_in_optimization)) {
                                        include = t('Evet')
                                    }
                                    return <tr key={index}>
                                        <th>{profile.profile}</th>
                                        <td>{profile.grade}</td>
                                        <td>{include}</td>
                                        <td>{profile.stock_length}</td>
                                        <td>{profile.blade_thickness}</td>
                                        <td>{profile.kg_weight_per_meter.toFixed(2)}</td>
                                    </tr>

                                })}
                            </tbody>

                        </Table>
                    </div>
                </Collapse>
            </Container></Col></Row>
            <Row>
                {ownCp ?
                    purchasable ?
                        <Col md={{ offset: 1, span: 10 }}>
                            <Container className='text-center border p-2 mb-3 bg-white'>
                                <p>{t("Bu, kesim planının özetidir. Detaylı kesim planını elde etmek için bu proje için kesim planı oluşturma hakkını satın almanız gerekmektedir.")}</p>
                                <p><span className='fw-bold text-danger'>{t("NOT")}:</span> {t("Satın alım işlemini sadece bu kesim planı için değil, kesim planının ait olduğu projenin bütün kesim planları için yapmış olacaksınız. Ödemenizin onaylanmasının ardından bu kesim planıyla birlikte")} <a href={`/${i18n.language}/project-info/${projectId}`}>{projectName}</a> {t("isimli projenizde oluşturduğunuz ve oluşturacağınız bütün kesim planlarına erişebileceksiniz.")} </p>
                            </Container>
                        </Col>
                        :
                        <Col md={{ offset: 1, span: 10 }}>
                            <Container className='text-center border p-2 mb-3 bg-white'>
                                {t("Bu, kesim planının özetidir. Detaylı kesim planını elde etmek için bu proje için kesim planı oluşturma hakkını satın almanız gerekmektedir. Daha önceden bu proje için oluşturmuş olduğunuz")} <a href={`/${i18n.language}/project-purchase/${projectId}`}>{t("siparişinizin")}</a> {t("onaylanmasını bekleyiniz.")}
                            </Container>
                        </Col>
                    : <></>
                }
            </Row>

            {purchasable ?

                <Row className='text-center'>
                    <Col md={{ offset: 3, span: 6 }}>
                        <Button style={{ backgroundColor: '#318CE7' }} className='p-3 border' href={`/${i18n.language}/project-purchase/${projectId}`}>
                            <h5>{t("inactivecpinfo_paymentmsg1")} ({projectName}) {t("inactivecpinfo_paymentmsg2")}</h5>
                            <div>
                                {unitDiscount !== 0 ?
                                    <>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <s style={{ fontSize: "17pt", fontWeight: "400", alignItems: "center" }} >{Math.floor(projectTheoreticalLength * (unitDiscount + projectUnitPrice) * 1e-3)}₺</s>
                                            <s style={{ fontSize: "6pt", marginLeft: "10px" }}> ({(unitDiscount + projectUnitPrice)} ₺/1000m)</s>
                                            <span className='ms-2'>{t("inactive_yerine")}</span>
                                        </div>
                                    </>
                                    :
                                    <></>}
                                <div className='mb-2'>
                                    <span style={{ fontSize: "32pt", fontWeight: "600" }}>{Math.floor(projectTheoreticalLength * projectUnitPrice * 1e-3)}₺</span>
                                    <div style={{ fontSize: "9pt" }}> (({(projectTheoreticalLength / 1000).toFixed(3)} x1000m) &times; {projectUnitPrice} ₺/1000m)</div>
                                </div>
                            </div>

                            <p>{t("Ödeme yaptıktan sonra bu proje için istediğiniz kadar kesim planı oluşturabilir ve detaylarını elde edebilirsiniz.")}</p>
                        </Button>
                    </Col>

                    {/* <Col md={{ span: 5 }}>
                        <Button style={{ backgroundColor: '#0073ba', height:"230px" }} className='p-3 border'>
                            <h5>Kredi satın al</h5>
                            <h3>Fiyat bilgisi için tıklayınız</h3>
                            <p>Birim ağırlık başına daha az ücret ödeyerek toplu optimizasyon hakkı (kredi) satın alabilir, kredilerinizi gelecek projelerinizde de kullanabilirsiniz. Detaylı bilgi için tıklayınız.</p>
                        </Button>
                    </Col> */}
                </Row>

                : <></>}

        </Container>
    )
}

export default InactiveCpInfo;
