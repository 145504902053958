import React from 'react';
import { useTranslation } from 'react-i18next';
import TermsAndConditionsTr from './localePages/TermsAndConditionsTr';
import TermsAndConditionsEn from './localePages/TermsAndConditionsEn';

const TermsAndConditions = () => {

    const { i18n } = useTranslation();

    return (
        i18n.language === 'tr' ? <TermsAndConditionsTr /> : <TermsAndConditionsEn />
    );
};

export default TermsAndConditions;
