import React from 'react';

import useCpInfo from '../../../hooks/useCpInfo/useMetalProfileCpInfo';
import InactiveCpInfo from './MetalProfileInactiveCpInfo';

import ActiveCpInfo from './MetalProfileActiveCpInfo';
import BackgroundCpInfo from './MetalProfileBackgroundCpInfo';
import CpInfoHeader from './MetalProfileCpInfoHeader';

const MetalProfileCpInfo = ({
    id,
    result,
    projectUnitPrice,
    unitDiscount,
    unitPriceType,
    purchasable,
    userType,
    ownCp,
    owner,
    backgroundTask
}) => {

    const {
        isComponentLoading,

        allPieces,
        allProfilesInfo,

        availableStocks,

        profilesForCheckboxList,
        
        setCheckedProfiles,

        currentPatterns,
        currentWastes,
        currentResultSummary,
        allStatistics,
        cuttingPlanInfo,

        showPieceInfo,
        setShowPieceInfo,
    } = useCpInfo(id, result, userType);

    let CpInfoContainer;

    const CpHeader = (
        <CpInfoHeader
            id={id}
            userType={userType}
            activated={result.activated}
            backgroundTask={backgroundTask}
            ownCp={ownCp}
            owner={owner}
        
            result={result}

            patternGroups={currentPatterns}
            cuttingPlanInfo={cuttingPlanInfo}
            currentWastes={currentWastes}
            currentResultSummary={currentResultSummary}
            allStatistics={allStatistics}
            allProfilesInfo={allProfilesInfo}
            availableStocks={availableStocks}
            showPieceInfoInPrint={showPieceInfo}
        />
    )

    if (isComponentLoading) {
        CpInfoContainer = <></>
    }

    else if (backgroundTask) {
        CpInfoContainer = <>
            {CpHeader}
            <BackgroundCpInfo
                cuttingPlanInfo={cuttingPlanInfo}
                allProfilesInfo={allProfilesInfo}
            />
        </>
    }
    else {
        if ([4, 5].includes(userType) || result.activated) {
            CpInfoContainer = <>
                {CpHeader}
                <ActiveCpInfo
                    allPieces={allPieces}
                    allProfilesInfo={allProfilesInfo}
                    availableStocks={availableStocks}
                    profilesForCheckboxList={profilesForCheckboxList}
                    setCheckedProfiles={setCheckedProfiles}
                    currentPatterns={currentPatterns}
                    currentWastes={currentWastes}
                    currentResultSummary={currentResultSummary}
                    allStatistics={allStatistics}
                    cuttingPlanInfo={cuttingPlanInfo}
                    handleCheckboxChange={setCheckedProfiles}
                    showPieceInfoInPatterns={showPieceInfo}
                    setShowPieceInfoInPatterns={setShowPieceInfo}
                />
            </>
        }
        else {
            CpInfoContainer = <>
                {CpHeader}
                <InactiveCpInfo
                    result={result}
                    cuttingPlanInfo={cuttingPlanInfo}
                    allProfilesInfo={allProfilesInfo}
                    projectUnitPrice={projectUnitPrice}
                    unitDiscount={unitDiscount}
                    purchasable={purchasable}
                    ownCp={ownCp}
                />
            </>
        }
    }

    return  CpInfoContainer;
};

export default MetalProfileCpInfo;
