import { useState, useEffect } from 'react';
import { getUserData } from '../../services/userService';
import { checkIfUserActive } from '../../services/isUserActiveService';
import { logoutUser } from '../../services/authService';
import { sendAdminAuthCode } from '../../services/adminServices/sendAdminAuthCode';
import { verifyAdminAuthCode } from '../../services/adminServices/verifyAdminAuthCode';
import { useNavigate } from "react-router-dom";

const useAdminAuth = () => {
    const navigate = useNavigate();
    const [pageLoading, setPageLoading] = useState(true)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [passwordLoading, setPasswordLoading] = useState(false)
    const [passwordNote, setPasswordNote] = useState("")

    const [passwordCorrect, setPasswordCorrect] = useState(false)
    const [authCode, setAuthCode] = useState("")

    const [codeLoading, setCodeLoading] = useState(false)
    const [wrongCodes, setWrongCodes] = useState(0)
    const [codeNote, setCodeNote] = useState("")
    const [codeFinished, setCodeFinished] = useState(false);

    const [authorized, setAuthorized] = useState(false)

    const wrongPasswordLimit = 5
    const wrongCodesLimit = 5

    useEffect(() => {
        const fetchData = async () => {
            let userInfoResponse
            try {
                userInfoResponse = await getUserData();
            }
            catch (e) {
                // getUserError
            }
            const _email = userInfoResponse.email
            const _userType = userInfoResponse.user_type
            if (_userType !== 5) {
                return
            }
            setEmail(_email)
            setPageLoading(false)
        }

        fetchData();
    }, []);

    const handlePasswordSubmitted = async () => {
        setPasswordLoading(true);
        let passwordResponse
        try {
            passwordResponse = await checkIfUserActive(email, password);
        }
        catch {
            if (passwordResponse.status !== 401) {
                var props = {
                    error_code: "46GK3H",
                    detail: "Sistem Hatası.",
                }
                navigate("/error", { state: { props: props } })
            }
        }
        if (passwordResponse.ok) {
            setPasswordLoading(false);
            setPasswordCorrect(true);
            let sendCodeResponse
            try {
                sendCodeResponse = await sendAdminAuthCode()
            }
            catch {
                var props = {
                    error_code: "UR5ZVJ",
                    detail: "Sistem Hatası.",
                }
                navigate("/error", { state: { props: props } })
            }
            if (!(sendCodeResponse.ok)) {
                var props = {
                    error_code: "XX7QV1",
                    detail: "Sistem Hatası.",
                }
                navigate("/error", { state: { props: props } })
            }
        }
        else {
            let info
            try {
                info = await passwordResponse.json();
            }
            catch {
                var props = {
                    error_code: "X4SQSF",
                    detail: "Sistem Hatası.",
                }
                navigate("/error", { state: { props: props } })
            }
            const wrongPasswords = info.failed_attempt
            if (wrongPasswords === wrongPasswordLimit) {
                logoutUser()
            } else {
                setPasswordNote(`Şifre yanlış. Kalan deneme hakkı: ${wrongPasswordLimit - wrongPasswords}`)
                setPasswordLoading(false);
            }
        }
    }

    const handleCodeSubmitted = async (code) => {
        setCodeLoading(true);

        let verifyCodeResponse
        try {
            verifyCodeResponse = await verifyAdminAuthCode(code);
        }
        catch {
            var props = {
                error_code: "ORS83K",
                detail: "Sistem Hatası.",
            }
            navigate("/error", { state: { props: props } })
        }
        if (verifyCodeResponse.ok) {
            setCodeLoading(false)
            setCodeFinished(true)
            setAuthorized(true)
        }
        else {
            const data = await verifyCodeResponse.json();
            if (data.detail === "wrong code") {
                setCodeLoading(false);
                const _wrongCodes = wrongCodes + 1
                if (_wrongCodes === wrongCodesLimit) {
                    setCodeFinished(true);
                    logoutUser()
                } else {
                    setCodeNote(`Kod yanlış. Kalan deneme hakkı: ${wrongCodesLimit - _wrongCodes}`)
                    setWrongCodes(_wrongCodes)
                }
            }
            else if (data.detail === "signature expired") {
                setCodeLoading(false);
                setCodeFinished(true);
                setCodeNote("Kod geçerlilik süresi geçmiş. Sayfayı yenileyerek tekrar giriş yapmayı deneyin.")
            }
            else {
                setCodeLoading(false);
                setCodeFinished(true);
                var props = {
                    error_code: "F7VTQ8",
                    detail: "Sistem Hatası.",
                }
                navigate("/error", { state: { props: props } })
            }
        }
    }

    return {
        pageLoading,
        email,
        password,
        setPassword,
        handlePasswordSubmitted,
        passwordLoading,
        passwordNote,
        passwordCorrect,
        authCode,
        setAuthCode,
        handleCodeSubmitted,
        codeLoading,
        codeNote,
        codeFinished,
        authorized,
    }
}

export default useAdminAuth;