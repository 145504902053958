// You can call the refreshAccessToken function when your 
// API returns a 401 Unauthorized response, indicating that 
// the access token has expired. One approach is to create a 
// custom API client that automatically handles token refreshing 
// when needed. Here's an example using the fetch function:

// services/apiClient.js
import { refreshAccessToken } from './authService';
import i18n from '../i18n';

const apiClient = async (url, options) => {
    let access = localStorage.getItem("ca_a")

    let defaultHeaders = {
        'Authorization': `Bearer ${access}`,
        ...options.headers,
    };

    if (!('Accept-Language' in defaultHeaders)){
        defaultHeaders['Accept-Language'] = i18n.language || 'tr'
    }

    // Only set the 'Content-Type' header to 'application/json' if it hasn't been specified
    if (!options.headers || !options.headers['Content-Type']) {
        // If body is not FormData, set 'Content-Type' to 'application/json'
        if (!(options.body instanceof FormData)) {
            defaultHeaders['Content-Type'] = 'application/json';
        }
    }

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    defaultHeaders["X-CSRFToken"] = getCookie('csrftoken')

    const response = await fetch(url, {
        ...options,
        headers: defaultHeaders,
    });

    if (response.ok){
        return response;
    }
    else{
        if (response.status === 401) {
            // If the response is 401 Unauthorized, try refreshing the access token
            try {
                await refreshAccessToken();
                access = localStorage.getItem("ca_a")
    
                // Retry the original request with the new access token
                const retryResponse = await fetch(url, {
                    ...options,
                    headers: {
                        ...defaultHeaders,
                        'Authorization': `Bearer ${access}`,
                    },
                });
    
                return retryResponse;
    
            } catch (error) {
                // If refreshing the token fails, clear the tokens and redirect to the login page
                localStorage.removeItem("ca_a")
                localStorage.removeItem("ca_r")
                window.location = `/${i18n.language}/login?try-again=true`
            }
        } else {
                return response;
        }
    }


};

export default apiClient;
