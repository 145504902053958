import React, { useMemo } from "react";
import Container from 'react-bootstrap/Container';
import { MaterialReactTable } from 'material-react-table';

const AssignedUsersPurchases = ({
    assignedUsersPayments,
    tableKeys,
}) => {

    const columns = useMemo(
        () => tableKeys, [tableKeys],
    );

    return (
        <Container className="border-start border-end border-bottom p-2">

            <MaterialReactTable
                columns={columns}
                data={assignedUsersPayments}
                muiTableBodyRowProps={({ row }) => ({
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })} />
        </Container>
    )
}

export default AssignedUsersPurchases;