import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';

import { useTranslation } from 'react-i18next';

function sum(array, return_type = 'string') {
    const number_array = array.map(item => Number(item))
    const total = number_array.reduce((partialSum, a) => partialSum + a, 0)
    return total
}

const nFormat = new Intl.NumberFormat();

function format(item) {
    return nFormat.format(item);
}

export const AllSummaryTable = ({ allStatistics, fontSize='12pt' }) => {
    const { t } = useTranslation();

    const allLogsUsed = sum(allStatistics.map(item => item.totalLogsUsed))
    const allLogLength = format(sum(allStatistics.map(item => item.totalLogLength)))
    const allLogWeight = format(sum(allStatistics.map(item => item.totalLogWeight)))
    const allUsedLength = format(sum(allStatistics.map(item => item.totalUsedLength)))
    const allUsedWeight = format(sum(allStatistics.map(item => item.totalUsedWeight)))
    const allWaste = format(sum(allStatistics.map(item => item.totalWaste)))
    const allWastedWeight = format(sum(allStatistics.map(item => item.totalWastedWeight)))
    const allYieldPercentage = (sum(allStatistics.map(item => item.totalUsedWeight)) / sum(allStatistics.map(item => item.totalLogWeight)) * 100).toFixed(2)
    const allWastedPercentage = (100 - sum(allStatistics.map(item => item.totalUsedWeight)) / sum(allStatistics.map(item => item.totalLogWeight)) * 100).toFixed(2)

    return <div className='table-responsive' style={{fontSize: fontSize}}>
        <table className={`table border text-center align-middle`}>
            <thead>
                <tr>
                    <th>{t("Profil")}</th>
                    <th>{t("Toplam Boy Uzunluğu")} (m)</th>
                    <th>{t("Toplam Ağırlık")} (kg)</th>
                    <th>{t("Toplam Kullanılan Uzunluk")} (m)</th>
                    <th>{t("Toplam Kullanılan Ağırlık")} (kg)</th>
                    <th>{t("Toplam Fire Uzunluğu")} (m)</th>
                    <th>{t("Toplam Fire Ağırlığı")} (kg)</th>
                    <th>{t("Verim Yüzdesi")}</th>
                    <th>{t("Fire Yüzdesi")}</th>
                </tr>
            </thead>
            <tbody>
                {allStatistics.map((item, index) => (
                    <tr key={index}>
                        <td>{item.profileName}</td>
                        <td>{nFormat.format(item.totalLogLength)}</td>
                        <td>{nFormat.format(item.totalLogWeight)}</td>
                        <td>{nFormat.format(item.totalUsedLength)}</td>
                        <td>{nFormat.format(item.totalUsedWeight)}</td>
                        <td>{nFormat.format(item.totalWaste)}</td>
                        <td>{nFormat.format(item.totalWastedWeight)}</td>
                        <td>{item.usedPercentage}%</td>
                        <td>{item.wastedPercentage}%</td>
                    </tr>
                ))}
                <tr>
                    <th>{t("TOPLAM")}</th>
                    <th>{allLogLength}</th>
                    <th>{allLogWeight}</th>
                    <th>{allUsedLength}</th>
                    <th>{allUsedWeight}</th>
                    <th>{allWaste}</th>
                    <th>{allWastedWeight}</th>
                    <th>{allYieldPercentage}%</th>
                    <th>{allWastedPercentage}%</th>
                </tr>
            </tbody>
        </table>
    </div>
}

export const StockStatsTable = ({ allStatistics, fontSize='12pt' }) => {
    const { t } = useTranslation();

    const totalStockPiecesUsed = sum(allStatistics.map(item => item.stockUsed))
    const totalStockLength = format(sum(allStatistics.map(item => item.stockLength)))
    const totalStockWeight = format(sum(allStatistics.map(item => item.stockWeight)))
    const totalAllStockWeight = format(sum(allStatistics.map(item => item.allStockWeight)))
    const totalUsedStockLength = format(sum(allStatistics.map(item => item.stockUsedLength)))
    const totalUsedStockWeight = format(sum(allStatistics.map(item => item.stockUsedWeight)))
    const totalStockWaste = format(sum(allStatistics.map(item => item.stockWaste)))
    const totalStockWastedWeight = format(sum(allStatistics.map(item => item.stockWastedWeight)))
    const totalStockYieldPercentage = (sum(allStatistics.map(item => item.stockUsedWeight)) / sum(allStatistics.map(item => item.stockWeight)) * 100).toFixed(2)
    const totalWastedPercentage = (100 - sum(allStatistics.map(item => item.stockUsedWeight)) / sum(allStatistics.map(item => item.stockWeight)) * 100).toFixed(2)
    const totalAllStockYieldPercentage = (sum(allStatistics.map(item => item.stockUsedWeight)) / sum(allStatistics.map(item => item.allStockWeight)) * 100).toFixed(2)

    return <div className='table-responsive' style={{fontSize: fontSize}}>
        <table className={`table border text-center align-middle`}>
            <thead>
                <tr>
                    <th>{t("Profil")}</th>
                    <th>{t("Stoktan Kullanılan Parça Sayısı")}</th>
                    <th>{t("Stoktan Kullanılan Parça Uzunluğu")} (m)</th>
                    <th>{t("Stoktan Kullanılan Parça Ağırlığı")} (kg)</th>
                    <th>{t("Toplam Stok Ağırlığı")} (kg)</th>
                    <th>{t("Stoktan Yerleştirilen Uzunluk")} (m)</th>
                    <th>{t("Stoktan Yerleştirilen Ağırlık")} (kg)</th>
                    <th>{t("Stoktan Kullanılan Parçaların Fire Uzunluğu")} (m)</th>
                    <th>{t("Stoktan Kullanılan Parçaların Fire Ağırlığı")} (kg)</th>
                    <th>{t("Kullanılan Stok Verim Yüzdesi")}</th>
                    <th>{t("Kullanılan Stok Fire Yüzdesi")}</th>
                    <th>{t("Toplam Stok Verim Yüzdesi")}</th>
                </tr>
            </thead>
            <tbody>
                {allStatistics.map((item, index) => (
                    <tr key={index}>
                        <td>{item.profileName}</td>
                        <td>{item.stockUsed}</td>
                        <td>{nFormat.format(item.stockLength)}</td>
                        <td>{nFormat.format(item.stockWeight)}</td>
                        <td>{nFormat.format(item.allStockWeight)}</td>
                        <td>{nFormat.format(item.stockUsedLength)}</td>
                        <td>{nFormat.format(item.stockUsedWeight)}</td>
                        <td>{nFormat.format(item.stockWaste)}</td>
                        <td>{nFormat.format(item.stockWastedWeight)}</td>
                        <td>{item.stockUsedPercentage}%</td>
                        <td>{item.stockWastedPercentage}%</td>
                        <td>{item.allStockUsedPercentage}%</td>
                    </tr>
                ))}
                <tr>
                    <th>{t("TOPLAM")}</th>
                    <th>{totalStockPiecesUsed}</th>
                    <th>{totalStockLength}</th>
                    <th>{totalStockWeight}</th>
                    <th>{totalAllStockWeight}</th>
                    <th>{totalUsedStockLength}</th>
                    <th>{totalUsedStockWeight}</th>
                    <th>{totalStockWaste}</th>
                    <th>{totalStockWastedWeight}</th>
                    <th>{totalStockYieldPercentage}%</th>
                    <th>{totalWastedPercentage}%</th>
                    <th>{totalAllStockYieldPercentage}%</th>
                </tr>
            </tbody>
        </table>
    </div>
}


export const OrderStatsTable = ({ allStatistics, fontSize='12pt' }) => {
    const { t } = useTranslation();

    const allLogsUsed = sum(allStatistics.map(item => item.orderLogsUsed))
    const allLogLength = format(sum(allStatistics.map(item => item.orderLength)))
    const allLogWeight = format(sum(allStatistics.map(item => item.orderLogWeight)))
    const allUsedLength = format(sum(allStatistics.map(item => item.orderUsedLength)))
    const allUsedWeight = format(sum(allStatistics.map(item => item.orderUsedWeight)))
    const allWaste = format(sum(allStatistics.map(item => item.orderWaste)))
    const allWastedWeight = format(sum(allStatistics.map(item => item.orderWastedWeight)))
    const allYieldPercentage = (sum(allStatistics.map(item => item.orderUsedWeight)) / sum(allStatistics.map(item => item.orderLogWeight)) * 100).toFixed(2)
    const allWastedPercentage = (100 - sum(allStatistics.map(item => item.orderUsedWeight)) / sum(allStatistics.map(item => item.orderLogWeight)) * 100).toFixed(2)

    return <div className='table-responsive' style={{fontSize: fontSize}}>
        <table className={`table border text-center align-middle`}>
            <thead>
                <tr>
                    <th>{t("Profil")}</th>
                    <th>{t("Sipariş Boy Sayısı")}</th>
                    <th>{t("Sipariş Boy Uzunluğu")} (m)</th>
                    <th>{t("Sipariş Ağırlığı")} (kg)</th>
                    <th>{t("Siparişten Yerleştirilen Uzunluk")} (m)</th>
                    <th>{t("Siparişten Yerleştirilen Ağırlık")} (kg)</th>
                    <th>{t("Sipariş Parçaların Fire Uzunluğu")} (m)</th>
                    <th>{t("Sipariş Parçaların Fire Ağırlığı")} (kg)</th>
                    <th>{t("Sipariş Verim Yüzdesi")}</th>
                    <th>{t("Sipariş Fire Yüzdesi")}</th>
                </tr>
            </thead>
            <tbody>
                {allStatistics.map((item, index) => (
                    <tr key={index}>
                        <td>{item.profileName}</td>
                        <td>{item.orderLogsUsed}</td>
                        <td>{nFormat.format(item.orderLength)}</td>
                        <td>{nFormat.format(item.orderLogWeight)}</td>
                        <td>{nFormat.format(item.orderUsedLength)}</td>
                        <td>{nFormat.format(item.orderUsedWeight)}</td>
                        <td>{nFormat.format(item.orderWaste)}</td>
                        <td>{nFormat.format(item.orderWastedWeight)}</td>
                        <td>{item.orderUsedPercentage}%</td>
                        <td>{item.orderWastedPercentage}%</td>
                    </tr>
                ))}
                <tr>
                    <th>{t("TOPLAM")}</th>
                    <th>{allLogsUsed}</th>
                    <th>{allLogLength}</th>
                    <th>{allLogWeight}</th>
                    <th>{allUsedLength}</th>
                    <th>{allUsedWeight}</th>
                    <th>{allWaste}</th>
                    <th>{allWastedWeight}</th>
                    <th>{allYieldPercentage}%</th>
                    <th>{allWastedPercentage}%</th>
                </tr>
            </tbody>
        </table>
    </div>
}

export const Summary = ({ currentResultSummary, allStatistics }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            <div className="justify-content-center">
                <Container className="border shadow-sm my-3 py-3">
                    <h5 className="text-center">{t("Mevcut Seçim Sonuç Özeti")}</h5>
                    <table className={`table table-striped-columns border my-3 text-center align-middle`}>
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: "60%" }}>{t("Sipariş Boy Sayısı")}</th>
                                <td style={{ width: "40%" }}>{currentResultSummary.totalLogs}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Toplam Ağırlık")} (kg)</th>
                                <td>{nFormat.format(currentResultSummary.totalWeight)}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Fire Ağırlığı")} (kg)</th>
                                <td>{nFormat.format(currentResultSummary.wastedWeight)}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Verim Yüzdesi")}</th>
                                <td>{currentResultSummary.yieldPercentage}%</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Fire Yüzdesi")}</th>
                                <td>{currentResultSummary.wastePercentage}%</td>
                            </tr>

                        </tbody>
                    </table>
                </Container>
            </div>
            <div className="justify-content-center">
                <Container className="border shadow-sm my-3 py-3">
                    <h5 className="text-center mb-3">{t("Tüm Sonuç Özeti")}</h5>
                    <p className='fw-bold' style={{fontSize:'14pt'}}>{t("Toplam Sonuç İstatistikleri")}</p>
                    <AllSummaryTable allStatistics={allStatistics} />
                    <p className='fw-bold mt-3' style={{fontSize:'14pt'}}>{t("Stok İstatistikleri")}</p>
                    <StockStatsTable allStatistics={allStatistics} />
                    <p className='fw-bold mt-3' style={{fontSize:'14pt'}}>{t("Sipariş İstatistikleri")}</p>
                    <OrderStatsTable allStatistics={allStatistics} />
                </Container>
            </div>
        </Container>
    )
}

export default Summary;
