import React from 'react';
import { useTranslation } from 'react-i18next';

const WeightTable = (props) => {

    const { t } = useTranslation();

    const weights = props.data[0];

    const getHeadings = () => {
        const keys = Object.keys(props.data[0]);
        keys.sort((a, b) => {
            return a - b
        })
        return keys
    }

    let diameters = getHeadings();
    diameters = diameters.map(Number)

    const nofThins = diameters.filter(d => d <= 12).length
    const nofThicks = diameters.filter(d => d > 12).length

    const thinDiameters = diameters.slice(0, nofThins)
    const thickDiameters = diameters.slice(nofThins, diameters.length)

    const isThin = nofThins !== 0
    const isThick = nofThicks !== 0

    return (
        <div className="table-responsive">
            <table className="table border text-center align-middle">
                <thead>
                    <tr>
                        {thinDiameters.map(heading => {
                            return <th key={heading}>{`Φ${heading}`}</th>
                        })}
                        {isThin ? <th  >{t("İnce")}</th> : <></>}
                        {thickDiameters.map(heading => {
                            return <th key={heading}>{`Φ${heading}`}</th>
                        })}
                        {isThick ? <th>{t("Kalın")}</th> : <></>}
                        <th>{t("TOPLAM")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {thinDiameters.map((diameter, index) => <td key={index}>{weights[diameter].toFixed(0)}</td>)}
                        {isThin ? <th>
                            {thinDiameters.reduce((acc, diameter) => acc + weights[diameter], 0).toFixed(0)}
                        </th> : <></>}
                        {thickDiameters.map((diameter, index) => <td key={index}>{weights[diameter].toFixed(0)}</td>)}
                        {isThick ? <th>
                            {thickDiameters.reduce((acc, diameter) => acc + weights[diameter], 0).toFixed(0)}
                        </th> : <></>}
                        <th>{diameters.reduce((acc, diameter) => acc + weights[diameter],0).toFixed(0)}</th>
                    </tr>

                </tbody>

            </table>
        </div>
    )
}

export default WeightTable;