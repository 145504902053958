import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { resetPassword } from '../../services/resetPasswordService';

import { useTranslation } from 'react-i18next';

const validatePassword = (password) => {
    // Minimum 6 characters, at least one letter and one number
    const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(password);
};

const useResetPassword = () => {

    const { t } = useTranslation();

    const [resetNote, setResetNote] = useState({ state: "dontshow", note: "" });

    const [isLoading, setIsLoading] = useState(false);

    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');

    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);


    const location = useLocation();


    const searchParams = new URLSearchParams(location.search.replaceAll("&amp;", "&"));

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        let isPasswordMismatch = false;
        let isInvalidPassword = false;

        if (password !== passwordConfirm) {
            isPasswordMismatch = true;
        }
        if (!validatePassword(password)) {
            isInvalidPassword = true;
        }

        setPasswordMismatch(isPasswordMismatch);
        setInvalidPassword(isInvalidPassword);

        if (!isPasswordMismatch && !isInvalidPassword) {
            if (searchParams.get('user_id') && searchParams.get('timestamp') && searchParams.get('signature')) {
                const user_id = searchParams.get('user_id')
                const timestamp = searchParams.get('timestamp')
                const signature = searchParams.get('signature')

                async function fetchData() {
                    try {
                        const response = await resetPassword(password, user_id, timestamp, signature);

                        if (response.ok) {
                            const data = await response.json()
                            setResetNote({
                                "state": "success",
                                "note": t("Şifreniz başarıyla değiştirildi.")
                            })
                            setIsLoading(false)
                        }
                        else {
                            const data = await response.json()
                            if (data.detail === 'Signature expired') {
                                setResetNote({
                                    "state": "danger",
                                    "note": t("Sıfırlama linkinizin süresi geçmiştir.")
                                })
                            }
                            else {
                                setResetNote({
                                    "state": "danger",
                                    "note": t("Sıfırlama başarısız. Tekrar deneyin. (Hata Kodu: 2E2V)")
                                })
                            }
                            setIsLoading(false)

                        }
                    } catch (error) {
                        setResetNote({
                            "state": "danger",
                            "note": t("Sistem hatası. (Hata Kodu: N2KC)")
                        })
                    }
                }
                fetchData()
            }
            else {
                setResetNote({
                    "state": "danger",
                    "note": t("Sıfırlama başarısız. Tekrar deneyin. (Hata Kodu: 4LYI)")
                })
            }
        }

    };

    return {
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,

        invalidPassword,
        passwordMismatch,

        handleSubmit,

        resetNote,

        isLoading,
    };
};

export default useResetPassword;
