import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Landing from './PanelPages/0_Landing';
import UserInfo from './PanelPages/1_UserInfo';
import AddRepresentative from './PanelPages/2_AddRepresentative';
import AdminAuth from './AuthorizationPages/AdminAuth';
import useAdminAuth from '../../hooks/admin/useAdminAuth';
import UnitPrices from './PanelPages/3_UnitPrices';
import Purchases from './PanelPages/4_Purchases';
import LoginAttempts from './PanelPages/5_LoginAttempts';

function AdminPanel(props) {

    const authProps = useAdminAuth()

    const { panel_window } = props;

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [page, setPage] = useState(<Landing />)
    const [pageState, setPageState] = useState(0)
    
    const [showOffCanvas, setShowOffCanvas] = useState(false);

    return (
        authProps.authorized ?
            <>
                <Navbar bg="light" data-bs-theme="dark" className='mb-3' expand="lg">
                    <Container>
                        <Navbar.Brand href="#home">Tasnifer Admin</Navbar.Brand>
                        <Navbar.Toggle/>
                        <Navbar.Offcanvas >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Panel</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="me-auto">
                                    <Nav.Link
                                        onClick={() => {
                                            setPage(<Landing />);
                                            setPageState(0);
                                        }}
                                    >
                                        Giriş
                                    </Nav.Link>
                                    <Nav.Link
                                        onClick={() => {
                                            setPage(<UserInfo />);
                                            setPageState(1);
                                        }}
                                    >
                                        Kullanıcı İşlemleri
                                    </Nav.Link>
                                    <Nav.Link
                                        onClick={() => {
                                            setPage(<UnitPrices />);
                                            setPageState(3);
                                        }}
                                    >
                                        Fiyat ve Oranlar
                                    </Nav.Link>
                                    <Nav.Link
                                        onClick={() => {
                                            setPage(<Purchases />);
                                            setPageState(4);
                                        }}
                                    >
                                        Satın Alımlar
                                    </Nav.Link>
                                    <Nav.Link
                                        onClick={() => {
                                            setPage(<LoginAttempts />);
                                            setPageState(5);
                                        }}
                                    >
                                        Giriş Denemeleri
                                    </Nav.Link>
                                </Nav>
                                <Nav>
                                    <Nav.Link href="/projects">Çıkış</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>

                    </Container>
                </Navbar>
                {page}

            </>
            :
            <AdminAuth props={authProps} />
    );
}

export default AdminPanel;
