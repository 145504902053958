import React, { useEffect, useState } from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';

import projectsPage from '../../img/ss/1-projeler.png'
import createProjectsPage from '../../img/ss/2-create-project.png'
import projectInfo from '../../img/ss/3-project-info.png'
import rebarInspection from '../../img/ss/3-1-rebar-inspection.png'
import cuttingPlans from '../../img/ss/3-2-cutting-plans.png'
import projectDetails from '../../img/ss/3-3-project-details.png'
import projectDetailsUnpaid from '../../img/ss/3-3-project-details-unpaid.png'
import createCp from '../../img/ss/4-create-cp.png'
import cpInfo from '../../img/ss/5-cp-info.png'
import cpInfoUnpaid from '../../img/ss/5-cp-info-unpaid.png'
import cpConf from '../../img/ss/5-1-cp-configuration.png'
import cpSummary from '../../img/ss/5-2-cp-summary.png'
import yieldTable from '../../img/ss/5-2-1-yield-table.png'
import cpPatterns from '../../img/ss/5-3-cp-patterns.png'
import pattern1 from '../../img/ss/5-3-1-pattern.png'
// import pattern2 from '../../img/ss/5-3-2-pattern.png'
import cpOther from '../../img/ss/5-4-cp-other.png'
import myAccount from '../../img/ss/6-my-account.png'
import myPurchases from '../../img/ss/6-1-my-purchases.png'
import excelTable from '../../img/project-manual/table.png'
import yerlesimPlani from '../../img/project-manual/yerlesim-plani.png'
import projectInspection from '../../img/project-manual/project-info.png'
import projectInfoWarning from '../../img/project-manual/project-info-warning.png'
import longRebars from '../../img/project-manual/longRebars.png'
import purchaseStep1 from '../../img/purchase-manual/step1.png'
import purchaseStep2Transfer from '../../img/purchase-manual/step2-transfer.png'
import purchaseStep2CC from '../../img/purchase-manual/step2-creditcard.png'
import purchaseStep3 from '../../img/purchase-manual/step3.png'
import purchaseStep4 from '../../img/purchase-manual/step4.png'
import sta1 from '../../img/sta/sta1.png'
import sta2 from '../../img/sta/sta2.png'
import sta3 from '../../img/sta/sta3.png'
import ide1 from '../../img/ide/ide1.png'
import ide2 from '../../img/ide/ide2.png'
import ide3 from '../../img/ide/ide3.png'
import ide4 from '../../img/ide/ide4.png'

import { useLocation } from 'react-router-dom';
const UserManualEn = () => {

    document.title = "User Guide"

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

    const [show, setShow] = useState(false);
    const [modalImg, setModalImg] = useState(null)

    const handleClose = () => setShow(false);
    const handleShow = (img) => { setShow(true); setModalImg(img) };

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const id = hash.replace('#', '');

            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 3000);
        }
    }, [location]);


    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>User Guide</h2>

                    <p><span className='fw-bold'>Welcome to Tasnifer.</span></p>
                    <p>This documentation is written to guide our users while using Tasnifer.</p>
                    {/* <p>To learn more about what Tasnifer is and its theoretical background, you can visit the What is Tasnifer? page.</p> */}
                    <p>For any questions, feedback, or suggestions, you can contact us at <span className='fw-bold'>{contactEmail}</span> via email.</p>
                    <p>Last Updated: November 21, 2023</p>

                    <div className='d-flex justify-content-center'>
                        <div className='flex-column border p-4 bg-light'>
                            <h3 className='text-center mb-3'>Table of Contents</h3>
                            <ul style={{ listStyleType: "none" }}>
                                <li> <span className='fw-bold'>1.</span> <a href="#key-concepts">Key Concepts</a></li>
                                <li> <span className='fw-bold'>2.</span> <a href="#pages">Page Introductions</a>
                                    <ul style={{ listStyleType: "none" }}>
                                        <li> <span className='fw-bold'>2.1.</span> <a href="#projects">"Projects" Page</a></li>
                                        <li> <span className='fw-bold'>2.2.</span> <a href="#create-project">"Create Project" Page</a></li>
                                        <li> <span className='fw-bold'>2.3.</span> <a href="#project-info-page">"Project Review" Page</a>
                                            <ul style={{ listStyleType: "none" }}>
                                                <li> <span className='fw-bold'>2.3.1.</span> <a href="#rebar-inspection">"Rebar Information" Tab</a></li>
                                                <li> <span className='fw-bold'>2.3.2.</span> <a href="#cutting-plans">"Cutting Plans" Tab</a></li>
                                                <li> <span className='fw-bold'>2.3.3.</span> <a href="#project-details">"Project Details" Tab</a></li>
                                            </ul>
                                        </li>
                                        <li> <span className='fw-bold'>2.4.</span> <a href="#create-cp">"Create Cutting Plan" Page</a></li>
                                        <li> <span className='fw-bold'>2.5.</span> <a href="#cp-info">"Cutting Plan Review" Page</a>
                                            <ul style={{ listStyleType: "none" }}>
                                                <li> <span className='fw-bold'>2.5.1.</span> <a href="#cp-conf">"Selection" Tab</a></li>
                                                <li> <span className='fw-bold'>2.5.2.</span> <a href="#cp-summary">"Summary" Tab</a></li>
                                                <li> <span className='fw-bold'>2.5.3.</span> <a href="#cp-patterns">"Cuts" Tab</a></li>
                                                <li> <span className='fw-bold'>2.5.4.</span> <a href="#cp-other">"Substitute Usage & Waste" Tab</a></li>
                                            </ul>
                                        </li>
                                        <li> <span className='fw-bold'>2.6.</span> <a href="#my-account-page">"My Account" Page</a></li>
                                    </ul>
                                </li>
                                <li> <span className='fw-bold'>3.</span> <a href="#project-manual">Project Guide</a>
                                    <ul style={{ listStyleType: "none" }}>
                                        <li> <span className='fw-bold'>3.1.</span> <a href="#project-upload">Project Upload</a></li>
                                        <ul style={{ listStyleType: "none" }}>
                                            <li> <span className='fw-bold'>3.1.1.</span> <a href="#ide-upload">ideCad Static Project Upload</a></li>
                                            <li> <span className='fw-bold'>3.1.2.</span> <a href="#sta-upload">STA4Cad Project Upload</a></li>
                                            <li> <span className='fw-bold'>3.1.3.</span> <a href="#excel-upload">Excel Project Upload</a></li>
                                        </ul>
                                        <li> <span className='fw-bold'>3.2.</span> <a href="#project-inspect">Project Review</a></li>
                                    </ul>
                                </li>
                                <li> <span className='fw-bold'>4.</span> <a href="#cp-manual">Cutting Plan Guide</a>
                                    <ul style={{ listStyleType: "none" }}>
                                        <li> <span className='fw-bold'>4.1.</span> <a href="#cp-upload">Cutting Plan Upload</a></li>
                                        <li> <span className='fw-bold'>4.2.</span> <a href="#cp-inspect">Cutting Plan Review</a></li>
                                    </ul>
                                </li>
                                <li> <span className='fw-bold'>5.</span> <a href="#purchase-manual">Purchase Guide</a></li>
                                <li> <span className='fw-bold'>6.</span> <a href="#account-management">Account Management</a></li>
                            </ul>
                        </div>
                    </div>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="key-concepts">1. Key Concepts</h3>
                    <ul>
                        <li className='mb-2'><span className='fw-bold'>Detailed Measurement File</span>: Electronic files provided by ideCad Static and STA4Cad programs in txt and xlsx (Microsoft Excel) formats respectively, containing reinforcement, concrete, and formwork measurements of a structure. Excel files containing reinforcement information in the format specified by Tasnifer are also accepted as reinforcement measurement files. These files are used to create projects within Tasnifer.</li>
                        <li className='mb-2'><span className='fw-bold'>Project</span>: Created within Tasnifer by uploading detailed measurement outputs from ideCad Static and STA4Cad programs or Microsoft Excel files compatible with our specified format; includes information about the floors, structural elements, quantities, lengths, diameters, etc., of reinforcements. These projects enable the examination of reinforcements and their measurement information according to placement plans within Tasnifer's interface. Projects created in Tasnifer can be downloaded again as an Excel file in Tasnifer's specified format. For more detailed information, you can refer to the <a href="#project-manual">Project Guide</a>.</li>
                        <li className='mb-2'><span className='fw-bold'>Cutting Plan</span>: Generated by optimizing the cutting of selected reinforcements from <span className='fst-italic'>projects</span> into standard lengths to minimize waste. Each cutting plan may have different values for floors, diameters, substitute usage, combined solutions for floors, and remaining waste lengths. Multiple cutting plans with different values can be created within each project. For more detailed information, you can refer to the <a href="#cp-manual">Cutting Plan Guide</a>.</li>
                        <li className='mb-2'><span className='fw-bold'>Substitute Usage</span>: The utilization of surplus reinforcements from higher diameters that can be used in lower diameters after cutting when creating a cutting plan. Substitute usage is only allowed between adjacent diameters. For example, suitable leftovers from Φ12 can be used in Φ10 reinforcements, but not in Φ8 reinforcements.</li>
                        <li className='mb-2'><span className='fw-bold'>Remaining Waste</span>: The waste that should remain if the reinforcement cutting process is carried out in accordance with the cutting plan generated by Tasnifer. When creating a cutting plan, the "Shortest length to consider for remaining waste" setting is used to specify the shortest length of waste to be considered in this calculation. For example, if this length is set to 10 cm, wastes shorter than 10 cm will not be included in this statistic.</li>
                    </ul>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="pages">2. Page Introductions</h3>

                    <h4 className='mb-3 fw-bold' id="projects">2.1. "Projects" Page</h4>
                    <p>The <span className='fst-italic'>"Projects"</span> page is where all the projects created by the user are listed. By clicking on the listed projects, the user can navigate to the "Project Review" page for the respective project.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectsPage} onClick={() => handleShow(projectsPage)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <h4 className='my-3 fw-bold' id="create-project">2.2. "Create Project" Page</h4>
                    <p>The <span className='fst-italic'>"Create Project"</span> page is where the project file is uploaded, and the length of the cut reinforcement and lap rate are adjusted.</p>
                    <p>To upload the project, a name must be given to the project, then the measurement file must be uploaded, and the length of the cut reinforcement and lap rate must be adjusted.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={createProjectsPage} onClick={() => handleShow(createProjectsPage)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <ol className='mt-3'>
                        <li>Form input for setting the project name</li>
                        <li>Location where detailed measurement files provided by ideCad in txt format can be uploaded</li>
                        <li>Location where detailed measurement files provided by STA4Cad in xlsx (Microsoft Excel) format can be uploaded</li>
                        <li>Location where detailed measurement files in xlsx (Microsoft Excel) format, where the user manually enters reinforcement information, can be uploaded, excluding these programs</li>
                        <li>Place where the length of the cut reinforcement is entered in centimeters. If no changes are made, cuts are made based on 1200 cm.</li>
                        <li>Place where the lap rate is adjusted. For detailed information about the lap rate, you can click on the "Info" text on the same page.</li>
                    </ol>

                    <h4 className='my-3 fw-bold' id="project-info-page">2.3. "Project Review" Page</h4>
                    <p>This is the page where detailed information about the projects can be obtained. On this page, reinforcements can be examined according to the placement plan, and the measurement information of selected reinforcements can be obtained. Reinforcement information can be downloaded in xlsx (Microsoft Excel) format and can be re-uploaded as a new project after modifications.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectInfo} onClick={() => handleShow(projectInfo)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <ol className='mt-3'>
                        <li>"Rebar Information" Tab</li>
                        <li>"Cutting Plans" Tab</li>
                        <li>"Project Details" Tab</li>
                        <li>Button to download the project as a Microsoft Excel file</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="rebar-inspection">2.3.1. "Rebar Information" Tab</h5>
                    <p>In this tab, reinforcements can be examined according to the selected locations on the placement plan. A list of reinforcements and their metering information can be obtained based on reinforcement diameters.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={rebarInspection} onClick={() => handleShow(rebarInspection)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <ol className='mt-3'>
                        <li>Selection of structural elements is made on the placement plan.</li>
                        <li>The metering of reinforcements in the selected elements is displayed.</li>
                        <li>The list of reinforcements in the selected elements is displayed.</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cutting-plans">2.3.2. "Cutting Plans" Tab</h5>
                    <p>This tab displays the list of cutting plans for the reviewed project. Users can navigate to the page for creating a new cutting plan.</p>

                    <p><span></span></p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cuttingPlans} onClick={() => handleShow(cuttingPlans)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <h5 className='my-3 fw-bold' id="project-details">2.3.3. "Project Details" Tab</h5>
                    <p>This tab displays details about the created project. For unpaid projects, users can navigate to the payment screen, and for projects with payment requests, users can view the order page.</p>
                    <p><span></span></p>

                    <Row className='d-flex align-items-center'>
                        <Col md={{ span: 6 }} className='mb-2'>
                            <Card onClick={() => handleShow(projectDetails)}>
                                <Card.Img variant="top" src={projectDetails} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        "Project Details" tab for purchased projects
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <Card onClick={() => handleShow(projectDetailsUnpaid)}>
                                <Card.Img variant="top" src={projectDetailsUnpaid} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        "Project Details" tab for unpurchased projects
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <h4 className='my-3 fw-bold' id="create-cp">2.4. "Create Cutting Plan" Page</h4>
                    <p>This is the page that can be used to create a cutting plan for the reviewed project. From this page, the floors and diameters of the reinforcements to be included in the cutting plan can be selected, and adjustments can be made for replacement usage and the shortest remaining stub.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={createCp} onClick={() => handleShow(createCp)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <ol className='mt-3'>
                        <li>Form input for setting the cutting plan name</li>
                        <li>Location where the floors containing the reinforcements to be included in the cutting plan are selected</li>
                        <li>Location where the diameters of the reinforcements to be included in the cutting plan are selected</li>
                        <li>Place where other optimization options are adjusted
                            <ul>
                                <li><span className='fw-bold'>Use scraps in a lower diameter</span> option: Scraps from one diameter can be used in a lower diameter if there is suitable reinforcement. If you want to activate this option, you can check this box.</li>
                                <li><span className='fw-bold'>Resolve selected floors among themselves</span> option: In the resulting cutting plan, reinforcements from selected floors are optimized within themselves. Reinforcements from different floors are not placed on the same cut in the cutting plan.</li>
                                <li><span className='fw-bold'>Shortest remaining stub to be considered for remaining scraps</span>: To realistically calculate the value of the <span className='fst-italic'>remaining scraps</span>, you can set the shortest length of scraps you want to remain. Scraps shorter than this value are assumed to be left in the last piece during cutting.</li>
                            </ul>
                        </li>
                    </ol>

                    <p><span className='fw-bold'>CALCULATE</span> button initiates the optimization process. This optimization process can take up to a few minutes. During this optimization, it is important not to close your browser or the tab where the calculation is being performed.</p>

                    <h4 className='my-3 fw-bold' id="cp-info">2.5. "Cutting Plan Review" Page</h4>
                    <p>This is the page where the details of the created cutting plan are examined. If the cutting plan is not associated with an activated project (i.e., if payment has not been made for the project), this page will display basic summary information about the cutting plan and a button to redirect to the payment page. If payment has been made for the project, details of the cutting plan can be accessed.</p>

                    <Row className='d-flex align-items-center'>
                        <Col md={{ span: 6 }} className='mb-2'>
                            <Card onClick={() => handleShow(cpInfo)}>
                                <Card.Img variant="top" src={cpInfo} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        "Cutting Plan Review" page for purchased projects
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <Card onClick={() => handleShow(cpInfoUnpaid)}>
                                <Card.Img variant="top" src={cpInfoUnpaid} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        "Cutting Plan Review" page for unpurchased projects
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <ol className='mt-3'>
                        <li>The tab where the floors and diameter information to be examined in the cutting plan are selected</li>
                        <li>The tab containing summary information about the cutting plan</li>
                        <li>The tab containing the cuts in the cutting plan</li>
                        <li>The tab containing the list of replacements and wastages in the cutting plan</li>
                        <li>The button used to print the cutting plan</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cp-conf">2.5.1. "Selection" Tab</h5>
                    <p>In this tab, the floors and diameter information to be examined in the cutting plan are selected. The optimization results of the selected floors and diameters will be examined in the other tabs.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpConf} onClick={() => handleShow(cpConf)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <h5 className='my-3 fw-bold' id="cp-summary">2.5.2. "Summary" Tab</h5>
                    <p>This tab provides various statistical information about the optimization results of the reinforcements corresponding to the selected floor and diameter information in the "Selection" tab.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpSummary} onClick={() => handleShow(cpSummary)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <ol className='mt-3'>
                        <li> <span className='fw-bold'>Solution Options</span>: Information about the solution options includes the solution time for the selected diameter, quick solution, and minimum waste.
                            <ul>
                                <li><span className='fw-bold'>Solution Time</span>: Optimization is performed from larger to smaller diameters. Each diameter is optimized individually, so the solution time for each diameter may vary. The solution time for the selected diameter is displayed here.</li>
                                <li><span className='fw-bold'>Quick Solution</span>: As the number and variety of reinforcements increase, the optimization process may take longer. To reduce this time without compromising efficiency, Tasnifer uses quick solution techniques. These techniques may sometimes result in very slight differences in efficiency. This section indicates whether the solution was performed using quick solution techniques.</li>
                                <li><span className='fw-bold'>Minimum Waste</span>: This displays the <span className='fst-italic'>minimum waste</span> length set during the creation of the cutting plan.</li>
                            </ul>
                        </li>

                        <li><span className='fw-bold'>Current Result Summary</span>: Various statistics are provided based on the selected floor and diameter in the Current Result Summary table. Detailed information about these statistics can be obtained by clicking on the "Info" text in the table cells.</li>
                        <li><span className='fw-bold'>All Result Summary</span>: In the All Result Summary, statistics calculated in the Current Result Summary for the entire project are provided collectively. Below is an example table showing the "Efficiency" statistic:
                            <Row>
                                <Col md={{ offset: 2, span: 8 }}>
                                    <img src={yieldTable} onClick={() => handleShow(yieldTable)} className='img-fluid shadow-sm img-thumbnail'></img>
                                </Col>
                            </Row>
                        </li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cp-summary">2.5.3. "Cuts" Tab</h5>
                    <p>In this tab, the cutting plan resulting from the optimization of the selected floors and diameters in the "Selection" tab can be examined. The cuts in the cutting plan are listed here.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpPatterns} onClick={() => handleShow(cpPatterns)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <p className='my-3'>The information displayed in a cut includes:</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={pattern1} onClick={() => handleShow(pattern1)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={pattern2} onClick={() => handleShow(pattern2)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row> */}
                    <ol className='mt-3'>
                        <li>Number of cuts from the cutting shape</li>
                        <li>Textual representation of the cutting shape</li>
                        <li><span className='fw-bold'>Total Waste</span> in the cutting shape (Number of Cutting Shapes &times; Waste per Cutting Shape)</li>
                        <li>Unique cutting number assigned to each cutting shape</li>
                        <li>Visual representation of the cutting shape</li>
                        <li>Locations where the resulting cuts will be placed</li>
                        <li>Information on whether the waste from the cutting shape can be used in a lower diameter</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cp-other">2.5.4. "Replacement & Waste" Tab</h5>
                    <p>In this tab, you can access the list of <span className='fwt-italic'>Replacements</span> and <span className='fwt-italic'>Wastes</span> in the cuts related to the selected floor and diameter in the "Selection" tab.</p>

                    <p><span></span></p>
                    <Row className='mb-3'>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpOther} onClick={() => handleShow(cpOther)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <p>The "Actual Reinforcement" column in the Replacement table displays the reinforcement code of the original reinforcement where a higher diameter reinforcement is used instead. "Replaced With" contains the diameter and length information of the higher diameter reinforcement used in place of the original one. "16-54" represents a Φ16 reinforcement with a length of 54 cm.</p>

                    <h4 className='my-3 fw-bold' id="my-account-page">2.6. "My Account" Page</h4>

                    <p>This page displays details related to your membership. It can be accessed by clicking on the "My Account" text in the navigation bar at the top of the page. You can change your email address, password, and billing information on this page.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={myAccount} onClick={() => handleShow(myAccount)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='my-3'>By clicking on the "Purchases" text at the bottom of this page, you can access a page containing information about projects purchased through Tasnifer, as well as invoices.</p>

                    <Row className='mb-3'>
                        <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                            <img src={myPurchases} onClick={() => handleShow(myPurchases)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                        </Col>
                    </Row>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="project-manual">3. Project Guide</h3>

                    <p>This guide explains how to upload detailed quantity files to Tasnifer and how to interpret the project after uploading.</p>

                    <h4 className='my-3 fw-bold' id="project-upload">3.1. Uploading a Project</h4>

                    <p>In Tasnifer, you can create a project with 3 different types of input file. You can upload detailed quantity files generated by ideCad Statik and STA4Cad programs, or you can upload your Excel file in the format specified by us.</p>

                    <p>Project upload operations are done on the <a href="#create-project">Project Creation Page</a>. You can access this page by clicking on the "Create Project" text in the navigation bar at the top of the page or by clicking on the "Create Project" button on the <a href="#projects">My Projects Page</a>. To create your project, you need to provide the following information on this page:</p>

                    <ul>
                        <li><span className='fw-bold'>Project Name:</span> You need to give a name to your project. The project name can contain up to 25 characters and cannot have the same name as your other projects. The project name cannot be changed after creation.</li>
                        <li><span className='fw-bold'>Detailed Quantity Survey File:</span> You need to upload this file to the system by clicking on the appropriate button among the three large buttons on the project creation page.</li>
                        <li><span className='fw-bold'>Cutting Length of Reinforcement:</span> The length of the reinforcement to be cut in centimeters. Ribbed bars are commonly sold in the market with a standard length of 12 meters. If you want to cut bars from longer or shorter bars produced as standard, you can adjust this.</li>
                        <li><span className='fw-bold'>Overlap Factor:</span> If the uploaded Quantity Survey File contains reinforcements longer than the Cutting Length of Reinforcement, these reinforcements are split by adding an overlapping length. If you want to change the coefficient value required to calculate this overlap, you can do so here. Detailed information is provided below.</li>
                    </ul>

                    <div className='m-3 p-3 border bg-light'>
                        <h5 className='my-3 fw-bold'>What is the Overlap Factor? How is the Overlap Length Calculated?</h5>
                        <p>If the uploaded project file contains reinforcements longer than the cutting length of reinforcement, these reinforcements are split by adding an overlapping length. The overlap length varies depending on the diameter, class, and concrete quality of the reinforcement. For practicality in application and considering the need to stay on the safe side, a value of <span style={{ fontWeight: "bold" }}>40 &times; Φ mm</span> is used, providing sufficient overlap distance for S420 steel in C25 and above concrete classes. However, for situations where the overlap length may be insufficient, this distance can be increased by multiplying it by a coefficient <span style={{ fontWeight: "bold" }}>k</span>. The overlap length is calculated according to the following formula:</p>
                        <p className="text-center">Overlap Length (cm) &#x3d; 4 &times; Φ &times; k</p>
                        <p>In this formula, <span style={{ fontWeight: "bold" }}>Φ</span> represents the diameter of the reinforcement in millimeters, and <span style={{ fontWeight: "bold" }}>k</span> is the <span style={{ fontStyle: "italic" }}>overlap coefficient</span> entered here. The overlap coefficient is a decimal number between 0 and 5. <span style={{ fontWeight: "bold" }}>If a sleeve is to be used, the overlap coefficient should be entered as 0.</span> The result of the formula is rounded up. </p>
                        <p>For example, for a 14 mm diameter reinforcement with a length of 1270 cm, when the overlap coefficient is taken as 1.2, the program calculates the overlap length as follows:</p>
                        <p className="text-center"> L &#x3d; 4 &times; 14 &times; 1.2 &#x3d; 67.2 </p>
                        <p>The result is rounded up, and the overlap length is obtained as <span style={{ fontWeight: "bold" }}>68</span> cm. Therefore, the 1270 cm reinforcement is divided into two reinforcements of 1200 cm and 70 + 68 = 138 cm respectively.</p>
                        <p>As the number of reinforcements resulting from the splitting increases, another overlap length is added to the last reinforcement. For example, a 2540 cm reinforcement with a diameter of 14 mm is divided into 2 reinforcements of 1200 cm and 1 reinforcement of 140 + <span style={{ fontWeight: "bold" }}>2</span> &times; L cm. L is calculated as above. Therefore, the 2540 cm reinforcement is divided into 2 reinforcements of 1200 cm and 1 reinforcement of 70 + 2 &times; 68 = 206 cm respectively.</p>
                    </div>

                    <p>After entering the project information mentioned above, the project creation process is completed by clicking the <span className='fw-bold'>NEXT</span> button.</p>

                    <p><span className='fw-bold'>NOTE: </span>Before uploading your detailed bill of quantities file, make sure it is compatible with the drawing sheets to obtain reinforcements correctly.</p>

                    <p>You can refer to the guides below for the formats of detailed bill of quantities files and how to obtain them from relevant programs.</p>

                    <h5 className='my-3 fw-bold' id="ide-upload">3.1.1. Uploading ideCad Static Project (Turkish)</h5>

                    <p>To obtain a detailed bill of quantities file from ideCad Static, follow the steps below:</p>

                    <ol>
                        <li className='mb-4'>After the analysis is complete, click on the <span className='fw-bold'>Rapor</span> tab at the top. Then click on the <span className='fw-bold'>Metraj</span> option to access the <span className='fw-bold'>Metraj Rapor Ayarları</span>.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={ide1} onClick={() => handleShow(ide1)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <span className='fw-bold'>You can also access the <span className='fw-bold'>Metraj Rapor Ayarları</span> from the Ribbon menu under the "Raporlar" tab under the "Adet" heading.</span>
                        <Row className='my-4'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={ide2} onClick={() => handleShow(ide2)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='my-4'>From the <span className='fw-bold'>Metraj Rapor Ayarları</span> menu, select the following options as the type of quantities: <span className='fw-bold'>Kalıp metrajı</span>, <span className='fw-bold'>Beton Metrajı</span>, <span className='fw-bold'>Donatı Metrajı</span>, and <span className='fw-bold'>Toplam Metraj</span>. In the "Metraj Detayı" section, select <span>Detaylı Metraj</span>. In the "Eleman tipleri" section, select all elements calculated in the project. In the "Diğer" tab, select the <span className='fw-bold'>Detaylı metrajda poz listesi</span> option, then click the <span className='fw-bold'>Oluştur</span> button.</li>

                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={ide3} onClick={() => handleShow(ide3)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='mb-4'>Click on the <span className='fw-bold'>File</span> tab at the top of the opened PDF file. Click on the <span className='fw-bold'>Save As</span> option, and save the bill of quantities file as a <u className='fw-bold'>.txt file extension</u>. Since Tasnifer processes the data from ideCad Statik in txt format, this PDF file must be saved as a txt file.</li>
                        <Row>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={ide4} onClick={() => handleShow(ide4)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                    </ol>

                    <p>After obtaining this detailed bill of quantities file from ideCad Statik, you need to click on the <a href="#project-upload">Project Upload Page</a> and then click on the <span className='fw-bold'>ideCad Statik txt File</span> button. From the window that opens, select the location of your created txt file and upload it to the system. Then, click on the NEXT button to upload your project.</p>

                    <h5 className='my-3 fw-bold' id="sta-upload">3.1.2. STA4Cad Project Upload (Turkish)</h5>

                    <p>To obtain a detailed bill of quantities file from STA4Cad, follow these steps:</p>
                    <ol>
                        <li className='mb-4'>After the analysis of the model is completed, click on the <span className='fw-bold'>"Sonuçların Printerden Alınması"</span> command located in the main menu of STA4Cad.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={sta1} onClick={() => handleShow(sta1)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='mb-4'>Then, in the "Printer Yazdırma Seçimi" window that will open, check the <span className='fw-bold'>"Yapı Genel Bilgisi"</span> tab, select the options for <span className='fw-bold'>"Yapı Elemanları Metrajı"</span> and <span className='fw-bold'>"Excel Metric"</span>, and click on the <span className='fw-bold'>"Print"</span> button.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={sta2} onClick={() => handleShow(sta2)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='mb-4'>After waiting for a while, save the automatically opened Excel file with a different name.</li>
                        <Row>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={sta3} onClick={() => handleShow(sta3)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                    </ol>

                    <p>After obtaining this detailed quantity survey file from STA4Cad, click on the <a href="#project-upload">Project Upload Page</a> and then click on the <span className='fw-bold'>STA4Cad xlsx File</span> button. From the opened window, select the location of your created Excel file and upload it to the system. Then, click on the NEXT button to upload your project.</p>

                    <h5 className='my-3 fw-bold' id="excel-upload">3.1.3. Excel Project Upload</h5>

                    <p>You can also create a project by preparing an Excel file in the specified format. In the Excel file, you should enter the following values in the top cells:</p>

                    <div className='table-responsive '>
                        <table className='table text-center'>
                            <tbody>
                                <tr>
                                    <th className='border'>POSE</th>
                                    <th className='border'>QUANTITY</th>
                                    <th className='border'>LENGTH</th>
                                    <th className='border'>DIAMETER</th>
                                    <th className='border'>FLOOR</th>
                                    <th className='border'>STRUCTURE ELEMENT</th>
                                    <th className='border'>ELEMENT NAME</th>
                                    <th className='border'>DESCRIPTION</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>It doesn't matter whether these values are written in uppercase or lowercase.</p>

                    <p>After entering these values in the top row, you should write the necessary information for each reinforcement group under the respective column in the relevant row. An example view is as follows:</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={excelTable} onClick={() => handleShow(excelTable)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='mt-3'><span className='fw-bold'>POSE:</span> The position value of the reinforcement group. It can be a number or text. It is not mandatory to enter. If not entered, the program automatically assigns a position number to each reinforcement group starting from 1 in the order it reads the reinforcements.</p>
                    <p><span className='fw-bold'>QUANTITY:</span> The quantity of the reinforcement group. It is mandatory to enter. If the quantity information is not entered, the project cannot be created.</p>
                    <p><span className='fw-bold'>LENGTH:</span> The length of the reinforcements in the reinforcement group in centimeters. It is mandatory to enter. If the length information is not entered, the project cannot be created.</p>
                    <p><span className='fw-bold'>DIAMETER:</span> The diameter of the reinforcements in the reinforcement group in millimeters. It is mandatory to enter. If the diameter information is not entered, the project cannot be created.</p>
                    <p><span className='fw-bold'>FLOOR:</span> The floor information where the reinforcement group is located. It is important to enter the floors in sequence to obtain the correct floor order. For example, if no reinforcement is entered for the "1st Floor" and reinforcement for the "Basement Floor" is entered before the reinforcements for the "2nd Floor", the program perceives the "2nd Floor" as the floor after "Basement" and considers "1st Floor" to come after "2nd Floor". Before entering the reinforcement for the "2nd Floor" after entering the reinforcements for the "Basement" floor, at least one row belonging to the "1st Floor" must be entered. Entering floor information is not mandatory. If floor information cannot be obtained, "#" is written instead of floor information for reinforcements.</p>
                    <p><span className='fw-bold'>STRUCTURE ELEMENT:</span> The name of the structural element to which the reinforcement group belongs (Slab, Beam, Column, etc.). It is crucial that the structure element information for reinforcements belonging to the same structure element be exactly the same. For example, if the information for a reinforcement group belonging to a Slab is entered as "Slab", then the information for another reinforcement group belonging to the same structure element should not be written as "Slabs" or "Slab". It should be written as "Slab" again. Entering structure element information is not mandatory. If structure element information cannot be obtained, "#" is written instead of structure element information for reinforcements.</p>
                    <p><span className='fw-bold'>ELEMENT NAME:</span> The specific code of the structural element to which the reinforcement group belongs. Such as D130, S345, P123, etc. Entering element name information is not mandatory. If element name information cannot be obtained, "#" is written instead of element name information for reinforcements.</p>
                    <p><span className='fw-bold'>DESCRIPTION:</span> Description related to the reinforcement group. You can write the function of the reinforcement group. Such as stirrup, tie rod, dowel, etc. Entering description information is not mandatory. If description information cannot be obtained, "#" is written instead of description information for reinforcements.</p>

                    <p>All projects uploaded to the system are converted to this Excel format by Tasnifer, allowing these projects to be downloaded later and re-uploaded to the system again. By uploading detailed quantity survey files from ideCad or STA4Cad to Tasnifer, you can download the project file in the specified Excel format for free, make necessary changes, and re-upload it as a new project to Tasnifer.</p>

                    <h4 className='my-3 fw-bold' id="project-inspect">3.2. Project Inspection</h4>

                    <p>After successfully uploading your project to Tasnifer, you need to go to the <a href="#project-info-page">Project Inspection Page</a> to review your project, the reinforcements within it, and their quantities. You will be automatically redirected to this page after your project is uploaded.</p>

                    <Row>
                        <Col md="4" className='d-flex justify-content-center'>
                            <img src={yerlesimPlani} onClick={() => handleShow(yerlesimPlani)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                        </Col>
                        <Col md="8">
                            <p>To obtain information about the reinforcements in the uploaded project, you can navigate to the "Reinforcement Information" tab on the Project Inspection Page. On the left side of this tab, you can find the <span className='fst-italic'>Layout Plan</span> of the uploaded project.</p>

                            <div className='m-4 p-3 border bg-light'>
                                <p><span className='fw-bold'>Layout Plan:</span> Classification of reinforcements in the uploaded structure by floor, structural element, and structural element names.</p>
                            </div>

                            <p>To examine the layout plan and view the structural elements of a floor or the structural element codes of a structural element on a floor, you can click on the arrow next to the layout plan elements. Under the "Theoretical Weights (kg)" heading, you can find the quantities of reinforcements included in the selected structural elements. To inspect the reinforcements present in the selected structural element, you can review the list under the "Rebars" heading.</p>

                        </Col>
                    </Row>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectInspection} onClick={() => handleShow(projectInspection)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='mt-3'>During the upload of your project, a unique <span className='fw-bold'>reinforcement code</span> is assigned to each reinforcement group in the detailed reinforcement file based on the quantity, length, diameter, and, if available, position, floor, structural element, and element code information. This reinforcement code consists of the diameter, length, and a sequence number determined by Tasnifer. For example, the reinforcement group with the code 8-206-29 indicates the 29th group of 8 mm diameter reinforcements with a length of 206 cm. These reinforcement codes are generated to facilitate easier differentiation of reinforcements based on their locations and are distinct from the position information found in the reinforcement file.</p>

                    <p>To perform more detailed analyses, you can click on the Excel icon at the top right to download the project in the Excel format described earlier. You can then use Excel tools to conduct more detailed calculations. After making any necessary changes to the uploaded project, you can re-upload it to Tasnifer as a new project.</p>

                    <p>If there was a problem during the upload of your project that did not prevent it from being uploaded to the system, a warning message related to this problem will be displayed at the top of the <a href="#project-info-page">Project Information Page</a> in the <a href="#rebar-inspection">Rebar Information Tab</a> as follows:</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectInfoWarning} onClick={() => handleShow(projectInfoWarning)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='mt-3'>If there are rebars longer than the cutting length of rebars (typically 12 meters) in your project, you can find information about these rebars at the bottom of the "Reinforcement Information" tab. After selecting the structural elements you want to examine from the layout plan, you will find a list of these long rebars under the "Long Rebars" section at the bottom of the page.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                            <img src={longRebars} onClick={() => handleShow(longRebars)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "400px" }}></img>
                        </Col>
                    </Row>

                    <p className='mt-3'>On the Project Inspection Page, you can view the cutting plans for this project in the <a href="#cutting-plans">Cutting Plans tab</a>, obtain some information about your project in the <a href="#project-details">Project Information tab</a>, and access the payment page for your project from this tab as well.</p>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="cp-manual">5. Cutting Plan Guide</h3>

                    <p>This guide explains how to create cutting plans for projects created after detailed quantity files are uploaded to Tasnifer and how to review these cutting plans.</p>

                    <h4 className='mb-3 fw-bold' id="cp-upload">5.1. Cutting Plan Upload</h4>

                    <p>To create a new cutting plan for a project, click on the "Create Cutting Plan" button in the <a href="#cutting-plans">Cutting Plans tab</a> of the <a href="#project-info-page">Project Inspection Page</a> for the project. Then, you need to make adjustments for your cutting plan on the <a href="#create-cp">Cutting Plan Creation Page</a> that appears:</p>

                    <ul>
                        <li>
                            <span className='fw-bold'>Cutting Plan Name:</span> You need to give a name to each cutting plan, which can be up to 25 characters long.
                        </li>
                        <li>
                            <span className='fw-bold'>Floors to Include in the Cutting Plan:</span> Select the floors to include in the cutting plan under the "Layout Plan" heading. If floor information was not obtained when the project was created, it is normal to see only "#" here.
                        </li>
                        <li>
                            <span className='fw-bold'>Diameters to Include in the Cutting Plan:</span> Under the "Diameters for Optimization" heading, you can select the diameters you want to include in the cutting plan.
                        </li>
                        <li>
                            <span className='fw-bold'>Other Optimization Options:</span>
                            <ul>
                                <li><span className='fw-bold'>Remains are used for a lower diameter:</span> If you want leftovers from one diameter to be used for a lower diameter if suitable rebars are available, you can check this option.</li>
                                <li><span className='fw-bold'>Selected floors are resolved among themselves:</span> Rebars from selected floors are optimized separately. Rebars from different floors are not placed on the same cut.</li>
                                <li><span className='fw-bold'>The shortest length to be considered for the remaining waste:</span> To calculate the remaining waste realistically, you can adjust the minimum length of waste to be considered. Rebars shorter than this value are assumed to be left in the final piece during cutting.</li>
                            </ul>
                        </li>
                    </ul><p>After making these settings, clicking the "CALCULATE" button starts the optimization process. During optimization, you will see the message "Optimizing. This may take a while, please wait. Do not close the page." on the screen. Depending on the number and length of rebars included in the optimization, the optimization process can take up to 10 minutes. During this time, you should wait and not close the browser tab where the optimization is being performed. After the optimization is complete, you will be automatically redirected to the <a href="#cp-info">Cutting Plan Inspection Page</a> for the cutting plan you created.</p>

                    <p>After making these settings, clicking the "CALCULATE" button starts the optimization process. During optimization, you will see the message "Optimizing. This may take a while, please wait. Do not close the page." on the screen. Depending on the number and length of rebars included in the optimization, the optimization process can take up to 10 minutes. During this time, you should wait and not close the browser tab where the optimization is being performed. After the optimization is complete, you will be automatically redirected to the <a href="#cp-info">Cutting Plan Inspection Page</a> for the cutting plan you created.</p>

                    <h4 className='mb-3 fw-bold' id="cp-inspect">5.2. Kesim Planı İnceleme</h4>

                    <p>You can access the list of cutting plans for a project from the <a href="#project-info">Project Inspection Page</a>'s <a href="#cutting-plans">Cutting Plans Tab</a>. From this page, you can navigate to the <a href="#cp-info">Cutting Plan Inspection Page</a> for the cutting plan you want to inspect.</p>

                    <p>If you haven't made a payment for the project the cutting plan belongs to, the <a href="#cp-info">Cutting Plan Inspection Page</a> will display summary information about the cutting plan and a button to navigate to the payment page. If you have made a payment, you can access detailed information about the cutting plan on this page.</p>

                    <p>Tasnifer provides every registered user with a "Sample Project" that allows them to try Tasnifer and create cutting plans for free. You can use this sample project to create cutting plans and try out the system.</p>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="purchase-manual">6. Purchase Guide</h3>

                    <p>Uploading projects to Tasnifer is free of charge. You can upload reinforcement quantity files to Tasnifer for free, create projects, inspect interactive reinforcement quantities, download the project in the specified Excel format, and re-upload it to the system. There is no need to pay any fee to do these tasks.</p>


                    <Row className='mb-3'>
                        <Col md="7">
                            <p>Creating cutting plans for projects is also free of charge. After creating a cutting plan, you can learn the following statistics for free:</p>

                            <ul>
                                <li>Total Weight Used in the Cutting Plan (kg)</li>
                                <li>Remaining Waste Weight (kg)</li>
                                <li>Theoretical Waste Weight (kg)</li>
                                <li>Replacement Usage Weight (kg)</li>
                                <li>Efficiency Percentage</li>
                            </ul>

                            <p>By learning these statistics for free, you can assess the efficiency of the optimization beforehand and decide whether to purchase the right to create the project's cutting plan or not.</p>

                        </Col>
                        <Col md="5" className='d-flex justify-content-center'>
                            <img src={cpInfoUnpaid} onClick={() => handleShow(cpInfoUnpaid)} className='img-fluid shadow-sm img-thumbnail' style={{}}></img>
                        </Col>
                    </Row>

                    <p>To access the details of this cutting plan, you need to purchase the right to create cutting plans for the project to which the cutting plan belongs. The purchase process is made for the project, not for the cutting plan. After making the payment, you gain access not only to one cutting plan but to all cutting plans created and to be created for the project.</p>

                    <p>You can access the Project's Purchase Page via the "Project Details" tab on the Project Page or via the Cutting Plan's Cutting Plan Inspection Page. (If you don't have a Detailed Metraj File to create a Project and want to see the purchase pages, you can download the Excel file of the Sample Project, upload it again to the system, and examine the payment steps.)</p>

                    <p>Tasnifer accepts payments via Bank Transfer and Credit/Bank Cards.</p>

                    <p>Currently, Tasnifer only accepts payments in Turkish Lira.</p>

                    <p>To complete the payment process, follow these steps:</p>

                    <ol>
                        <li className='mb-3'>Go to the Project Purchase Page.</li>
                        <li className='mb-3'>Fill in the Invoice Details.</li>
                        <Row className='mb-3'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={purchaseStep1} onClick={() => handleShow(purchaseStep1)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                            </Col>
                        </Row>
                        <li className='mb-3'>Select your payment method. If you choose to pay by Credit/Bank Card, enter your card details.</li>
                        <li className='mb-3'>Confirm the Sales Agreement.</li>

                        <Row className='d-flex align-items-center'>
                            <Col md={{ span: 6 }} className='mb-2'>
                                <Card onClick={() => handleShow(purchaseStep2Transfer)}>
                                    <Card.Img variant="top" src={purchaseStep2Transfer} />
                                    <Card.Body>
                                        <Card.Text className='text-center'>
                                            Wire Transfer/EFT
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={{ span: 6 }}>
                                <Card onClick={() => handleShow(purchaseStep2CC)}>
                                    <Card.Img variant="top" src={purchaseStep2CC} />
                                    <Card.Body>
                                        <Card.Text className='text-center'>
                                            Credit/Debit Card
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <li className='mb-3'>Check your Invoice and Payment details. Click on the "Complete Order" button to finalize your payment.</li>

                        <Row className='mb-3'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={purchaseStep3} onClick={() => handleShow(purchaseStep3)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                            </Col>
                        </Row>

                        <li className='mb-3'>If you have chosen to pay with Credit/Debit card, complete the 3D Secure process to confirm your payment. After that, your payment will be completed, and access to your Project's Cutting Plans will be granted.</li>

                        <li className='mb-3'>If you have chosen to pay via Bank Transfer, after completing the order process, transfer the specified amount to the bank account provided on the following screen. After your payment is confirmed, you will gain access to your Project's Cutting Plans.</li>


                        <Row className='mb-3'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={purchaseStep4} onClick={() => handleShow(purchaseStep4)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                            </Col>
                        </Row>
                    </ol>

                    <p>Your invoice will be sent to your email account after completing the payment process.</p>

                    <p>You can access all information regarding your purchases and invoices by navigating to "My Account" {">"} "Purchases" from the top Navigation bar of the page. In this screen, after selecting the service you purchased and your payment method from the top, you can view payments with relevant features.</p>


                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="account-management">6. Account Management</h3>

                    <p>You can access information about your account and membership via the <a href="#my-account-page">My Account Page</a>.</p>

                    <h4 className='mb-3 fw-bold' id="change-email">6.1. Changing Email Address</h4>

                    <p>To change your email address, click on the "Change" button next to the "Email" field on the "My Account" page. Enter your new email address. Confirm the change through the email sent to your old email address. If the email change is successful, you will be automatically logged out of your account. Log in again with your new email address.</p>

                    <h4 className='mb-3 fw-bold' id="change-password">6.2. Changing Password</h4>

                    <p>To change your password, click on the "Change" button next to the "Password" field on the "My Account" page. Enter your old and new passwords in the form.</p>

                    <h4 className='mb-3 fw-bold' id="change-other-info">6.3. Changing Other Information</h4>

                    <p>"Other Information" allows for automatic filling of Invoice details during purchases. Click on the "Change" button next to "Other Information" to modify these details. It's not mandatory to fill out this information.</p>

                    <h4 className='mb-3 fw-bold' id="delete-account">6.4. Deleting Account</h4>

                    <p>The account deletion process cannot be done directly on Tasnifer. If you wish to delete your account, you need to send an email with the subject "Account Deletion" to {contactEmail}. Your account deletion request will be evaluated by us, and if deemed necessary, you will be asked for further confirmation. Please note that deleting your account will permanently erase your projects, cutting plans, and purchase details.</p>

                    <hr className='my-4' />

                </Container>
            </div>

            <Modal show={show} fullscreen onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='d-flex justify-content-center'><img className='img-fluid img-thumbnail' style={{ maxWidth: "100%", height: "auto" }} src={modalImg} /></Modal.Body>
            </Modal>
            <Footer />
        </div>
    );
};

export default UserManualEn;
