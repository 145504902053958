import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate, useRoutes, useNavigate, Outlet } from "react-router-dom";

import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import PublicRoute from "./components/PublicRoute/PublicRoute";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import Login from "./pages/Login/Login";
import LandingPage from "./pages/LandingPage/LandingPage";
import Signup from "./pages/Signup/Signup";
import Projects from "./pages/Projects/Projects";
import ProjectCreation from "./pages/ProjectCreationNew/ProjectCreationNew";
import ProjectInfo from "./pages/ProjectInfo/ProjectInfo";
import CpCreation from "./pages/CpCreation/CpCreation";
import CpInfo from "./pages/CpInfo/RebarCpInfo/RebarCpInfo";
import BeforeVerification from "./pages/Verification/BeforeVerification";
import AfterVerification from "./pages/Verification/AfterVerification";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetLinkSent from "./pages/ForgotPassword/ResetLinkSent";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";
import Account from "./pages/Account/Account";
import EmailChangeVerification from "./pages/Verification/EmailChangeVerification";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import SaleContract from "./pages/SaleContract/SaleContract";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import InactiveAccount from "./pages/InactiveAccount/InactiveAccount";
import ProjectPurchase from "./pages/ProjectPurchase/ProjectPurchase";
import UsersPurchases from "./pages/UsersPurchases/UsersPurchases";
import UserManual from "./pages/UserManual/UserManual";
import Contact from "./pages/Contact/Contact";
import Prices from "./pages/Prices/Prices";
import ReturnContract from "./pages/ReturnContract/ReturnContract";
import AboutUs from "./pages/AboutUs/AboutUs";
import ThreeDPage from "./pages/ProjectPurchase/CreditCardPages/ThreeDPage";
import PaymentComplete from "./pages/ProjectPurchase/CreditCardPages/PaymentComplete";
import Error from "./pages/Error/Error";
import DelegationContract from "./pages/DelegationContract/DelegationContract";
import DelegatePanel from "./pages/DelegatePanel/DelegatePanel";

import CpInfoInitializer from "./pages/CpInfo/CpInfoInitializer";

import { useTranslation } from "react-i18next";

import { supportedLanguages, defaultLanguage } from './i18n'

// LanguageWrapper component
const LanguageWrapper = ({ lang, children }) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    i18n.changeLanguage(lang || 'tr');
    setLoading(false);
  }, []);

  if (loading) {
    return <></>;
  }
  else {
    return children;
  }
};

function RedirectToDefaultLanguage() {
  const navigate = useNavigate();

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    const langSegment = pathSegments[1];
    if (langSegment === defaultLanguage) {
      const pathWithoutLang = pathSegments.slice(2).join('/');
      const searchParams = new URLSearchParams(window.location.search);
      const queryParams = searchParams.toString();
      if (queryParams) {
        navigate(`/${pathWithoutLang}?${queryParams}`);
      }
      else {
        navigate(`/${pathWithoutLang}`);
      }
    }
  }, [navigate]);

  return null;
}

export default function App() {

  const MyRoutes = (
    <>
      <Route path="" element={<PublicRoute><LandingPage /></PublicRoute>} />
      <Route path="login" element={<PublicRoute><Login /></PublicRoute>} />
      <Route path="signup" element={<PublicRoute><Signup /></PublicRoute>} />
      <Route path="verify-your-email" element={<PublicRoute><BeforeVerification /></PublicRoute>} />
      <Route path="verify" element={<PublicRoute><AfterVerification /></PublicRoute>} />
      <Route path="forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
      <Route path="reset-link-sent" element={<PublicRoute><ResetLinkSent /></PublicRoute>} />
      <Route path="reset-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />

      <Route path="about-us" element={<AboutUs />} />
      <Route path="contact" element={<Contact />} />
      <Route path="user-manual" element={<UserManual />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="sale-contract" element={<SaleContract />} />
      <Route path="return-contract" element={<ReturnContract />} />
      <Route path="prices" element={<Prices />} />
      <Route path="error" element={<Error />} />

      <Route path="*" element={<PrivateRoutes />} >
        <Route path="projects" element={<Projects />} />
        <Route path="create-project/" element={<ProjectCreation />} />
        <Route path="project-info/:id" element={<ProjectInfo />} />
        <Route path="project-purchase/:id" element={<ProjectPurchase />} />
        <Route path="create-cp/:id" element={<CpCreation />} />
        <Route path="cp-info/:id" element={<CpInfoInitializer />} />
        <Route path="account/" element={<Account />} />
        <Route path="admin-panel/" element={<AdminPanel />} />
        <Route path="verify-email-change" element={<EmailChangeVerification />} />
        <Route path="purchases" element={<UsersPurchases />} />
        <Route path="inactive-account" element={<InactiveAccount />} />
        <Route path="card-payment" element={<ThreeDPage />} />
        <Route path="payment-complete" element={<PaymentComplete />} />
        <Route path="delegation-contract" element={<DelegationContract />} />
        <Route path="delegate-panel" element={<DelegatePanel />} />

      </Route>
    </>
  )

  return (

    <BrowserRouter>
      <Routes>
        {supportedLanguages.map((lang, i) => (
          <Route path={`/${lang}`} key={i} element={<LanguageWrapper lang={lang}><Outlet /></LanguageWrapper>}>
            {MyRoutes}
          </Route>
        ))}
        {/* <Route path={`/${defaultLanguage}/*`} element={<RedirectToDefaultLanguage />} /> */}
        <Route path='/' element={<LanguageWrapper lang={defaultLanguage}><Outlet/></LanguageWrapper>}>
          {MyRoutes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
