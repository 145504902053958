import React, { useState, } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import Pattern from '../../../../components/Pattern/MetalProfilePattern';

import { useTranslation } from 'react-i18next';

const Patterns = ({ patternGroups, availableStocks, showPieceInfo }) => {

    let ratio = 1.2

    const { t } = useTranslation();

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            {Object.values(patternGroups).map((patternGroup, i) => (
                <div key={i}>
                    {i !== 0 && <hr className='my-3' />}
                    <div className='my-3'>
                        <h3 className='fw-bold'>{patternGroup.profileName}</h3>
                        <p>
                            <span className='fw-bold'>{t('Kesim Uzunluğu')}:</span> {patternGroup.logLength} mm &nbsp;|&nbsp;
                            <span className='fw-bold'>{t('Testere Kalınlığı')}:</span> {patternGroup.bladeThickness} mm
                        </p>
                    </div>
                    {patternGroup.patterns.map((pattern, j) => {
                        return <Pattern
                            key={`${i}-${j}`}
                            pattern={pattern}
                            profileLogLength={patternGroup.logLength}
                            showPieceInfo={showPieceInfo}
                            bladeThickness={pattern.bladeThickness}
                            ratio={ratio}
                        />
                    })}
                </div>

            ))}
        </Container>
    )
}

export default Patterns;