import apiClient from "../apiClient";

export const cancelProjectPayment = async (orderId, cancelMessage, lang) => {
    const url = `${process.env.REACT_APP_API_URL}/api/payment/cancel-project-payment/`;

    const response = await apiClient(url, {
        method: 'POST',
        body: JSON.stringify({
            order_id: orderId,
            cancel_message: cancelMessage,
        }),
        headers: {
            'Accept-Language': lang,
        },
    })

    return response;

}