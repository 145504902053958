import React, { useEffect, useState } from "react";
import { Button, Table, Container, Form } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { deepEqual } from "../../../other/utilFunctions";
import { metalProfileInfoUpdate } from "../../../services/metalProfileInfoUpdate";

const ProfileStats = ({ 
    id,
    pieces,
    setPieces,
    profileInfo,
    setProfileInfo,
}) => {

    const { i18n, t } = useTranslation();

    profileInfo.sort((a, b) => a.profile.localeCompare(b.profile))

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    const getNumberOfUniquePieces = (profile_name) => {
        return new Set(pieces.filter((piece) => piece.profile === profile_name).map((piece) => [piece.profile, piece.grade, piece.length])).size
    }
    const getNumberOfAllUniquePieces = () => {
        return new Set(pieces.map((piece) => [piece.profile, piece.grade, piece.length])).size
    }
    const getTotalNumberOfPieces = (profile_name) => {
        return pieces.filter((piece) => piece.profile === profile_name).reduce((acc, piece) => acc + Number(piece.quantity), 0)
    }
    const getAllTotalNumberOfPieces = () => {
        return pieces.reduce((acc, piece) => acc + Number(piece.quantity), 0)
    }
    const getTotalWeight = (profile_name) => {
        return pieces.filter((piece) => piece.profile === profile_name).reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.weight_of_one_piece), 0)
    }
    const getAllTotalWeight = () => {
        return pieces.reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.weight_of_one_piece), 0)
    }
    const getTotalLength = (profile_name) => {
        const lengthInMm = pieces.filter((piece) => piece.profile === profile_name).reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.length), 0)
        const lengthInM = lengthInMm / 1000
        return lengthInM.toFixed(2)
    }
    const getAllTotalLength = () => {
        const lengthInMm = pieces.reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.length), 0)
        const lengthInM = lengthInMm / 1000
        return lengthInM.toFixed(2)
    }
    const getIncludeInOptimization = (profile) => {
        const value = profile.include_in_optimization;
        if (value === true || value === "true") {
            return t("Evet")
        }
        else {
            return t("Hayır")
        }
    }

    const [currentProfiles, setCurrentProfiles] = useState(profileInfo)
    const [updatedProfileInfo, setUpdatedProfileInfo] = useState(profileInfo)

    const [updateEnabled, setUpdateEnabled] = useState(false)

    const [updating, setUpdating] = useState(false)

    const [updatedByUser, setUpdatedByUser] = useState(false)

    useEffect(() => {
        setUpdatedByUser(!(deepEqual(profileInfo, updatedProfileInfo)))
    },[updatedProfileInfo])

    const [error, setError] = useState(false)

    const handleUpdate = async () => {
        setUpdating(true);
        let response
        try{
            response = await metalProfileInfoUpdate(
                id,
                updatedProfileInfo
            );
        }
        catch (e) {
            setUpdatedProfileInfo(profileInfo);
            setError(true);
        }
        
        setUpdating(false);
        setUpdateEnabled(false)
        if (response.status === 200) {
            setProfileInfo(updatedProfileInfo);
        } else {
            setUpdatedProfileInfo(profileInfo);
            setError(true);
        }
    }

    return (
        <Container className="border border-top-0 p-3 table-responsive" fluid style={{ backgroundColor: "white" }}>
            <h3 className="text-center d-block d-md-none">{t("Profiller")}</h3>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <div className="table-responsive">
                <Table className="border">
                    <thead>
                        <tr className="bg-light text-center">
                            <th>#</th>
                            <th>{t("Profil")}</th>
                            <th>{t("Malzeme")}</th>
                            <th>{t("Farklı Parça Adedi")}</th>
                            <th>{t("Toplam Parça Adedi")}</th>
                            <th>{t("Toplam Ağırlık")} (kg)</th>
                            <th>{t("Toplam Uzunluk")} (m)</th>
                            <th>{t("Optimizasyona Dahil mi?")}</th>
                            <th>{t("Kesim Yapılacak Boy")} (mm)</th>
                            <th>{t("Testere Kalınlığı")} (mm)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {updateEnabled ?
                            updatedProfileInfo.map((profile,index) => (
                                <tr key={index} style={{ height: "60px" }}>
                                    <td>{index+1}</td>
                                    <td>{profile.profile}</td>
                                    <td className="text-center">{profile.grade}</td>
                                    <td className="text-center">{getNumberOfUniquePieces(profile.profile)}</td>
                                    <td className="text-center">{getTotalNumberOfPieces(profile.profile)}</td>
                                    <td className="text-center">{getTotalWeight(profile.profile).toFixed(2)}</td>
                                    <td className="text-center">{getTotalLength(profile.profile)}</td>

                                    <td className="text-center">
                                        <Form.Select
                                            disabled={updating}
                                            size="sm"
                                            className="text-center"
                                            value={profile.include_in_optimization}
                                            onChange={(e) => {
                                                setUpdatedProfileInfo(
                                                    updatedProfileInfo.map((item) => {
                                                        if (item.group_id === profile.group_id) {
                                                            return {
                                                                ...item,
                                                                include_in_optimization: e.target.value
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }}
                                        >
                                            <option value={true}>{t("Evet")}</option>
                                            <option value={false}>{t("Hayır")}</option>
                                        </Form.Select>
                                    </td>
                                    <td className="text-center">
                                        <Form.Control
                                            disabled={updating}
                                            size="sm"
                                            className="text-center"
                                            type="number"
                                            min={1}
                                            value={profile.stock_length}
                                            onChange={(e) => {
                                                setUpdatedProfileInfo(
                                                    updatedProfileInfo.map((item) => {
                                                        if (item.group_id === profile.group_id) {
                                                            return {
                                                                ...item,
                                                                stock_length: Number(e.target.value)
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Form.Control
                                            disabled={updating}
                                            size="sm"
                                            className="text-center"
                                            style={{ maxWidth: "100px" }}
                                            type="number"
                                            min={0}
                                            value={profile.blade_thickness}
                                            onChange={(e) => {
                                                setUpdatedProfileInfo(
                                                    updatedProfileInfo.map((item) => {
                                                        if (item.group_id === profile.group_id) {
                                                            return {
                                                                ...item,
                                                                blade_thickness: Number(e.target.value)
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))
                            :
                            profileInfo.map((profile,index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td style={{height:"60px"}}>{profile.profile}</td>
                                    <td className="text-center">{profile.grade}</td>
                                    <td className="text-center">{getNumberOfUniquePieces(profile.profile)}</td>
                                    <td className="text-center">{getTotalNumberOfPieces(profile.profile)}</td>
                                    <td className="text-center">{getTotalWeight(profile.profile).toFixed(2)}</td>
                                    <td className="text-center">{getTotalLength(profile.profile)}</td>
                                    <td className="text-center">{getIncludeInOptimization(profile)}</td>
                                    <td className="text-center">{profile.stock_length}</td>
                                    <td className="text-center">{profile.blade_thickness}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan={3} className="fw-bold text-center" style={{height:"60px"}}>{t("TOPLAM")}</td>
                            <td className="text-center">{getNumberOfAllUniquePieces()}</td>
                            <td className="text-center">{getAllTotalNumberOfPieces()}</td>
                            <td className="text-center">{getAllTotalWeight().toFixed(2)}</td>
                            <td className="text-center">{getAllTotalLength()}</td>
                        </tr>

                    </tbody>
                </Table>

                <div className="text-center">
                    {!updateEnabled &&
                        <Button
                            variant="primary"
                            className="m-2"
                            onClick={() => {
                                setUpdateEnabled(true)
                                setError(false)
                            }}
                        >
                            {t("Düzenle")}
                        </Button>
                    }
                    {updateEnabled &&
                        <Button
                            disabled={updating || !updatedByUser}
                            variant="success"
                            className="m-2"
                            onClick={() => {
                                handleUpdate()
                            }}
                        >
                            {t("Tamamla")}
                        </Button>
                    }
                    {updateEnabled &&
                        <Button
                            disabled={updating}
                            variant="danger"
                            className="m-2"
                            onClick={() => {
                                setUpdatedProfileInfo(profileInfo)
                                setUpdateEnabled(false)
                            }}
                        >
                            {t("İptal")}
                        </Button>
                    }
                </div>
                {error && <div className="text-danger text-center mt-3">Bir hata oluştu. Lütfen tekrar deneyin.</div>}
            </div>
        </Container>
    );
};

export default ProfileStats;