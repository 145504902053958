/**
 * @typedef ISO31661AssignedEntry
 *   Object representing an assigned country.
 * @property {'assigned'} state
 *   State (example: `'assigned'`)
 * @property {string} alpha2
 *   ISO 3166-1 alpha-2 code (example: `'GB'`)
 * @property {string} alpha3
 *   ISO 3166-1 alpha-3 code (example: `'GBR'`)
 * @property {string} numeric
 *   ISO 3166-1 numeric (UN M49) code (example: `'826'`)
 * @property {string} name
 *   Name (example: `'United Kingdom of Great Britain and Northern Ireland'`)
 */

/**
 * List of assigned countries.
 *
 * @type {Array<ISO31661AssignedEntry>}
 */
export const iso31661 = [
    {
      name: 'Andorra',
      state: 'assigned',
      alpha2: 'AD',
      alpha3: 'AND',
      numeric: '020'
    },
    {
      name: 'United Arab Emirates',
      state: 'assigned',
      alpha2: 'AE',
      alpha3: 'ARE',
      numeric: '784'
    },
    {
      name: 'Afghanistan',
      state: 'assigned',
      alpha2: 'AF',
      alpha3: 'AFG',
      numeric: '004'
    },
    {
      name: 'Antigua and Barbuda',
      state: 'assigned',
      alpha2: 'AG',
      alpha3: 'ATG',
      numeric: '028'
    },
    {
      name: 'Anguilla',
      state: 'assigned',
      alpha2: 'AI',
      alpha3: 'AIA',
      numeric: '660'
    },
    {
      name: 'Albania',
      state: 'assigned',
      alpha2: 'AL',
      alpha3: 'ALB',
      numeric: '008'
    },
    {
      name: 'Armenia',
      state: 'assigned',
      alpha2: 'AM',
      alpha3: 'ARM',
      numeric: '051'
    },
    {
      name: 'Angola',
      state: 'assigned',
      alpha2: 'AO',
      alpha3: 'AGO',
      numeric: '024'
    },
    {
      name: 'Antarctica',
      state: 'assigned',
      alpha2: 'AQ',
      alpha3: 'ATA',
      numeric: '010'
    },
    {
      name: 'Argentina',
      state: 'assigned',
      alpha2: 'AR',
      alpha3: 'ARG',
      numeric: '032'
    },
    {
      name: 'American Samoa',
      state: 'assigned',
      alpha2: 'AS',
      alpha3: 'ASM',
      numeric: '016'
    },
    {
      name: 'Austria',
      state: 'assigned',
      alpha2: 'AT',
      alpha3: 'AUT',
      numeric: '040'
    },
    {
      name: 'Australia',
      state: 'assigned',
      alpha2: 'AU',
      alpha3: 'AUS',
      numeric: '036'
    },
    {
      name: 'Aruba',
      state: 'assigned',
      alpha2: 'AW',
      alpha3: 'ABW',
      numeric: '533'
    },
    {
      name: 'Åland Islands',
      state: 'assigned',
      alpha2: 'AX',
      alpha3: 'ALA',
      numeric: '248'
    },
    {
      name: 'Azerbaijan',
      state: 'assigned',
      alpha2: 'AZ',
      alpha3: 'AZE',
      numeric: '031'
    },
    {
      name: 'Bosnia and Herzegovina',
      state: 'assigned',
      alpha2: 'BA',
      alpha3: 'BIH',
      numeric: '070'
    },
    {
      name: 'Barbados',
      state: 'assigned',
      alpha2: 'BB',
      alpha3: 'BRB',
      numeric: '052'
    },
    {
      name: 'Bangladesh',
      state: 'assigned',
      alpha2: 'BD',
      alpha3: 'BGD',
      numeric: '050'
    },
    {
      name: 'Belgium',
      state: 'assigned',
      alpha2: 'BE',
      alpha3: 'BEL',
      numeric: '056'
    },
    {
      name: 'Burkina Faso',
      state: 'assigned',
      alpha2: 'BF',
      alpha3: 'BFA',
      numeric: '854'
    },
    {
      name: 'Bulgaria',
      state: 'assigned',
      alpha2: 'BG',
      alpha3: 'BGR',
      numeric: '100'
    },
    {
      name: 'Bahrain',
      state: 'assigned',
      alpha2: 'BH',
      alpha3: 'BHR',
      numeric: '048'
    },
    {
      name: 'Burundi',
      state: 'assigned',
      alpha2: 'BI',
      alpha3: 'BDI',
      numeric: '108'
    },
    {
      name: 'Benin',
      state: 'assigned',
      alpha2: 'BJ',
      alpha3: 'BEN',
      numeric: '204'
    },
    {
      name: 'Saint Barthélemy',
      state: 'assigned',
      alpha2: 'BL',
      alpha3: 'BLM',
      numeric: '652'
    },
    {
      name: 'Bermuda',
      state: 'assigned',
      alpha2: 'BM',
      alpha3: 'BMU',
      numeric: '060'
    },
    {
      name: 'Brunei Darussalam',
      state: 'assigned',
      alpha2: 'BN',
      alpha3: 'BRN',
      numeric: '096'
    },
    {
      name: 'Bolivia (Plurinational State of)',
      state: 'assigned',
      alpha2: 'BO',
      alpha3: 'BOL',
      numeric: '068'
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      state: 'assigned',
      alpha2: 'BQ',
      alpha3: 'BES',
      numeric: '535'
    },
    {
      name: 'Brazil',
      state: 'assigned',
      alpha2: 'BR',
      alpha3: 'BRA',
      numeric: '076'
    },
    {
      name: 'Bahamas',
      state: 'assigned',
      alpha2: 'BS',
      alpha3: 'BHS',
      numeric: '044'
    },
    {
      name: 'Bhutan',
      state: 'assigned',
      alpha2: 'BT',
      alpha3: 'BTN',
      numeric: '064'
    },
    {
      name: 'Bouvet Island',
      state: 'assigned',
      alpha2: 'BV',
      alpha3: 'BVT',
      numeric: '074'
    },
    {
      name: 'Botswana',
      state: 'assigned',
      alpha2: 'BW',
      alpha3: 'BWA',
      numeric: '072'
    },
    {
      name: 'Belarus',
      state: 'assigned',
      alpha2: 'BY',
      alpha3: 'BLR',
      numeric: '112'
    },
    {
      name: 'Belize',
      state: 'assigned',
      alpha2: 'BZ',
      alpha3: 'BLZ',
      numeric: '084'
    },
    {
      name: 'Canada',
      state: 'assigned',
      alpha2: 'CA',
      alpha3: 'CAN',
      numeric: '124'
    },
    {
      name: 'Cocos (Keeling) Islands',
      state: 'assigned',
      alpha2: 'CC',
      alpha3: 'CCK',
      numeric: '166'
    },
    {
      name: 'Congo, Democratic Republic of the',
      state: 'assigned',
      alpha2: 'CD',
      alpha3: 'COD',
      numeric: '180'
    },
    {
      name: 'Central African Republic',
      state: 'assigned',
      alpha2: 'CF',
      alpha3: 'CAF',
      numeric: '140'
    },
    {
      name: 'Congo',
      state: 'assigned',
      alpha2: 'CG',
      alpha3: 'COG',
      numeric: '178'
    },
    {
      name: 'Switzerland',
      state: 'assigned',
      alpha2: 'CH',
      alpha3: 'CHE',
      numeric: '756'
    },
    {
      name: "Côte d'Ivoire",
      state: 'assigned',
      alpha2: 'CI',
      alpha3: 'CIV',
      numeric: '384'
    },
    {
      name: 'Cook Islands',
      state: 'assigned',
      alpha2: 'CK',
      alpha3: 'COK',
      numeric: '184'
    },
    {
      name: 'Chile',
      state: 'assigned',
      alpha2: 'CL',
      alpha3: 'CHL',
      numeric: '152'
    },
    {
      name: 'Cameroon',
      state: 'assigned',
      alpha2: 'CM',
      alpha3: 'CMR',
      numeric: '120'
    },
    {
      name: 'China',
      state: 'assigned',
      alpha2: 'CN',
      alpha3: 'CHN',
      numeric: '156'
    },
    {
      name: 'Colombia',
      state: 'assigned',
      alpha2: 'CO',
      alpha3: 'COL',
      numeric: '170'
    },
    {
      name: 'Costa Rica',
      state: 'assigned',
      alpha2: 'CR',
      alpha3: 'CRI',
      numeric: '188'
    },
    {
      name: 'Cuba',
      state: 'assigned',
      alpha2: 'CU',
      alpha3: 'CUB',
      numeric: '192'
    },
    {
      name: 'Cabo Verde',
      state: 'assigned',
      alpha2: 'CV',
      alpha3: 'CPV',
      numeric: '132'
    },
    {
      name: 'Curaçao',
      state: 'assigned',
      alpha2: 'CW',
      alpha3: 'CUW',
      numeric: '531'
    },
    {
      name: 'Christmas Island',
      state: 'assigned',
      alpha2: 'CX',
      alpha3: 'CXR',
      numeric: '162'
    },
    {
      name: 'Cyprus',
      state: 'assigned',
      alpha2: 'CY',
      alpha3: 'CYP',
      numeric: '196'
    },
    {
      name: 'Czechia',
      state: 'assigned',
      alpha2: 'CZ',
      alpha3: 'CZE',
      numeric: '203'
    },
    {
      name: 'Germany',
      state: 'assigned',
      alpha2: 'DE',
      alpha3: 'DEU',
      numeric: '276'
    },
    {
      name: 'Djibouti',
      state: 'assigned',
      alpha2: 'DJ',
      alpha3: 'DJI',
      numeric: '262'
    },
    {
      name: 'Denmark',
      state: 'assigned',
      alpha2: 'DK',
      alpha3: 'DNK',
      numeric: '208'
    },
    {
      name: 'Dominica',
      state: 'assigned',
      alpha2: 'DM',
      alpha3: 'DMA',
      numeric: '212'
    },
    {
      name: 'Dominican Republic',
      state: 'assigned',
      alpha2: 'DO',
      alpha3: 'DOM',
      numeric: '214'
    },
    {
      name: 'Algeria',
      state: 'assigned',
      alpha2: 'DZ',
      alpha3: 'DZA',
      numeric: '012'
    },
    {
      name: 'Ecuador',
      state: 'assigned',
      alpha2: 'EC',
      alpha3: 'ECU',
      numeric: '218'
    },
    {
      name: 'Estonia',
      state: 'assigned',
      alpha2: 'EE',
      alpha3: 'EST',
      numeric: '233'
    },
    {
      name: 'Egypt',
      state: 'assigned',
      alpha2: 'EG',
      alpha3: 'EGY',
      numeric: '818'
    },
    {
      name: 'Western Sahara',
      state: 'assigned',
      alpha2: 'EH',
      alpha3: 'ESH',
      numeric: '732'
    },
    {
      name: 'Eritrea',
      state: 'assigned',
      alpha2: 'ER',
      alpha3: 'ERI',
      numeric: '232'
    },
    {
      name: 'Spain',
      state: 'assigned',
      alpha2: 'ES',
      alpha3: 'ESP',
      numeric: '724'
    },
    {
      name: 'Ethiopia',
      state: 'assigned',
      alpha2: 'ET',
      alpha3: 'ETH',
      numeric: '231'
    },
    {
      name: 'Finland',
      state: 'assigned',
      alpha2: 'FI',
      alpha3: 'FIN',
      numeric: '246'
    },
    {
      name: 'Fiji',
      state: 'assigned',
      alpha2: 'FJ',
      alpha3: 'FJI',
      numeric: '242'
    },
    {
      name: 'Falkland Islands (Malvinas)',
      state: 'assigned',
      alpha2: 'FK',
      alpha3: 'FLK',
      numeric: '238'
    },
    {
      name: 'Micronesia (Federated States of)',
      state: 'assigned',
      alpha2: 'FM',
      alpha3: 'FSM',
      numeric: '583'
    },
    {
      name: 'Faroe Islands',
      state: 'assigned',
      alpha2: 'FO',
      alpha3: 'FRO',
      numeric: '234'
    },
    {
      name: 'France',
      state: 'assigned',
      alpha2: 'FR',
      alpha3: 'FRA',
      numeric: '250'
    },
    {
      name: 'Gabon',
      state: 'assigned',
      alpha2: 'GA',
      alpha3: 'GAB',
      numeric: '266'
    },
    {
      name: 'United Kingdom of Great Britain and Northern Ireland',
      state: 'assigned',
      alpha2: 'GB',
      alpha3: 'GBR',
      numeric: '826'
    },
    {
      name: 'Grenada',
      state: 'assigned',
      alpha2: 'GD',
      alpha3: 'GRD',
      numeric: '308'
    },
    {
      name: 'Georgia',
      state: 'assigned',
      alpha2: 'GE',
      alpha3: 'GEO',
      numeric: '268'
    },
    {
      name: 'French Guiana',
      state: 'assigned',
      alpha2: 'GF',
      alpha3: 'GUF',
      numeric: '254'
    },
    {
      name: 'Guernsey',
      state: 'assigned',
      alpha2: 'GG',
      alpha3: 'GGY',
      numeric: '831'
    },
    {
      name: 'Ghana',
      state: 'assigned',
      alpha2: 'GH',
      alpha3: 'GHA',
      numeric: '288'
    },
    {
      name: 'Gibraltar',
      state: 'assigned',
      alpha2: 'GI',
      alpha3: 'GIB',
      numeric: '292'
    },
    {
      name: 'Greenland',
      state: 'assigned',
      alpha2: 'GL',
      alpha3: 'GRL',
      numeric: '304'
    },
    {
      name: 'Gambia',
      state: 'assigned',
      alpha2: 'GM',
      alpha3: 'GMB',
      numeric: '270'
    },
    {
      name: 'Guinea',
      state: 'assigned',
      alpha2: 'GN',
      alpha3: 'GIN',
      numeric: '324'
    },
    {
      name: 'Guadeloupe',
      state: 'assigned',
      alpha2: 'GP',
      alpha3: 'GLP',
      numeric: '312'
    },
    {
      name: 'Equatorial Guinea',
      state: 'assigned',
      alpha2: 'GQ',
      alpha3: 'GNQ',
      numeric: '226'
    },
    {
      name: 'Greece',
      state: 'assigned',
      alpha2: 'GR',
      alpha3: 'GRC',
      numeric: '300'
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      state: 'assigned',
      alpha2: 'GS',
      alpha3: 'SGS',
      numeric: '239'
    },
    {
      name: 'Guatemala',
      state: 'assigned',
      alpha2: 'GT',
      alpha3: 'GTM',
      numeric: '320'
    },
    {
      name: 'Guam',
      state: 'assigned',
      alpha2: 'GU',
      alpha3: 'GUM',
      numeric: '316'
    },
    {
      name: 'Guinea-Bissau',
      state: 'assigned',
      alpha2: 'GW',
      alpha3: 'GNB',
      numeric: '624'
    },
    {
      name: 'Guyana',
      state: 'assigned',
      alpha2: 'GY',
      alpha3: 'GUY',
      numeric: '328'
    },
    {
      name: 'Hong Kong',
      state: 'assigned',
      alpha2: 'HK',
      alpha3: 'HKG',
      numeric: '344'
    },
    {
      name: 'Heard Island and McDonald Islands',
      state: 'assigned',
      alpha2: 'HM',
      alpha3: 'HMD',
      numeric: '334'
    },
    {
      name: 'Honduras',
      state: 'assigned',
      alpha2: 'HN',
      alpha3: 'HND',
      numeric: '340'
    },
    {
      name: 'Croatia',
      state: 'assigned',
      alpha2: 'HR',
      alpha3: 'HRV',
      numeric: '191'
    },
    {
      name: 'Haiti',
      state: 'assigned',
      alpha2: 'HT',
      alpha3: 'HTI',
      numeric: '332'
    },
    {
      name: 'Hungary',
      state: 'assigned',
      alpha2: 'HU',
      alpha3: 'HUN',
      numeric: '348'
    },
    {
      name: 'Indonesia',
      state: 'assigned',
      alpha2: 'ID',
      alpha3: 'IDN',
      numeric: '360'
    },
    {
      name: 'Ireland',
      state: 'assigned',
      alpha2: 'IE',
      alpha3: 'IRL',
      numeric: '372'
    },
    {
      name: 'Israel',
      state: 'assigned',
      alpha2: 'IL',
      alpha3: 'ISR',
      numeric: '376'
    },
    {
      name: 'Isle of Man',
      state: 'assigned',
      alpha2: 'IM',
      alpha3: 'IMN',
      numeric: '833'
    },
    {
      name: 'India',
      state: 'assigned',
      alpha2: 'IN',
      alpha3: 'IND',
      numeric: '356'
    },
    {
      name: 'British Indian Ocean Territory',
      state: 'assigned',
      alpha2: 'IO',
      alpha3: 'IOT',
      numeric: '086'
    },
    {
      name: 'Iraq',
      state: 'assigned',
      alpha2: 'IQ',
      alpha3: 'IRQ',
      numeric: '368'
    },
    {
      name: 'Iran (Islamic Republic of)',
      state: 'assigned',
      alpha2: 'IR',
      alpha3: 'IRN',
      numeric: '364'
    },
    {
      name: 'Iceland',
      state: 'assigned',
      alpha2: 'IS',
      alpha3: 'ISL',
      numeric: '352'
    },
    {
      name: 'Italy',
      state: 'assigned',
      alpha2: 'IT',
      alpha3: 'ITA',
      numeric: '380'
    },
    {
      name: 'Jersey',
      state: 'assigned',
      alpha2: 'JE',
      alpha3: 'JEY',
      numeric: '832'
    },
    {
      name: 'Jamaica',
      state: 'assigned',
      alpha2: 'JM',
      alpha3: 'JAM',
      numeric: '388'
    },
    {
      name: 'Jordan',
      state: 'assigned',
      alpha2: 'JO',
      alpha3: 'JOR',
      numeric: '400'
    },
    {
      name: 'Japan',
      state: 'assigned',
      alpha2: 'JP',
      alpha3: 'JPN',
      numeric: '392'
    },
    {
      name: 'Kenya',
      state: 'assigned',
      alpha2: 'KE',
      alpha3: 'KEN',
      numeric: '404'
    },
    {
      name: 'Kyrgyzstan',
      state: 'assigned',
      alpha2: 'KG',
      alpha3: 'KGZ',
      numeric: '417'
    },
    {
      name: 'Cambodia',
      state: 'assigned',
      alpha2: 'KH',
      alpha3: 'KHM',
      numeric: '116'
    },
    {
      name: 'Kiribati',
      state: 'assigned',
      alpha2: 'KI',
      alpha3: 'KIR',
      numeric: '296'
    },
    {
      name: 'Comoros',
      state: 'assigned',
      alpha2: 'KM',
      alpha3: 'COM',
      numeric: '174'
    },
    {
      name: 'Saint Kitts and Nevis',
      state: 'assigned',
      alpha2: 'KN',
      alpha3: 'KNA',
      numeric: '659'
    },
    {
      name: "Korea (Democratic People's Republic of)",
      state: 'assigned',
      alpha2: 'KP',
      alpha3: 'PRK',
      numeric: '408'
    },
    {
      name: 'Korea, Republic of',
      state: 'assigned',
      alpha2: 'KR',
      alpha3: 'KOR',
      numeric: '410'
    },
    {
      name: 'Kuwait',
      state: 'assigned',
      alpha2: 'KW',
      alpha3: 'KWT',
      numeric: '414'
    },
    {
      name: 'Cayman Islands',
      state: 'assigned',
      alpha2: 'KY',
      alpha3: 'CYM',
      numeric: '136'
    },
    {
      name: 'Kazakhstan',
      state: 'assigned',
      alpha2: 'KZ',
      alpha3: 'KAZ',
      numeric: '398'
    },
    {
      name: "Lao People's Democratic Republic",
      state: 'assigned',
      alpha2: 'LA',
      alpha3: 'LAO',
      numeric: '418'
    },
    {
      name: 'Lebanon',
      state: 'assigned',
      alpha2: 'LB',
      alpha3: 'LBN',
      numeric: '422'
    },
    {
      name: 'Saint Lucia',
      state: 'assigned',
      alpha2: 'LC',
      alpha3: 'LCA',
      numeric: '662'
    },
    {
      name: 'Liechtenstein',
      state: 'assigned',
      alpha2: 'LI',
      alpha3: 'LIE',
      numeric: '438'
    },
    {
      name: 'Sri Lanka',
      state: 'assigned',
      alpha2: 'LK',
      alpha3: 'LKA',
      numeric: '144'
    },
    {
      name: 'Liberia',
      state: 'assigned',
      alpha2: 'LR',
      alpha3: 'LBR',
      numeric: '430'
    },
    {
      name: 'Lesotho',
      state: 'assigned',
      alpha2: 'LS',
      alpha3: 'LSO',
      numeric: '426'
    },
    {
      name: 'Lithuania',
      state: 'assigned',
      alpha2: 'LT',
      alpha3: 'LTU',
      numeric: '440'
    },
    {
      name: 'Luxembourg',
      state: 'assigned',
      alpha2: 'LU',
      alpha3: 'LUX',
      numeric: '442'
    },
    {
      name: 'Latvia',
      state: 'assigned',
      alpha2: 'LV',
      alpha3: 'LVA',
      numeric: '428'
    },
    {
      name: 'Libya',
      state: 'assigned',
      alpha2: 'LY',
      alpha3: 'LBY',
      numeric: '434'
    },
    {
      name: 'Morocco',
      state: 'assigned',
      alpha2: 'MA',
      alpha3: 'MAR',
      numeric: '504'
    },
    {
      name: 'Monaco',
      state: 'assigned',
      alpha2: 'MC',
      alpha3: 'MCO',
      numeric: '492'
    },
    {
      name: 'Moldova, Republic of',
      state: 'assigned',
      alpha2: 'MD',
      alpha3: 'MDA',
      numeric: '498'
    },
    {
      name: 'Montenegro',
      state: 'assigned',
      alpha2: 'ME',
      alpha3: 'MNE',
      numeric: '499'
    },
    {
      name: 'Saint Martin (French part)',
      state: 'assigned',
      alpha2: 'MF',
      alpha3: 'MAF',
      numeric: '663'
    },
    {
      name: 'Madagascar',
      state: 'assigned',
      alpha2: 'MG',
      alpha3: 'MDG',
      numeric: '450'
    },
    {
      name: 'Marshall Islands',
      state: 'assigned',
      alpha2: 'MH',
      alpha3: 'MHL',
      numeric: '584'
    },
    {
      name: 'North Macedonia',
      state: 'assigned',
      alpha2: 'MK',
      alpha3: 'MKD',
      numeric: '807'
    },
    {
      name: 'Mali',
      state: 'assigned',
      alpha2: 'ML',
      alpha3: 'MLI',
      numeric: '466'
    },
    {
      name: 'Myanmar',
      state: 'assigned',
      alpha2: 'MM',
      alpha3: 'MMR',
      numeric: '104'
    },
    {
      name: 'Mongolia',
      state: 'assigned',
      alpha2: 'MN',
      alpha3: 'MNG',
      numeric: '496'
    },
    {
      name: 'Macao',
      state: 'assigned',
      alpha2: 'MO',
      alpha3: 'MAC',
      numeric: '446'
    },
    {
      name: 'Northern Mariana Islands',
      state: 'assigned',
      alpha2: 'MP',
      alpha3: 'MNP',
      numeric: '580'
    },
    {
      name: 'Martinique',
      state: 'assigned',
      alpha2: 'MQ',
      alpha3: 'MTQ',
      numeric: '474'
    },
    {
      name: 'Mauritania',
      state: 'assigned',
      alpha2: 'MR',
      alpha3: 'MRT',
      numeric: '478'
    },
    {
      name: 'Montserrat',
      state: 'assigned',
      alpha2: 'MS',
      alpha3: 'MSR',
      numeric: '500'
    },
    {
      name: 'Malta',
      state: 'assigned',
      alpha2: 'MT',
      alpha3: 'MLT',
      numeric: '470'
    },
    {
      name: 'Mauritius',
      state: 'assigned',
      alpha2: 'MU',
      alpha3: 'MUS',
      numeric: '480'
    },
    {
      name: 'Maldives',
      state: 'assigned',
      alpha2: 'MV',
      alpha3: 'MDV',
      numeric: '462'
    },
    {
      name: 'Malawi',
      state: 'assigned',
      alpha2: 'MW',
      alpha3: 'MWI',
      numeric: '454'
    },
    {
      name: 'Mexico',
      state: 'assigned',
      alpha2: 'MX',
      alpha3: 'MEX',
      numeric: '484'
    },
    {
      name: 'Malaysia',
      state: 'assigned',
      alpha2: 'MY',
      alpha3: 'MYS',
      numeric: '458'
    },
    {
      name: 'Mozambique',
      state: 'assigned',
      alpha2: 'MZ',
      alpha3: 'MOZ',
      numeric: '508'
    },
    {
      name: 'Namibia',
      state: 'assigned',
      alpha2: 'NA',
      alpha3: 'NAM',
      numeric: '516'
    },
    {
      name: 'New Caledonia',
      state: 'assigned',
      alpha2: 'NC',
      alpha3: 'NCL',
      numeric: '540'
    },
    {
      name: 'Niger',
      state: 'assigned',
      alpha2: 'NE',
      alpha3: 'NER',
      numeric: '562'
    },
    {
      name: 'Norfolk Island',
      state: 'assigned',
      alpha2: 'NF',
      alpha3: 'NFK',
      numeric: '574'
    },
    {
      name: 'Nigeria',
      state: 'assigned',
      alpha2: 'NG',
      alpha3: 'NGA',
      numeric: '566'
    },
    {
      name: 'Nicaragua',
      state: 'assigned',
      alpha2: 'NI',
      alpha3: 'NIC',
      numeric: '558'
    },
    {
      name: 'Netherlands, Kingdom of the',
      state: 'assigned',
      alpha2: 'NL',
      alpha3: 'NLD',
      numeric: '528'
    },
    {
      name: 'Norway',
      state: 'assigned',
      alpha2: 'NO',
      alpha3: 'NOR',
      numeric: '578'
    },
    {
      name: 'Nepal',
      state: 'assigned',
      alpha2: 'NP',
      alpha3: 'NPL',
      numeric: '524'
    },
    {
      name: 'Nauru',
      state: 'assigned',
      alpha2: 'NR',
      alpha3: 'NRU',
      numeric: '520'
    },
    {
      name: 'Niue',
      state: 'assigned',
      alpha2: 'NU',
      alpha3: 'NIU',
      numeric: '570'
    },
    {
      name: 'New Zealand',
      state: 'assigned',
      alpha2: 'NZ',
      alpha3: 'NZL',
      numeric: '554'
    },
    {
      name: 'Oman',
      state: 'assigned',
      alpha2: 'OM',
      alpha3: 'OMN',
      numeric: '512'
    },
    {
      name: 'Panama',
      state: 'assigned',
      alpha2: 'PA',
      alpha3: 'PAN',
      numeric: '591'
    },
    {
      name: 'Peru',
      state: 'assigned',
      alpha2: 'PE',
      alpha3: 'PER',
      numeric: '604'
    },
    {
      name: 'French Polynesia',
      state: 'assigned',
      alpha2: 'PF',
      alpha3: 'PYF',
      numeric: '258'
    },
    {
      name: 'Papua New Guinea',
      state: 'assigned',
      alpha2: 'PG',
      alpha3: 'PNG',
      numeric: '598'
    },
    {
      name: 'Philippines',
      state: 'assigned',
      alpha2: 'PH',
      alpha3: 'PHL',
      numeric: '608'
    },
    {
      name: 'Pakistan',
      state: 'assigned',
      alpha2: 'PK',
      alpha3: 'PAK',
      numeric: '586'
    },
    {
      name: 'Poland',
      state: 'assigned',
      alpha2: 'PL',
      alpha3: 'POL',
      numeric: '616'
    },
    {
      name: 'Saint Pierre and Miquelon',
      state: 'assigned',
      alpha2: 'PM',
      alpha3: 'SPM',
      numeric: '666'
    },
    {
      name: 'Pitcairn',
      state: 'assigned',
      alpha2: 'PN',
      alpha3: 'PCN',
      numeric: '612'
    },
    {
      name: 'Puerto Rico',
      state: 'assigned',
      alpha2: 'PR',
      alpha3: 'PRI',
      numeric: '630'
    },
    {
      name: 'Palestine, State of',
      state: 'assigned',
      alpha2: 'PS',
      alpha3: 'PSE',
      numeric: '275'
    },
    {
      name: 'Portugal',
      state: 'assigned',
      alpha2: 'PT',
      alpha3: 'PRT',
      numeric: '620'
    },
    {
      name: 'Palau',
      state: 'assigned',
      alpha2: 'PW',
      alpha3: 'PLW',
      numeric: '585'
    },
    {
      name: 'Paraguay',
      state: 'assigned',
      alpha2: 'PY',
      alpha3: 'PRY',
      numeric: '600'
    },
    {
      name: 'Qatar',
      state: 'assigned',
      alpha2: 'QA',
      alpha3: 'QAT',
      numeric: '634'
    },
    {
      name: 'Réunion',
      state: 'assigned',
      alpha2: 'RE',
      alpha3: 'REU',
      numeric: '638'
    },
    {
      name: 'Romania',
      state: 'assigned',
      alpha2: 'RO',
      alpha3: 'ROU',
      numeric: '642'
    },
    {
      name: 'Serbia',
      state: 'assigned',
      alpha2: 'RS',
      alpha3: 'SRB',
      numeric: '688'
    },
    {
      name: 'Russian Federation',
      state: 'assigned',
      alpha2: 'RU',
      alpha3: 'RUS',
      numeric: '643'
    },
    {
      name: 'Rwanda',
      state: 'assigned',
      alpha2: 'RW',
      alpha3: 'RWA',
      numeric: '646'
    },
    {
      name: 'Saudi Arabia',
      state: 'assigned',
      alpha2: 'SA',
      alpha3: 'SAU',
      numeric: '682'
    },
    {
      name: 'Solomon Islands',
      state: 'assigned',
      alpha2: 'SB',
      alpha3: 'SLB',
      numeric: '090'
    },
    {
      name: 'Seychelles',
      state: 'assigned',
      alpha2: 'SC',
      alpha3: 'SYC',
      numeric: '690'
    },
    {
      name: 'Sudan',
      state: 'assigned',
      alpha2: 'SD',
      alpha3: 'SDN',
      numeric: '729'
    },
    {
      name: 'Sweden',
      state: 'assigned',
      alpha2: 'SE',
      alpha3: 'SWE',
      numeric: '752'
    },
    {
      name: 'Singapore',
      state: 'assigned',
      alpha2: 'SG',
      alpha3: 'SGP',
      numeric: '702'
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      state: 'assigned',
      alpha2: 'SH',
      alpha3: 'SHN',
      numeric: '654'
    },
    {
      name: 'Slovenia',
      state: 'assigned',
      alpha2: 'SI',
      alpha3: 'SVN',
      numeric: '705'
    },
    {
      name: 'Svalbard and Jan Mayen',
      state: 'assigned',
      alpha2: 'SJ',
      alpha3: 'SJM',
      numeric: '744'
    },
    {
      name: 'Slovakia',
      state: 'assigned',
      alpha2: 'SK',
      alpha3: 'SVK',
      numeric: '703'
    },
    {
      name: 'Sierra Leone',
      state: 'assigned',
      alpha2: 'SL',
      alpha3: 'SLE',
      numeric: '694'
    },
    {
      name: 'San Marino',
      state: 'assigned',
      alpha2: 'SM',
      alpha3: 'SMR',
      numeric: '674'
    },
    {
      name: 'Senegal',
      state: 'assigned',
      alpha2: 'SN',
      alpha3: 'SEN',
      numeric: '686'
    },
    {
      name: 'Somalia',
      state: 'assigned',
      alpha2: 'SO',
      alpha3: 'SOM',
      numeric: '706'
    },
    {
      name: 'Suriname',
      state: 'assigned',
      alpha2: 'SR',
      alpha3: 'SUR',
      numeric: '740'
    },
    {
      name: 'South Sudan',
      state: 'assigned',
      alpha2: 'SS',
      alpha3: 'SSD',
      numeric: '728'
    },
    {
      name: 'Sao Tome and Principe',
      state: 'assigned',
      alpha2: 'ST',
      alpha3: 'STP',
      numeric: '678'
    },
    {
      name: 'El Salvador',
      state: 'assigned',
      alpha2: 'SV',
      alpha3: 'SLV',
      numeric: '222'
    },
    {
      name: 'Sint Maarten (Dutch part)',
      state: 'assigned',
      alpha2: 'SX',
      alpha3: 'SXM',
      numeric: '534'
    },
    {
      name: 'Syrian Arab Republic',
      state: 'assigned',
      alpha2: 'SY',
      alpha3: 'SYR',
      numeric: '760'
    },
    {
      name: 'Eswatini',
      state: 'assigned',
      alpha2: 'SZ',
      alpha3: 'SWZ',
      numeric: '748'
    },
    {
      name: 'Turks and Caicos Islands',
      state: 'assigned',
      alpha2: 'TC',
      alpha3: 'TCA',
      numeric: '796'
    },
    {
      name: 'Chad',
      state: 'assigned',
      alpha2: 'TD',
      alpha3: 'TCD',
      numeric: '148'
    },
    {
      name: 'French Southern Territories',
      state: 'assigned',
      alpha2: 'TF',
      alpha3: 'ATF',
      numeric: '260'
    },
    {
      name: 'Togo',
      state: 'assigned',
      alpha2: 'TG',
      alpha3: 'TGO',
      numeric: '768'
    },
    {
      name: 'Thailand',
      state: 'assigned',
      alpha2: 'TH',
      alpha3: 'THA',
      numeric: '764'
    },
    {
      name: 'Tajikistan',
      state: 'assigned',
      alpha2: 'TJ',
      alpha3: 'TJK',
      numeric: '762'
    },
    {
      name: 'Tokelau',
      state: 'assigned',
      alpha2: 'TK',
      alpha3: 'TKL',
      numeric: '772'
    },
    {
      name: 'Timor-Leste',
      state: 'assigned',
      alpha2: 'TL',
      alpha3: 'TLS',
      numeric: '626'
    },
    {
      name: 'Turkmenistan',
      state: 'assigned',
      alpha2: 'TM',
      alpha3: 'TKM',
      numeric: '795'
    },
    {
      name: 'Tunisia',
      state: 'assigned',
      alpha2: 'TN',
      alpha3: 'TUN',
      numeric: '788'
    },
    {
      name: 'Tonga',
      state: 'assigned',
      alpha2: 'TO',
      alpha3: 'TON',
      numeric: '776'
    },
    {
      name: 'Türkiye',
      state: 'assigned',
      alpha2: 'TR',
      alpha3: 'TUR',
      numeric: '792'
    },
    {
      name: 'Trinidad and Tobago',
      state: 'assigned',
      alpha2: 'TT',
      alpha3: 'TTO',
      numeric: '780'
    },
    {
      name: 'Tuvalu',
      state: 'assigned',
      alpha2: 'TV',
      alpha3: 'TUV',
      numeric: '798'
    },
    {
      name: 'Taiwan, Province of China',
      state: 'assigned',
      alpha2: 'TW',
      alpha3: 'TWN',
      numeric: '158'
    },
    {
      name: 'Tanzania, United Republic of',
      state: 'assigned',
      alpha2: 'TZ',
      alpha3: 'TZA',
      numeric: '834'
    },
    {
      name: 'Ukraine',
      state: 'assigned',
      alpha2: 'UA',
      alpha3: 'UKR',
      numeric: '804'
    },
    {
      name: 'Uganda',
      state: 'assigned',
      alpha2: 'UG',
      alpha3: 'UGA',
      numeric: '800'
    },
    {
      name: 'United States Minor Outlying Islands',
      state: 'assigned',
      alpha2: 'UM',
      alpha3: 'UMI',
      numeric: '581'
    },
    {
      name: 'United States of America',
      state: 'assigned',
      alpha2: 'US',
      alpha3: 'USA',
      numeric: '840'
    },
    {
      name: 'Uruguay',
      state: 'assigned',
      alpha2: 'UY',
      alpha3: 'URY',
      numeric: '858'
    },
    {
      name: 'Uzbekistan',
      state: 'assigned',
      alpha2: 'UZ',
      alpha3: 'UZB',
      numeric: '860'
    },
    {
      name: 'Holy See',
      state: 'assigned',
      alpha2: 'VA',
      alpha3: 'VAT',
      numeric: '336'
    },
    {
      name: 'Saint Vincent and the Grenadines',
      state: 'assigned',
      alpha2: 'VC',
      alpha3: 'VCT',
      numeric: '670'
    },
    {
      name: 'Venezuela (Bolivarian Republic of)',
      state: 'assigned',
      alpha2: 'VE',
      alpha3: 'VEN',
      numeric: '862'
    },
    {
      name: 'Virgin Islands (British)',
      state: 'assigned',
      alpha2: 'VG',
      alpha3: 'VGB',
      numeric: '092'
    },
    {
      name: 'Virgin Islands (U.S.)',
      state: 'assigned',
      alpha2: 'VI',
      alpha3: 'VIR',
      numeric: '850'
    },
    {
      name: 'Viet Nam',
      state: 'assigned',
      alpha2: 'VN',
      alpha3: 'VNM',
      numeric: '704'
    },
    {
      name: 'Vanuatu',
      state: 'assigned',
      alpha2: 'VU',
      alpha3: 'VUT',
      numeric: '548'
    },
    {
      name: 'Wallis and Futuna',
      state: 'assigned',
      alpha2: 'WF',
      alpha3: 'WLF',
      numeric: '876'
    },
    {
      name: 'Samoa',
      state: 'assigned',
      alpha2: 'WS',
      alpha3: 'WSM',
      numeric: '882'
    },
    {
      name: 'Yemen',
      state: 'assigned',
      alpha2: 'YE',
      alpha3: 'YEM',
      numeric: '887'
    },
    {
      name: 'Mayotte',
      state: 'assigned',
      alpha2: 'YT',
      alpha3: 'MYT',
      numeric: '175'
    },
    {
      name: 'South Africa',
      state: 'assigned',
      alpha2: 'ZA',
      alpha3: 'ZAF',
      numeric: '710'
    },
    {
      name: 'Zambia',
      state: 'assigned',
      alpha2: 'ZM',
      alpha3: 'ZMB',
      numeric: '894'
    },
    {
      name: 'Zimbabwe',
      state: 'assigned',
      alpha2: 'ZW',
      alpha3: 'ZWE',
      numeric: '716'
    }
  ]