import React from 'react';
import styles from './RebarTable.module.css';
import { useTranslation } from 'react-i18next';

const RebarTable = (props) => {

    const {t} = useTranslation();

    const data = props.data;
    let floorAvailable = false;

    if (data.length > 0) {
        floorAvailable = "floor" in data[0]
    }

    return (
        <div className={`mb-3 border ${styles.tableWrap}`}>
            <table className={`table ${styles.table} text-center align-middle`}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th>#</th>
                        {floorAvailable ?
                            <>
                                <th>{t("Donatı Kodu")}</th>
                            </>
                            : <></>}
                        <th>{t("Adet")}</th>
                        <th>{t("Uzunluk")}</th>
                        <th>{t("Çap")}</th>
                        <th>{t("Poz")}</th>
                        {floorAvailable ?
                            <>
                                <th>{t("Kat")}</th>
                                <th>{t("Eleman")}</th>
                                <th>{t("Eleman Kodu")}</th>
                                <th>{t("Açıklama")}</th>
                            </>
                            : <></>}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => {
                        return <tr key={row.id}>
                            <td>{row.id}</td>
                            {floorAvailable ?
                            <>
                                <td>{row.code}</td>
                            </>
                            : <></>}
                            <td>{row.number}</td>
                            <td>{row.length}</td>
                            <td>{row.diameter}</td>
                            <td>{row.poz}</td>
                            {floorAvailable ?
                                <>
                                    <td>{row.floor}</td>
                                    <td>{row.element}</td>
                                    <td>{row.elementCode}</td>
                                    <td>{row.explanation}</td>
                                </>
                                : <></>}
                        </tr>;
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default RebarTable;