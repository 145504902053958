import React from 'react';

import { useTranslation } from 'react-i18next';

import PricesEn from './localePages/PricesEn';
import PricesTr from './localePages/PricesTr';

const Prices = () => {

    const { i18n, t } = useTranslation();

    return (
        i18n.language === 'en' ? <PricesEn /> : <PricesTr />
    );
};

export default Prices;
