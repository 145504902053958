import apiClient from "../apiClient";

export const verifyAdminAuthCode = async (code) => {
    const url = `${process.env.REACT_APP_API_URL}/api/verify-admin-auth-code/`;

    const response = await apiClient(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "code": code,
        }),
    });

    return response;

}