export const calculateWeight = (diameter, length) => {
    const density = 7.85
    const radius = diameter / 2 * 1e-1
    const A = Math.PI * radius ** 2
    const V = A * length
    const mass = V * density
    return mass * 1e-3
}

export function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
}