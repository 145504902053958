import React from 'react';
import styles from './SubstitutionTable.module.css'
import { useTranslation } from 'react-i18next';

const SubstitutionTable = ({ substitutes, donatiDict, floorAvailable, ratio }) => {
    const { t } = useTranslation()
    let nofColumns = floorAvailable ? 8 : 3
    let width = 100 / nofColumns

    let fontSize1 = `${12 * ratio}pt`
    // let fontSize2 = `${11 * ratio}pt`
    // let fontSize3 = `${10 * ratio}pt`
    // let fontSize4 = `${9 * ratio}pt`

    // let marginSize1 = `${6 * ratio}pt`
    // let marginSize2 = `${2 * ratio}pt`
    // let marginSize3 = `${1 * ratio}pt`

    return (
        <div className={`mb-3 border ${styles.tableWrap}`} style={{fontSize:`${fontSize1}`}}>
            <table className={`table table-sm table-striped text-center align-middle`}>
                <thead className={styles.tableHeader}>
                    <tr>
                        {floorAvailable ?
                            <th scope="col" style={{ width: `${width}%` }}>{t("Kesim No.")}</th>
                            : <></>}
                        <th scope="col" style={{ width: `${width}%` }}>{t("Adet")}</th>
                        <th scope="col" style={{ width: `${width}%` }}>{t("Asıl Donatı")}</th>
                        <th scope="col" style={{ width: `${width}%` }}>{t("Yerine Kulanılan")}</th>
                        {floorAvailable ?
                            <>
                                <th scope="col" style={{ width: `${width}%` }}>{t("Kat")}</th>
                                <th scope="col" style={{ width: `${width}%` }}>{t("Y. Elemanı")}</th>
                                <th scope="col" style={{ width: `${width}%` }}>{t("Eleman İsmi")}</th>
                                <th scope="col" style={{ width: `${width}%` }}>{t("Açıklama")}</th>
                            </>
                            : <></>}
                    </tr>
                </thead>
                <tbody>
                    {substitutes.map((sub,i) => (
                        sub.nCuts > 0 ?
                        <tr key={i}>
                            {floorAvailable ?
                                <td>{`${sub.patternNo}`}</td>
                                : <></>}
                            <td>{sub.nCuts}</td>
                            <td>{sub.actual}</td>
                            <td>{sub.subs_by}</td>
                            {floorAvailable ?
                                <>
                                    <td>{donatiDict[sub.actual]["kat"]}</td>
                                    <td>{donatiDict[sub.actual]["yapiElemani"]}</td>
                                    <td>{donatiDict[sub.actual]["yapiElemaniKodu"]}</td>
                                    <td>{donatiDict[sub.actual]["aciklama"]}</td>
                                </>
                                : <></>}
                        </tr> : <></>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default SubstitutionTable