import React, { useState } from "react";
import { useParams } from "react-router-dom";

import LoadingContainer from "../../components/LoadingContainer/LoadingContainer";

import useCpInfoInitializer from "../../hooks/useCpInfo/useCpInfoInitializer";
import mainStyles from '../Pages.module.css';

import AppNavbar from "../../components/AppNavbar/AppNavbar";
import Footer from "../../components/Footer/Footer";

import RebarCpInfo from "./RebarCpInfo/RebarCpInfo";
import MetalProfileCpInfo from "./MetalProfileCpInfo/MetalProfileCpInfo";

const CpInfoInitializer = () => {
    const { id } = useParams();

    const {
        isLoading,
        result,
        projectUnitPrice,
        unitDiscount,
        unitPriceType,
        purchasable,
        userType,
        ownCp,
        owner,
        backgroundTask,
    } = useCpInfoInitializer(id);

    let projectId = -1

    let content = <div />;
    if (isLoading) {
        content = LoadingContainer;
    }
    else {
        const projectType = result.projectType;
        projectId = result.projectId;
        if (projectType === "rebar") {
            content = <RebarCpInfo
                id={id}
                result={result}
                projectUnitPrice={projectUnitPrice}
                unitDiscount={unitDiscount}
                unitPriceType={unitPriceType}
                purchasable={purchasable}
                userType={userType}
                ownCp={ownCp}
                owner={owner}
                backgroundTask={backgroundTask}
            />;
        }
        else {
            content = <MetalProfileCpInfo
                id={id}
                result={result}
                projectUnitPrice={projectUnitPrice}
                unitDiscount={unitDiscount}
                unitPriceType={unitPriceType}
                purchasable={purchasable}
                userType={userType}
                ownCp={ownCp}
                owner={owner}
                backgroundTask={backgroundTask}
            />;
        }
    }
    
    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar projectId={projectId} />
            <div className={mainStyles.content}>
                {content}
            </div>
            <Footer />
        </div >
    );

}

export default CpInfoInitializer;