import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

const homeURL = process.env.REACT_APP_HOME_URL
const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

const TermsAndConditionsEn = () => {
    document.title = "Terms and Conditions"

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>Membership Agreement</h2>


                    <ol>
                        <h5 className='mb-4 fw-bold'><li>Parties and Definitions</li></h5>
                        <ol>
                            <li className='my-1'>This Membership Agreement ("Agreement") has been entered into as of the date of acceptance of this Agreement by the user ("Member") who agrees to the terms and conditions herein, and Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi ("Teber Soft"), the owner of the web-based software called Tasnifer ("Software") located at Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3 Şahinbey / Gaziantep address, in order to determine the conditions to which the Member is subject while using the Software. The Member and Teber Soft shall be referred to as "Party" and "Parties" respectively. </li>
                            <li className='my-1'>The construction steel optimization program called Tasnifer, which is accessed via the web address <a href={`${homeURL}/en`}>{homeURL}</a> and is the subject of the Agreement, will hereinafter be referred to as the Software.</li>
                            <li className='my-1'>Electronic files containing the detailed schedule of a construction project, produced by third-party Construction applications determined by Teber Soft or prepared by the Customer in a format determined by Teber Soft, and uploaded to the Software, will be referred to as Construction Project or Construction Projects.</li>
                            <li className='my-1'>All electronic documents and web pages containing the most optimum steel cutting plan and details about this cutting plan, generated by the Software's optimization algorithms as a result of processing the Construction Projects, will be referred to as Cutting Plan or Cutting Plans.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Services Subject to the Agreement</li></h5>
                        <ol>
                            <li className='my-1'>The Software generates Cutting Plans by processing Construction Projects with optimization algorithms.</li>
                            <li className='my-1'>In the Cutting Plans, the Member will be able to learn in advance how much steel will be used in the construction project and how much scrap will be obtained from this steel.</li>
                            <li className='my-1'>After uploading the Construction Project, the Member will be able to interactively examine the reinforcement schedule in the Construction Project.</li>
                            <li className='my-1'>The right to access the Cutting Plans is sold to the Member by Teber Soft.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Rights and Obligations of the Parties</li></h5>
                        <ol>
                            <li className='my-1'>The Member agrees and undertakes to comply with all legal regulations and the terms of this Agreement while using the Software and not to violate the said regulations. Otherwise, the Member will be solely responsible for all legal and criminal sanctions that may arise.</li>
                            <li className='my-1'>It is illegal to use the Software or any content, documents or electronic materials produced by the Software in a manner contrary to the terms and conditions set forth in this Agreement and the applicable legal regulations. Teber Soft reserves the right to file a lawsuit in case of detection of such situations.</li>
                            <li className='my-1'><a href={`${homeURL}/en/privacy-policy`}>{homeURL}/privacy-policy</a> web address Privacy Policy regulates the rights and responsibilities of Teber Soft and the Member regarding the personal data that the Member shares with Teber Soft while using the Software. The Privacy Policy is an integral part of this Agreement. The Member acknowledges that they have read, understood and accepted the Privacy Policy as long as they use the Software.</li>
                            <li className='my-1'>The Member cannot request access to the source code of the Software or to servers, databases and other units.</li>
                            <li className='my-1'>The Member is responsible for protecting their membership information. The Member is obliged to protect and store the email and password information they specified while registering for the Software. Teber Soft cannot be held responsible for any damages that may arise due to the Member's weakness and the information falling into the hands of third parties.</li>
                            <li className='my-1'>The Member cannot assign, lease, or allow another person or institution to use the rights they have acquired with this Agreement and by using the Software, for free or for a fee. If such a situation is detected, Teber Soft reserves the right to permanently cancel the service received by the Member without notice.</li>
                            <li className='my-1'>Since the Software is developed in a web-based manner, it requires an internet connection. Teber Soft cannot be held responsible for the Member's inability to receive service due to problems that may arise with the internet connection.</li>
                            <li className='my-1'>The Member undertakes not to use the Software in any way that will cause technical damage to the Software and the servers and databases where the components of the Software are hosted, harm Teber Soft financially, or violate intellectual and industrial property rights.</li>
                            <li className='my-1'>Teber Soft cannot be held responsible for the incorrect or illegal use of the Software by the Member.</li>
                            <li className='my-1'>The Member declares that the information they provide to Teber Soft while purchasing the services offered by the Software is accurate and up-to-date.</li>
                            <li className='my-1'>Teber Soft is not responsible for the Member's inability to receive service due to version updates made by third-party construction program producers who produce the Construction Project.</li>
                            <li className='my-1'>Teber Soft is not responsible for the Member's inability to receive service due to uploading a Construction Project to the Software in a format different from the formats that can be processed in the Software.</li>
                            <li className='my-1'>Teber Soft reserves the right to make any updates and bug fixes to the technical specifications and documents of the Software at its own discretion and timing.</li>
                            <li className='my-1'>Teber Soft is not responsible for the on-site execution, logistics, errors made by subcontractors in the application of the cutting plan, and other errors arising from the execution of this Cutting Plan. The accuracy of the outputs calculated by the Software depends on full adherence to the Cutting Plan. Since cutting operations performed contrary to the Cutting Plan will not be compatible with the calculations made by the Software, Teber Soft cannot be held responsible for the Member's actions contrary to the Cutting Plan.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Confidentiality</li></h5>
                        <ol>
                            <li className='my-1'>Teber Soft undertakes to act in accordance with all legal regulations, including the Law No. 6698 on the Protection of Personal Data, regarding the processing, security and storage of personal information provided by the Member for the purpose of using the Software. Accordingly, Teber Soft can collect, use, transfer and process the Member's personal data in various ways in accordance with the Privacy Policy specified at the web address <a href={`${homeURL}/en/privacy-policy`}>{homeURL}/privacy-policy</a>. The Privacy Policy is considered an inseparable part of this Membership Agreement.</li>
                            <li className='my-1'>By using the Software, the Member consciously and freely accepts the collection, use, sharing and other processing of their personal data as specified in the Privacy Policy. You can review our Privacy Policy for more information about the details of the use of personal data and your rights in this regard. You can also send an email to <a href={`mailto:${contactEmail}`}>{contactEmail}</a> to exercise your legal rights.</li>
                            <li className='my-1'>The Member acknowledges and expresses their consent to the processing and protection of their personal data information by Teber Soft in this way. Teber Soft undertakes to take all necessary measures to ensure that the aforementioned personal data is kept securely in accordance with Article 12 of the Law No. 6698 on the Protection of Personal Data, and to prevent unauthorized access and illegal data processing. The Member, based on Article 11 of the Law No. 6698 on the Protection of Personal Data, can exercise their rights on their personal data and make changes or updates to this data at any time.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Intellectual and Industrial Property Rights</li></h5>
                        <ol>
                            <li className='my-1'>All intellectual property rights relating to the design, software, and any documents and electronic materials produced by the Software belong to Teber Soft and cannot be used by the Member without Teber Soft's written permission. No material produced by the Software can be sold, distributed for commercial gain, modified, or included in any other work, publication, application, or website.</li>
                            <li className='my-1'>In the event of a direct infringement of Teber Soft's intellectual property rights by the Member, or the infringement of Teber Soft's intellectual property rights due to the Member's weakness, the Member shall be liable for all direct and indirect damages with compensation.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Pricing and Payment</li></h5>
                        <ol>
                            <li className='my-1'>Teber Soft has determined two methods for the sale of services offered in the Software: pay-as-you-go and subscription. The Customer has the right to create Cutting Plans within the limits of the relevant method, following the payment of the determined fees in both methods.</li>

                            <li className='my-1'>In the pay-as-you-go method, the Customer pays the fee calculated by the Software based on the weight of the steel calculated by the Software in the uploaded Construction Project. After paying this fee, the Customer has the right to optimize the steel in this Construction Project and create Cutting Plans. In this method, the Customer must make a new payment to obtain the Cutting Plans of other Construction Projects.</li>

                            <li className='my-1'>In the subscription method, the Customer purchases a weight entitlement ("Credit") predetermined by Teber Soft. Teber Soft determines a fee per unit weight. The Customer's Credit is reduced by the weight of the Construction Project for each Construction Project for which they create Cutting Plans. The Customer does not pay any fees after purchasing Credit until they exhaust their allocated Credit. If the Customer's credit is insufficient for the uploaded project, they must purchase new credit.</li>

                            <li className='my-1'>The fees per unit weight may differ between the pay-as-you-go and subscription methods. The Customer gains the right to create a Cutting Plan upon payment of the fee. Teber Soft reserves the right to change the fees. In their purchases, the Customer accepts that the terms and conditions of the Sales Agreement determined by Teber Soft are applicable.</li>

                            <li className='my-1'>The Member accepts that the terms and conditions of the Sales Agreement determined by Teber Soft are applicable in their purchases.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Changes to the Agreements</li></h5>
                        <p>Teber Soft has the unilateral right to change all policies, terms and conditions, including this Membership Agreement and the Privacy Policy in the Software, on condition that they are not contrary to applicable legal regulations, by making an announcement on the Software. The amended articles of this Membership Agreement will enter into effect on the date they are announced on the Software, while the remaining articles will continue to be valid and have their effects.</p>

                        <h5 className='my-4 fw-bold'><li>Termination of the Agreement</li></h5>
                        <p>Teber Soft may terminate this Agreement in the presence of a justified reason. The Member can terminate this Agreement at any time by notifying Teber Soft in writing to the address <a href={`mailto:${contactEmail}`}>{contactEmail}</a>. The Agreement will terminate on the date the written notification reaches Teber Soft, and Teber Soft will delete the Member's registration from the database.</p>

                        <h5 className='my-4 fw-bold'><li>Force Majeure</li></h5>
                        <p>If any events beyond Teber Soft's control, not caused by Teber Soft's fault and not reasonably foreseeable, such as uprising, embargo, government intervention, rebellion, occupation, war, mobilization, strike, lockout, labor-employer conflicts, cyber attacks, communication disruptions, infrastructure and internet problems, delays caused by system development or renewal activities, power cuts, fire, explosion, storm, flood, earthquake, landslide, epidemics or other natural disasters, or ("Force Majeure") prevent or delay Teber Soft from fulfilling its obligations arising from this Agreement, Teber Soft cannot be held responsible for its obligations that cannot be fulfilled or delayed as a result of Force Majeure, and this situation will not be considered a breach of this Agreement.</p>
                        <p>In such cases, Teber Soft will notify the Member of the Force Majeure event in writing as soon as possible. If the Force Majeure event continues for more than 30 (thirty) days, the Member has the right to terminate this Agreement by notifying Teber Soft in writing. The Member acknowledges that Teber Soft is not responsible for any damages that may arise due to the Force Majeure event.</p>

                        <h5 className='my-4 fw-bold'><li>Dispute Resolution</li></h5>
                        <p>This Agreement shall be governed by and construed in accordance with the laws of the Republic of Turkey. Any disputes arising out of or in connection with this Agreement shall be submitted to the competent courts and arbitration boards located in the Province of Gaziantep, Turkey.</p>

                        <h5 className='my-4 fw-bold'><li>Entire Agreement</li></h5>
                        <p>This Agreement constitutes the entire agreement between the Parties with respect to the subject matter hereof and supersedes all prior or contemporaneous communications, representations, or agreements, whether oral or written.</p>

                    </ol>

                    <p>This Membership Agreement has lastly been updated on 2023-07-23.</p>

                </Container>
            </div>

            <Footer />
        </div>
    );
};

export default TermsAndConditionsEn;