import React from 'react';
import styles from './ProfileTable.module.css';
import { useTranslation } from 'react-i18next';

const ProfileTable = ({ data }) => {

    const { t } = useTranslation();

    return (
        <div className={`mb-3 border ${styles.tableWrap}`}>
            <table className={`table ${styles.table} text-center align-middle`}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th>#</th>
                        <th>{t("Poz")}</th>
                        <th>{t("Profil")}</th>
                        <th>{t("Malzeme")}</th>
                        <th>{t("Adet")}</th>
                        <th>{t("Uzunluk")}(mm)</th>
                        <th>{t("Parça Ağırlığı")}(kg)</th>
                        <th>{t("Toplam Ağırlık")}(kg)</th>
                        <th>{t("Açıklama")}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row,index) => {
                        const total_weight = Number(row.weight_of_one_piece) * Number(row.quantity);
                        return (
                            <tr key={index}>
                                <td>{row.piece_code}</td>
                                <td>{row.piece_name}</td>
                                <td>{row.profile}</td>
                                <td>{row.grade}</td>
                                <td>{row.quantity}</td>
                                <td>{Number(row.length).toFixed(2)}</td>
                                <td>{Number(row.weight_of_one_piece).toFixed(2)}</td>
                                <td>{total_weight.toFixed(2)}</td>
                                <td>{row.description}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ProfileTable;