import React, { useState } from "react";

import { Form, Button } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import { metalProfileInfoUpdate } from "../../services/metalProfileInfoUpdate";

const MetalProfileProjectInfoNotSet = (
    {
        id,
        name,
        profileInfo
    }
) => {
    const { i18n, t } = useTranslation();

    const [updatedProfileInfo, setUpdatedProfileInfo] = useState(profileInfo);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(false);

    const handleUpdate = async () => {
        setUpdating(true);
        const response = await metalProfileInfoUpdate(
            id,
            updatedProfileInfo
        );

        if (response.status === 200) {
            window.location.reload();
        } else {
            setUpdating(false);
            setError(true);
        }
    }

    return (
        <div className="m-3">
            <div className="bg-white p-3 border mb-3 h5" style={{ lineHeight: "1.6" }}>
                {t("")}
                {t("profile_1d_warning_part1")} <b>{t("profile_1d_warning_part2")}</b> {t("profile_1d_warning_part3")}. {t("Levha tipi profillerde alan optimizasyonu yapılamamaktadır. Proje detaylarına ilerlemeden önce aşağıdaki profillerden tek boyutlu optimizasyona dahil edilmeyecekleri seçebilirsiniz. Ayrıca kesim uzunluğunu ve testere kalınlığını ayarlayabilirsiniz. Bu seçimlerinizi sonradan değiştirebilirsiniz.")}
            </div>
            <div className="bg-white p-2 border table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: "10%" }}>{t("Profil")}</th>
                            <th scope="col" style={{ width: "10%" }}>{t("Malzeme")}</th>
                            <th scope="col" style={{ width: "15%" }}>{t("Optimizasyona Dahil Edilsin mi?")}</th>
                            <th scope="col" style={{ width: "20%" }}>{t("Kesim Yapılacak Boy")} (mm)</th>
                            <th scope="col" style={{ width: "10%" }}>{t("Testere Kalınlığı")} (mm)</th>
                        </tr>
                        {/* line for changing all at once: */}
                        <tr>
                            <th colSpan={2} className="text-center">
                            </th>
                            <td>
                                <Form.Select
                                    disabled={updating}
                                    value={updatedProfileInfo[0].include_in_optimization}
                                    onChange={(e) => {
                                        setUpdatedProfileInfo(
                                            updatedProfileInfo.map((item) => {
                                                return {
                                                    ...item,
                                                    include_in_optimization: e.target.value == 'true'
                                                }
                                            })
                                        )
                                    }}
                                >
                                    <option value={true}>{t("Evet")}</option>
                                    <option value={false}>{t("Hayır")}</option>
                                </Form.Select>
                            </td>
                            <td>
                                <Form.Control
                                    disabled={updating}
                                    type="number"
                                    value={updatedProfileInfo[0].stock_length}
                                    onChange={(e) => {
                                        setUpdatedProfileInfo(
                                            updatedProfileInfo.map((item) => {
                                                return {
                                                    ...item,
                                                    stock_length: e.target.value
                                                }
                                            })
                                        )
                                    }}
                                />
                            </td>
                            <td>
                                <Form.Control
                                    disabled={updating}
                                    type="number"
                                    value={updatedProfileInfo[0].blade_thickness}
                                    onChange={(e) => {
                                        setUpdatedProfileInfo(
                                            updatedProfileInfo.map((item) => {
                                                return {
                                                    ...item,
                                                    blade_thickness: e.target.value
                                                }
                                            })
                                        )
                                    }}
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {updatedProfileInfo.map((profile) => {
                            return (
                                <tr key={profile.group_id}>
                                    <td>{profile.profile}</td>
                                    <td>{profile.grade}</td>
                                    <td>
                                        <Form.Select
                                            disabled={updating}
                                            value={profile.include_in_optimization}
                                            onChange={(e) => {
                                                setUpdatedProfileInfo(
                                                    updatedProfileInfo.map((item) => {
                                                        if (item.group_id === profile.group_id) {
                                                            return {
                                                                ...item,
                                                                include_in_optimization: e.target.value
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }}
                                        >
                                            <option value={true}>{t("Evet")}</option>
                                            <option value={false}>{t("Hayır")}</option>
                                        </Form.Select>
                                    </td>
                                    <td>
                                        <Form.Control
                                            disabled={updating}
                                            type="number"
                                            value={profile.stock_length}
                                            onChange={(e) => {
                                                setUpdatedProfileInfo(
                                                    updatedProfileInfo.map((item) => {
                                                        if (item.group_id === profile.group_id) {
                                                            return {
                                                                ...item,
                                                                stock_length: e.target.value
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Form.Control
                                            disabled={updating}
                                            type="number"
                                            value={profile.blade_thickness}
                                            onChange={(e) => {
                                                setUpdatedProfileInfo(
                                                    updatedProfileInfo.map((item) => {
                                                        if (item.group_id === profile.group_id) {
                                                            return {
                                                                ...item,
                                                                blade_thickness: e.target.value
                                                            }
                                                        }
                                                        return item;
                                                    })
                                                )
                                            }}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="d-flex justify-content-center">
                    <Button
                        size="lg"
                        className="fw-bold"
                        onClick={() => {
                            handleUpdate();
                        }}
                    >
                        {
                            updating ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="spinner-border text-light" role="status" style={{ width: "1.5rem", height: "1.5rem" }}></div>
                                    <span className="ms-2">{t("Güncelleniyor")}...</span>
                                </div>
                                :
                                t("İLERLE")
                        }
                    </Button>
                </div>

                {error && <div className="text-danger text-center mt-3">{t("Bir hata oluştu. Lütfen tekrar deneyin.")}</div>}
            </div>

        </div>
    )

}

export default MetalProfileProjectInfoNotSet;