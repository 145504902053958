import { useState, useEffect } from 'react';
import { getPurchases } from '../../services/paymentServices/getPurchases';
import { approveProjectPayment } from '../../services/adminServices/approveProjectPaymentService';
import { cancelProjectPayment } from '../../services/adminServices/cancelProjectPaymentService';
import { downloadInvoice } from '../../services/paymentServices/downloadInvoice';
import { useNavigate } from "react-router-dom";

const usePurchases = () => {
    const navigate = useNavigate();

    const standardKeys = [
        {
            accessorKey: 'order_id', //access nested data with dot notation
            header: 'Sipariş No',
            size: 150,
        },
        {
            accessorFn: (row) => {
                let status = row.status
                if(status === "success") return "Başarılı"
                else if(status === "waiting") return "Bekliyor"
                else if(status === "failed") return "Başarısız"
                else if(status === "in_progress") return "Devam Ediyor"
                else return "HATA"
            },
            header: 'Durum',
            size: 150,
            Cell: ({ renderedCellValue, row }) => {
                let bg_variant, text_variant, message
                if (renderedCellValue === "Bekliyor") {
                    bg_variant = "bg-warning"
                    text_variant = "text-dark"
                }
                else if (renderedCellValue === "Başarılı") {
                    bg_variant = "bg-success"
                    text_variant = "text-white"
                }
                else if (renderedCellValue === "Başarısız") {
                    bg_variant = "bg-danger"
                    text_variant = "text-white"
                }
                else if (renderedCellValue === "Devam Ediyor") {
                    bg_variant = "bg-info"
                    text_variant = "text-dark"
                }
                else {
                    bg_variant = "bg-dark"
                    text_variant = "text-white"
                }
                return <p className={`text-center border ${text_variant} ${bg_variant}`}>{renderedCellValue}</p>
            }
        },
        {
            accessorKey: 'user_email',
            header: 'Kullanıcı',
            size: 150,
        },
        {
            accessorKey: 'delegate_user_id',
            header: 'Temsilci',
            size: 150,
        },
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [selectedPurchased, setSelectedPurchased] = useState('1')
    const [selectedMethod, setSelectedMethod] = useState('1')

    const [tableKeys, setTableKeys] = useState(standardKeys)

    const [allData, setAllData] = useState([])
    const [tableData, setTableData] = useState([])

    const [modalInfo, setModalInfo] = useState({
        show: false,
        type: "",
        params: {},
        note_variant: "",
        note: "",
    })

    const [loadedInvoiceFile, setLoadedInvoiceFile] = useState(null)
    const [loadedBankReceiptFile, setLoadedBankReceiptFile] = useState(null)

    const [showCancelModalFooter, setShowCancelModalFooter] = useState(false)

    const [approveProjectPaymentLoading, setApproveProjectPaymentLoading] = useState(false)
    const [cancelMessage, setCancelMessage] = useState("")

    const [cancelProjectPaymentLoading, setCancelProjectPaymentLoading] = useState(false)

    const [mailLanguage, setMailLanguage] = useState("tr")

    const columnsDict = {
        "1-1": standardKeys,
        "1-project": standardKeys,
        "1-credits": standardKeys,
        "transfer-1": standardKeys,
        "transfer-project": [
            ...standardKeys,
            {
                accessorFn: (row) => `₺${row.payment_detail.total_price}`,
                header: 'Tutar',
                size: 120,
                Cell: ({ renderedCellValue, row }) => {
                    return <p className={`fw-bold`} style={{ fontSize: "20px" }}>{renderedCellValue}</p>
                }
            },
            {
                accessorFn: (row) => `${row.payment_detail.project_name} (${row.payment_detail.project})`,
                header: 'Proje İsmi',
                size: 150,
            },
            {
                accessorFn: (row) => `${(row.payment_detail.project_weight / 1000).toFixed(3)}`,
                header: 'Ağırlık (ton)',
                size: 100,
            },
            {
                accessorFn: (row) => `${(new Date(row.payment_detail.date_created)).toLocaleDateString('tr-TR')}`,
                header: 'Tarih',
                sortingFn: 'datetime',
                size: 150,
            },
        ],
        "transfer-credits": [
            ...standardKeys,
        ],
        "credit_card-1": standardKeys,
        "credit_card-project": [
            ...standardKeys,
            {
                accessorFn: (row) => `₺${row.payment_detail.total_price}`,
                header: 'Tutar',
                size: 120,
                Cell: ({ renderedCellValue, row }) => {
                    return <p className={`fw-bold`} style={{ fontSize: "20px" }}>{renderedCellValue}</p>
                }
            },
            {
                accessorFn: (row) => {
                    let success = row.status === "success"
                    let invoice_null = row.invoice_details.invoice_url === null
                    if(!success) return "-"
                    else{
                        return invoice_null ? "Kesilmedi" : "Kesildi"
                    }
                },
                header: 'Fatura',
                size: 120,
                Cell: ({ renderedCellValue, row }) => {
                    let color = ""
                    if(renderedCellValue === "Kesilmedi") color="danger"
                    else if(renderedCellValue === "Kesildi") color="success"
                    return <p className={`text-${color}`}>{renderedCellValue}</p>
                }
            },
            {
                accessorFn: (row) => `${row.payment_detail.project_name} (${row.payment_detail.project})`,
                header: 'Proje İsmi',
                size: 150,
            },
            {
                accessorFn: (row) => `${(row.payment_detail.project_weight / 1000).toFixed(3)}`,
                header: 'Ağırlık (ton)',
                size: 100,
            },
            {
                accessorFn: (row) => `${(new Date(row.payment_detail.date_created)).toLocaleDateString('tr-TR')}`,
                header: 'Tarih',
                sortingFn: 'datetime',
                size: 150,
            },
        ],
        "credit_card-credits": [
            ...standardKeys,
        ],

    }


    useEffect(() => {

        async function fetchData() {
            try {
                const response = await getPurchases()

                if (response.ok) {
                    let data = await response.json()
                    setAllData(data)
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false)
                    var props = {
                        error_code: "CSUKLQ",
                        detail: "Sistem Hatası.",
                    }
                }
            }
            catch (error) {
                setIsLoading(false)
                var props = {
                    error_code: "7QAAJB",
                    detail: "Sistem Hatası.",
                }
                navigate("/error", { state: { props: props } })
            }
        }

        fetchData()

    }, [])

    const handleMethodChange = (newMethod) => {
        setSelectedMethod(newMethod)
        setTableData(allData.filter((payment) => {
            return payment.method === newMethod && payment.purchased === selectedPurchased
        }))
        setTableKeys(columnsDict[`${newMethod}-${selectedPurchased}`])
    }

    const handlePurchasedChange = (newPurchased) => {
        setSelectedPurchased(newPurchased)
        setTableData(allData.filter((payment) => {
            return payment.method === selectedMethod && payment.purchased === newPurchased
        }))
        setTableKeys(columnsDict[`${selectedMethod}-${newPurchased}`])
    }

    const modalOnHide = () => {
        setModalInfo({
            ...modalInfo,
            show: false,
            note_variant: "",
            note: "",
        })
        setLoadedInvoiceFile(null)
        setLoadedBankReceiptFile(null)
        setShowCancelModalFooter(false)
        setCancelMessage(null)
    }

    const modalOnShow = (type, params) => {
        setModalInfo({
            ...modalInfo,
            show: true,
            type: type,
            params: params,
        })
    }

    const handleInvoiceUpload = (e) => {
        try {
            setLoadedInvoiceFile(e.target.files[0])
        }
        catch (err) {
            setLoadedInvoiceFile(null)
        }
    }

    const removeInvoice = () => {
        setLoadedInvoiceFile(null)
    }

    const handleBankReceiptUpload = (e) => {
        try {
            setLoadedBankReceiptFile(e.target.files[0])
        }
        catch (err) {
            setLoadedBankReceiptFile(null)
        }
    }

    const removeBankReceipt = () => {
        setLoadedBankReceiptFile(null)
    }

    const handleApproveProjectPayment = async (order_id) => {
        setApproveProjectPaymentLoading(true)
        setModalInfo({
            ...modalInfo,
            note_variant:"",
            note:"",
        })

        let response;
        try{
            response = await approveProjectPayment(order_id, loadedInvoiceFile, loadedBankReceiptFile, mailLanguage)
        }
        catch{
            setModalInfo({
                ...modalInfo,
                note_variant: 'danger',
                note: `Sistem hatası. (AALOPG)`,
            })
        }
        const current = Date()
        if (response.ok) {
            setApproveProjectPaymentLoading(false);
            setModalInfo({
                ...modalInfo,
                note_variant: 'success',
                note: "Onay Başarılı",
                params: {
                    ...modalInfo.params,
                    status: "success",
                    payment_detail: {
                        ...modalInfo.params.payment_detail,
                        completed: true,
                        date_completed: current,
                    },
                    invoice_details : {
                        ...modalInfo.params.invoice_details,
                        invoice_url:'url', // to indicate invoice has been uploaded
                    }
                },
            })
            
            const updatedAllData = allData.map(obj => {
                if(obj.order_id === order_id){
                    return {
                        ...obj,
                        status : "success",
                        payment_detail : {
                            ...obj.payment_detail,
                            completed : true,
                            date_completed : current,
                        },
                        invoice_details : {
                            ...obj.invoice_details,
                            invoice_url:'url',
                        }
                    }
                }
                return obj
            })
            setAllData(updatedAllData);
            setTableData(updatedAllData.filter((payment) => {
                return payment.method === selectedMethod && payment.purchased === selectedPurchased
            }))
        }
        else {
            const data = await response.json()
            if (response.status === 413) {
                setLoadedInvoiceFile(null)
                setLoadedBankReceiptFile(null)
            }
            if('detail' in data){
                setModalInfo({
                    ...modalInfo,
                    note_variant: 'danger',
                    note: `HATA: ${data.detail}`,
                })
            }
            else{
                setModalInfo({
                    ...modalInfo,
                    note_variant: 'danger',
                    note: `HATA: Kod ${response.status}`,
                })
            }


            setApproveProjectPaymentLoading(false);

        }
    }

    const handleCancelProjectPayment = async (order_id) => {
        setCancelProjectPaymentLoading(true)
        setModalInfo({
            ...modalInfo,
            note_variant:"",
            note:"",
        })

        let response
        try{
            response = await cancelProjectPayment(order_id, cancelMessage, mailLanguage)
        }
        catch{
            setModalInfo({
                ...modalInfo,
                note_variant: 'danger',
                note: `Sistem hatası. (WTZD5G)`,
            })
        }
        const current = Date()
        if (response.ok) {
            setCancelProjectPaymentLoading(false);
            setModalInfo({
                ...modalInfo,
                note_variant: 'success',
                note: "İptal Başarılı",
                params: {
                    ...modalInfo.params,
                    status: "failed",
                    payment_detail: {
                        ...modalInfo.params.payment_detail,
                        completed: true,
                        date_completed: current,
                        cancel_message: cancelMessage,
                    }
                },
            })
            
            const updatedAllData = allData.map(obj => {
                if(obj.order_id === order_id){
                    return {
                        ...obj,
                        status : "failed",
                        payment_detail : {
                            ...obj.payment_detail,
                            completed : true,
                            date_completed : current,
                            cancel_message : cancelMessage,
                        }
                    }
                }
                return obj
            })
            setShowCancelModalFooter(false);
            setAllData(updatedAllData);
            setTableData(updatedAllData.filter((payment) => {
                return payment.method === selectedMethod && payment.purchased === selectedPurchased
            }))
        }
        else {
            const data = await response.json()
            if('detail' in data){
                setModalInfo({
                    ...modalInfo,
                    note_variant: 'danger',
                    note: `HATA: ${data.detail} (${response.status}) (36846)`,
                })
            }
            else{
                setModalInfo({
                    ...modalInfo,
                    note_variant: 'danger',
                    note: `HATA: Kod ${response.status} (72364)`,
                })
            }
            setCancelProjectPaymentLoading(false);
        }


    }

    const handleDownloadInvoice = async (order_id) => {
        let response
        try{
            response = await downloadInvoice(order_id)
        }
        catch{
            setModalInfo({
                ...modalInfo,
                note_variant: 'danger',
                note: `Sistem hatası. (MI6RJZ)`,
            })
        }
        if (response.ok) {
            const data = await response.json()
            const link = document.createElement('a');
            link.href = data.url;
            link.download = 'project.xlsx';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            setModalInfo({
                ...modalInfo,
                note_variant: 'danger',
                note: `Sistem hatası. (EBVQUD)`,
            })
        }
    }


    return {
        isLoading,
        selectedMethod,
        selectedPurchased,

        handleMethodChange,
        handlePurchasedChange,

        tableData,
        tableKeys,

        modalInfo,
        modalOnShow,
        modalOnHide,

        loadedInvoiceFile,
        handleInvoiceUpload,

        handleBankReceiptUpload,

        showCancelModalFooter,
        setShowCancelModalFooter,

        handleApproveProjectPayment,
        approveProjectPaymentLoading,

        cancelMessage,
        setCancelMessage,

        handleCancelProjectPayment,
        cancelProjectPaymentLoading,

        handleDownloadInvoice,

        mailLanguage,
        setMailLanguage,
    }
}

export default usePurchases