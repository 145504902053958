import React from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import styles from '../ProjectInfo.module.css'

import { useTranslation } from "react-i18next";

const CuttingPlans = (props) => {

    const { i18n, t } = useTranslation();

    return (
        <Container className='p-3 border border-top-0' fluid style={{ backgroundColor: 'white' }}>
            <h3 className="text-center d-block d-md-none">{t("Kesim Planları")}</h3>
            <Row>
                <Col xs={12} className='d-flex justify-content-center my-2'>
                    <Button variant="primary" size="lg" className={styles.customButton} href={`/${i18n.language}/create-cp/${props.id}`}>
                        {t("Kesim Planı Oluştur")}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={styles.cardTitle}>{t("Bu Projeye Ait Kesim Planları")}</div> {/* Apply the cardTitle style */}
                    <Row className="align-items-center fw-bold m-2 mt-4" >
                        <Col className={styles.boldText}>{t("İsim")}</Col>
                        <Col>{t("Verim")}</Col>
                        {props.activated &&
                            <Col className="d-none d-sm-block">{t("Toplam Ağırlık (ton)")}</Col>
                        }
                        <Col >{t("Tarih")}</Col>
                    </Row>
                    <hr className={styles.separator} /> {/* Add the separator */}
                    {props.cps.map((cp, index) => {
                        let rawDate = new Date(cp.creationTime);
                        const date = rawDate.toLocaleDateString(i18n.language);

                        let tonWeight;
                        if (cp.status === 'pending') {
                            tonWeight = t('Bekliyor...')
                        }
                        else if (cp.status === 'processing') {
                            tonWeight = t('Hesaplanıyor...')
                        }
                        else if ('totalWeight' in cp) {
                            tonWeight = (cp.totalWeight / 1000).toFixed(1)
                        }
                        else {
                            tonWeight = '-'
                        }


                        let cpYield = "-";
                        if (cp.status === 'pending') {
                            cpYield = t('Bekliyor...')
                        }
                        else if (cp.status === 'processing') {
                            cpYield = t('Hesaplanıyor...')
                        }
                        else if (props.activated) {
                            cpYield = `${cp.percentYield}%`
                        }


                        return (
                            <Link to={`/${i18n.language}/cp-info/${cp.id}`} className={styles.cpLink} key={index}>
                                <Row className={`align-items-center ${styles.row}`}>
                                    <Col className={styles.boldText}>{cp.name}</Col>
                                    <Col>{cpYield}</Col>
                                    {props.activated &&
                                        <Col className="d-none d-sm-block">{tonWeight}</Col>
                                    }
                                    <Col>{date}</Col>
                                </Row>
                            </Link>

                        );
                    })}
                </Col>
            </Row>
        </Container>
    )
}

export default CuttingPlans;