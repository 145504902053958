import apiClient from "./apiClient";

const createCp = async ({
    projectType,
    id,
    cpName,
    optimizationInput = null,
    diameters = null,
    useSubstitutes = null,
    solveFloorByFloor = null,
    minBarLength = null,
    selectedFloors = null,
    availableStocksFile = null,
}) => {
    const url = `${process.env.REACT_APP_API_URL}/api/optimize/`;

    let formData = new FormData();
    let body;

    if (projectType === 'rebar') {
        body = {
            'projectId': id,
            'cpName': cpName,
            'optimizationInput': optimizationInput,
            'diameters': diameters,
            'useSubstitutes': useSubstitutes,
            'solveFloorByFloor': solveFloorByFloor,
            'minOutageLength': minBarLength,
            'selectedFloors': selectedFloors
        }
    }
    else {
        formData.append('projectId', id)
        formData.append('cpName', cpName)
        formData.append('availableStocksFile', availableStocksFile)
    }

    let response;
    if (projectType === 'rebar') {
        response = await apiClient(url, {
            method: 'POST',
            body: JSON.stringify(body),
        })
    }
    else {
        response = await apiClient(url, {
            method: 'POST',
            body: formData,
        })
    }
    return response;

};

export { createCp };
