import apiClient from "./apiClient";

export const getProjects = async (id=null) => {
    let url;

    if (id) {
        url = `${process.env.REACT_APP_API_URL}/api/get-projects/${id}/`;
    }
    else{
        url = `${process.env.REACT_APP_API_URL}/api/get-projects/`;
    
    }

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}