import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import CheckboxList from '../../components/CheckboxList/CheckboxList';
import styles from './CpCreation.module.css';

import {
    subInfo,
} from '../../infoContents'
import InfoNote from '../../components/InfoNote/InfoNote';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

const RebarCpCreation = ({
    floors,
    diameters,
    id,
    handleSubmit,
    handleCpNameChange,
    handleFloorsChange,
    handleDiametersChange,
    handleUseSubstitutesChange,
    handleSolveFloorByFloorChange,
    handleMinBarLengthChange,
    useSubstitutesChecked,
    separeteFloorsChecked,
    disableUseSubstitutes,
    disableSeparateFloors,
}) => {

    const { i18n, t } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    return (
        <Container>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}

            <Form onSubmit={(e) => { handleSubmit(e, id) }}>

                <Row className="d-flex justify-content-between align-items-center mt-4 px-5">
                    <Col md={2} className='d-flex justify-content-start '>
                        {/* empty column */}
                    </Col>
                    <Col md={6} xs={12} className='d-flex justify-content-center '>
                        <Form.Control
                            type="text"
                            className={`shadow-sm border-light ${styles.nameField}`}
                            placeholder={t("Kesim Planı ismi")}
                            required={true}
                            maxLength={25}
                            onChange={handleCpNameChange}
                        />
                    </Col>
                    <Col md={2} className='d-flex justify-content-end d-none d-md-block'>
                        <Button variant="success" size="lg" type="submit">
                            {t("HESAPLA")}
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-4 justify-content-center">
                    <Col md={4} className={styles.cardWrapper}>
                        <Card className={`shadow-sm border-light ${styles.cardButton}`}>
                            <Card.Body>
                                <h4 className={styles.header}>{t("Yerleşim Planı")}</h4>
                                <div className={styles.checkboxListContainer}>
                                    <CheckboxList items={floors} onCheckedItemsChange={handleFloorsChange} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} className={styles.cardWrapper}>
                        <Card className={`shadow-sm border-light ${styles.cardButton}`}>
                            <Card.Body>
                                <h4 className={styles.header}>{t("Optimizasyon yapılması istenen çaplar")}</h4>
                                <div className={styles.checkboxListContainer}>
                                    <CheckboxList items={diameters} onCheckedItemsChange={handleDiametersChange} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4} className={styles.cardWrapper}>
                        <Card className={`shadow-sm border-light ${styles.cardButton}`}>
                            <Card.Body>
                                <h4 className={styles.header}>{t("Optimizasyon seçenekleri")}&nbsp;<InfoNote content={subInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h4>

                                <Form.Group as={Row} controlId="formHorizontalCheck1" className='align-items-center my-3'>
                                    <Col sm={{ span: 11, offset: 1 }} className="d-flex justify-content-start">
                                        <div style={{ textAlign: 'left' }}>
                                            <Form.Check
                                                label={t("Artıklar bir alt çap için kullanılsın")}
                                                checked={useSubstitutesChecked}
                                                onChange={handleUseSubstitutesChange}
                                                disabled={disableUseSubstitutes}
                                            />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalCheck2" className='align-items-center my-3'>
                                    <Col sm={{ span: 11, offset: 1 }} className="d-flex justify-content-start">
                                        <div style={{ textAlign: 'left' }}>
                                            <Form.Check
                                                label={t("Seçilen katlar kendi arasında çözülsün.")}
                                                checked={separeteFloorsChecked}
                                                onChange={handleSolveFloorByFloorChange}
                                                disabled={disableSeparateFloors}
                                            />
                                        </div>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className='align-items-center my-3'>
                                    <Form.Label column lg={{ span: 11, offset: 1 }} xl={{ span: 6, offset: 1 }} style={{ textAlign: 'left' }}>
                                        {t("Elde kalan fire için dikkate alınacak en kısa boy")}:
                                    </Form.Label>
                                    <Col lg={{ span: 11, offset: 1 }} xl={4}>
                                        <Form.Control
                                            type="number"
                                            defaultValue={0}
                                            min={0}
                                            max={100}
                                            onChange={handleMinBarLengthChange}
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-2 px-5 d-sm-block d-md-none">

                    <Button variant="success" size="lg" className="w-100" type="submit">
                        {t("HESAPLA")}
                    </Button>
                </Row>
            </Form>

        </Container>
    )
}

export default RebarCpCreation;