import i18n from "../i18n";

export const loginUser = async (email, password) => {
    const url = `${process.env.REACT_APP_API_URL}/api/token/`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language || 'tr',
        },
        body: JSON.stringify({ username: email, password: password }),
    });
    return response

};

export const logoutUser = () => {

    localStorage.clear();
    // localStorage.removeItem('ca_a');
    // localStorage.removeItem('ca_r');
    window.location=`/${i18n.language}/?logout=true`
    
};

export const refreshAccessToken = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/token/refresh/`;

    const refresh = localStorage.getItem('ca_r');

    if (!refresh) {
        return;
    }

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language || 'tr',
            },
            body: JSON.stringify({
                "refresh": refresh,
            }),
        });

        if (response.ok) {
            const data = await response.json();
            localStorage.setItem('ca_a', data.access);
            return true;
        } else {
            window.location = `/${i18n.language}/login?try-again=true`
            throw new Error(`Error: FBQ44K`);
        }
    } catch (error) {
        throw new Error(`Error: SK5JHC`);
    }
};
