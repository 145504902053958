import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { BsCheckCircle } from 'react-icons/bs'

import styles from '../ProjectPurchase.module.css'

import { iso31661_country_codes } from '../../../other/variables';
import { iso31662_state_codes } from '../../../other/variables';

import { useTranslation } from 'react-i18next';

const PurchaseCompleted = (props) => {

    const id = props.id
    const projectName = props.projectName
    const projectTheoreticalWeight = props.projectTheoreticalWeight
    const unitPrice = props.unitPrice
    const unitDiscount = props.unitDiscount
    const unitPriceType = props.unitPriceType
    const totalPrice = props.totalPrice
    const vat = props.vat
    const paymentMethod = props.paymentMethod
    const invoiceInfo = props.invoiceInfo
    const orderId = props.orderId
    const orderDate = props.orderDate

    const { i18n, t } = useTranslation();

    let dueDate = new Date(orderDate)
    dueDate.setDate(dueDate.getDate() + 7)

    const dueDateString = dueDate.toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })

    const name = invoiceInfo.type === "real_person" ? `${invoiceInfo.data.name} ${invoiceInfo.data.surname}` : invoiceInfo.data.company_name

    const serviceDetail = <div><a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}/project-info/${id}`} target='_blank'>{projectName}</a> {t("isimli projenin kesim planı oluşturma hakkı")}</div>

    let discountName = ""

    if(unitPriceType === "project_delegate"){
        discountName = t("Temsilcilik İndirimi")
    }
    else if(unitPriceType === "project_with_delegation"){
        discountName = t("Temsilci İndirimi")
    }

    return (
        <>
            <Container className='text-center bg-white align-items-center mt-4 border'>
                <Row>
                    <Col>
                        <div className={`${styles.statusStep}`}>
                            <span className={`${styles.statusCircle} ${styles.completedCircle}`}><BsCheckCircle /></span>
                            <span className={`${styles.statusLabel}`}>{t("Fatura Detayları")}</span>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.statusStep}`}>
                            <span className={`${styles.statusCircle} ${styles.completedCircle}`}><BsCheckCircle /></span>
                            <span className={`${styles.statusLabel}`}>{t("Ödeme Bilgileri")}</span>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.statusStep}`}>
                            <span className={`${styles.statusCircle} ${styles.completedCircle}`}><BsCheckCircle /></span>
                            <span className={styles.statusLabel}>{t("Siparişi Tamamla")}</span>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-4 bg-white border p-4'>
                {paymentMethod === 1 ?
                    <h4 className='text-center text-success mb-4'>{t("Siparişiniz Başarıyla Alındı.")}</h4>
                    :
                    <h4 className='text-center text-success mb-4'>{t("Proje Satın Alımı Başarıyla Tamamlandı.")}</h4>}
                <div className='d-flex justify-content-center'>
                    <Row className='border p-2 mb-2'>
                        <Col className='d-flex align-items-center' style={{ fontSize: "14pt" }}>
                            {t("Sipariş Numarası:")}
                        </Col>
                        <Col className='d-flex align-items-center fw-bold' style={{ fontSize: "20pt" }} >
                            {orderId}
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col>
                        <table className='table table-borderless table-sm'>
                            <tbody>
                                <tr className='text-success'><td>{t("Satıcı")}</td></tr>
                                <tr className='fw-bold'><td>Teber Soft Yazılım İnşaat Sanayi Ticaret Ltd.</td></tr>
                                <tr><td>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı</td></tr>
                                <tr><td>Gaziantep Teknopark No: 4/A İç Kapı No:3 </td></tr>
                                <tr><td>Şahinbey - Gaziantep</td></tr>
                                <tr><td>Türkiye</td></tr>
                                <tr><td>{t("VKN")}: 8331565876</td></tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col>
                        <table className='table table-borderless table-sm'>
                            <tbody>
                                <tr className='text-success'><td>{t("Müşteri")}</td></tr>
                                <tr className='fw-bold'><td>{name}</td></tr>
                                <tr>
                                    <td>{invoiceInfo.data.street}</td>
                                </tr>
                                <tr>
                                    <td>{invoiceInfo.data.address_line1}</td>
                                </tr>
                                {
                                    invoiceInfo.data.address_line2 ? <tr>
                                        <td>{invoiceInfo.data.address_line2}</td>
                                    </tr> : <></>
                                }
                                <tr>
                                    <td>{invoiceInfo.data.zipcode}</td>
                                </tr>
                                <tr>
                                    <td>{iso31661_country_codes[invoiceInfo.data.country]}</td>
                                </tr>
                                <tr>
                                    <td>{iso31662_state_codes[invoiceInfo.data.province]} - {invoiceInfo.data.county}</td>
                                </tr>
                                {invoiceInfo.type === "real_person" ?
                                    invoiceInfo.data.tr_identity_number ? <tr>
                                        <td>{t("TCKN")} : {invoiceInfo.data.tr_identity_number}</td>
                                    </tr> : <></> :
                                    invoiceInfo.data.taxno ? <tr>
                                        <td>{t("VKN")} : {invoiceInfo.data.taxno}</td>
                                    </tr> : <></>}
                                <tr><td>{invoiceInfo.data.email}</td></tr>
                                {invoiceInfo.data.telephone ?
                                    <tr>
                                        <td>{t("Tel. No.")} : +{invoiceInfo.data.telephone_country_code}{invoiceInfo.data.telephone}</td>
                                    </tr> : <></>}
                            </tbody>
                        </table>
                    </Col>
                </Row>
                <hr />
                {paymentMethod === 1 ?
                    <>
                        <table className='table border p-5'>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className='text-success'>{t("Ödeme Yapılacak Banka Hesabı")}</td>
                                </tr>
                                <tr>
                                    <td>IBAN</td>
                                    <th>TR540020500009861511400001</th>
                                </tr>
                                <tr>
                                    <td>{t("Hesap Sahibi")}</td>
                                    <th>TEBER SOFT YAZILIM İNŞAAT SANAYİ VE TİCARET LİMİTED ŞİRKETİ</th>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <table className='table border p-2'>
                            <tbody>
                                <tr>
                                    <td colSpan={2} className='text-success'>{t("Ödeme İşleminin Detayları")}</td>
                                </tr>
                                <tr>
                                    <td>{t("Ödenecek Tutar")}</td>
                                    <th>₺{totalPrice}</th>
                                </tr>
                                <tr>
                                    <td>{t("Para transferi açıklaması")}</td>
                                    <th>{orderId}</th>
                                </tr>
                                <tr>
                                    <td>{t("Ödemenin yapılması gereken en son tarih")}</td>
                                    <th>{dueDateString}</th>
                                </tr>
                            </tbody>
                        </table>

                        <hr />
                    </>
                    : <></>}

                <div style={{ padding: "5px 10%" }}>
                    <table className='table table-sm table-borderless' style={{ width: "100%" }}>
                        <tbody>
                            <tr className='text-center border-bottom'>
                                <th style={{ textAlign: "start" }}>{t("Açıklama")}</th>
                                <th style={{ textAlign: "end" }}>{t("Fiyat")}</th>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "middle", textAlign: "start" }}>{serviceDetail}</td>
                                <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>₺{props.totalPriceWithoutDiscount}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ fontSize: "8pt", fontWeight: "light", color: "#777777", textAlign: "end" }}>{props.priceCalculationValue} {props.priceCalculationUnit} &times; {unitPrice + unitDiscount}{props.perPriceUnit}</td>
                            </tr>
                            {unitDiscount !== 0 &&
                                <>
                                    <tr className='border-top'>
                                        <td style={{ verticalAlign: "middle", textAlign: "start" }}>{discountName}</td>
                                        <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>&minus; ₺{props.totalDiscount}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ fontSize: "8pt", fontWeight: "light", color: "#777777", textAlign: "end" }}>{props.priceCalculationValue} {props.priceCalculationUnit} &times; &minus;{unitDiscount} {props.perPriceUnit}</td>
                                    </tr>
                                </>
                            }
                            <tr className='border-top'>
                                <td style={{ verticalAlign: "middle", textAlign: "start" }}>%{(props.discount * 100).toFixed(2)} {t("Havale İndirimi")}</td>
                                <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>&minus; ₺{props.initialPrice - totalPrice}</td>
                            </tr>
                            <tr className='border-top'>
                                <td style={{ verticalAlign: "middle", textAlign: "start" }}>{t("Ödenecek Tutar")}</td>
                                <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>₺{totalPrice}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ fontSize: "8pt", fontWeight: "light", color: "#777777", textAlign: "end" }}>(%{100 * vat} {t("KDV")}{vat !== 0 ? ` ${t("Dahil")}` : ""})</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr />

                {paymentMethod === 1 ?
                    <>

                        <h5>{t("Notlar")}</h5>

                        <ul>
                            <li>
                                <div>
                                    {t("Havale / EFT ile ödeme yapmak için:")}
                                    <ol>
                                        <li>{t("Ekranda gördğünüz IBAN adresine")} <span style={{ fontWeight: "bold" }}>₺{totalPrice}</span> {t("tutarında para transferi yapmanız gerekmektedir.")}</li>
                                        <li>{t("Para transferi açıklamasında, sipariş numarasını yazmayı unutmamalısınız.")}</li>
                                        <li>{t("purchasecompleted_receiptmsg1")} {process.env.REACT_APP_CONTACT_EMAIL}{t("purchasecompleted_receiptmsg2")}</li>
                                    </ol>
                                </div>
                            </li>
                            <li>
                                <div className='mb-2'>{t("Para transferinizin incelenmesinin ardından faturanız e-posta adresinize gönderilecek ve projeniz için oluşturacağınız bütün kesim planlarının detaylarına erişebileceksiniz.")}</div>
                            </li>
                            <li>
                                <div className='mb-2'>{t("Para yatırma işlemini siparişinizi oluşturmanızdan sonraki")} <span className='fw-bold'>{t("1 hafta")} </span> {t("içerisinde gerçekleştirmeniz gerekmektedir. 1 hafta içerisinde ödemesi tamamlanmayan siparişler iptal edilecektir.")}</div>
                            </li>
                            <li>
                                <div className='mb-2'>{t("Buradaki ödeme bilgilerinizi içeren bir e-posta da ayrıca size gönderilmiştir.")}</div>
                            </li>
                            <li>
                                <div className='mb-2'> {t("Bu sayfa bir fatura değildir. Faturanız, ödemenizi başarılı bir şekilde yapmanızın ardından size e-posta yoluyla iletilecektir.")}</div>
                            </li>
                            <li>
                                <div className='mb-2'>{t("purchasecompleted_contactmsg1")} <span className='fw-bold'>{process.env.REACT_APP_CONTACT_EMAIL}</span>{t("purchasecompleted_contactmsg2")}</div>
                            </li>
                        </ul>
                        <hr />
                    </>
                    :
                    <></>
                }

            </Container>

        </>

    );
};

export default PurchaseCompleted;
