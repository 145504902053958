import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Nav from 'react-bootstrap/Nav';

import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';

import Footer from '../../components/Footer/Footer';
import styles from '../Pages.module.css';
import useDelegatePanel from '../../hooks/useDelegatePanel/useDelegatePanel';
import AssignedUsers from './TabPages/AssignedUsers';
import AssignedUsersPurchases from './TabPages/AssignedUsersPurchases';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import { useTranslation } from 'react-i18next';

const DelegatePanel = () => {

    const {
        activeTab,
        setActiveTab,
        isPageLoading,
        assignedUsers,

        tableKeys,

        assignedUsersPayments,

    } = useDelegatePanel()

    const { t } = useTranslation()


    document.title = t("Temsilci Paneli")

    return (
        <div className={styles.flexWrapper}>
            <AppNavbar />

            <div className={styles.content}>
                {isPageLoading ? LoadingContainer :
                    <Container>

                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Nav variant="tabs" defaultActiveKey="first">
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="first"
                                        onClick={() => { setActiveTab("first") }}
                                    >
                                        {t("Kullanıcılar")}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="second"
                                        onClick={() => { setActiveTab("second") }}
                                    >
                                        {t("Satın Alımlar")}
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content className="bg-white">
                                <Tab.Pane eventKey="first" transition={false}>
                                    <AssignedUsers assignedUsers={assignedUsers} />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" transition={false}>
                                    <AssignedUsersPurchases
                                        assignedUsersPayments={assignedUsersPayments}
                                        tableKeys={tableKeys}
                                    />
                                </Tab.Pane>
                            </Tab.Content>

                        </Tab.Container>
                    </Container>
                }
            </div>
            <Footer />
        </div>

    );
};

export default DelegatePanel;
