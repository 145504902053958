import apiClient from "../apiClient";

export const getLoginAttempts = async (page=1,user_id) => {

    let url = `${process.env.REACT_APP_API_URL}/api/login-attempts?page=${page}`;

    if (user_id) {
        url += `&user=${user_id}`
    }

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}