import i18n from "../i18n";

export const signUpUser = async (email, password, delegateCode="") => {
    const url = `${process.env.REACT_APP_API_URL}/api/accounts/register/`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language || 'tr',
        },
        body: JSON.stringify({
            username: email,
            email: email,
            password: password,
            password_confirm: password,
            delegate_code : delegateCode.toUpperCase(),
        }),
    });

    return response;

}