import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import CurrentResultTable from '../../../../components/CpInfoTables/CurrentResultTable/CurrentResultTable';
import AllDiameterResultsTable from '../../../../components/CpInfoTables/AllDiameterTables.js/AllDiameterTables';

import { useTranslation } from 'react-i18next';

const Summary = (props) => {
    const [open, setOpen] = useState(false);
    const floors = props.floors
    const { t } = useTranslation();
    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            <Row className="justify-content-center">
                <Col md={12} xs={12}>
                    <Container className="border shadow-sm my-3 py-3">
                        <h5 className="text-center">{t("Mevcut Seçim Sonuç Özeti")}</h5>
                        <CurrentResultTable
                            data={props.currentResultSummary}
                            floorAvailable={props.floorAvailable}
                            result={props.result}
                            checkedDiameter={props.checkedDiameter}
                        />
                    </Container>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xs={12}>
                    <Container fluid className="border shadow-sm mb-3">
                        <Row className="align-items-center py-3" onClick={() => setOpen(!open)}>
                            <Col lg={11} xs={10}>
                                <h5
                                    className="mb-0 text-center"
                                    aria-controls="collapsible-text"
                                    aria-expanded={open}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {t("Tüm Sonuç Özeti")}
                                </h5>
                            </Col>
                            <Col lg={1} xs={2}>
                                {open ? (
                                    <FiChevronUp
                                        style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
                                    />
                                ) : (
                                    <FiChevronDown
                                        style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
                                    />
                                )}
                            </Col>
                        </Row>
                        <Collapse in={open}>
                            <Container fluid id="collapsible-text">
                                <AllDiameterResultsTable
                                    result={props.result}
                                    floorAvailable={props.floorAvailable}
                                    floors={floors}
                                />
                            </Container>
                        </Collapse>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Summary;
