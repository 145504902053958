import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

const ProjectDetails = ({
    projectCreationTime,
    id,
    name,
    theoreticalWeight,
    user,
    uploadedFileType,
    logLength,
    bindirmeOrani,
    floorAvailable,
    activated,
    unitPrice, 
    unitDiscount, 
    unitPriceType, 
    purchasable, 
    userType, 
    ownProject
 }) => {
    const { i18n, t } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    let creationTime = new Date(projectCreationTime).toLocaleDateString(`${i18n.language}-${i18n.language.toUpperCase()}`);

    const purchasableContainer = <Container>

        <Row>
            <Col md={{ offset: 2, span: 8 }}>
                <Container className='text-center border p-2 mb-3 bg-white'>
                    {t("Bu proje için oluşturacağınız kesim planlarının detaylarını elde edebilmek için ödeme yapmanız gerekmektedir.")}
                </Container>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={{ offset: 3, span: 6 }}>
                <Button style={{ backgroundColor: '#318CE7' }} className='p-3 border' href={`/${i18n.language}/project-purchase/${id}`}>
                    <h5>{t("projectdetails_paynote1")}({name}){t("projectdetails_paynote2")}</h5>
                    <div>
                        {unitDiscount !== 0 ?
                            <>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <s style={{ fontSize: "17pt", fontWeight: "400", alignItems: "center" }} >{Math.floor(theoreticalWeight * (unitDiscount + unitPrice) * 1e-3)}₺</s>
                                    <s style={{ fontSize: "6pt", marginLeft: "10px" }}> ({(unitDiscount + unitPrice)} ₺/ton)</s>
                                    <span className='ms-2'>{t("inactive_yerine")}</span>
                                </div>
                            </>
                            :
                            <></>}
                        <div className='mb-2'>
                            <span style={{ fontSize: "32pt", fontWeight: "600" }}>{Math.floor(theoreticalWeight * unitPrice * 1e-3)}₺</span>
                            <div style={{ fontSize: "9pt" }}> ({(theoreticalWeight / 1000).toFixed(3)} ton &times; {unitPrice} ₺/ton)</div>
                        </div>
                    </div>

                    <p>{t("Ödeme yaptıktan sonra bu proje için istediğiniz kadar kesim planı oluşturabilir ve detaylarını elde edebilirsiniz.")}</p>
                </Button>
            </Col>
        </Row>

    </Container>

    const notPurchasableContainer = <Container>
        <Row>
            <Col md={{ offset: 2, span: 8 }}>
                <Container className='text-center border p-2 mb-3 bg-white'>
                    {t("projectdetails_orderednote1")}<a href={`/${i18n.language}/project-purchase/${id}`}>{t("projectdetails_orderednote2")}</a>{t("projectdetails_orderednote3")}
                </Container>
            </Col>
        </Row>
    </Container>

    const pname = name === "Ornek-Proje" ? t("Ornek-Proje") : name

    return (
        <>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Container className='border border-top-0 p-3' fluid style={{ backgroundColor: 'white' }}>
                <h3 className="text-center d-block d-md-none">{t("Proje Bilgileri")}</h3>
                <Row>
                    <Col md={{ offset: 2, span: 8 }}>
                        <table className="table table-striped-columns border my-3 align-middle bg-white">
                            <tbody>
                                <tr>
                                    <th style={{ width: "45%" }}>{t("Proje Numarası")}&nbsp;
                                    </th>
                                    <td style={{ width: "65%" }}>{id}</td>
                                </tr>
                                <tr>
                                    <th>{t("Proje İsmi")}&nbsp;
                                    </th>
                                    <td>{pname}</td>
                                </tr>
                                <tr>
                                    <th>{t("Kullanıcı")}&nbsp;
                                    </th>
                                    <td>{user}</td>
                                </tr>
                                <tr>
                                    <th>{t("Oluşturma Zamanı")}&nbsp;
                                    </th>
                                    <td>{creationTime}</td>
                                </tr>
                                <tr>
                                    <th>{t("Proje Tipi")}&nbsp;
                                    </th>
                                    <td>{uploadedFileType}</td>
                                </tr>
                                <tr>
                                    <th>{t("Kesim Yapılan Demir Uzunluğu")}&nbsp;
                                    </th>
                                    <td>{logLength}</td>
                                </tr>
                                <tr>
                                    <th>{t("Bindirme Katsayısı")}&nbsp;
                                    </th>
                                    <td>{bindirmeOrani}</td>
                                </tr>
                                <tr>
                                    <th>{t("Toplam Teorik Ağırlık (kg)")}&nbsp;
                                    </th>
                                    <td>{theoreticalWeight}</td>
                                </tr>
                                <tr>
                                    <th>{t("Aktive Edildi mi?")}&nbsp;
                                    </th>
                                    <td>{activated ? t("Evet") : t("Hayır")}</td>
                                </tr>
                            </tbody>
                        </table>

                    </Col>
                </Row>
                {activated || [4, 5].includes(userType) || !ownProject ? <></> :
                    purchasable ? purchasableContainer : notPurchasableContainer
                }

                {/* <Row className='text-center'>
                    <Col md={{ offset: 1, span: 5 }}>
                        <Button style={{ backgroundColor: '#318CE7', height: "230px" }} className='p-3 border' href={`/project-purchase/${projectId}`}>
                            <h5>Bu proje ({projectName}) için ödeme yap</h5>
                            <div style={{ fontSize: "24pt", fontWeight: "600" }}>{Math.floor(projectTheoreticalWeight * projectUnitPrice * 1e-3)} ₺</div>
                            <div style={{ fontSize: "9pt" }} className='mb-1'> ({(projectTheoreticalWeight / 1000).toFixed(3)} ton &times; {projectUnitPrice} ₺/ton)</div>
                            <p>Sadece bu proje için ödeme yapabilirsiniz. Ödeme yaptıktan sonra bu proje için istediğiniz kadar kesim planı oluşturabilir ve detaylarını elde edebilirsiniz.</p>
                        </Button>
                    </Col>
                    <Col md={{ span: 5 }}>
                        <Button style={{ backgroundColor: '#0073ba', height: "230px" }} className='p-3 border'>
                            <h5>Kredi satın al</h5>
                            <h3>Fiyat bilgisi için tıklayınız</h3>
                            <p>Birim ağırlık başına daha az ücret ödeyerek toplu optimizasyon hakkı (kredi) satın alabilir, kredilerinizi gelecek projelerinizde de kullanabilirsiniz. Detaylı bilgi için tıklayınız.</p>
                        </Button>
                    </Col>
                </Row> */}

            </Container>
        </>
    )
}

export default ProjectDetails;
