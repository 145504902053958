import React, { useState } from "react";
import { Table } from "react-bootstrap";

import Container from "react-bootstrap/Container";

import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from "react-icons/md";

import { useTranslation } from "react-i18next";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    projectInfoStatsInfo
} from '../../../infoContents'
import InfoNote from '../../../components/InfoNote/InfoNote';
import { all } from "country-codes-list";

const LayoutStats = ({ layoutPlan }) => {

    const { i18n, t } = useTranslation();

    const floors = Object.keys(layoutPlan["Tümü"]);

    const showElements_ = {}

    Object.keys(layoutPlan["Tümü"]).map((floor, index1) => {
        Object.keys(layoutPlan["Tümü"][floor]).map((element, index2) => {
            if (index1 in showElements_) {
                showElements_[index1][index2] = false;
            }
            else {
                showElements_[index1] = {};
                showElements_[index1][index2] = false;
            }
        })
    })

    const [showElements, setShowElements] = useState(showElements_)

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    function getNofUniquePieces(listOfPieces) {
        const uniquePieces = [];
        listOfPieces.forEach(piece => {
            const type = `${piece.split("-")[0]}-${piece.split("-")[1]}`;
            if (!uniquePieces.includes(type)) {
                uniquePieces.push(type);
            }
        });
        return uniquePieces.length;
    }

    function getNofAllPiecesOfFloor(floorDict) {
        const allPieces = [];
        Object.keys(floorDict).forEach(element => {
            Object.keys(floorDict[element]).forEach(elementCode => {
                floorDict[element][elementCode].forEach(piece => {
                    allPieces.push(piece);
                });
            });
        });
        return allPieces.length;
    }

    function getNofAllUniquePiecesOfFloor(floorDict) {
        const allUniquePieces = [];
        Object.keys(floorDict).forEach(element => {
            Object.keys(floorDict[element]).forEach(elementCode => {
                floorDict[element][elementCode].forEach(piece => {
                    const type = `${piece.split("-")[0]}-${piece.split("-")[1]}`;
                    if (!allUniquePieces.includes(type)) {
                        allUniquePieces.push(type);
                    }
                });
            });
        });
        return allUniquePieces.length;
    }

    function getNofElementCodesOfFloor(floorDict) {
        const allElementCodes = [];
        Object.keys(floorDict).forEach(element => {
            Object.keys(floorDict[element]).forEach(elementCode => {
                allElementCodes.push(elementCode);
            });
        });
        return allElementCodes.length;
    }

    function getNofAllPiecesOfElement(elementDict) {
        const allPieces = [];
        Object.keys(elementDict).forEach(elementCode => {
            elementDict[elementCode].forEach(piece => {
                allPieces.push(piece);
            });
        });
        return allPieces.length;
    }

    function getNofAllUniquePiecesOfElement(elementDict) {
        const allUniquePieces = [];
        Object.keys(elementDict).forEach(elementCode => {
            elementDict[elementCode].forEach(piece => {
                const type = `${piece.split("-")[0]}-${piece.split("-")[1]}`;
                if (!allUniquePieces.includes(type)) {
                    allUniquePieces.push(type);
                }
            });
        });
        return allUniquePieces.length;
    }

    return (
        <Container className="border border-top-0 p-3 table-responsive" fluid style={{ backgroundColor: "white" }}>
            <h3 className="text-center d-block d-md-none">{t("İstatistikler")}</h3>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Table className="border">
                <thead>
                    <tr>
                        <th style={{ width: "12%", textAlign: "center" }}></th>
                        <th style={{ width: "26%", textAlign: "center" }}>{t("Parça Tipi Sayısı")}</th>
                        <th style={{ width: "26%", textAlign: "center" }}>{t("Farklı Parça Tipi Sayısı")}</th>
                        <th style={{ width: "26%", textAlign: "center" }}>{t("Eleman Sayısı")}</th>
                        <th style={{ width: "4%", textAlign: "center" }}><InfoNote content={projectInfoStatsInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                    </tr>
                </thead>
                <tbody>
                    {floors.map((item, index1) => {
                        const elements = Object.keys(layoutPlan["Tümü"][item]);
                        const nofAllPiecesOfFloor = getNofAllPiecesOfFloor(layoutPlan["Tümü"][item]);
                        const nofAllUniquePiecesOfFloor = getNofAllUniquePiecesOfFloor(layoutPlan["Tümü"][item]);
                        const nofElementCodesOfFloor = getNofElementCodesOfFloor(layoutPlan["Tümü"][item]);
                        return (
                            <React.Fragment key={index1}>
                                <tr className="fw-bold">
                                    <td><h5 className="ms-3 fw-bold" style={{ textAlign: "start" }}>{item}</h5></td>
                                    <td style={{ textAlign: "center" }}>{nofAllPiecesOfFloor}</td>
                                    <td style={{ textAlign: "center" }}>{nofAllUniquePiecesOfFloor}</td>
                                    <td style={{ textAlign: "center" }}>{nofElementCodesOfFloor}</td>
                                    <td />
                                </tr>
                                {elements.map((element, index2) => {
                                    const uniqueElements = Object.keys(layoutPlan["Tümü"][item][element]);
                                    const nofAllPieces = getNofAllPiecesOfElement(layoutPlan["Tümü"][item][element]);
                                    const nofAllUniquePieces = getNofAllUniquePiecesOfElement(layoutPlan["Tümü"][item][element]);
                                    return (
                                        <React.Fragment key={`${index1}-${index2}`}>
                                            <tr className="fw-bold">
                                                <td style={{ textAlign: "end" }}>{element}</td>
                                                <td style={{ textAlign: "center" }}>{nofAllPieces}</td>
                                                <td style={{ textAlign: "center" }}>{nofAllUniquePieces}</td>
                                                <td style={{ textAlign: "center" }}>{uniqueElements.length}</td>
                                                <td onClick={() => {
                                                    setShowElements({
                                                        ...showElements,
                                                        [index1]: {
                                                            ...showElements[index1],
                                                            [index2]: !showElements[index1][index2]
                                                        }
                                                    })
                                                }} style={{ textAlign: "center" }}>
                                                    {showElements[index1][index2] ? <MdKeyboardDoubleArrowUp style={{ fontSize: "22px" }} /> : <MdKeyboardDoubleArrowDown style={{ fontSize: "22px" }} />}
                                                </td>
                                            </tr>
                                            {uniqueElements.map((uniqueElement, index3) => {
                                                const nofPieces = layoutPlan["Tümü"][item][element][uniqueElement].length;
                                                const nofUniquePieces = getNofUniquePieces(layoutPlan["Tümü"][item][element][uniqueElement]);
                                                return (
                                                    showElements[index1][index2] &&
                                                    <tr key={`${index1}-${index2}-${index3}`}>
                                                        <td style={{ textAlign: "end" }}>{uniqueElement}</td>
                                                        <td style={{ textAlign: "center" }}>{nofPieces}</td>
                                                        <td style={{ textAlign: "center" }}>{nofUniquePieces}</td>
                                                        <td style={{ textAlign: "center" }}>{1}</td>
                                                        <td />
                                                    </tr>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                })}
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </Table>
        </Container>
    );
};

export default LayoutStats;