import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

const ContactEn = () => {

    document.title = "Contact"

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-5 text-center'>Contact</h2>
                    <Row>
                        <Col md="6">
                            <div className='p-3'>
                                <h4 className='mb-3'>Address</h4>
                                <p className='fw-bold'>Teber Soft Yazılım İnşaat Sanayi ve Ticaret LTD. ŞTİ.</p>
                                <p><span className='fw-bold'>Tax ID:</span> 8331165876</p>
                                <p>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı</p>
                                <p>Gaziantep Teknopark No: 4/A İç Kapı No:3</p>
                                <p>Şahinbey / Gaziantep / Türkiye</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className='p-3'>
                                <h4 className='mb-3'>Email Address</h4>
                                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                            </div>
                            <div className='p-3'>
                                <h4 className='mb-3'>Phone</h4>
                                <p>+90 505 434 66 25</p>
                                <p><a href="https://abys.adiyaman.edu.tr/murat-pala-444/iletisim/" target='_blank'>Prof. Dr. Murat PALA</a></p>
                                <p>General Manager of Teber Soft Yazılım İnşaat Sanayi ve Ticaret LTD. ŞTİ.</p>
                            </div>
                        </Col>

                    </Row>

                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default ContactEn;
