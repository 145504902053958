import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import styles from './InactiveAccount.module.css';

import { AiFillWarning } from 'react-icons/ai'; // use react-icons for this

import { useTranslation } from 'react-i18next';

const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

const InactiveAccount = () => {

    document.title = "Tasnifer"

    const { t } = useTranslation();

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white mt-5 p-5 shadow-sm ${styles.verifyContainer}`}>
                    <AiFillWarning size={70} color="orange" className={styles.checkIcon} />
                    <h2 className={styles.title}>{t("Üyeliğiniz Aktif Değildir.")}</h2>
                    <p className={styles.message}> {t("inactive_account_msg1")} {contactEmail} {t("inactive_account_msg2")}</p>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default InactiveAccount;
