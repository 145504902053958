import React from 'react';
import Container from 'react-bootstrap/Container';

import WasteTable from '../../../../components/CpInfoTables/WasteTable/MetalProfileWasteTable';

import { useTranslation } from 'react-i18next';

const Wastes = ({ currentWastes, availableStocks }) => {

    const { t } = useTranslation();

    let wasteAvailable;

    let unusedStocks = availableStocks.filter(stock => !('patterns' in stock))

    let allWaste = currentWastes.concat(unusedStocks)

    if (allWaste) {
        wasteAvailable = Object.keys(allWaste).length !== 0
    }
    else {
        wasteAvailable = false;
    }

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            <Container fluid style={{ "display": "flex", "flexDirection": "column", "maxHeight": "100vh" }}>
                <h5 className="text-center py-2">{t("Fire Listesi")}</h5>
                {wasteAvailable ?
                    <WasteTable
                        wastes={currentWastes}
                        unusedStocks={unusedStocks}
                        ratio={1} />
                    : <p className="text-center">{t("Fire listesi mevcut değil.")}</p>}
            </Container>
        </Container>
    )
}

export default Wastes;