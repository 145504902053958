import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import ProfileTable from '../../../components/ProfileTable/ProfileTable';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    theoreticalWeightInfo,
    rebarTableInfo,
    placementTableInfo,
    longRebarTableInfo,
} from '../../../infoContents'
import InfoNote from '../../../components/InfoNote/InfoNote';

import { useTranslation } from 'react-i18next';
import DetailedProfileTable from '../../../components/ProfileTable/DetailedProfileTable';
import CheckboxList from '../../../components/CheckboxList/CheckboxList';
import { Table } from 'react-bootstrap';

const ProfileInspection = ({
    pieces,
    profileInfo
}) => {

    const { i18n, t } = useTranslation();

    const [selectedProfiles, setSelectedProfiles] = useState(profileInfo.map((profile) => ({"label": profile.profile, "value": profile.group_id})))

    const [unfilteredPieces, setUnfilteredPieces] = useState(pieces)
    const [selectedPieces, setSelectedPieces] = useState(pieces)

    const [numberOfUniquePieces, setNumberOfUniquePieces] = useState(
        new Set(pieces.map((piece) => [piece.profile, piece.grade, piece.length])).size
    )
    const [totalNumberOfPieces, setTotalNumberOfPieces] = useState(
        pieces.reduce((acc, piece) => acc + Number(piece.quantity), 0)
    )
    const [totalWeight, setTotalWeight] = useState(
        pieces.reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.weight_of_one_piece), 0)
    )

    const [checkboxListItems, setCheckboxListItems] = useState(profileInfo.map((profile) => ({"label": profile.profile, "value": profile.group_id, "show": profile.include_in_optimization.toString() === 'true'})))

    const handleCheckProfiles= (checked) => {

        const filteredPieces = unfilteredPieces.filter((piece) => checked.includes(piece.group_id))
        
        setSelectedPieces(filteredPieces)
        setNumberOfUniquePieces(
            new Set(filteredPieces.map((piece) => [piece.profile, piece.grade, piece.length])).size
        )
        setTotalNumberOfPieces(
            filteredPieces.reduce((acc, piece) => acc + Number(piece.quantity), 0)
        )
        setTotalWeight(
            filteredPieces.reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.weight_of_one_piece), 0)
        )
    }

    useEffect(() => {
        const unincludedProfileIds = profileInfo.filter(profile => (profile.include_in_optimization).toString() !== 'true').map((profile) => profile.group_id)
        const unincludedPieces = pieces.filter((piece) => unincludedProfileIds.includes(piece.group_id))
        // remove unincluded pieces from selectedpieces
        setSelectedPieces(selectedPieces.filter((piece) => !unincludedProfileIds.includes(piece.group_id)))

        setCheckboxListItems(checkboxListItems.map((item) => {
            if (unincludedProfileIds.includes(item.value)) {
                return {...item, "show": false}
            }
            return {...item, "show": true}
        }))

        setNumberOfUniquePieces(new Set(selectedPieces.map((piece) => [piece.profile, piece.grade, piece.length])).size)
        setTotalNumberOfPieces(selectedPieces.reduce((acc, piece) => acc + Number(piece.quantity), 0))
        setTotalWeight(selectedPieces.reduce((acc, piece) => acc + Number(piece.quantity) * Number(piece.weight_of_one_piece), 0))
    }, [profileInfo])

    const [showDetailedTable, setShowDetailedTable] = useState(false)

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    return (
        <Container className='border border-top-0 p-3' fluid style={{ backgroundColor: 'white' }}>
            <h3 className="text-center d-block d-md-none">{t("Donatı Bilgileri")}</h3>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Row>
                <Col lg={3} md={4} xs={12}>
                    <Container className="border shadow-sm my-3 py-3">
                        <h5 className="text-center">{t("Profiller")} </h5>
                        <Container className="py-3">
                            <CheckboxList
                                items={checkboxListItems}
                                onCheckedItemsChange={handleCheckProfiles}
                                singleSelection={false}
                                emptySelection={true}
                                showAllLabel={true}
                                allSelectedAtFirst={true}
                                // showDeselectLabel={true}
                            />
                        </Container>
                    </Container>
                </Col>

                <Col lg={9} md={8} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <Container className="border shadow-sm mb-3">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>{t("Farklı Parça Adedi")}</th>
                                            <th className='text-center'>{t("Toplam Parça Adedi")}</th>
                                            <th className='text-center'>{t("Toplam Ağırlık")}(kg)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='text-center'>{numberOfUniquePieces}</td>
                                            <td className='text-center'>{totalNumberOfPieces}</td>
                                            <td className='text-center'>{totalWeight.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Container>
                        </Col>
                        <Col xs={12} >
                            <Container className={`border shadow-sm ${showDetailedTable ? "pb-3" : ""}`} style={{ "display": "flex", "flexDirection": "column", "maxHeight": showDetailedTable ? "" : "100vh" }}>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <h5 className="text-center py-3">{t("Parçalar")}</h5>
                                    </Col>
                                    <Col className='d-flex align-items-center justify-content-center'>
                                        <Form.Check
                                            type='switch'
                                            label={t("Detaylı Tabloyu Göster")}
                                            value={showDetailedTable}
                                            onChange={() => setShowDetailedTable(!showDetailedTable)}
                                        />
                                    </Col>
                                </Row>
                                {showDetailedTable ?
                                    <DetailedProfileTable data={selectedPieces} /> :
                                    <ProfileTable data={selectedPieces} />
                                }
                            </Container>
                        </Col>

                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default ProfileInspection;