import React, { useEffect, useState } from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';

import projectsPage from '../../img/ss/1-projeler.png'
import createProjectsPage from '../../img/ss/2-create-project.png'
import projectInfo from '../../img/ss/3-project-info.png'
import rebarInspection from '../../img/ss/3-1-rebar-inspection.png'
import cuttingPlans from '../../img/ss/3-2-cutting-plans.png'
import projectDetails from '../../img/ss/3-3-project-details.png'
import projectDetailsUnpaid from '../../img/ss/3-3-project-details-unpaid.png'
import createCp from '../../img/ss/4-create-cp.png'
import cpInfo from '../../img/ss/5-cp-info.png'
import cpInfoUnpaid from '../../img/ss/5-cp-info-unpaid.png'
import cpConf from '../../img/ss/5-1-cp-configuration.png'
import cpSummary from '../../img/ss/5-2-cp-summary.png'
import yieldTable from '../../img/ss/5-2-1-yield-table.png'
import cpPatterns from '../../img/ss/5-3-cp-patterns.png'
import pattern1 from '../../img/ss/5-3-1-pattern.png'
// import pattern2 from '../../img/ss/5-3-2-pattern.png'
import cpOther from '../../img/ss/5-4-cp-other.png'
import myAccount from '../../img/ss/6-my-account.png'
import myPurchases from '../../img/ss/6-1-my-purchases.png'
import excelTable from '../../img/project-manual/table.png'
import yerlesimPlani from '../../img/project-manual/yerlesim-plani.png'
import projectInspection from '../../img/project-manual/project-info.png'
import projectInfoWarning from '../../img/project-manual/project-info-warning.png'
import longRebars from '../../img/project-manual/longRebars.png'
import purchaseStep1 from '../../img/purchase-manual/step1.png'
import purchaseStep2Transfer from '../../img/purchase-manual/step2-transfer.png'
import purchaseStep2CC from '../../img/purchase-manual/step2-creditcard.png'
import purchaseStep3 from '../../img/purchase-manual/step3.png'
import purchaseStep4 from '../../img/purchase-manual/step4.png'
import sta1 from '../../img/sta/sta1.png'
import sta2 from '../../img/sta/sta2.png'
import sta3 from '../../img/sta/sta3.png'
import ide1 from '../../img/ide/ide1.png'
import ide2 from '../../img/ide/ide2.png'
import ide3 from '../../img/ide/ide3.png'
import ide4 from '../../img/ide/ide4.png'

import { useLocation } from 'react-router-dom';
const UserManualTr = () => {

    document.title = "Kullanım Rehberi"

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

    const [show, setShow] = useState(false);
    const [modalImg, setModalImg] = useState(null)

    const handleClose = () => setShow(false);
    const handleShow = (img) => { setShow(true); setModalImg(img) };

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const id = hash.replace('#', '');

            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 3000);
        }
    }, [location]);


    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>Kullanım Rehberi</h2>

                    <p><span className='fw-bold'>Tasnifer</span>’e hoş geldiniz.</p>
                    <p>Bu dokümantasyon Tasnifer’i kullanırken kullanıcılarımıza rehberlik etmesi için yazılmıştır.</p>
                    {/* <p>Tasnifer’in ne olduğu ve teorik arka planı hakkında daha fazla bilgi almak için Tasnifer Nedir? sayfasını ziyaret edebilirsiniz.</p> */}
                    <p>Her türlü soru, görüş ve öneriniz için bizimle <span className='fw-bold'>{contactEmail}</span> e-posta adresi üzerinden iletişime geçebilirsiniz.</p>
                    <p>Son Güncellenme Tarihi: 21 Kasım 2023</p>

                    <div className='d-flex justify-content-center'>
                        <div className='flex-column border p-4 bg-light'>
                            <h3 className='text-center mb-3'>İçindekiler</h3>
                            <ul style={{ listStyleType: "none" }}>
                                <li> <span className='fw-bold'>1.</span> <a href="#key-concepts">Anahtar Kavramlar</a></li>
                                <li> <span className='fw-bold'>2.</span> <a href="#pages">Sayfa Tanıtımları</a>
                                    <ul style={{ listStyleType: "none" }}>
                                        <li> <span className='fw-bold'>2.1.</span> <a href="#projects">"Projeler" Sayfası</a></li>
                                        <li> <span className='fw-bold'>2.2.</span> <a href="#create-project">"Proje Oluşturma" Sayfası</a></li>
                                        <li> <span className='fw-bold'>2.3.</span> <a href="#project-info-page">"Proje İnceleme" Sayfası</a>
                                            <ul style={{ listStyleType: "none" }}>
                                                <li> <span className='fw-bold'>2.3.1.</span> <a href="#rebar-inspection">"Donatı Bilgileri" Sekmesi</a></li>
                                                <li> <span className='fw-bold'>2.3.2.</span> <a href="#cutting-plans">"Kesim Planları" Sekmesi</a></li>
                                                <li> <span className='fw-bold'>2.3.3.</span> <a href="#project-details">"Proje Bilgileri" Sekmesi</a></li>
                                            </ul>
                                        </li>
                                        <li> <span className='fw-bold'>2.4.</span> <a href="#create-cp">"Kesim Planı Oluşturma" Sayfası</a></li>
                                        <li> <span className='fw-bold'>2.5.</span> <a href="#cp-info">"Kesim Planı İnceleme" Sayfası</a>
                                            <ul style={{ listStyleType: "none" }}>
                                                <li> <span className='fw-bold'>2.5.1.</span> <a href="#cp-conf">"Seçim" Sekmesi</a></li>
                                                <li> <span className='fw-bold'>2.5.2.</span> <a href="#cp-summary">"Özet" Sekmesi</a></li>
                                                <li> <span className='fw-bold'>2.5.3.</span> <a href="#cp-patterns">"Kesim" Sekmesi</a></li>
                                                <li> <span className='fw-bold'>2.5.4.</span> <a href="#cp-other">"Yerine Kullanım & Fire" Sekmesi</a></li>
                                            </ul>
                                        </li>
                                        <li> <span className='fw-bold'>2.6.</span> <a href="#my-account-page">"Hesabım" Sayfası</a>
                                        </li>
                                    </ul>
                                </li>
                                <li> <span className='fw-bold'>3.</span> <a href="#project-manual">Proje Rehberi</a>
                                    <ul style={{ listStyleType: "none" }}>
                                        <li> <span className='fw-bold'>3.1.</span> <a href="#project-upload">Proje Yükleme</a></li>
                                        <ul style={{ listStyleType: "none" }}>
                                            <li> <span className='fw-bold'>3.1.1.</span> <a href="#ide-upload">ideCad Statik Projesi Yükleme</a></li>
                                            <li> <span className='fw-bold'>3.1.2.</span> <a href="#sta-upload">STA4Cad Projesi Yükleme</a></li>
                                            <li> <span className='fw-bold'>3.1.3.</span> <a href="#excel-upload">Excel Proje Yükleme</a></li>
                                        </ul>
                                        <li> <span className='fw-bold'>3.2.</span> <a href="#project-inspect">Proje İnceleme</a></li>
                                    </ul>
                                </li>
                                <li> <span className='fw-bold'>4.</span> <a href="#cp-manual">Kesim Planı Rehberi</a>
                                    <ul style={{ listStyleType: "none" }}>
                                        <li> <span className='fw-bold'>4.1.</span> <a href="#cp-upload">Kesim Planı Yükleme</a></li>
                                        <li> <span className='fw-bold'>4.2.</span> <a href="#cp-inspect">Kesim Planı İnceleme</a></li>
                                    </ul>
                                </li>
                                <li> <span className='fw-bold'>5.</span> <a href="#purchase-manual">Satın Alma Rehberi</a></li>
                                <li> <span className='fw-bold'>6.</span> <a href="#account-management">Hesap Yönetimi</a></li>
                            </ul>
                        </div>


                    </div>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="key-concepts">1. Anahtar Kavramlar</h3>
                    <ul>
                        <li className='mb-2'><span className='fw-bold'>Detaylı Metraj Dosyası</span>: ideCad Statik ve STA4Cad programları üzerinden, bir yapının donatı, beton ve kalıp metrajlarını içerebilen, ideCad Statik tarafından txt, STA4Cad tarafından xlsx (Microsoft Excel) formatında verilen elektronik dosyalardır. Bu iki programın çıktılarına ek olarak Tasnifer’in belirlediği formata uygun, donatı bilgilerini içeren Excel dosyaları da donatı metraj dosyası olarak kabul edilir. Bu dosyalar Tasnifer içerisinde Proje oluşturmak için kullanılır.
                        </li>
                        <li className='mb-2'><span className='fw-bold'>Proje</span>: Tasnifer içerisinde, ideCad Statik ve STA4Cad programlarının detaylı metraj çıktıları veya kendi belirlediğimiz formata uygun Microsoft Excel dosyalarının yüklenmesi ile oluşur; donatıların kat, yapı elemanı, adet, uzunluk, çap gibi bilgilerini içerir. Bu projeler, Tasnifer’in arayüzü üzerinden, donatıların ve bu donatıların metraj bilgilerinin yerleşim planlarına göre incelenebilmesini sağlar. Bu projeler Tasnifer’in belirlediği formata uygun bir Excel dosyası olarak Tasnifer üzerinden tekrar indirilebilir. Daha detaylı bilgiye <a href="#project-manual">Proje Rehberi</a> üzerinden erişebilirsiniz.
                        </li>
                        <li className='mb-2'><span className='fw-bold'>Kesim Planı</span>: <span className='fst-italic'>Proje</span>lerdeki seçilen belli donatıların standart boylardan, fireyi en aza indirecek şekilde optimize edilerek kesilmesiyle oluşur. Her kesim planı farklı kat, çap, yerine kullanım, katların birlikte çözümü ve elde kalan fire uzunluğu değerlerine sahip olabilir. Her projede bu değerleri farklı olan birden fazla kesim planı oluşturulabilir. Daha detaylı bilgiye <a href="#cp-manual">Kesim Planı Rehberi</a> üzerinden erişebilirsiniz.</li>
                        <li className='mb-2'><span className='fw-bold'>Yerine Kullanım</span>: Kesim planı oluşturulurken yüksek çaptaki donatılardan kesim sonrası artan ve alt çapta kullanılabilecek fire donatıların bu alt çaptaki donatılar yerine kullanılmasıdır. Sadece komşu çaplar arasında yerine kullanıma izin verilmektedir. Örneğin Φ12'den kalan uygun firelerin Φ10 donatılarında kullanılabilir, ancak Φ8 donatılarında kullanılamaz. </li>
                        <li className='mb-2'><span className='fw-bold'>Elde Kalan Fire</span>: Donatı kesim işlemi Tasnifer tarafından üretilen kesim planına uygun şekilde kesildiği takdirde elde kalması gereken firedir. Kesim planı oluşturulurken “Elde kalan fire için dikkate alınacak en kısa boy” ayarıyla bu fire hesaplanırken dikkate alınması gereken en kısa fire uzunluğu girilir. Örnek olarak bu uzunluk 10 cm olarak ayarlanırsa, 10 cm’den kısa olan fireler bu istatistiğin hesaplanmasına dahil edilmez.</li>
                    </ul>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="pages">2. Sayfa Tanıtımları</h3>

                    <h4 className='mb-3 fw-bold' id="projects">2.1. "Projeler" Sayfası</h4>
                    <p><span className='fst-italic'>Projeler</span> sayfası, kullanıcının şimdiye kadar oluşturmuş olduğu bütün projelerin listelendiği sayfadır. Kullanıcı bu sayfada listelenen projelere tıklayarak ilgili projenin "Proje İnceleme" sayfasına gidebilir.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectsPage} onClick={() => handleShow(projectsPage)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <h4 className='my-3 fw-bold' id="create-project">2.2. "Proje Oluşturma" Sayfası</h4>
                    <p><span className='fst-italic'>Proje</span> oluşturma sayfası, proje dosyasının yüklendiği, kesim yapılan demir uzunluğunun ve bindirme oranının ayarlandığı sayfadır.</p>
                    <p>Projenin yüklenebilmesi için projeye bir isim verilmeli, ardından metraj dosyası yüklenmeli, kesim yapılan demir uzunluğunun ve bindirme oranının ayarlanması gerekmektedir.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={createProjectsPage} onClick={() => handleShow(createProjectsPage)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <ol className='mt-3'>
                        <li>Proje isminin ayarlandığı form girişi</li>
                        <li>ideCad'in txt formatında verdiği detaylı metraj dosyasının yüklenebildiği yer</li>
                        <li>STA4Cad'in xlsx (Microsoft Excel) formatında verdiği detaylı metraj dosyasının yüklenebildiği yer</li>
                        <li>Bu programlar haricinde, kullanıcının demir bilgilerini elle girdiği xlsx (Microsoft Excel) formatındaki detaylı metraj dosyasının yüklenebildiği yer</li>
                        <li>Kesim yapılan demir uzunluğunun santimetre cinsinden girildiği yer. Eğer değişiklik yapılmazsa kesimler 1200 cm üzerinden yapılır. </li>
                        <li>Bindirme oranının ayarlandığı yer. Bindirme oranıyla ilgili detaylı bilgi için aynı sayfadaki ilgili "Bilgi" yazısına tıklayabilirsiniz. </li>
                    </ol>

                    <h4 className='my-3 fw-bold' id="project-info-page">2.3. "Proje İnceleme" Sayfası</h4>
                    <p>Projelerle ilgili detaylı bilgilerin öğrenilebileceği sayfadır. Bu sayfada donatılar yerleşim planına göre incelenebilir, seçilen donatıların metraj bilgisi elde edilebilir. Donatı bilgileri xlsx (Microsoft Excel) formatında indirilebilir, değişiklikler yapılarak tekrar yeni bir proje olarak yüklenebilir.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectInfo} onClick={() => handleShow(projectInfo)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <ol className='mt-3'>
                        <li>"Donatı Bilgileri" Sekmesi</li>
                        <li>"Kesim Planları" Sekmesi</li>
                        <li>"Proje Bilgileri" Sekmesi</li>
                        <li>Projeyi Microsoft Excel dosyası olarak indirme butonu</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="rebar-inspection">2.3.1. "Donatı Bilgileri" Sekmesi</h5>
                    <p>Bu sekmede donatılar yerleşim planından seçilen konumlarına göre incelenebilir. Donatıların listesi ve donatı çaplarına göre metraj bilgileri elde edilebilir.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={rebarInspection} onClick={() => handleShow(rebarInspection)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <ol className='mt-3'>
                        <li>Yerleşim planı üzerinden yapı elemanları seçimi yapılır.</li>
                        <li>Seçilen elemanlardaki donatıların metrajı görülür.</li>
                        <li>Seçilen elemanlardaki donatıların listesi görülür.</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cutting-plans">2.3.2. "Kesim Planları" Sekmesi</h5>
                    <p>Bu sekmede incelenen projeye ait kesim planlarının listesi görülür. Yeni kesim planı oluşturma sayfasına gidilebilir.</p>
                    <p><span></span></p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cuttingPlans} onClick={() => handleShow(cuttingPlans)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <h5 className='my-3 fw-bold' id="project-details">2.3.3. "Proje Bilgileri" Sekmesi</h5>
                    <p>Bu sekmede oluşturulan projeyle ilgili detaylar görülebilir. Ödeme yapılmamış projeler için ödeme ekranına gidilebilir, ödeme isteği oluşturulmuş projeler için de sipariş sayfası görülebilir.</p>
                    <p><span></span></p>
                    <Row className='d-flex align-items-center'>
                        <Col md={{ span: 6 }} className='mb-2'>
                            <Card onClick={() => handleShow(projectDetails)}>
                                <Card.Img variant="top" src={projectDetails} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        Satın alınmış projelerin "Proje Bilgileri" sekmesi
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <Card onClick={() => handleShow(projectDetailsUnpaid)}>
                                <Card.Img variant="top" src={projectDetailsUnpaid} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        Satın alınmamış projelerin "Proje Bilgileri" sekmesi
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <h4 className='my-3 fw-bold' id="create-cp">2.4. "Kesim Planı Oluşturma" Sayfası</h4>
                    <p>İncelenen projeye ait bir kesim planı oluşturmak için kullanılabilecek sayfadır. Bu sayfadan, oluşturulacak kesim planında yer alacak olan donatıların katları ve çapları seçilebilir, yerine kullanım ve elde kalacak en kısa fire ayarları yapılabilir.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={createCp} onClick={() => handleShow(createCp)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <ol className='mt-3'>
                        <li>Kesim planı isminin ayarlandığı form girişi</li>
                        <li>Kesim planına dahil edilecek donatıların bulunduğu katların seçildiği yer</li>
                        <li>Kesim planına dahil edilecek donatıların çaplarının seçildiği yer</li>
                        <li>Diğer optimizasyon seçeneklerinin ayarlandığı yers
                            <ul>
                                <li><span className='fw-bold'>Artıklar bir alt çapta kullanılsın</span> seçeneği: Bir çaptan artan fireler, eğer uygun donatı varsa bir alt çapta da kullanılabilir. Bu seçeneği aktif etmek isterseniz bu kutucuğu işaretleyebilirsiniz.</li>
                                <li><span className='fw-bold'>Seçilen katlar kendi arasında çözülsün</span> seçeneği: Bu seçim sonucu oluşan kesim planında seçilen katlara ait donatılar kendi içerisinde optimize edilir. Kesim planında farklı katlara ait donatılar aynı kesim üzerinde bulunmaz.</li>
                                <li><span className='fw-bold'>Elde kalacak fire için dikate alınacak en kısa boy</span>: <span className='fst-italic'>Elde kalacak fire</span> değerinin gerçekçi bir şekilde hesaplanabilmesi için, elde kalması istenen en kısa fire uzunluğunu ayarlayabilirsiniz. Bu değerden daha kısa olan firelerin kesim sırasında son parçada bırakıldığı kabul edilir.</li>
                            </ul>
                        </li>
                    </ol>

                    <p><span className='fw-bold'>HESAPLA</span> butonuna basılamsının ardından optimizasyon başlar. Bu optimizasyon işlemi birkaç dakikaya kadar sürebilir. Bu optimizasyon sırasında tarayıcınızı ve hesaplamanın yapıldığı sekmeyi kapatmamanız gerekmektedir.</p>

                    <h4 className='my-3 fw-bold' id="cp-info">2.5. "Kesim Planı İnceleme" Sayfası</h4>
                    <p>Oluşturulan kesim planının detaylarının incelendiği sayfadır. Kesim planının ait olduğu proje aktive edilmemişse (proje için ödeme yapılmamışsa) bu sayfada kesim planıyla ilgili temel özet bilgiler yer alır ve ödeme sayfasına yönlendirme butonu görülür. Proje için ödeme yapıldıysa kesim planıyla ilgili detaylara erişilebilir.</p>
                    <Row className='d-flex align-items-center'>
                        <Col md={{ span: 6 }} className='mb-2'>
                            <Card onClick={() => handleShow(cpInfo)}>
                                <Card.Img variant="top" src={cpInfo} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        Satın alınmış projelere ait kesim planlarının "Kesim Planı İnceleme" sayfası
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={{ span: 6 }}>
                            <Card onClick={() => handleShow(cpInfoUnpaid)}>
                                <Card.Img variant="top" src={cpInfoUnpaid} />
                                <Card.Body>
                                    <Card.Text className='text-center'>
                                        Satın alınmamış projelere ait kesim planlarının "Kesim Planı İnceleme" sayfası
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <ol className='mt-3'>
                        <li>Kesim planında incelenecek kat ve çap bilgilerinin seçildiği sekme</li>
                        <li>Kesim planıyla ilgili özet bilgilerin bulunduğu sekme</li>
                        <li>Kesim planındaki kesimlerin bulunduğu sekme</li>
                        <li>Kesim planındaki yerine kullanım ve fire listesinin bulunduğu sekme</li>
                        <li>Kesim planının yazdırılması için kullanılan buton</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cp-conf">2.5.1. "Seçim" Sekmesi</h5>
                    <p>Bu sekmede kesim planında incelenmek istenen katlar ve çap bilgisi seçilir. Burada seçilen katlarda bulunan seçilen çaptaki donatıların optimizasyon sonucu diğer sekmelerden incelenecektir.</p>
                    <p><span></span></p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpConf} onClick={() => handleShow(cpConf)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <h5 className='my-3 fw-bold' id="cp-summary">2.5.2. "Özet" Sekmesi</h5>
                    <p>Bu sekmede "Seçim" sekmesinde seçilen kat ve çap bilgisine ait donatıların optimizasyon sonucuyla ilgili çeşitli istatistik bilgileri verilir.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpSummary} onClick={() => handleShow(cpSummary)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <ol className='mt-3'>
                        <li> <span className='fw-bold'>Çözüm Seçenekleri</span> olarak, seçilen çapın çözüm süresi, hızlı çözüm ve en kısa fire bilgisi verilir.
                            <ul>
                                <li><span className='fw-bold'>Çözüm süresi</span>: Optimizasyon büyük çaptan başlayarak küçük çapa doğru yapılır. Her çap kendi içerisinde optimize edilir. Dolayısıyla her çapın çözümü farklı sürelerde tamamlanır. Seçilen çapın çözüm süresi burada gösterilir. </li>
                                <li><span className='fw-bold'>Hızlı çözüm</span>: Donatı sayı ve çeşidi arttıkça optimizasyon süresi uzayabilmektedir. Verimi azaltmadan bu süreyi azaltabilmek için Tasnifer hızlı çözüm teknikleri kullanır. Bu hızlı çözüm teknikleri bazen çok küçük verim farklılıklarına sebep olabilmektedir. Kullanıcıya çözümün hızlı çözüm teknikleriyle yapılıp yapılmadığı burada söylenir. </li>
                                <li><span className='fw-bold'>En kısa fire</span>: Kesim planı oluşturulurken ayarlanan <span className='fst-italic'>elde kalacak en kısa fire</span> uzunluğu burada yazar. </li>
                            </ul>
                        </li>
                        <li><span className='fw-bold'>Mevcut Sonuç Özeti</span> tablosunda seçilen kat ve çap bilgilerine göre çeşitli istatistikler verilir. Bu istatistiklerle ilgili detaylı bilgiler, tablo hücrelerinde bulunan "Bilgi" yazısına tıklanarak öğrenilebilir.</li>
                        <li><span className='fw-bold'>Tüm Sonuç Özeti</span>nde <span className='fst-italic'>Mevcut Sonuç Özeti</span>nde hesaplanan istatistikler tüm proje için toplu hâlde verilir. "Verim" istatistiğinin toplu hâlde verildiği tablo örneği aşağıdadır:
                            <Row>
                                <Col md={{ offset: 2, span: 8 }}>
                                    <img src={yieldTable} onClick={() => handleShow(yieldTable)} className='img-fluid shadow-sm img-thumbnail'></img>
                                </Col>
                            </Row>
                        </li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cp-summary">2.5.3. "Kesim" Sekmesi</h5>
                    <p>Bu sekmede "Seçim" sekmesinde seçilen kat ve çap bilgisine ait donatıların optimizasyonu sonucu oluşan kesim planı incelenebilir. Kesim planında bulunan kesimler burada sıralanır.</p>
                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpPatterns} onClick={() => handleShow(cpPatterns)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <p className='my-3'>Bir kesimde gösterilen bilgiler şunlardır:</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={pattern1} onClick={() => handleShow(pattern1)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={pattern2} onClick={() => handleShow(pattern2)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row> */}

                    <ol className='mt-3'>
                        <li>Kesim şeklinden kaç adet kesileceği bilgisi</li>
                        <li>Kesim şeklinin yazı halindeki gösterimi</li>
                        <li>Kesim şeklindeki <span className='fw-bold'>Toplam Fire</span> (Kesim Şekli Adeti &times; Bir kesim şeklinden artan fire)</li>
                        <li>Her kesim şekli için ayrı belirlenen kesim numarası</li>
                        <li>Kesim şeklinin gösterimi</li>
                        <li>Kesim sonucu oluşan kesimlerin yerleştirileceği yerler</li>
                        <li>Kesim şeklinden artan fire bir alt çapta kullanılabildiyse bu kullanımın bilgisi</li>
                    </ol>

                    <h5 className='my-3 fw-bold' id="cp-other">2.5.4. "Yerine Kullanım & Fire" Sekmesi</h5>
                    <p>Bu sekmede "Seçim" sekmesinde seçilen kat ve çap bilgisine ait kesimlerde yer alan <span className='fwt-italic'>Yerine Kullanım</span>ların ve <span className='fwt-italic'>fire</span>lerin listesine erişilebilir.</p>
                    <p><span></span></p>
                    <Row className='mb-3'>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={cpOther} onClick={() => handleShow(cpOther)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <p>Yerine Kullanım tablosundaki "Asıl Donatı", yerine üst çaptan donatı kullanılan asıl donatının donatı kodunu gösterir. "Yerine Kullanılan", asıl donatının yerine üst çaptan kullanılan donatının sırasıyla çap ve uzunluk bilgisini içerir. "16-54", 54 cm'lik Φ16 donatı demektir.</p>

                    <h4 className='my-3 fw-bold' id="my-account-page">2.6. "Hesabım" Sayfası</h4>

                    <p>Üyeliğinizle ilgili detayların göründüğü sayfadır. Sayfanın en üstünde bulunan navigasyon barındaki "Hesabım" yazısına tıklanarak erişilir. Bu sayfa üzerinden e-posta adresinizi, şifrenizi, fatura bilgilerinizi değiştirebilirsiniz.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={myAccount} onClick={() => handleShow(myAccount)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='my-3'>Bu sayfanın en altında bulunan "Satın Almalar" yazısına tıklanarak şimdiye kadar Tasnifer üzerinden satın alınan projelerle ilgili bilgilerin ve faturaların bulunduğu sayfaya erişilebilir.</p>

                    <Row className='mb-3'>
                        <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                            <img src={myPurchases} onClick={() => handleShow(myPurchases)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                        </Col>
                    </Row>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="project-manual">3. Proje Rehberi</h3>

                    <p>Bu rehber detaylı metraj dosyalarının Tasnifer'e nasıl yükleneceğini ve yükleme sonrası oluşan projenin nasıl yorumlanacağını açıklar.</p>

                    <h4 className='my-3 fw-bold' id="project-upload">3.1. Proje Yükleme</h4>

                    <p>Tasnifer’de 3 farklı girdi dosyası tipi ile proje oluşturabilirsiniz. ideCad Statik ve STA4Cad programlarının çıktısı olan detaylı metraj dosyalarını yükleyebilir, veya bizim belirlediğimiz formattaki Excel dosyanızı sisteme yükleyebilirsiniz.</p>

                    <p>Proje yükleme işlemleri <a href="#create-project">Proje Oluşturma Sayfası</a> üzerinden yapılmaktadır. Bu sayfaya sayfanın en üstünde bulunan navigasyon barından "Proje Oluştur" yazısına tıklayarak veya <a href="#projects">Projelerim Sayfası</a>nda bulunan "Proje Oluştur" butonuna tıklayarak erişebilirsiniz. Projenizi oluşturabilmek için bu sayfada şu bilgileri sağlamanız gerekmektedir:</p>

                    <ul>
                        <li><span className='fw-bold'>Proje İsmi: </span>Projenize bir isim vermeniz gerekmektedir. Proje ismi en fazla 25 karakterden oluşabilir ve oluşturduğunuz diğer projelerinizle aynı isme sahip olamaz. Proje ismi sonrasında değiştirilemez.</li>
                        <li><span className='fw-bold'>Detaylı Metraj Dosyası: </span>Proje oluşturma sayfasındaki 3 büyük butondan uygun olan butona tıklayarak bu dosyayı sisteme yüklemeniz gerekmektedir.</li>
                        <li><span className='fw-bold'>Kesim Yapılan Demir Uzunluğu: </span>Donatıların kesileceği demirin santimetre cinsinden uzunluğu. Nervürlü demirler piyasada standart olarak 12 metre uzunluğunda satılmaktadır. Eğer standart olarak üretilmiş daha uzun veya kısa demirlerden kesim yapmak istiyorsanız bunu ayarlayabilirsiniz.</li>
                        <li><span className='fw-bold'>Bindirme Katsayısı: </span>Yüklenen Metraj Dosyası, Kesim Yapılan Demir Uzunluğu’ndan daha uzun donatılar içeriyorsa bu donatılar bir bindirme boyu eklenerek parçalanır. Bu bindirme boyunun hesaplanması için gereken katsayı değerini değiştirmek isterseniz burada değiştirebilirsiniz. Detaylı bilgi aşağıda verilmiştir.</li>
                    </ul>

                    <div className='m-3 p-3 border bg-light'>
                        <h5 className='my-3 fw-bold'>Bindirme Katsayısı Nedir? Bindirme Boyu Nasıl Hesaplanır?</h5>

                        <p> Yüklenen proje dosyası, kesim yapılan demir uzunluğundan daha uzun donatılar içeriyorsa, bu demirler belli bir bindirme boyu eklenerek parçalanmaktadır. Bindirme boyu donatı çapı, sınıfı ve beton kalitesine göre değişiklik göstermektedir. Uygulamada pratiklik olması için güvenilir tarafta kalma gerekliliği de hesaba katılarak, S420 çeliği için C25 ve üzeri beton sınıflarında yeterli bindirme mesafesini veren <span style={{ fontWeight: "bold" }}>40 &times; Φ mm</span> değeri kullanılmıştır. Yine de bindirme boyunun yetersiz kalacağı durumlar için bir <span style={{ fontWeight: "bold" }}>k</span> katsayısıyla çarpılarak bu mesafe arttırılabilir. Bindirme boyu bu uygulamada aşağıdaki formüle göre hesaplanmaktadır:</p>
                        <p className="text-center"> Bindirme Boyu (cm) &#x3d; 4 &times; Φ &times; k</p>
                        <p> Bu formülde <span style={{ fontWeight: "bold" }}>Φ</span>, demirin milimetre cinsinden çapı; <span style={{ fontWeight: "bold" }}>k</span> ise burada girilen <span style={{ fontStyle: "italic" }}>bindirme boyu katsayısı</span>dır. Bindirme katsayısı 0 ile 5 arasında bir ondalık sayıdır. <span style={{ fontWeight: "bold" }}>Manşon kullanılacaksa bindirme katsayısının 0 girilmesi gerekir.</span> Formülde çıkan sonuç yukarı yuvarlanır. </p>
                        <p>Örneğin, standart 1200 cm boydan kesilen 14 mm çapında ve 1270 cm uzunluğunda bir donatı için bindirme katsayısı 1.2 alındığında programda bindirme boyu şöyle hesaplanır: </p>
                        <p className="text-center"> L &#x3d; 4 &times; 14 &times; 1.2 &#x3d; 67.2 </p>
                        <p>Çıkan sonuç yukarı yuvarlanır ve bindirme boyu <span style={{ fontWeight: "bold" }}>68</span> cm olarak elde edilir. Dolayısıyla 1270 cm'lik donatı 1200 cm ve 70 + 68 = 138 cm olacak şekilde iki donatıya ayrılır.</p>
                        <p>Parçalanma sonucu oluşan donatı sayısı arttıkça son parçaya bir bindirme boyu daha eklenir. Örneğin 14 mm çapındaki 2540 cm'lik bir donatı 2 adet 1200 cm'lik ve 1 adet 140 + <span style={{ fontWeight: "bold" }}>2</span> &times; L cm'lik parçalara bölünür. L yukarıdaki gibi hesaplanır. Dolayısıyla 2540 cm'lik donatı 2 adet 1200 ve 1 adet 70 + 2 &times; 68 = 206 cm'lik parçalara bölünür.</p>
                    </div>

                    <p>Yukarıda belirtilen Proje bilgileri girildikten sonra <span className='fw-bold'>İLERLE</span> butonuna basılarak proje oluşturma işlemi tamamlanır.</p>

                    <p><span className='fw-bold'>NOT: </span>Detaylı metraj dosyanızı yüklemeden önce, donatıların doğru bir şekilde elde edilebilmesi için bu dosyanın çizim paftalarıyla uyumlu olduğundan emin olmanız gerekmektedir.</p>

                    <p>Detaylı Metraj Dosyalarının formatları ve ilgili programlardan nasıl alınacağıyla ilgili aşağıdaki rehberleri inceleyebilirsiniz.</p>

                    <h5 className='my-3 fw-bold' id="ide-upload">3.1.1. ideCad Statik Projesi Yükleme</h5>

                    <p>ideCad Statik üzerinden detaylı metraj dosyası almak için aşağıdaki adımları izlemeniz gerekmektedir:</p>
                    <ol>
                        <li className='mb-4'>Analiz bittikten sonra üstteki <span className='fw-bold'>Rapor</span> sekmesine tıklayın. Sonra <span className='fw-bold'>Metraj</span> seçeneğine tıklayarak <span className='fw-bold'>Metraj Rapor Ayarları</span>'na erişin.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={ide1} onClick={() => handleShow(ide1)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <span className='fw-bold'>Metraj Rapor Ayarları</span>'na Ribbon menü Raporlar sekmesi Adet başlığı altından da ulaşabilirsiniz.
                        <Row className='my-4'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={ide2} onClick={() => handleShow(ide2)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='my-4'><span className='fw-bold'>Metraj Rapor Ayarları</span> menüsünden metraj türü olarak <span className='fw-bold'>Kalıp metrajı</span>, <span className='fw-bold'>Beton Metrajı</span>, <span className='fw-bold'>Donatı Metrajı</span> ve <span className='fw-bold'>Toplam Metraj</span> seçeneklerini seçin. Metraj Detayı bölümünde <span>Detaylı Metraj</span>ı seçin. Eleman tipleri bölümünde projede hesaplanan tüm elemanları seçin. Diğer sekmesinde <span className='fw-bold'>Detaylı metrajda poz listesi</span> seçeneğini seçin ve <span className='fw-bold'>Oluştur</span> butonuna tıklayın.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={ide3} onClick={() => handleShow(ide3)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='mb-4'>Ekranda açılan PDF dosyasının üst tarafındaki <span className='fw-bold'>Dosya</span> sekmesine tıklayın. <span className='fw-bold'>Farklı Kaydet</span> seçeneğini tıklayarak metraj dosyasını <u className='fw-bold'>txt uzantılı olarak</u> kaydedin. Tasnifer ideCad Statik'in txt formatındaki verilerini işlediğinden bu PDF dosyası mutlaka txt olarak kaydedilmelidir.</li>
                        <Row>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={ide4} onClick={() => handleShow(ide4)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                    </ol>

                    <p>ideCad Statik üzerinden bu detaylı metraj dosyasını aldıktan sonra <a href="#project-upload">Proje Yükleme Sayfası</a>'nda bulunan <span className='fw-bold'>ideCad Statik txt Dosyası</span> butonuna tıklamanız ve açılan pencereden oluşturduğunuz txt dosyasının dizindeki yerini seçerek bu dosyayı sisteme yüklemeniz gerekmektedir. Ardından İLERLE butonuna basarak projenizi yükleyebilirsiniz.</p>

                    <h5 className='my-3 fw-bold' id="sta-upload">3.1.2. STA4Cad Projesi Yükleme</h5>

                    <p>STA4Cad üzerinden detaylı metraj dosyası almak için aşağıdaki adımları izlemeniz gerekmektedir:</p>
                    <ol>
                        <li className='mb-4'>Modelin analizi tamamlandıktan sonra STA4Cad ana menüde yer alan <span className='fw-bold'>"Sonuçların Printerden Alınması"</span> komutuna tıklayın.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={sta1} onClick={() => handleShow(sta1)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='mb-4'>Daha sonra  açılacak olan "Printer Yazdırma Seçimi" penceresinde yer alan "Yapı Genel Bilgisi" sekmesinde bulunan <span className='fw-bold'>Yapı Elemanları Metrajı</span> ve <span className='fw-bold'>Excell Metraj</span> seçeneklerini işaretleyin ve <span className='fw-bold'>Print</span> tuşuna basın.</li>
                        <Row className='mb-4'>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={sta2} onClick={() => handleShow(sta2)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                        <li className='mb-4'>Bir süre bekledikten sonra otomatik olarak açılan Excel dosyasını farklı kaydedin.</li>
                        <Row>
                            <Col md={{ offset: 2, span: 8 }}>
                                <img src={sta3} onClick={() => handleShow(sta3)} className='img-fluid shadow-sm img-thumbnail'></img>
                            </Col>
                        </Row>
                    </ol>

                    <p>STA4Cad üzerinden bu detaylı metraj dosyasını aldıktan sonra <a href="#project-upload">Proje Yükleme Sayfası</a>'nda bulunan <span className='fw-bold'>STA4Cad xlsx Dosyası</span> butonuna tıklamanız ve açılan pencereden oluşturduğunuz Excel dosyasının dizindeki yerini seçerek bu dosyayı sisteme yüklemeniz gerekmektedir. Ardından İLERLE butonuna basarak projenizi yükleyebilirsiniz.</p>

                    <h5 className='my-3 fw-bold' id="excel-upload">3.1.3. Excel Projesi Yükleme</h5>

                    <p>Belirlediğimiz formata uygun bir Excel dosyası hazırlayarak da proje oluşturabilirsiniz. Excel dosyasında en üst satırdaki hücrelere şu değerleri girmelisiniz:</p>

                    <div className='table-responsive '>
                        <table className='table text-center'>
                            <tbody>
                                <tr>
                                    <th className='border'>POZ</th>
                                    <th className='border'>ADET</th>
                                    <th className='border'>UZUNLUK</th>
                                    <th className='border'>ÇAP</th>
                                    <th className='border'>KAT</th>
                                    <th className='border'>YAPI ELEMANI</th>
                                    <th className='border'>ELEMAN İSMİ</th>
                                    <th className='border'>AÇIKLAMA</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p>Bu değerlerin büyük veya küçük harfle yazılması, Türkçe karakter kullanılıp kullanılmaması önemli değildir.</p>

                    <p>En üst satırda bu değerleri girmenizin ardından her donatı grubu için gerekli bilgileri ilgili satırda ilgili sütunun altına yazmalısınız. Örnek bir görünüm aşağıdaki gibidir:</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={excelTable} onClick={() => handleShow(excelTable)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='mt-3'><span className='fw-bold'>POZ: </span>Donatı grubunun poz değeri. Bir sayı veya metin olabilir. Girilmesi zorunlu değildir. Girilmediği takdirde program otomatik olarak donatıları okuduğu sırayla 1’den başlayarak her donatı grubuna bir poz numarası verir.</p>
                    <p><span className='fw-bold'>ADET: </span>Donatı grubunun adedi. Girilmesi zorunludur. Adet bilgisi girilmediği takdirde proje oluşturulamaz.</p>
                    <p><span className='fw-bold'>UZUNLUK: </span>Donatı grubundaki donatıların cm cinsinden uzunluğu. Girilmesi zorunludur. Uzunluk bilgisi girilmediği takdirde proje oluşturulamaz.</p>
                    <p><span className='fw-bold'>ÇAP: </span>Donatı grubundaki donatıların mm cinsinden çapı. Girilmesi zorunludur. Çap bilgisi girilmediği takdirde proje oluşturulamaz.</p>
                    <p><span className='fw-bold'>KAT: </span>Donatı grubunun bulunduğu kat bilgisi. Katların sıralı bir şekilde girilmesi, kat sırasının doğru elde edilebilmesi açısından önemlidir. “1. Kat”a ait hiçbir donatı girilmemişken “Bodrum kat”ın donatılarından sonra “2. Kat” donatıları girilirse program “2. Kat”’ı “Bodrum”dan sonraki kat olarak algılar ve “1. Kat” “2. Kat”tan sonra geliyormuş gibi kabul edilir. “Bodrum” donatılarının girilmesinin ardından “2. Kat” donatısı girilmeden önce en azından bir adet “1. Kat”a ait bir satır girilmiş olmalıdır. Kat bilgisi girilmesi zorunlu değildir. Kat bilgisi elde edilemediği takdirde donatılar için kat bilgisi yerine “#” yazılır.</p>
                    <p><span className='fw-bold'>YAPI ELEMANI: </span>Donatı grubunun ait olduğu yapı elemanı ismi (Döşeme, Kiriş, Kolon vs.). Aynı yapı elemanına ait donatıların Yapı Elemanı bilgisinin tıpatıp aynı yazılması önem arz etmektedir. Sözgelimi Döşeme’ye ait bir donatı grubu için bu bilgi “Döşeme” olarak girilmişse, sonrasında aynı yapı elemanına ait bir donatı grubu için bu bilgi “Döşemeler” veya “Doseme” şeklinde yazılmamalı, yine aynı şekilde “Döşeme” olarak yazılmalıdır. Yapı Elemanı bilgisi girilmesi zorunlu değildir. Yapı elemanı bilgisi elde edilemediği takdirde donatılar için Yapı Elemanı bilgisi yerine “#” yazılır.</p>
                    <p><span className='fw-bold'>ELEMAN İSMİ: </span>Donatı grubunun ait olduğu yapı elemanının spesifik kodu. D130, S345, P123 gibi. Eleman İsmi bilgisi girilmesi zorunlu değildir. Eleman İsmi bilgisi elde edilemediği takdirde donatılar için Eleman İsmi bilgisi yerine “#” yazılır.</p>
                    <p><span className='fw-bold'>AÇIKLAMA: </span>Donatı grubuyla ilgili açıklama. Donatı grubunun işlevini yazabilirsiniz. Etriye, çiroz, pilye vs. gibi. Açıklama bilgisi girilmesi zorunlu değildir. Açıklama bilgisi elde edilemediği takdirde donatılar için Açıklama bilgisi yerine “#” yazılır.</p>

                    <p>Sisteme yüklenen bütün projeler Tasnifer tarafından bu Excel formatına getirilerek bu projelerin sonradan indirilmesine ve tekrardan sisteme yüklenmesine imkan tanınır. ideCad veya STA4Cad’in detaylı metraj dosyalarını Tasnifer’e yükleyerek bu projelerin belirlediğimiz Excel formatındaki proje dosyasını ücretsiz olarak indirebilir, gerekli değişiklikleri yaparak yeni bir proje olarak tekrardan Tasnifer’e yükleyebilirsiniz.</p>

                    <h4 className='my-3 fw-bold' id="project-inspect">3.2. Proje İnceleme</h4>

                    <p>Tasnifer’e projenizi başarıyla yükledikten sonra projenizi, projeniz içerisindeki donatıları ve bu donatıların metrajını incelemek için <a href="#project-info-page">Proje İnceleme Sayfası</a>na gitmeniz gerekmektedir. Projeniz yüklendikten sonra otomatik olarak bu sayfaya yönlendirilirsiniz.</p>

                    <Row>
                        <Col md="4" className='d-flex justify-content-center'>
                            <img src={yerlesimPlani} onClick={() => handleShow(yerlesimPlani)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                        </Col>
                        <Col md="8">
                            <p>Yüklediğiniz projede bulunan donatılarla ilgili bilgileri elde etmek için Proje İnceleme Sayfasındaki “Donatı Bilgileri” sekmesine gidebilirsiniz. Bu sekmenin sol tarafında yüklenen projenin <span className='fst-italic'>Yerleşim Planı</span>nı bulabilirsiniz.</p>

                            <div className='m-4 p-3 border bg-light'>
                                <p><span className='fw-bold'>Yerleşim Planı: </span>Yüklenen yapıdaki donatıların kat, yapı elemanı ve yapı elemanı isimlerine göre sınıflandırılmasıdır.</p>
                            </div>

                            <p>Yerleşim planını incelemek ve bir kata ait yapı elemanlarını veya bir kattaki yapı elemanına ait yapı elemanı kodlarını görmek için yerleşim planı elemanlarının yanında bulunan ok işaretine tıklayabilirsiniz. Yerleşim planı üzerinden seçtiğiniz yapı elemanlarına dahil olan donatıların metrajını “Teorik Ağırlıklar (kg)” başlığı altında bulabilirsiniz. Seçtiğiniz yapı elemanında bulunan donatıları incelemek için “Donatılar” başlığı altındaki listeyi inceleyebilirsiniz. </p>

                        </Col>
                    </Row>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectInspection} onClick={() => handleShow(projectInspection)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='mt-3'>Projeniz yüklenirken, detaylı metraj dosyasında adet, uzunluk, çap, varsa poz, kat, yapı elemanı, eleman kodu bilgisi biricik olan her donatı grubuna bir <span className='fw-bold'>donatı kodu</span> atanır. Bu donatı kodu donatı grubunun çapı, uzunluğu ve Tasnifer tarafından belirlenen bir sıra numarasından oluşur. Sözgelimi, 8-206-29 donatı kodlu donatı grubu 8 mm çaplı, 206 cm uzunluğundaki 29. donatı grubuna delalet etmektedir. Bu donatı kodları donatıların yerlerine göre ayrımlarının daha kolay yapılmasını sağlamak amaçlı olarak üretilmiştir ve metraj dosyasında bulunan poz bilgisinden farklıdır.</p>

                    <p>Daha detaylı incelemeler yapmak için projeyi sağ üstteki Excel simgesine tıklayarak daha önce açıkladığımız Excel formatında indirebilir, Excel araçlarını kullanarak daha detaylı hesaplar yapabilirsiniz. Yüklediğiniz proje üzerinde gerekli gördüğünüz değişiklikleri yaparak yeni bir proje olarak tekrar Tasnifer’e yükleyebilirsiniz.</p>

                    <p>Projenizin sisteme yüklenmesi sırasında projenizin sisteme yüklenmesini engellemeyen bir problemle karşılaşılmışsa bu problemle ilgili bir uyarı metni <a href="#project-info-page">Proje Bilgileri Sayfası</a>nın <a href="#rebar-inspection">Donatı Bilgileri Sekmesi</a>nde aşağıdaki gibi en üstte gösterilir:</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }}>
                            <img src={projectInfoWarning} onClick={() => handleShow(projectInfoWarning)} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>

                    <p className='mt-3'>Projenizde kesim yapılan demirin uzunluğundan (standart olarak 12 metre) daha uzun donatılar varsa, bu donatılarla ilgili bilgileri “Donatı Bilgileri” sekmesinin en altından öğrenebilirsiniz. Yerleşim planından incelemek istediğiniz yapı elemanlarını seçtikten sonra sayfanın en altında “Uzun Donatılar” başlığı altında bu uzun donatıların listesi verilir.</p>

                    <Row>
                        <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                            <img src={longRebars} onClick={() => handleShow(longRebars)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "400px" }}></img>
                        </Col>
                    </Row>

                    <p className='mt-3'>Proje İnceleme Sayfasının <a href="#cutting-plans">Kesim Planları Sekmesi</a>nde bu projeye ait olan kesim planlarınızı görebilir, <a href="#project-details">Proje Bilgileri Sekmesi</a>nde de projenize ait bazı bilgileri elde edebilirsiniz. Projenize ait ödeme sayfasına da bu sekmeden ulaşabilirsiniz.</p>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="cp-manual">5. Kesim Planı Rehberi</h3>

                    <p>Bu rehber Tasnifer’e detaylı metraj dosyalarının yüklenmesinin ardından oluşan projelerde nasıl kesim planı oluşturulacağını ve bu kesim planlarının nasıl inceleneceğini açıklar.</p>

                    <h4 className='mb-3 fw-bold' id="cp-upload">5.1. Kesim Planı Yükleme</h4>

                    <p>Bir projeye ait yeni bir kesim planı oluşturmak için projeye ait <a href="#project-info-page">Proje İnceleme Sayfası</a>nın <a href="#cutting-plans">Kesim Planları Sekmesi</a>ndeki “Kesim Planı Oluştur” butonuna tıklayabilirsiniz. Ardından gelen <a href="#create-cp">Kesim Planı Oluşturma Sayfası</a>ndan kesim planınıza ait ayarlamaları yapmanız gerekmektedir:</p>

                    <ul>
                        <li>
                            <span className='fw-bold'>Kesim Planı İsmi: </span>Her kesim planına en fazla 25 karakterden oluşacak bir isim vermeniz gerekmektedir.
                        </li>
                        <li>
                            <span className='fw-bold'>Kesim planına dahil edilecek katlar: </span>"Yerleşim Planı" başlığı altından kesim planına dahil edilecek katları seçmeniz gerekmektedir. Proje oluşturulurken kat bilgisi elde edilememişse burada sadece “#” seçeneğini görmeniz normaldir.
                        </li>
                        <li>
                            <span className='fw-bold'>Kesim planına dahil edilecek çaplar: </span>“Optimizasyon yapılması istenen çaplar” başlığı altından kesim planına dahil olmasını istediğiniz çapları seçebilirsiniz.
                        </li>
                        <li>
                            <span className='fw-bold'>Diğer Optimizasyon Seçenekleri:</span>
                            <ul>
                                <li><span className='fw-bold'>Artıklar bir alt çap için kullanılsın: </span>Bir çaptan artan firelerin eğer uygun donatı varsa bir alt çapta da kullanılabilmesini <span className='fst-italic'>(Yerine Kullanım)</span> istiyorsanız bu seçeneği işaretleyebilirsiniz.</li>
                                <li><span className='fw-bold'>Seçilen katlar kendi arasında çözülsün: </span>Kesim planında seçilen katlara ait donatılar kendi içerisinde optimize edilir. Kesim planında farklı katlara ait donatılar aynı kesim üzerinde bulunmaz.</li>
                                <li><span className='fw-bold'><span className='fst-italic'>Elde kalacak fire</span> için dikate alınacak en kısa boy: </span><span className='fst-italic'>Elde kalacak fire</span> değerinin gerçekçi bir şekilde hesaplanabilmesi için, elde kalması istenen en kısa fire uzunluğunu ayarlayabilirsiniz. Bu değerden daha kısa olan firelerin kesim sırasında son parçada bırakıldığı kabul edilir.</li>
                            </ul>
                        </li>
                    </ul>

                    <p>Bu ayarlamaların ardından “HESAPLA” butonuna basılmasıyla optimizasyona başlanır. Optimizasyon sırasında ekranda “Optimizasyon yapılıyor. Bu biraz zaman alabilir, lütfen bekleyiniz. Sayfayı kapatmayınız.” yazısı çıkar. Optimizasyona dahil edilen donatıların adedi ve uzunluklarına bağlı olarak optimizasyonun süresi 10 dakikaya kadar uzayabilir. Bu sırada beklemeniz ve optimizasyonun yapıldığı tarayıcı sekmesini kapatmamanız gerekmektedir. Optimizasyonun bitmesinin ardından otomatik olarak oluşturduğunuz kesim planının <a href="#cp-info">Kesim Planı İnceleme Sayfası</a>na yönlendirilirsiniz.</p>

                    <h4 className='mb-3 fw-bold' id="cp-inspect">5.2. Kesim Planı İnceleme</h4>

                    <p>Bir projeye ait kesim planlarının listesine <a href="#project-info">Proje İnceleme Sayfası</a>nın <a href="#cutting-plans">Kesim Planları Sekmesi</a>nden erişebilirsiniz. Bu sayfadan incelemek istediğiniz kesim planına ait <a href="#cp-info">Kesim Planı İnceleme Sayfası</a>na erişebilirsiniz. </p>

                    <p>Kesim planının ait olduğu proje için ödeme yapmadıysanız <a href="#cp-info">Kesim Planı İnceleme Sayfası</a>nda kesim planına ait özet bilgiler ve ödeme sayfasına yönlendiren bir buton bulunur. Eğer ödeme yaptıysanız bu sayfada kesim planına ait detaylara erişebilirsiniz.</p>

                    <p>Tasnifer, üye olan her kullanıcısına Tasnifer’i deneyebilecekleri ve bedava kesim planları oluşturabilecekleri bir “Örnek Proje” verir. Bu örnek proje üzerinden kesim planları oluşturarak sistemi deneyebilirsiniz.</p>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="purchase-manual">6. Satın Alma Rehberi</h3>

                    <p>Tasnifer’e Proje yüklemek ücretsizdir. Donatı metraj dosyalarınızı Tasnifer’e ücretsiz olarak yükleyerek proje oluşturabilir, interaktif donatı metrajını inceleyebilir, bu projeyi belirlediğimiz Excel formatında tekrar indirebilir ve tekrar sisteme yükleyebilirsiniz. Bunları yapmak için herhangi bir ücret ödemenize gerek yoktur.</p>

                    <Row className='mb-3'>
                        <Col md="7">
                            <p>Projelere ait kesim planları üretmek için de ücret ödemezsiniz. Bir kesim planı oluşturmanızın ardından Kesim planına ait şu istatistikleri ücretsiz olarak öğrenirsiniz:</p>

                            <ul>
                                <li>Kesim Planında kullanılan Toplam Ağırlık (kg)</li>
                                <li>Elde Kalan Fire Ağırlığı (kg)</li>
                                <li>Teorik Fire Ağırlığı (kg)</li>
                                <li>Yerine Kullanım Ağırlığı (kg)</li>
                                <li>Verim Yüzdesi</li>
                            </ul>

                            <p>Bu istatistikleri ücretsiz bir şekilde öğrenerek optimizasyonun verimini önceden öğrenebilir ve projenin kesim planı oluşturma hakkını satın alıp almama kararınızı verebilirsiniz. </p>

                        </Col>
                        <Col md="5" className='d-flex justify-content-center'>
                            <img src={cpInfoUnpaid} onClick={() => handleShow(cpInfoUnpaid)} className='img-fluid shadow-sm img-thumbnail' style={{}}></img>
                        </Col>
                    </Row>

                    <p>Oluşturduğunuz bu kesim planının detaylarına erişebilmek için ise kesim planının ait olduğu projenin kesim planı oluşturma hakkını satın almanız gerekmektedir. Satın alım işlemi kesim planı için değil, proje için yapılır ve ödemeyi yapmanızın ardından sadece bir adet kesim planına değil, projeye ait oluşturduğunuz ve oluşturacağınız bütün kesim planlarına erişim hakkı kazanırsınız.</p>

                    <p>Projenin Satın Alma Sayfasına Proje Sayfası’nın “Proje Detayları” sekmesi üzerinden veya oluşturduğunuz Kesim Planı’nın Kesim Planı İnceleme Sayfası üzerinden erişebilirsiniz. (Elinizde Proje oluşturmak için Detaylı Metraj Dosyası yoksa ve satın alım sayfalarını görmek istiyorsanız Örnek Proje’nin Excel dosyasını indirip tekrar sisteme yükleyerek ödeme adımlarını inceleyebilirsiniz.)</p>

                    <p>Tasnifer Havale ve Kredi/Banka Kartı üzerinden ödeme alabilmektedir.</p>

                    <p>Ödeme işlemlerini gerçekleştirebilmek için:</p>

                    <ol>
                        <li className='mb-3'>Proje Satın Alım Sayfasına gidin.</li>
                        <li className='mb-3'>Fatura Detaylarını doldurun.</li>
                        <Row className='mb-3'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={purchaseStep1} onClick={() => handleShow(purchaseStep1)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                            </Col>
                        </Row>
                        <li className='mb-3'>Ödeme yönteminizi seçin. Kredi/Banka Kartı ile ödemeyi seçtiyseniz Kart bilgilerinizi girin.</li>
                        <li className='mb-3'>Satış Sözleşmesini onaylayın.</li>

                        <Row className='d-flex align-items-center'>
                            <Col md={{ span: 6 }} className='mb-2'>
                                <Card onClick={() => handleShow(purchaseStep2Transfer)}>
                                    <Card.Img variant="top" src={purchaseStep2Transfer} />
                                    <Card.Body>
                                        <Card.Text className='text-center'>
                                            Havale/EFT
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={{ span: 6 }}>
                                <Card onClick={() => handleShow(purchaseStep2CC)}>
                                    <Card.Img variant="top" src={purchaseStep2CC} />
                                    <Card.Body>
                                        <Card.Text className='text-center'>
                                            Kredi/Banka Kartı
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <li className='mb-3'>Fatura ve Ödeme bilgilerinizi kontrol edin. Ödemenizi tamamlamak için “Siparişi Tamamla” butonuna tıklayın.</li>
                        <Row className='mb-3'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={purchaseStep3} onClick={() => handleShow(purchaseStep3)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                            </Col>
                        </Row>

                        <li className='mb-3'>Kredi/Banka kartı ile ödemeyi seçtiyseniz ödemenizin onaylanması için 3D Secure işlemlerini tamamlayın. Ardından ödemeniz tamamlanmış olacak ve Projenizin Kesim Planları erişiminize açılacak.</li>

                        <li className='mb-3'>Havale/EFT ile ödemeyi seçtiyseniz sipariş işlemlerini tamamlamanızın ardından gelen ekrandaki banka hesabına belirtilen miktarda parayı yatırın. Ödemenizin onaylanmasının ardından Projenizin Kesim Planlarına erişebileceksiniz.</li>

                        <Row className='mb-3'>
                            <Col md={{ offset: 2, span: 8 }} className='d-flex justify-content-center'>
                                <img src={purchaseStep4} onClick={() => handleShow(purchaseStep4)} className='img-fluid shadow-sm img-thumbnail' style={{ maxHeight: "300px" }}></img>
                            </Col>
                        </Row>
                    </ol>

                    <p>Faturanız ödeme işlemlerini tamamlamanızın ardından e-posta hesabınıza gönderilecektir.</p>

                    <p>Bütün satın alımlarınız ile ilgili bilgilere ve faturalarınıza sayfanın üstündeki Navigasyon barından Hesabım {">"} Satın Almalar üzerinden erişebilirsiniz. Bu ekranda üst taraftan satın aldığınız hizmeti ve ödeme yönteminizi seçmenizin ardından ilgili özelliklere sahip ödemelerinizi görebilirsiniz.</p>

                    <hr className='my-4' />

                    <h3 className='mb-3 fw-bold' id="account-management">6. Hesap Yönetimi</h3>

                    <p>Hesabınız ve üyeliğinizle ilgili bilgilere <a href="#my-account-page">Hesabım Sayfası</a> üzerinden erişebilirsiniz. </p>

                    <h4 className='mb-3 fw-bold' id="change-email">6.1. E-posta değişikliği</h4>

                    <p>E-postanızı değiştirmek için “Hesabım” sayfasındaki “E-posta” alanının yanında bulunan “Değiştir” butonuna tıklayın. Yeni e-posta adresinizi yazın. Eski e-posta adresinize gönderilen e-posta üzerinden değişikliğe onay verin. E-posta değişikliğinizin başarılı olması durumunda otomatik olarak hesabınızdan çıkış yapılır. Yeni e-posta adresinizle hesabınıza tekrardan giriş yapın.</p>

                    <h4 className='mb-3 fw-bold' id="change-password">6.2. Şifre değişikliği</h4>

                    <p>Şifrenizi değiştirmek için “Hesabım” sayfasındaki “Şifre” alanının yanındaki “Değiştir” butonuna tıklayın. Açılan formda eski ve yeni şifrelerinizi girin.</p>

                    <h4 className='mb-3 fw-bold' id="change-other-info">6.3. Diğer Bilgiler Değişikliği</h4>

                    <p>“Diğer Bilgiler” satın alımlarınız sırasında Fatura bilgilerinin otomatik doldurulabilmesini sağlar. “Diğer Bilgiler” alanının yanındaki “Değiştir” butonuna tıklayarak bu bilgileri değiştirebilirsiniz. Bu bilgileri doldurmanız zorunlu değildir.</p>

                    <h4 className='mb-3 fw-bold' id="change-other-info">6.4. Hesap Silme</h4>

                    <p>Hesap Silme işlemi Tasnifer üzerinden yapılamamaktadır. Hesabınızı silmek istediğiniz takdirde {contactEmail} e-posta adresine “Hesap Silme” konulu bir e-posta yollamanız gerekmektedir. Hesap silme isteğiniz tarafımızca değerlendirilip gerekli görüldüğü takdirde tarafınızdan yeni bir onay alınacaktır. Hesabınızı sildiğiniz takdirde Projelerinizin, Kesim Planlarınızın, Satın Alım işlemlerinize ait detayların kalıcı olarak silineceğini aklınızda bulundurun.</p>

                    <hr className='my-4' />

                </Container>
            </div>

            <Modal show={show} fullscreen onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='d-flex justify-content-center'><img className='img-fluid img-thumbnail' style={{ maxWidth: "100%", height: "auto" }} src={modalImg} /></Modal.Body>
            </Modal>
            <Footer />
        </div>
    );
};

export default UserManualTr;
