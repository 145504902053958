import { useState } from 'react';

const useRebarInspection = (props) => {

    const [unfilteredRebars, setUnfilteredRebars] = useState([])
    const [rebars, setRebars] = useState([]);
    const [longRebars, setLongRebars] = useState([]);
    const [weights, setWeights] = useState([{}])
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const [showDetailedTable, setShowDetailedTable] = useState(false)

    const [selectedExplanations, setSelectedExplanations] = useState([])

    let floorAvailable = "kat" in Object.values(props.donatiDict)[0]

    const calculateWeight = (diameter, length) => {
        const density = 7.85
        const radius = diameter / 2 * 1e-1
        const A = Math.PI * radius ** 2
        const V = A * length
        const mass = V * density
        return mass * 1e-3
    }

    const createTableRows = (rawRebars, selectedExplanations=[]) => {
        let dict = {}
        let weights = {}
        let rebars
        let longRebars = new Set()

        rebars = rawRebars.map((rawRebar, i) => {

            let [diameter, length] = rawRebar.split('-').map(Number);
            let number = Number(props.donatiDict[rawRebar]["adet"]);

            if (weights[diameter]) {
                weights[diameter] += calculateWeight(Number(diameter), Number(length) * number)
            }
            else {
                weights[diameter] = calculateWeight(Number(diameter), Number(length) * number)
            }
            let result = {
                diameter: diameter,
                length: length,
                number: number,
                poz: props.donatiDict[rawRebar]["poz"],
            }

            if (floorAvailable) {
                result = {
                    ...result,
                    floor: props.donatiDict[rawRebar]["kat"].trim(),
                    element: props.donatiDict[rawRebar]["yapiElemani"].trim(),
                    elementCode: props.donatiDict[rawRebar]["yapiElemaniKodu"].trim(),
                    explanation: props.donatiDict[rawRebar]["aciklama"].trim(),
                    code: props.donatiDict[rawRebar]["donatiKodu"]
                }
            }
            return result
        })

        // long rebars:
        for (let i = 0; i < rawRebars.length; i++) {
            let rawRebar = rawRebars[i]
            let info = props.donatiDict[rawRebar]
            let [diameter, length] = rawRebar.split('-').map(Number);
            if ("kesik" in info) {
                let longRebar = {
                    "diameter": diameter,
                    "totalLength": info.toplamUzunluk,
                    "number": Number(info.adet),
                    "floor": info.kat,
                    "element": info.yapiElemani,
                    "elementCode": info.yapiElemaniKodu,
                    "explanation": info.aciklama,
                    "rebarCodes": info.kesikParcaKodlari,
                }
                longRebars.add(JSON.stringify(longRebar))
            }
            else {
                continue
            }
        }
        longRebars = Array.from(longRebars)
        longRebars = longRebars.map((item) => JSON.parse(item))
        // longRebars.sort((a,b) => a.elementCode.localeCompare(b.elementCode))


        for (let i = 0; i < rebars.length; i++) {
            rebars[i]["id"] = i + 1
        }

        return [rebars, [weights], longRebars]

    }

    const handleCheck = (checked) => {
        setChecked(checked)

        let _rebars = checked.reduce((acc, curr) => {
            return acc.concat(props.rebarListDict[`${curr}`]);
        }, []);

        setUnfilteredRebars(_rebars)

        if(selectedExplanations.length > 0){
            _rebars = _rebars.filter((rebar) => {
                return selectedExplanations.includes(props.donatiDict[rebar]["aciklama"].trim())
            })
        }

        const [rebars_, weights_, longRebars_] = createTableRows(_rebars)
        setRebars(rebars_)
        setWeights(weights_)
        setLongRebars(longRebars_)
    }

    const handleCheckExplanations = (checked) => {
        setSelectedExplanations(checked)
        let filteredRebars
        if(checked.length === 0){
            filteredRebars = unfilteredRebars
        }
        else{
            filteredRebars = unfilteredRebars.filter((rebar) => {
                return checked.includes(props.donatiDict[rebar]["aciklama"].trim())
            } )
        }
        const [rebars_, weights_, longRebars_] = createTableRows(filteredRebars, checked)
        setRebars(rebars_)
        setWeights(weights_)
        setLongRebars(longRebars_)
    }

    return {
        rebars,
        weights,
        longRebars,
        checked,
        expanded,
        setExpanded,
        handleCheck,
        handleCheckExplanations,
        showDetailedTable,
        setShowDetailedTable
    }
}

export default useRebarInspection;