import React from 'react';
import { useParams } from "react-router-dom";

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Footer from '../../components/Footer/Footer';

import mainStyles from '../Pages.module.css';

import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import useProjectPurchase from '../../hooks/useProjectPurchase/useProjectPurchase';
import InvoiceDetails from './StepFiles/1_InvoiceDetails';
import PurchaseDetails from './StepFiles/2_PurchaseDetails';
import PurchaseEnd from './StepFiles/3_PurchaseEnd';
import PurchaseCompleted from './StepFiles/4_PurchaseCompleted';

const ProjectPurchase = () => {

    const { id } = useParams();

    const param = useProjectPurchase(id);

    const isLoading = param.isLoading
    const step = param.step

    let innerContainer = LoadingContainer

    const projectTheoreticalWeight = param.projectTheoreticalWeight
    const projectTheoreticalLength = param.projectTheoreticalLength
    const priceCalculationMethod = param.priceCalculationMethod
    const unitDiscount = param.unitDiscount
    const unitPrice = param.unitPrice

    const unitPriceWithoutDiscount = unitPrice + unitDiscount
    let totalPriceWithoutDiscount, totalDiscount, priceCalculationUnit, perPriceUnit, priceCalculationValue
    if(priceCalculationMethod === 'weight_per_1ton'){
        totalPriceWithoutDiscount = Math.floor(projectTheoreticalWeight * unitPriceWithoutDiscount * 1e-3)
        totalDiscount = Math.floor(projectTheoreticalWeight * unitDiscount * 1e-3)
        priceCalculationValue = (projectTheoreticalWeight * 1e-3).toFixed(3)
        priceCalculationUnit = 'ton'
        perPriceUnit = '₺/ton'
    }
    else{
        totalPriceWithoutDiscount = Math.floor(projectTheoreticalLength * unitPriceWithoutDiscount * 1e-3)
        totalDiscount = Math.floor(projectTheoreticalLength * unitDiscount * 1e-3)
        priceCalculationValue = (projectTheoreticalLength * 1e-3).toFixed(3)
        priceCalculationUnit = 'x1000m'
        perPriceUnit = '₺/1000m'
    }

    if (!isLoading) {
        if (step === 1) {
            innerContainer = <InvoiceDetails
                setStep={param.setStep}
                invoiceInfo={param.invoiceInfo}
                setInvoiceInfo={param.setInvoiceInfo}
                setSaveInvoiceInfo={param.setSaveInvoiceInfo}
                saveInvoiceInfo={param.saveInvoiceInfo}
                telephoneError={param.telephoneError}
                countryError={param.countryError}
                provinceError={param.provinceError}
                handleInvoiceInfoStep={param.handleInvoiceInfoStep}
            />
        }
        else if (step === 2) {
            innerContainer = <PurchaseDetails
                id={id}
                setStep={param.setStep}
                projectName={param.projectName}
                totalPriceWithoutDiscount={totalPriceWithoutDiscount}
                totalDiscount={totalDiscount}
                priceCalculationValue={priceCalculationValue}
                priceCalculationUnit={priceCalculationUnit}
                perPriceUnit={perPriceUnit}

                projectTheoreticalWeight={param.projectTheoreticalWeight}
                unitPrice={param.unitPrice}
                unitPriceType={param.unitPriceType}
                unitDiscount={param.unitDiscount}
                initialPrice={param.initialPrice}
                discount={param.discount}
                totalPrice={param.totalPrice}
                vat={param.vat}
                paymentMethod={param.paymentMethod}
                setPaymentMethod={param.setPaymentMethod}
                invoiceInfo={param.invoiceInfo}
                saleContractApproved={param.saleContractApproved}
                setSaleContractApproved={param.setSaleContractApproved}
                setSaleContract={param.setSaleContract}
                cardHolder={param.cardHolder}
                setCardHolder={param.setCardHolder}
                cardNumber={param.cardNumber}
                cardType={param.cardType}
                cardTypeLogo={param.cardTypeLogo}
                handleCardNumberChange={param.handleCardNumberChange}
                handleKeyDown={param.handleKeyDown}
                expirationDate={param.expirationDate}
                handleExpirationDateChange={param.handleExpirationDateChange}
                cvv={param.cvv}
                setCvv={param.setCvv}
                cardHolderError={param.cardHolderError}
                cardError={param.cardError}
                expDateError={param.expDateError}
                cvvError={param.cvvError}
                handleChangePaymentMethod={param.handleChangePaymentMethod}
                handleSubmitOnStepTwo={param.handleSubmitOnStepTwo}
        />
        }
        else if (step === 3) {
            innerContainer = <PurchaseEnd
                id={id}
                setStep={param.setStep}
                projectName={param.projectName}
                projectTheoreticalWeight={param.projectTheoreticalWeight}
                unitPrice={param.unitPrice}
                unitPriceType={param.unitPriceType}
                unitDiscount={param.unitDiscount}
                initialPrice={param.initialPrice}
                discount={param.discount}
                totalPrice={param.totalPrice}
                vat={param.vat}
                paymentMethod={param.paymentMethod}
                cardNumber={param.cardNumber}
                cardType={param.cardType}
                cardTypeLogo={param.cardTypeLogo}
                invoiceInfo={param.invoiceInfo}
                orderLoading={param.orderLoading}
                handleSubmit={param.handleSubmit}
            />
        }
        else {
            innerContainer = <PurchaseCompleted
                id={id}
                projectName={param.projectName}

                totalPriceWithoutDiscount={totalPriceWithoutDiscount}
                totalDiscount={totalDiscount}
                priceCalculationValue={priceCalculationValue}
                priceCalculationUnit={priceCalculationUnit}
                perPriceUnit={perPriceUnit}
                
                projectTheoreticalWeight={param.projectTheoreticalWeight}
                unitPrice={param.unitPrice}
                unitPriceType={param.unitPriceType}
                unitDiscount={param.unitDiscount}
                initialPrice={param.initialPrice}
                discount={param.discount}
                totalPrice={param.totalPrice}
                vat={param.vat}
                paymentMethod={param.paymentMethod}
                invoiceInfo={param.invoiceInfo}
                orderId={param.orderId}
                orderDate={param.orderDate}
            />
        }
    }

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />
            <div className={mainStyles.content}>
                {innerContainer}
            </div>
            <Footer />
        </div >
    );
};

export default ProjectPurchase;
