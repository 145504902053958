// services/userService.js
import apiClient from './apiClient';

export const getUserData = async () => {

    const url = `${process.env.REACT_APP_API_URL}/api/user/`;

    const response = await apiClient(url, {
        method: 'GET',
    });

    const data = await response.json();
    return data
};
