import React from "react"
import { Spinner } from "react-bootstrap"
import i18n from "../../i18n"

const LoadingContainerFunc = () => {

    let loadingMessage;

    if (i18n.language === "tr") {
        loadingMessage = "Yükleniyor..."
    }
    else {
        loadingMessage = "Loading..."
    }

    return (<div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '75vh',
        flexDirection: 'column'
    }}>
        <Spinner animation="border" role="status" className="mb-2" />
        <p>{loadingMessage}</p>
    </div>)
}

const LoadingContainer = <LoadingContainerFunc />

export default LoadingContainer