import React from 'react';
import AboutUsEn from './localePages/AboutUsEn';
import AboutUsTr from './localePages/AboutUsTr';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {

    const { i18n, t } = useTranslation();

    if (i18n.language === 'en') {
        return <AboutUsEn />;
    }
    else {
        return <AboutUsTr />;
    }

};

export default AboutUs;
