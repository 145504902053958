import React from 'react';
import Container from 'react-bootstrap/Container';

// import lines from './lines.png';
import cross from './cross.png';
import lines from './lines.png'

import { useTranslation } from 'react-i18next';

function generateRGB(num1, num2) {
    // convert inputs to a string
    let str = num1.toString() + num2.toString();

    // hash the input
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // convert the hash to an RGB color
    let color = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        color[i] = value;
    }

    // increase contrast if necessary
    let luma = 0.299 * color[0] + 0.587 * color[1] + 0.114 * color[2]; // per ITU-R BT.709
    if (luma < 128) {
        color = color.map(value => value + (128 - luma) / 2);
    }

    // convert color array to CSS color
    let cssColor = 'rgba(' + color.join(',') + ',0.6)';

    return cssColor;
}

function generatePrintGray(num1, num2) {
    // convert inputs to a string
    let str = num1.toString() + num2.toString();

    // hash the input
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // convert the hash to a gray value
    let value = Math.abs(hash) % 256; // This generates a number between 0 and 255

    // invert the color to make it lighter (closer to white)
    value = 255 - value;

    // ensure the color is not too light for printing and has high contrast with black
    if (value > 220) {
        value = 220; // This caps the maximum gray value to 220, preventing very light colors
    }

    // enforce a minimum gray value for high contrast with black
    if (value < 180) {
        value = 180; // This sets the minimum gray value to 180, ensuring the colors will contrast with black
    }

    // create a gray color with the same value for all channels
    let color = [value, value, value];

    // convert color array to CSS color
    let cssColor = 'rgba(' + color.join(',') + ',0.6)';

    return cssColor;
}



const Pattern = ({
    pattern,
    profileLogLength,
    showPieceInfo = true,
    bladeThickness,
    ratio = 1,
    print = false,
}) => {
    const { t } = useTranslation();

    const cuts = pattern.cuts

    let totalLength = cuts.reduce((sum, cut) => sum + cut.nCuts * cut.cutLength, 0) + pattern.waste;

    let emptyLength = profileLogLength - totalLength
    if (emptyLength < 0) {
        emptyLength = 0
    }

    if (totalLength != profileLogLength) {
        totalLength = profileLogLength
    }

    let waste = pattern.waste

    let bladeTrim = pattern.waste + bladeThickness

    const isWasted = waste > 0

    const isTrimmed = waste < 0

    let listOfCuts = cuts.map((item, i) => (
        {
            "cutLength": item.cutLength,
            "nCuts": item.nCuts,
        }
    ))

    let lengths = cuts.map(cut => Number(cut.cutLength))

    let fontSize1 = `${12 * ratio}pt`
    let fontSize2 = `${11 * ratio}pt`
    let fontSize3 = `${10 * ratio}pt`
    let fontSize4 = `${9 * ratio}pt`

    let marginSize1 = `${6 * ratio}pt`
    let marginSize2 = `${2 * ratio}pt`
    let marginSize3 = `${1 * ratio}pt`

    let patternBorderBlack = `${marginSize3} solid black`
    let patternBorderGray = `${marginSize3} solid gray`


    if (pattern.nLogs === 0) {
        return <></>
    }

    let bgColor = "white"
    let borderColor = "#dee2e6"
    let borderWeight = marginSize3
    let borderType = 'solid'
    if (!print) {
        bgColor = 'rgba(248, 249, 250)'
        borderColor = '#808080'
    }
    if (pattern.stock_pattern) {
        borderColor = 'black'
        borderWeight = marginSize2
    }
    if(isTrimmed){
        borderType = 'dashed'
    }

    let showDescriptionCol = true
    let longPieceExists = false
    let descriptions = new Set()
    for (let pieceInfo of pattern.piecesInfo) {
        descriptions.add(pieceInfo.description)
        longPieceExists = pieceInfo.is_long ? true : longPieceExists
    }
    descriptions = Array.from(descriptions)
    if (descriptions.length === 1 && descriptions[0] === '') {
        showDescriptionCol = false
    }

    return (
        <div className="rounded" style={{
            backgroundColor: `${bgColor}`,
            marginBottom: `${marginSize1}`,
            padding: `${marginSize2} ${marginSize1}`,
            border:  `${borderWeight} ${borderType} ${borderColor}`
        }}>

            {print ?
                <table
                    className="table table-sm"
                    style={{ marginTop: `${marginSize3}`, width: "100%" }}
                >
                    <tbody>
                        <tr style={{ verticalAlign: "middle", }}>
                            <th style={{ width: '6%', fontSize: `${fontSize1}` }}>{pattern.nLogs}</th>
                            <th style={{ width: '19%', fontWeight: "normal", fontSize: `${fontSize1}` }}>{t("Adet")}</th>
                            <th style={{ width: '50%', fontWeight: "normal", fontSize: `${fontSize1}` }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", alignItems: "center" }}>
                                    {listOfCuts.map((item, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#40;</div>
                                                <div style={{ fontWeight: '400', fontSize: `${fontSize1}` }}>{item.nCuts}</div>
                                                <div style={{ fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>x</div>
                                                <div style={{ fontWeight: '500', fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>{item.cutLength}</div>
                                                <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#41;</div>
                                                {index !== listOfCuts.length - 1 ?
                                                    <div style={{ fontSize: `${fontSize1}`, margin: `0pt ${marginSize2}` }}> + </div>
                                                    : <></>}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </th>
                            <th style={{ width: '8%', fontSize: `${fontSize3}` }}>{t("Fire")}:</th>
                            <th style={{ width: '3%', fontSize: `${fontSize2}`, }}>{waste > 0 ? waste : 0}</th>
                            <td style={{ width: '14%', fontSize: `${fontSize4}`, textAlign: 'end' }}><div>{t("Kesim No:")} {pattern.patternNo}</div></td>
                        </tr>
                    </tbody>
                </table>
                :
                <div>
                    <div className='table-responsive d-none d-md-block'>
                        <table
                            className={`table table-sm`}
                            style={{ marginTop: `${marginSize3}`, width: "100%" }}
                        >
                            <tbody>
                                <tr style={{ verticalAlign: "middle", }}>
                                    <th style={{ width: '6%', fontSize: `${fontSize1}` }}>{pattern.nLogs}</th>
                                    <th style={{ width: '19%', fontWeight: "normal", fontSize: `${fontSize1}` }}>{t("Adet")}</th>
                                    <th style={{ width: '50%', fontWeight: "normal", fontSize: `${fontSize1}` }}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", alignItems: "center" }}>
                                            {listOfCuts.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#40;</div>
                                                        <div style={{ fontWeight: '400', fontSize: `${fontSize1}` }}>{item.nCuts}</div>
                                                        <div style={{ fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>x</div>
                                                        <div style={{ fontWeight: '500', fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>{item.cutLength}</div>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#41;</div>
                                                        {index !== listOfCuts.length - 1 ?
                                                            <div style={{ fontSize: `${fontSize1}`, margin: `0pt ${marginSize2}` }}> + </div>
                                                            : <></>}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </th>
                                    <th style={{ width: '8%', fontSize: `${fontSize3}` }}>{t("Fire")}:</th>
                                    <th style={{ width: '3%', fontSize: `${fontSize2}`, }}>{waste > 0 ? waste : 0}</th>
                                    <td style={{ width: '14%', fontSize: `${fontSize4}`, textAlign: 'end' }}><div>{t("Kesim No:")} {pattern.patternNo}</div></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className='d-md-none'>

                        <div className='text-center' style={{ marginTop: `${marginSize3}` }}>
                            <span style={{ fontWeight: "bold", fontSize: `${fontSize1}` }}>{pattern.nLogs}</span>&nbsp;&nbsp;&nbsp;
                            <span style={{ fontWeight: "normal", fontSize: `${fontSize1}` }}>{t("Adet")}</span>
                        </div>

                        <table className="table table-sm m-0 table-borderless" >
                            <tbody>
                                <tr style={{ verticalAlign: "middle", textAlign: "center" }}>
                                    <th style={{ fontWeight: "normal", fontSize: `${fontSize1}`, textAlign: 'center' }}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center", alignItems: "center" }}>
                                            {listOfCuts.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#40;</div>
                                                        <div style={{ fontWeight: '400', fontSize: `${fontSize1}` }}>{item.nCuts}</div>
                                                        <div style={{ fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>x</div>
                                                        <div style={{ fontWeight: '500', fontSize: `${fontSize1}`, margin: `0pt 0pt 0pt ${marginSize3}` }}>{item.cutLength}</div>
                                                        <div style={{ fontWeight: '300', fontSize: `${fontSize1}` }}>&#41;</div>
                                                        {index !== listOfCuts.length - 1 ?
                                                            <div style={{ fontSize: `${fontSize1}`, margin: `0pt ${marginSize2}` }}> + </div>
                                                            : <></>}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>


                        <table className="table table-sm" >
                            <tbody>
                                <tr style={{ verticalAlign: "middle", }}>
                                    <th><div><span style={{ fontSize: `${fontSize3}` }}>{t("Fire")}:&nbsp;</span><span style={{ fontSize: `${fontSize2}`, }}>{waste > 0 ? waste : 0}</span></div></th>
                                    <td style={{ fontSize: `${fontSize4}`, textAlign: 'end' }}><div>{t("Kesim No:")} {pattern.patternNo}</div></td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </div>

            }


            <div className='table-responsive'>
                <table style={{ width: "100%", marginBottom: "10px" }}>
                    <tbody>
                        <tr>
                            {cuts.map((cut, i) => (
                                Array(cut.nCuts).fill().map((_, j) => {
                                    const length = cut.cutLength
                                    const total = totalLength
                                    let width = (length / total) * 100;
                                    let patternBgColor = generatePrintGray(width, i)
                                    if (!print) {
                                        patternBgColor = generateRGB(width, i)
                                    }
                                    return (
                                        <th key={`${i}-${j}`} style={{
                                            borderLeft: patternBorderBlack,
                                            borderTop: patternBorderBlack,
                                            borderBottom: patternBorderBlack,
                                            textAlign: 'center',
                                            verticalAlign: 'top',
                                            backgroundColor: patternBgColor,
                                            // backgroundImage: (print && !floorActiveInCut) ? `url('${lines}')` : "",
                                            backgroundPosition: 'center',
                                            backgroundSize: '300pt',
                                            // backgroundRepeat: 'no-repeat',
                                            width: `${width}%`,
                                            fontSize: `${fontSize4}`,
                                            padding: 0,
                                            position: 'relative',
                                            opacity: 1,
                                            zIndex: 2,
                                        }}>
                                            <div> {length} </div>
                                        </th>
                                    )
                                })
                            ))}
                            {isWasted ?
                                <th style={{
                                    border: `${patternBorderBlack}`,
                                    textAlign: 'center',
                                    verticalAlign: 'top',
                                    color: print ? 'black' : 'white',
                                    backgroundColor: print ? "" : 'red',
                                    backgroundImage: print ? `url('${cross}')` : "",
                                    backgroundPosition: 'center',
                                    backgroundSize: '150pt',
                                    width: `${((waste > 0 ? waste : 0) / totalLength) * 100}%`,
                                    fontSize: `${fontSize4}`,
                                    padding: 0,
                                    position: 'relative',
                                }}>
                                    <div style={{
                                        backgroundColor: print ? "white" : "",
                                        display: print ? 'inline-block' : "",
                                    }}> {waste > 0 ? waste : 0} </div>
                                </th>
                                : <th style={{
                                    width: "0",
                                    padding: 0,
                                    borderLeft: `${patternBorderBlack}`,
                                }}></th>
                            }
                            {isTrimmed ?
                                <th style={{
                                    border: `${patternBorderBlack}`,
                                    textAlign: 'center',
                                    verticalAlign: 'top',
                                    color: 'white',
                                    backgroundColor: 'black',
                                    width: `${((bladeTrim) / totalLength) * 100}%`,
                                    fontSize: `${fontSize4}`,
                                    padding: 0,
                                    position: 'relative',
                                }}>
                                    {bladeTrim}
                                </th>
                                : <th style={{
                                    width: "0",
                                    padding: 0,
                                    borderLeft: `${patternBorderBlack}`,
                                }}></th>
                            }
                            {emptyLength > 0 &&
                                <th />
                            }
                        </tr>
                    </tbody>
                </table>
            </div>

            {isTrimmed &&
                <p style={{fontSize: `${fontSize4}`,}}><span className='fw-bold'>{t("DİKKAT")}</span>: {t("Bu kesimde parçanın ucundan")} <span className='fw-bold'>{bladeTrim} mm</span> {t("uzunluğunda bir tıraşlama yapmanız gerekmektedir.")}</p>
            }

            <div className={`table-responsive ${showPieceInfo ? '' : 'd-none'}`}>
                {longPieceExists ?
                    <table className="table table-sm"
                        style={{
                            fontSize: `${fontSize4}`,
                            marginTop: `${marginSize1}`
                        }}>
                        <tbody>
                            <tr>
                                <th scope='col' width='15%'>{t("Profil")}</th>
                                <th scope='col' width='15%'>{t("Malzeme")}</th>
                                <th scope='col' width='15%'>{t("Adet")}</th>
                                <th scope='col' width='15%'>{t("Asıl Uzunluk")}</th>
                                <th scope='col' width='15%'>{t("Parçalar")}</th>
                                <th scope='col' width='15%'>{t("Poz")}</th>
                                <th scope='col' width='10%'>{t("Parça Kodu")}</th>
                                {showDescriptionCol &&
                                    <th scope='col' width='15%'>{t("Açıklama")}</th>
                                }
                            </tr>
                            {pattern.piecesInfo.map((piece, i) => {
                                const rowSpan = piece.is_long ? piece.parts.length : 1
                                const parts = piece.is_long ? piece.parts : [piece.length]
                                return parts.map((part, index) => {
                                    const partInPattern = piece.is_long && lengths.includes(Number(part))
                                    if (index === 0) {
                                        return (
                                            <tr key={`${i}-${index}`}>
                                                <td rowSpan={rowSpan}>{piece.profile}</td>
                                                <td rowSpan={rowSpan}>{piece.grade}</td>
                                                <td rowSpan={rowSpan}>{pattern.pieces[piece.piece_code]}</td>
                                                <td rowSpan={rowSpan} style={{
                                                    fontWeight: piece.is_long ? 'bold' : ''
                                                }}>{piece.length}</td>
                                                <td style={{
                                                    fontWeight: partInPattern ? 'bold' : ''
                                                }}>{part}</td>
                                                <td rowSpan={rowSpan}>{piece.piece_name}</td>
                                                <td rowSpan={rowSpan}>{piece.piece_code}</td>
                                                {showDescriptionCol &&
                                                    <td rowSpan={rowSpan}>{piece.description}</td>
                                                }
                                            </tr>
                                        )
                                    }
                                    else {
                                        return (
                                            <tr key={`${i}-${index}`}>
                                                <td style={{
                                                    fontWeight: partInPattern ? 'bold' : ''
                                                }}>{part}</td>
                                            </tr>
                                        )
                                    }
                                })

                            })}
                        </tbody>
                    </table>
                    :
                    <table className="table table-sm"
                        style={{
                            fontSize: `${fontSize4}`,
                            marginTop: `${marginSize1}`
                        }}>
                        <tbody>
                            <tr>
                                <th scope='col' width='15%'>{t("Profil")}</th>
                                <th scope='col' width='15%'>{t("Malzeme")}</th>
                                <th scope='col' width='15%'>{t("Adet")}</th>
                                <th scope='col' width='15%'>{t("Uzunluk")}</th>
                                <th scope='col' width='15%'>{t("Poz")}</th>
                                <th scope='col' width='10%'>{t("Parça Kodu")}</th>
                                {showDescriptionCol &&
                                    <th scope='col' width='15%'>{t("Açıklama")}</th>
                                }
                            </tr>
                            {pattern.piecesInfo.map((piece, i) => {

                                return (<tr key={i}>
                                    <td>{piece.profile}</td>
                                    <td>{piece.grade}</td>
                                    <td>{pattern.pieces[piece.piece_code]}</td>
                                    <td>{piece.length}</td>
                                    <td>{piece.piece_name}</td>
                                    <td>{piece.piece_code}</td>
                                    {showDescriptionCol &&
                                        <td>{piece.description}</td>
                                    }

                                </tr>)
                            })}
                        </tbody>
                    </table>
                }
                {pattern.stock_pattern &&
                    <>
                        <div className="border rounded" style={{ margin: `${marginSize1}`, padding: `${marginSize2} ${marginSize1}` }}>
                            <div style={{ fontWeight: "bold", fontSize: `${fontSize1}` }}>{t("Stoktan Kullanım")}</div>
                            <div className='table-responsive'>
                                <table className={`table table-sm`}
                                    style={{
                                        fontSize: `${fontSize4}`,
                                        marginTop: `${marginSize3}`,
                                        verticalAlign: 'middle'
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <th>{t("Stok No")}</th>
                                            <th>{t("Profil")}</th>
                                            <th>{t("Malzeme")}</th>
                                            <th>{t("Adet")}</th>
                                            <th>{t("Uzunluk")}</th>
                                            <th>{t("Açıklama")}</th>
                                        </tr>
                                        {pattern.stockInfo.map((stock, i) => {
                                            return (<tr key={i}>
                                                <td>{stock.id}</td>
                                                <td>{stock.profile}</td>
                                                <td>{stock.grade}</td>
                                                <td>{pattern.stocks[stock.id]}</td>
                                                <th>{stock.length}</th>
                                                <td>{stock.description}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Pattern;