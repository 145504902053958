import { useState, useEffect } from 'react';
import { getUserInfos } from '../../services/adminServices/getUserInfos';
import { setUserInfo } from '../../services/adminServices/setUserInfo';
import { deleteUser } from '../../services/adminServices/deleteUser';
import { useNavigate } from "react-router-dom";
import { getProjects } from '../../services/getProjectsOfUserService';

const useUserInfos = () => {
    const navigate = useNavigate();

    const emptyInfo = {
        "user_id": null,
        "date": null,
        "last_login": null,
        "email": null,
        "user_type": null,
        "credits": null,
        "email_verified": null,
        "additional_info": {
            "user": null,
            "name": null,
            "surname": null,
            "telephone_country_code": null,
            "telephone": null,
            "company_name": null,
            "tax_office": null,
            "taxno": null,
            "trade_registration_no": null,
            "address": null
        },
        "delegate_code": null, // Delegate code for delegates
        "delegate_active": false,
    }

    const [isLoading, setIsLoading] = useState(true)
    const [isChangeLoading, setIsChangeLoading] = useState(false)
    const [allUserInfos, setAllUserInfos] = useState({})
    const [userInfos, setUserInfos] = useState({})

    const [selectedUserInfo, setSelectedUserInfo] = useState(emptyInfo)
    const [currentUserInfo, setCurretUserInfo] = useState(emptyInfo)

    const [userInfoError, setUserInfoError] = useState("")

    const [userDeleteModal, setUserDeleteModal] = useState({ user: null, show: false })
    const [userDeleteLoading, setUserDeleteLoading] = useState(false)
    const [userDeleteError, setUserDeleteError] = useState("")
    const [userDeleteSuccess, setUserDeleteSuccess] = useState(false)

    const [activeTab, setActiveTab] = useState('user_info')

    const [projectsLoading, setProjectsLoading] = useState(false)
    const [projectsUser, setProjectsUser] = useState(null)
    const [projects, setProjects] = useState(null)

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getUserInfos()

                if (response.ok) {
                    const data = await response.json()
                    const infos = data.reduce((acc, cur) => ({ ...acc, [cur.user_id]: cur }), {})
                    setUserInfos(infos)
                    setAllUserInfos(infos)
                    setIsLoading(false)

                }
                else {
                    setIsLoading(false)
                    var props = {
                        error_code: "P7OWMY",
                        detail: "Sistem Hatası.",
                    }

                    navigate("/error", { state: { props: props } })
                }
            }
            catch (error) {
                setIsLoading(false)
                var props = {
                    error_code: "2347AK",
                    detail: "Sistem Hatası.",
                }

                navigate("/error", { state: { props: props } })
            }

        }

        fetchData()

    }, [])


    const detectChanges = () => {

        let changes = ["user_id", "email", "user_type", "credits", "delegate_code", "assigned_delegate_code"].reduce((acc, cur) => {
            if (selectedUserInfo[cur] === currentUserInfo[cur]) {
                return acc
            }
            return ({
                ...acc, [cur]: {
                    "old": selectedUserInfo[cur],
                    "new": currentUserInfo[cur],
                }
            })
        }, {})

        changes = ["name", "surname", "telephone_country_code", "telephone", "company_name", "tax_office", "taxno",
            "trade_registration_no", "address", "country", "province", "county", "zipcode",
            "street", "address_line1", "address_line2"].reduce((acc, cur) => {
                if (selectedUserInfo.additional_info[cur] === currentUserInfo.additional_info[cur]) {
                    return acc
                }
                return ({
                    ...acc, additional_info: {
                        ...acc.additional_info,
                        [cur]: {
                            "old": selectedUserInfo.additional_info[cur],
                            "new": currentUserInfo.additional_info[cur],
                        }
                    }
                })
            }, changes)

        return changes
    }

    const handleTableClick = (id) => {
        setUserInfoError("");
        setSelectedUserInfo(userInfos[id])
        setCurretUserInfo(userInfos[id])
    }

    const handleChangeSubmit = async (e, id) => {
        e.preventDefault();
        if (currentUserInfo.user_type === 3 && currentUserInfo.delegate_code.length < 4) {
            setUserInfoError("Temsilci Kodu 4 karakterden kısa olamaz.")
            return
        }
        else {
            setUserInfoError("");
        }

        setIsChangeLoading(true);
        let changes = detectChanges()
        let response

        try {
            response = await setUserInfo(id, changes)
        }
        catch {
            setUserInfoError("CG00CA: Beklenmeyen bir hata oluştu. Bazı değişiklikler kaydedilmemiş olabilir.")
            return
        }
        setIsChangeLoading(false)
        if (response.ok) {
            setUserInfos({ ...userInfos, [id]: currentUserInfo })
            setSelectedUserInfo(currentUserInfo)
        }
        else {
            try {
                const err_data = await response.json();
                setUserInfoError(`${err_data.code}: ${err_data.detail}`)
            }
            catch {
                setUserInfoError(`NZKED9: Beklenmeyen bir hata oluştu. Bazı değişiklikler kaydedilmemiş olabilir.`)
            }
        }
    }

    const handleCloseUserDeleteModal = () => {
        if (userDeleteLoading) {
            return
        }
        setUserDeleteModal({ ...userDeleteModal, show: false })
        setUserDeleteLoading(false)
        setUserDeleteError("")
        setUserDeleteSuccess(false)
    }

    const handleDeleteUser = async (id) => {
        setUserDeleteLoading(true);
        let response
        try {
            response = await deleteUser(id);
        }
        catch {
            setUserDeleteLoading(false)
            setUserDeleteSuccess(false)
            setUserDeleteError(`HATA: Kullanıcı silinemedi. K85HLQ`)
        }
        if (response.ok) {
            setUserDeleteLoading(false)
            setUserDeleteError("")
            setUserDeleteSuccess(true)
            setSelectedUserInfo(emptyInfo)
            setCurretUserInfo(emptyInfo)

            const { [id]: _, ...newUserInfos } = userInfos
            setUserInfos(newUserInfos)

        }
        else {
            setUserDeleteLoading(false)
            setUserDeleteSuccess(false)
            try {
                const data = await response.json()
                if ("detail" in data) {
                    setUserDeleteError(`HATA: ${data.detail}`)
                }
                else {
                    setUserDeleteError(`HATA: Kullanıcı silinemedi. ATQ76`)
                }
            }
            catch (e) {
                setUserDeleteError(`HATA: Kullanıcı silinemedi. KTX62`)
            }

        }

    }

    const handleSearch = (q) => {
        let filtered = Object.values(allUserInfos).filter(
            info => {
                let searchResult = info.email.toLowerCase().includes(q.toLowerCase())
                if (info.additional_info.name) {
                    searchResult = searchResult || info.additional_info.name.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.surname) {
                    searchResult = searchResult || info.additional_info.surname.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.telephone_country_code) {
                    searchResult = searchResult || info.additional_info.telephone_country_code.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.telephone) {
                    searchResult = searchResult || info.additional_info.telephone.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.company_name) {
                    searchResult = searchResult || info.additional_info.company_name.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.tax_office) {
                    searchResult = searchResult || info.additional_info.tax_office.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.taxno) {
                    searchResult = searchResult || info.additional_info.taxno.toLowerCase().includes(q.toLowerCase())
                }
                if (info.additional_info.address) {
                    searchResult = searchResult || info.additional_info.address.toLowerCase().includes(q.toLowerCase())
                }

                return searchResult
            }
        );
        filtered = filtered.reduce((acc, cur) => ({ ...acc, [cur.user_id]: cur }), {})
        setUserInfos(filtered)
    }

    const handleLoadUserProjects = async () => {
        let response;
        setProjectsLoading(true)
        try {
            response = await getProjects(selectedUserInfo.user_id)
        }
        catch {
            setProjectsLoading(false)
        }

        if (response.ok) {
            setProjectsUser(selectedUserInfo.user_id)
            const data = await response.json()
            setProjects(data)
            setProjectsLoading(false)
        }
        else {
            setProjectsLoading(false)
        }
    }

    return {
        userInfos,
        isLoading,
        isChangeLoading,
        selectedUserInfo,
        currentUserInfo,
        activeTab,
        projectsLoading,
        projectsUser,
        projects,

        userInfoError,

        userDeleteModal,
        userDeleteLoading,
        userDeleteError,
        userDeleteSuccess,
        setUserDeleteModal,

        setCurretUserInfo,
        setActiveTab,
        handleTableClick,
        handleChangeSubmit,
        handleSearch,
        handleDeleteUser,
        handleCloseUserDeleteModal,
        handleLoadUserProjects,
    }
}

export default useUserInfos;