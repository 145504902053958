import apiClient from "./apiClient";

export const getCuttingPlan= async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/api/get-cutting-plan/${id}`;

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}