// hooks/useLogin.js
import { useEffect, useState } from 'react';
import { loginUser } from '../../services/authService';
import { useLocation } from 'react-router-dom';
import { checkIfUserActive } from '../../services/isUserActiveService';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const useLogin = () => {
    const navigate = useNavigate();

    const {i18n, t} = useTranslation();

    const [showAgainToast, setShowAgainToast] = useState(false);
    const [errorToast, setErrorToast] = useState({ show: false, message: "" });

    const [showSignUpToast, setShowSignUpToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (searchParams.get('try-again')) {
            setShowAgainToast(true);
        }
        if (searchParams.get('signup')) {
            setShowSignUpToast(true);
        }
    }, [location.search]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (email === "" || password === "") {
            setErrorToast({ show: true, message: t("E-posta ve şifre boş bırakılamaz.") });
        }
        else {
            setIsLoading(true);

            let ifActiveResponse;
            try{
                ifActiveResponse = await checkIfUserActive(email, password)
            }
            catch{
                var props = {
                    error_code: "HIC42R",
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }
                
                navigate(`/error`, { state: { props: props } })
            }
            let loginResponse;
            try{
                loginResponse = await loginUser(email, password);
            }
            catch{
                var props = {
                    error_code: "7IEQR9",
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }
                
                navigate(`/error`, { state: { props: props } })
            }

            if (ifActiveResponse.ok) {
                const activeData = await ifActiveResponse.json();
                if (activeData.verified === "True") {
                    const loginData = await loginResponse.json();
                    localStorage.setItem('ca_a', loginData.access);
                    localStorage.setItem('ca_r', loginData.refresh);
                    window.location = `/${i18n.language}/projects`
                }
                else {
                    window.location = `/${i18n.language}/verify-your-email`
                }
            }
            else {
                setIsLoading(false)
                const activeData = await ifActiveResponse.json();
                setErrorToast({ show: true, message: t("E-posta veya şifre yanlış. Tekrar deneyin.") });
            }
        }

    };

    return {
        email,
        setEmail,
        password,
        setPassword,
        handleSubmit,

        errorToast,
        setErrorToast,

        showAgainToast,
        setShowAgainToast,

        showSignUpToast,
        setShowSignUpToast,

        isLoading,
    };
};

export default useLogin;
