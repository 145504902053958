import React, { useState, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Toast from 'react-bootstrap/Toast';

import { deleteCp } from '../../../services/deleteCpService';
import { downloadCpExcel } from '../../../services/cpExcelDownloadService';

import { PiMicrosoftExcelLogoBold, } from 'react-icons/pi';
import { RiDeleteBin2Line, } from 'react-icons/ri';
import { FiPrinter } from 'react-icons/fi';
import { BsThreeDotsVertical, } from 'react-icons/bs';

import styles from './CpInfo.module.css';

import ReactToPrint from 'react-to-print';
import PrintableCpInfo from '../PrintableCpInfo/PrintableCpInfo';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useTranslation } from 'react-i18next';

const CpInfoHeader = ({
    id,
    userType,
    activated,
    backgroundTask,
    ownCp,
    owner,

    result,

    checkedDiameter = [],
    checkedFloors = [],
    currentPatterns = [],
    showedPatterns = [],
    currentSubstitutes = [],
    currentWastes = [],
    currentResultSummary = {},
}) => {

    const { i18n, t } = useTranslation();

    const componentRef = useRef();

    const floorAvailable = true;

    const [deleteScreen, setDeleteScreen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [errorToast, setErrorToast] = useState({ show: false, message: '' });

    const activeCp = [4, 5].includes(userType) || activated;

    const handleDeleteCp = async () => {
        try {
            const response = await deleteCp(id)
            if (response.ok) {
                window.location = `/${i18n.language}/project-info/${result.projectId}?deleted=true`;
            }
            else {
                setIsDeleting(false);
                setErrorToast({ show: true, message: `${t("Kesim planı silinemedi:")} ${response.statusText} (${response.status})` })
            }
        }
        catch {
            setErrorToast({ show: true, message: `${t("Kesim planı silinemedi:")}` })
        }
    }

    const handleDownloadCpExcel = async () => {
        try {
            const response = await downloadCpExcel(id)
            if (response.ok) {
                const data = await response.json()
                const link = document.createElement('a');
                link.href = data.url;
                link.download = `${result.cpName}.xlsx`;
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                setErrorToast({ show: true, message: `${t("Kesim Planı indirilemedi:")} ${response.statusText} (${response.status})` })
            }
        }
        catch {
            setErrorToast({ show: true, message: `${t("Kesim Planı indirilemedi.")} ${t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin.")}` })
        }
    }

    return (
        <>

            {deleteScreen && (
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={deleteScreen}
                    onClose={() => setDeleteScreen(false)}>
                    <DialogTitle>
                        {t("Bu kesim planını silmek istediğinizden emin misiniz?")}
                    </DialogTitle>
                    <DialogContent>
                        {t("Bu işlem geri alınamaz.")}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="success"
                            onClick={() => {
                                setDeleteScreen(false)
                            }}>
                            {t("Hayır")}
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setDeleteScreen(false)
                                setIsDeleting(true)
                                handleDeleteCp()
                            }}>
                            {t("Evet")}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {!ownCp && <div className='text-danger text-center'><span className='fw-bold'>{t("DİKKAT")}</span>: {t("Bu size ait bir kesim planı değil. Bu kesim planı")} <span className='fw-bold'>{owner.email} ({owner.id})</span> {t("kullanıcısına aittir. Lütfen kullanıcılara yardımcı olmak haricinde, onların onayı olmayan işlemler yapmayınız.")}</div>}

            <Card className={styles.pageHeaderCard}>
                <Card.Body className={styles.cardBody}>
                    <a href={`/${i18n.language}/project-info/${result.projectId}`}><h5 className={styles.projelerim}>{result.projectName}</h5></a>
                    <h4 className={styles.mySeparator}>&gt;</h4>
                    <h1 className={styles.pageHeader}>{result.cpName}</h1>
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                        <div className='d-none d-sm-flex justify-content-center'>
                            {(!backgroundTask && activeCp) && (
                                <>
                                    <Dropdown drop='down-centered'>
                                        <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                            <FiPrinter style={{ "color": "black", "fontSize": "2rem" }} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item >
                                                <div>
                                                    <ReactToPrint
                                                        trigger={() => <div>{t("Kesim Planını Yazdır")}</div>}
                                                        content={() => componentRef.current}

                                                    />
                                                    <div style={{ display: 'none' }}>
                                                        <PrintableCpInfo
                                                            projectName={result.projectName}
                                                            cpName={result.cpName}
                                                            floorAvailable={floorAvailable}
                                                            currentResultSummary={currentResultSummary}
                                                            allFloors={result.allFloors}
                                                            currentPatterns={currentPatterns}
                                                            showedPatterns={showedPatterns}
                                                            currentSubstitutes={currentSubstitutes}
                                                            checkedDiameter={checkedDiameter}
                                                            checkedFloors={checkedFloors}
                                                            currentWastes={currentWastes}

                                                            donatiDict={result.donatiDict}
                                                            bindirmeOrani={result.bindirmeOrani}

                                                            ratio={0.9}

                                                            ref={componentRef}
                                                        />
                                                    </div>
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown drop='down-centered'>
                                        <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                            <PiMicrosoftExcelLogoBold style={{ "color": "black", "fontSize": "2rem" }} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleDownloadCpExcel()}>{t("Excel İndir")}</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>)
                            }
                            {!backgroundTask && (
                                <Dropdown drop='down-centered'>
                                    <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                        <RiDeleteBin2Line style={{ "color": "black", "fontSize": "2rem" }} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setDeleteScreen(true)}>{t("Kesim Planını Sil")}</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>


                        <div className='d-flex justify-content-center d-sm-none'>
                            <Dropdown drop='down-centered'>
                                <Dropdown.Toggle id="dropdown-basic" style={{ "backgroundColor": "transparent", "border": "transparent", "color": "transparent" }}>
                                    <BsThreeDotsVertical style={{ "color": "black", "fontSize": "2rem" }} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {(!backgroundTask && activeCp) && (<>
                                        <Dropdown.Item onClick={() => handleDownloadCpExcel()}>{t("Excel İndir")}</Dropdown.Item>
                                        <Dropdown.Item>
                                            <div>
                                                <ReactToPrint
                                                    trigger={() => <div>{t("Kesim Planını Yazdır")}</div>}
                                                    content={() => componentRef.current}

                                                />
                                                <div style={{ display: 'none' }}>
                                                    <PrintableCpInfo
                                                        projectName={result.projectName}
                                                        cpName={result.cpName}
                                                        floorAvailable={floorAvailable}
                                                        currentResultSummary={currentResultSummary}
                                                        allFloors={result.allFloors}
                                                        currentPatterns={currentPatterns}
                                                        showedPatterns={showedPatterns}
                                                        currentSubstitutes={currentSubstitutes}
                                                        checkedDiameter={checkedDiameter}
                                                        checkedFloors={checkedFloors}
                                                        currentWastes={currentWastes}

                                                        donatiDict={result.donatiDict}
                                                        bindirmeOrani={result.bindirmeOrani}

                                                        ratio={0.9}

                                                        ref={componentRef}
                                                    />
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                    </>)}
                                    <Dropdown.Item onClick={() => setDeleteScreen(true)}>{t("Kesim Planını Sil")}</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </div>
                </Card.Body>
            </Card>

            {isDeleting && (
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                    open={isDeleting}
                >
                    <p className="mt-2">{t("Kesim planı siliniyor.")}</p>
                    <CircularProgress color="inherit" />
                </Backdrop>
            )}
            <Toast
                onClose={() => setErrorToast({ show: false, message: '' })}
                show={errorToast.show}
                bg="danger"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{errorToast.message}</Toast.Body>
            </Toast>
        </>
    );
};

export default CpInfoHeader;
