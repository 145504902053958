import React from 'react';
import { useTranslation } from 'react-i18next';
import PrivacyPolicyTr from './localePages/PrivacyPolicyTr';
import PrivacyPolicyEn from './localePages/PrivacyPolicyEn';

const PrivacyPolicy = () => {

    const { i18n } = useTranslation();

    return (
        i18n.language === 'en' ? <PrivacyPolicyEn /> : <PrivacyPolicyTr />
    );
};

export default PrivacyPolicy;
