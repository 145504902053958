import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import { useTranslation } from 'react-i18next';

const SolutionOptions = ({ resultOptions }) => {
    const { t } = useTranslation();

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>

            <Container className="border shadow-sm p-4">
                <Table>
                    <tbody>
                        <tr>
                            <th scope="row" style={{width:'50%'}}>{t("Kesim Planı")}</th>
                            <td style={{width:'50%'}}>{resultOptions.cpName}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Proje")}</th>
                            <td><a href={`/project-info/${resultOptions.projectId}`} target='_blank'>{`${resultOptions.projectName} (${resultOptions.projectId})`}</a></td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Kullanıcı")}</th>
                            <td>{resultOptions.projectUser}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Katlar")}</th>
                            <td>{resultOptions.floors}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Çaplar")}</th>
                            <td>{resultOptions.diameters}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Bindirme Oranı")}</th>
                            <td>{resultOptions.projectOverlapRatio}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Elde Kalacak En Kısa Fire")}</th>
                            <td>{resultOptions.minOutageLength}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Katlar Kendi Arasında mı Çözüldü?")}</th>
                            <td>{t(resultOptions.solveFloorsSeparately)}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Oluşturma Zamanı")}</th>
                            <td>{resultOptions.creationTime}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Toplam Çözüm Süresi")}</th>
                            <td>{resultOptions.totalExecutionTime} s.</td>
                        </tr>

                    </tbody>

                </Table>
            </Container>

            <Container className="border shadow-sm my-4 p-4">

                <Table>

                    <thead>
                        <tr>
                            <th>{t("Çap")}</th>
                            <th>{t("Çözüm Süresi")}</th>
                            <th>{t("Hızlı Çözüm")}</th>
                            <th>{t("Yerine Kullanım")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resultOptions.diameterOptions.map((diameterOption, index) => (
                            <tr key={index}>
                                <th>{diameterOption.diameter}</th>
                                <td>{diameterOption.executionTime} s.</td>
                                <td>{diameterOption.use_partitions}</td>
                                <td>{diameterOption.use_substitutes}</td>
                            </tr>
                        
                        ))}
                    </tbody>

                </Table>
            
            </Container>


        </Container>
    )
}

export default SolutionOptions;
