import React, { useEffect, useState } from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

import { useLocation } from 'react-router-dom';

const AboutUsEn = () => {

    document.title = "About Us";

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;

    const [show, setShow] = useState(false);
    const [modalImg, setModalImg] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (img) => { setShow(true); setModalImg(img) };

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;

        if (hash) {
            const id = hash.replace('#', '');

            setTimeout(() => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        }
    }, [location]);

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>About Us</h2>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="what-is-tasnifer">What is Tasnifer? Who Develops It?</h4>

                    <p><span className='fw-bold'>Tasnifer</span> is a web-based program that optimizes reinforcement optimization using mathematical optimization methods to minimize wastage during the cutting of construction reinforcements and provides users with cutting plans.</p>

                    <p>Tasnifer is developed by <span className='fw-bold'>Teber Soft Yazılım İnşaat Sanayi ve Ticaret LTD. ŞTİ.</span> (Tax ID: 8331165876) within the scope of the project named <span className='fw-bold'>"Reinforcement Cutting Plan Creation"</span> with project code 89116 by the Ministry of Industry and Technology of the Republic of Turkey, under Gaziantep Technopark. This project has been included in the VAT Exemption scope by the Ministry of Industry and Technology of the Republic of Turkey.</p>

                    <p>Tasnifer operates web-based and does not require installation. The system is used by becoming a member via the website.</p>

                    <p>For more detailed information on how to use Tasnifer, you can visit our <a href="/en/user-manual">User Manual</a>.</p>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="what-is-optimization">What is Reinforcement Cutting Optimization?</h4>
                    <p><span className='fw-bold'>Reinforcement Cutting Optimization</span> is the process of minimizing the amount of waste during the cutting of construction reinforcements using mathematical models and optimization algorithms. For this to be achieved, reinforcements must be cut according to a certain plan.</p>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="why-optimization">Why Reinforcement Cutting Optimization?</h4>

                    <p>The existing reinforcement cutting operations without optimization contain many problems within themselves. Some of the problems with existing reinforcement cutting are as follows:</p>

                    <ol>
                        <li>Increased costs of iron and labor</li>
                        <li>Excessive wastage - Loss of efficiency</li>
                        <li>Unpredictability of how much wastage will occur in advance</li>
                        <li>Failure to achieve full compliance with projects</li>
                        <li>Failure to hand over the waste to the employer</li>
                    </ol>

                    <p>Some of the reasons why waste is not delivered to the employer are as follows:</p>
                    <ol>
                        <li>Alteration of lengths for placement</li>
                        <li>Selling of waste by workers in secret</li>
                        <li>Disposal of waste into concrete</li>
                        <li>Burying the waste in the ground</li>
                    </ol>

                    <p>All these problems related to reinforcement cutting and waste management have been optimized and require a planned cutting process with scientific methods.</p>


                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="gains">What Are the Gains with Tasnifer?</h4>

                    <p>The main gains that project owners can achieve with Tasnifer are as follows:</p>
                    <ul>
                        <li><u className='fw-bold'>Cost reduction with increased efficiency</u>: In unplanned and unoptimized cutting, the amount of waste is very high. With Tasnifer, the most optimum cutting plan will be obtained, and the waste will be minimized to the lowest possible amount.</li>
                        <li><u className='fw-bold'>Knowing the amount of waste before starting the project</u>: With Tasnifer, the amount of waste remaining after cutting can be clearly known before starting the project.</li>
                        <li><u className='fw-bold'>Reduction in the fee paid to ironworkers</u>: Since the ironworker cutting will process less iron, there will be a decrease in labor costs.</li>
                        <li><u className='fw-bold'>Achieving full compliance with the project</u>: With the planned cutting of existing reinforcements in the project, full compliance with the project will be ensured, and safer structures will be built.</li>
                    </ul>

                    <hr className='my-4' />

                    <h4 className='mb-3 fw-bold' id="pages">Features of Tasnifer</h4>

                    <ul>
                        <li>Operates web-based and does not require installation.</li>
                        <li>Prepares cutting plans by minimizing waste during the cutting of construction reinforcements.</li>
                        <li>Can directly obtain reinforcement metering data from existing reinforced concrete package programs without the need for manual data entry.</li>
                        <li>Can also obtain metering data from Excel files prepared by the user.</li>
                        <li>Allows detailed metering obtained from package programs to be downloaded in Excel format after project analysis.</li>
                        <li>Thus, it allows changes made in the drawing editor by structural engineers to be taken into account after the analysis is completed.</li>
                        <li>Classifies data obtained from the metering file, allowing reinforcement information and metering to be interactively examined by element and floor. It provides control capability on an element basis for ironworkers, site managers, and building inspection personnel.</li>
                        <li>Takes into account the overlap lengths of reinforcements longer than 12 meters in the cutting plan.</li>
                        <li>Can prepare cutting plans for reinforcements produced at lengths other than 12 meters.</li>
                        <li>Can prepare cutting plans with different floor and diameter configurations.</li>
                        <li>Can use excess waste from one diameter to replace suitable reinforcements in a lower diameter when preparing the cutting plan.</li>
                        <li>Allows adjustment of the shortest length to be considered for remaining waste after cutting.</li>
                        <li>Shows where each part belongs in the cutting plan.</li>
                        <li>Can calculate how much reinforcement needs to be ordered for each floor before construction starts and for the entire building.</li>
                        <li>Can calculate the efficiency you will achieve on a floor and diameter basis.</li>
                        <li>Provides a list of the remaining waste after cutting.</li>
                        <li>Allows the cutting plans created to be downloaded in PDF and Excel format.</li>
                        <li>Allows the program to be used by paying a fee per project without purchasing the program.</li>
                        <li>Charges a fee only for the right to create cutting plans for a project.</li>
                        <li>Allows you to learn how much efficiency you will achieve without paying a fee before purchasing the right to create cutting plans.</li>
                    </ul>

                    <hr className='my-4' />

                    <p>For any questions, comments, or suggestions, you can contact us via email at <span className='fw-bold'>{contactEmail}</span>.</p>

                </Container>
            </div>

            <Modal show={show} fullscreen="lg-down" onHide={handleClose} size='lg' centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='d-flex justify-content-center'><img className='img-fluid img-thumbnail' style={{ maxHeight: "80vh" }} src={modalImg} /></Modal.Body>
            </Modal>
            <Footer />
        </div>
    );
};

export default AboutUsEn;
