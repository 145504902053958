import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useCreditCardPaymentSuccess } from '../../../hooks/useCreditCardPaymentSuccess';
import mainStyles from '../../Pages.module.css';

import AppNavbar from '../../../components/AppNavbar/AppNavbar'
import Footer from '../../../components/Footer/Footer';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';

import { BsCheckCircle } from 'react-icons/bs'

import styles from '../ProjectPurchase.module.css'
import { Navigate } from 'react-router-dom';

import { iso31661_country_codes } from '../../../other/variables';
import { iso31662_state_codes } from '../../../other/variables';

import { useTranslation } from 'react-i18next';

const PaymentComplete = () => {
    const {
        showPage,
        isLoading,
        error,
        data,
    } = useCreditCardPaymentSuccess()

    const { i18n, t } = useTranslation();

    const purchaseBanner = (<Container className='text-center bg-white align-items-center mt-4 border'>
        <Row>
            <Col>
                <div className={`${styles.statusStep}`}>
                    <span className={`${styles.statusCircle} ${styles.completedCircle}`}><BsCheckCircle /></span>
                    <span className={`${styles.statusLabel}`}>{t("Fatura Detayları")}</span>
                </div>
            </Col>
            <Col>
                <div className={`${styles.statusStep}`}>
                    <span className={`${styles.statusCircle} ${styles.completedCircle}`}><BsCheckCircle /></span>
                    <span className={`${styles.statusLabel}`}>{t("Ödeme Bilgileri")}</span>
                </div>
            </Col>
            <Col>
                <div className={`${styles.statusStep}`}>
                    <span className={`${styles.statusCircle} ${styles.completedCircle}`}><BsCheckCircle /></span>
                    <span className={styles.statusLabel}>{t("Siparişi Tamamla")}</span>
                </div>
            </Col>
        </Row>
    </Container>)

    if (!showPage) {
        return <></>
    }

    if (isLoading) {
        return (
            <div className={mainStyles.flexWrapper}>
                <AppNavbar />
                <div className={mainStyles.content}>
                    {LoadingContainer}
                </div>
                <Footer />
            </div >
        )
    }
    else if (error) {
        return <Navigate to={`/error`} state={{ props: data }}></Navigate>
    }
    else {
        const projectName = data.project_name
        const projectTheoreticalWeight = data.project_weight
        const projectId = data.project_id
        const unitPrice = data.unit_price
        const unitDiscount = data.unit_discount
        const unitPriceType = data.unit_price_type
        const totalPrice = data.total_price
        const invoiceInfo = data.invoice_info
        const orderId = data.order_id
        const vat = data.vat

        const name = invoiceInfo.type === "real_person" ? `${invoiceInfo.name} ${invoiceInfo.surname}` : invoiceInfo.company_name

        const serviceDetail = <div><a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}/project-info/${projectId}`} target='_blank'>{projectName}</a> {t("isimli projenin kesim planı oluşturma hakkı")}</div>

        let discountName = ""

        if (unitPriceType === "project_delegate") {
            discountName = t("Temsilcilik İndirimi")
        }
        else if (unitPriceType === "project_with_delegation") {
            discountName = t("Temsilci İndirimi")
        }
        return (
            <div className={mainStyles.flexWrapper}>
                <AppNavbar />
                <div className={mainStyles.content}>
                    {purchaseBanner}
                    <Container className='mt-4 bg-white border p-4'>
                        <h4 className='text-center text-success mb-4'>{t("Proje Satın Alımı Başarıyla Tamamlandı.")}</h4>
                        <div className='d-flex justify-content-center'>
                            <Row className='border p-2 mb-2'>
                                <Col className='d-flex align-items-center' style={{ fontSize: "14pt" }}>
                                    {t("Sipariş Numarası:")}
                                </Col>
                                <Col className='d-flex align-items-center fw-bold' style={{ fontSize: "20pt" }} >
                                    {orderId}
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col>
                                <table className='table table-borderless table-sm'>
                                    <tbody>
                                        <tr className='text-success'><td>{t("Satıcı")}</td></tr>
                                        <tr className='fw-bold'><td>Teber Soft Yazılım İnşaat Sanayi Ticaret Ltd.</td></tr>
                                        <tr><td>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı</td></tr>
                                        <tr><td>Gaziantep Teknopark No: 4/A İç Kapı No:3 </td></tr>
                                        <tr><td>Şahinbey / Gaziantep / Türkiye</td></tr>
                                        <tr><td>{t("VKN")}: 8331565876</td></tr>
                                    </tbody>
                                </table>
                            </Col>
                            <Col>
                                <table className='table table-borderless table-sm'>
                                    <tbody>
                                        <tr className='text-success'><td>{t("Müşteri")}</td></tr>
                                        <tr className='fw-bold'><td>{name}</td></tr>
                                        <tr>
                                            <td>{invoiceInfo.street}</td>
                                        </tr>
                                        <tr>
                                            <td>{invoiceInfo.address_line1}</td>
                                        </tr>
                                        {
                                            invoiceInfo.address_line2 ? <tr>
                                                <td>{invoiceInfo.address_line2}</td>
                                            </tr> : <></>
                                        }
                                        <tr>
                                            <td>{invoiceInfo.zipcode}</td>
                                        </tr>
                                        <tr>
                                            <td>{iso31661_country_codes[invoiceInfo.country]}</td>
                                        </tr>
                                        <tr>
                                            <td>{iso31662_state_codes[invoiceInfo.province]} - {invoiceInfo.county}</td>
                                        </tr>
                                        {invoiceInfo.type === "real_person" ?
                                            invoiceInfo.tr_identity_number ? <tr>
                                                <td>{t("TCKN")} : {invoiceInfo.tr_identity_number}</td>
                                            </tr> : <></> :
                                            invoiceInfo.taxno ? <tr>
                                                <td>{t("VKN")} : {invoiceInfo.taxno}</td>
                                            </tr> : <></>}
                                        <tr><td>{invoiceInfo.email}</td></tr>
                                        {invoiceInfo.telephone ?
                                            <tr>
                                                <td>{t("Tel. No.")} : +{invoiceInfo.telephone_country_code}{invoiceInfo.telephone}</td>
                                            </tr> : <></>}
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <hr />

                        <div style={{ padding: "5px 10%" }}>
                            <table className='table table-sm table-borderless' style={{ width: "100%" }}>
                                <tbody>
                                    <tr className='text-center border-bottom'>
                                        <th style={{ textAlign: "start" }}>{t("Açıklama")}</th>
                                        <th style={{ textAlign: "end" }}>{t("Fiyat")}</th>
                                    </tr>
                                    <tr>
                                        <td style={{ verticalAlign: "middle", textAlign: "start" }}>{serviceDetail}</td>
                                        <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>₺{Math.floor(projectTheoreticalWeight * 1e-3 * (unitPrice + unitDiscount))}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ fontSize: "8pt", fontWeight: "light", color: "#777777", textAlign: "end" }}>{(projectTheoreticalWeight * 1e-3).toFixed(3)} ton &times; {(unitPrice + unitDiscount)} ₺/ton</td>
                                    </tr>
                                    {unitDiscount !== 0 &&
                                        <>
                                            <tr className='border-top'>
                                                <td style={{ verticalAlign: "middle", textAlign: "start" }}>{discountName}</td>
                                                <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>&minus; ₺{Math.floor(projectTheoreticalWeight * 1e-3 * (unitDiscount))}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} style={{ fontSize: "8pt", fontWeight: "light", color: "#777777", textAlign: "end" }}>{(projectTheoreticalWeight * 1e-3).toFixed(3)} ton &times; &minus;{unitDiscount} ₺/ton</td>
                                            </tr>
                                        </>
                                    }
                                    <tr className='border-top'>
                                        <td style={{ verticalAlign: "middle", textAlign: "start" }}>{t("Ödenecek Tutar")}</td>
                                        <td style={{ fontSize: "16pt", fontWeight: "bold", textAlign: "end" }}>₺{totalPrice}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ fontSize: "8pt", fontWeight: "light", color: "#777777", textAlign: "end" }}>(%{100 * vat} {t("KDV")}{vat !== 0 ? " Dahil" : ""})</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr />

                        <p className='my-2'>{t("Faturanız hazırlandıktan sonra e-posta ile tarafınıza gönderilecektir.")} {t("paymentcomplete_purchasesmsg1")}<span className='fw-bold'>{t("Hesabım > Satın Alımlar")}</span>{t("paymentcomplete_purchasesmsg2")}</p>

                        <hr />

                    </Container>
                </div>
                <Footer />
            </div >
        )
    }
}

export default PaymentComplete;