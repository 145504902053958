import { useState, useEffect } from "react";
import { getPurchases } from "../../services/paymentServices/getPurchases";
import { downloadInvoice } from "../../services/paymentServices/downloadInvoice";
import { getUserData } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useUsersPurchases = () => {
    const navigate = useNavigate();

    const { i18n, t } = useTranslation()

    const standardKeys = [
        {
            accessorKey: "order_id", //access nested data with dot notation
            header: t("Sipariş No"),
            size: 150,
        },
        {
            accessorFn: (row) => {
                let status = row.status
                if (status === "success") return t("Başarılı")
                else if (status === "waiting") return t("Bekliyor")
                else if (status === "failed") return t("Başarısız")
                else if (status === "in_progress") return t("Devam Ediyor")
                else return t("HATA")
            },
            header: t("Durum"),
            size: 150,
            Cell: ({ renderedCellValue, row }) => {
                let bg_variant, text_variant, message
                if (renderedCellValue === t("Bekliyor")) {
                    bg_variant = "bg-warning"
                    text_variant = "text-dark"
                }
                else if (renderedCellValue === t("Başarılı")) {
                    bg_variant = "bg-success"
                    text_variant = "text-white"
                }
                else if (renderedCellValue === t("Başarısız")) {
                    bg_variant = "bg-danger"
                    text_variant = "text-white"
                }
                else if (renderedCellValue === t("Devam Ediyor")) {
                    bg_variant = "bg-info"
                    text_variant = "text-dark"
                }
                else {
                    bg_variant = "bg-dark"
                    text_variant = "text-white"
                }
                return <p className={`text-center border ${text_variant} ${bg_variant}`}>{renderedCellValue}</p>
            }
        },
    ]

    const [isLoading, setIsLoading] = useState(true)
    const [selectedPurchased, setSelectedPurchased] = useState("1")
    const [selectedMethod, setSelectedMethod] = useState("1")

    const [tableKeys, setTableKeys] = useState(standardKeys)

    const [allData, setAllData] = useState([])
    const [tableData, setTableData] = useState([])

    const [modalInfo, setModalInfo] = useState({
        show: false,
        type: "",
        params: {},
        note_variant: "",
        note: "",
    })

    const columnsDict = {
        "1-1": standardKeys,
        "1-project": standardKeys,
        "1-credits": standardKeys,
        "transfer-1": standardKeys,
        "transfer-project": [
            ...standardKeys,
            {
                accessorFn: (row) => `₺${row.payment_detail.total_price}`,
                header: t("Tutar"),
                size: 120,
                Cell: ({ renderedCellValue, row }) => {
                    return <p className={`fw-bold`} style={{ fontSize: "20px" }}>{renderedCellValue}</p>
                }
            },
            {
                accessorFn: (row) => `${row.payment_detail.project_name} (${row.payment_detail.project})`,
                header: t("Proje İsmi"),
                size: 150,
            },
            {
                accessorFn: (row) => `${(row.payment_detail.project_weight / 1000).toFixed(3)}`,
                header: t("Ağırlık (ton)"),
                size: 100,
            },
            {
                accessorFn: (row) => `${(new Date(row.payment_detail.date_created)).toLocaleDateString("tr-TR")}`,
                header: t("Tarih"),
                sortingFn: "datetime",
                size: 150,
            },
        ],
        "transfer-credits": [
            ...standardKeys,
        ],
        "credit_card-1": standardKeys,
        "credit_card-project": [
            ...standardKeys,
            {
                accessorFn: (row) => `₺${row.payment_detail.total_price}`,
                header: t("Tutar"),
                size: 120,
                Cell: ({ renderedCellValue, row }) => {
                    return <p className={`fw-bold`} style={{ fontSize: "20px" }}>{renderedCellValue}</p>
                }
            },
            {
                accessorFn: (row) => {
                    let success = row.status === "success"
                    let invoice_null = row.invoice_details.invoice_url === null
                    if (!success) return "-"
                    else {
                        return invoice_null ? t("Kesilmedi") : t("Kesildi")
                    }
                },
                header: t("Fatura"),
                size: 120,
                Cell: ({ renderedCellValue, row }) => {
                    let color = ""
                    if (renderedCellValue === t("Kesilmedi")) color = "danger"
                    else if (renderedCellValue === t("Kesildi")) color = "success"
                    return <p className={`text-${color}`}>{renderedCellValue}</p>
                }
            },
            {
                accessorFn: (row) => `${row.payment_detail.project_name} (${row.payment_detail.project})`,
                header: t("Proje İsmi"),
                size: 150,
            },
            {
                accessorFn: (row) => `${(row.payment_detail.project_weight / 1000).toFixed(3)}`,
                header: t("Ağırlık (ton)"),
                size: 100,
            },
            {
                accessorFn: (row) => `${(new Date(row.payment_detail.date_created)).toLocaleDateString("tr-TR")}`,
                header: t("Tarih"),
                sortingFn: "datetime",
                size: 150,
            },
        ],
        "credit_card-credits": [
            ...standardKeys,
        ],

    }


    useEffect(() => {

        async function fetchData() {
            let userData
            try {
                userData = await getUserData();
            }
            catch {
                // getUserError
            }
            if (userData.user_type === 3 && !userData.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_id = userData.id

            try {
                const response = await getPurchases(user_id)

                if (response.ok) {
                    let data = await response.json()
                    setAllData(data)

                    setIsLoading(false);

                }
                else {
                    setIsLoading(false)
                    var props = {
                        error_code: "2CZT95",
                        detail: `${t("Satın alım bilgisi yüklenemedi:")} ${response.statusText} (${response.status})`,
                    }

                    navigate(`/error`, { state: { props: props } })
                }
            }
            catch (error) {
                setIsLoading(false)
                var props = {
                    error_code: "KDKUFR",
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }

                navigate(`/error`, { state: { props: props } })
            }
        }

        fetchData()

    }, [])

    const handleMethodChange = (newMethod) => {
        setSelectedMethod(newMethod)
        setTableData(allData.filter((payment) => {
            return payment.method === newMethod && payment.purchased === selectedPurchased
        }))
        setTableKeys(columnsDict[`${newMethod}-${selectedPurchased}`])
    }

    const handlePurchasedChange = (newPurchased) => {
        setSelectedPurchased(newPurchased)
        setTableData(allData.filter((payment) => {
            return payment.method === selectedMethod && payment.purchased === newPurchased
        }))
        setTableKeys(columnsDict[`${selectedMethod}-${newPurchased}`])
    }

    const modalOnHide = () => {
        setModalInfo({
            ...modalInfo,
            show: false,
            note_variant: "",
            note: "",
        })
    }

    const modalOnShow = (type, params) => {
        setModalInfo({
            ...modalInfo,
            show: true,
            type: type,
            params: params,
        })
    }

    const handleDownloadInvoice = async (order_id) => {
        let response
        try {
            response = await downloadInvoice(order_id)
        }
        catch {
            var props = {
                error_code: "NHCSGG",
                detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
            }

            navigate(`/error`, { state: { props: props } })
        }
        if (response.ok) {
            const data = await response.json()
            const link = document.createElement("a");
            link.href = data.url;
            link.download = "project.xlsx";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        else {
            var props = {
                error_code: "3C8GDS",
                detail: `${t("Fatura indirilemedi:")} ${response.statusText} (${response.status})`,
            }

            navigate(`/error`, { state: { props: props } })
        }
    }


    return {
        isLoading,
        selectedMethod,
        selectedPurchased,

        handleMethodChange,
        handlePurchasedChange,

        tableData,
        tableKeys,

        modalInfo,
        modalOnShow,
        modalOnHide,

        handleDownloadInvoice,
    }
}

export default useUsersPurchases