import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';

import Footer from '../../components/Footer/Footer';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import styles from '../Pages.module.css'

import useSignUp from '../../hooks/useSignUp/useSignUp';

import InfoNote from '../../components/InfoNote/InfoNote';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { delegateCodeInfo } from '../../infoContents'

import { useTranslation } from "react-i18next";
import { defaultLanguage } from '../../i18n';

const Signup = () => {

    const {
        handleSubmit,
        email,
        setEmail,
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,
        delegateCode,
        setDelegateCode,
        invalidEmail,
        invalidPassword,
        passwordMismatch,
        errorToast,
        setErrorToast,
        isLoading,
    } = useSignUp();

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    const { i18n, t } = useTranslation();

    document.title = t("Üye Ol")

    return (
        <div className={styles.flexWrapper}>

            <AppNavbar />

            <div className={styles.content}>
                {dialogOpen &&
                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        open={dialogOpen}
                        onClose={() => setDialogOpen(false)}>
                        <DialogContent>
                            {dialogContent}
                        </DialogContent>
                    </Dialog>}
                <Container style={{ marginTop: '5vh' }}>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={6}>
                            <h3 className="text-center mt-5">{t("Üye Ol")}</h3>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>{t("E-posta")}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        required={true}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword" className="mb-3">
                                    <Form.Label>{t("Şifre")}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        required={true}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPasswordConfirm" className="mb-3">
                                    <Form.Label>{t("Şifreyi Doğrulayın")}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        required={true}
                                        value={passwordConfirm}
                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPasswordConfirm" className="mb-3">
                                    <Form.Label>{t("Temsilci Kodu (Zorunlu Değil)")}<InfoNote content={delegateCodeInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></Form.Label>
                                    <Form.Control
                                        type="text"
                                        minLength={4}
                                        maxLength={6}
                                        value={delegateCode.toUpperCase()}
                                        onChange={(e) => setDelegateCode(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Check // prettier-ignore
                                    className='mb-3'
                                    type='checkbox'
                                    required={true}
                                    id={`default-checkbox`}
                                    label={<div>{t("onay_notu_part1")}<a href={`/${i18n.language}/terms-and-conditions`}>{t("onay_notu_part2")}</a>{t("onay_notu_part3")}<a href={`/${i18n.language}/privacy-policy`}>{t("onay_notu_part4")}</a>{t("onay_notu_part5")}</div>}
                                />
                                {invalidEmail && (
                                    <p className="text-danger">{t("Lütfen geçerli bir email giriniz.")}</p>
                                )}
                                {invalidPassword && (
                                    <p className="text-danger">{t("Şifreniz en az bir rakam ve bir büyük harf içermeli, en az 6 karakterden oluşmalıdır.")}</p>
                                )}
                                {passwordMismatch && (
                                    <p className="text-danger">{t("Şifreler eşleşmiyor.")}</p>
                                )}
                                <Button variant="primary" type="submit" className="w-100 d-block mb-3" disabled={isLoading}>
                                    {isLoading ? <Spinner size='sm' /> : t("Üye Ol")}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
            <Toast
                onClose={() => setErrorToast({ show: false, message: "" })}
                show={errorToast.show}
                bg="danger"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{errorToast.message}</Toast.Body>
            </Toast>
        </div>
    );
};

export default Signup;
