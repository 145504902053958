import { useState, useEffect } from 'react';
import { getCuttingPlan } from '../../services/getCpService';
import { deleteCp } from '../../services/deleteCpService';
import { getUserData } from '../../services/userService';
import { getSingleUnitPrice } from '../../services/getSingleUnitPrice';
import { isProjectPurchasable } from '../../services/paymentServices/projectPurchasableService';
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const useCpInfoInitializer = (id) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)

    const [result, setResult] = useState({})
    const [projectUnitPrice, setProjectUnitPrice] = useState(0)
    const [unitDiscount, setUnitDiscount] = useState(0)
    const [unitPriceType, setUnitPriceType] = useState(0)

    const [purchasable, setPurchasable] = useState(true);

    const [userType, setUserType] = useState(0)

    const [ownCp, setOwnCp] = useState(true)
    const [owner, setOwner] = useState({})

    const [backgroundTask, setBackgroundTask] = useState(false)

    useEffect(() => {
        async function fetchData() {

            let user_data
            try {
                user_data = await getUserData()
            }
            catch {
                // getUserError
            }
            if (user_data.user_type === 3 && !user_data.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_type = user_data.user_type
            setUserType(user_type)
            if (user_type === 0) {
                window.location = `/${i18n.language}/inactive-account`
            }

            let cuttingPlanResponse

            try {
                cuttingPlanResponse = await getCuttingPlan(id);
            }
            catch (error) {

                var props = {
                    error_code: "59GU6W",
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }

                // navigate(`/error`, { state: { props: props } })
            }

            if (!cuttingPlanResponse.ok) {
                var props = {
                    error_code: "EUZMPQ",
                    detail: `${t("Kesim planı yüklenemedi:")} ${cuttingPlanResponse.statusText} (${cuttingPlanResponse.status})`,
                }

                navigate(`/error`, { state: { props: props } })
            }

            const _result = await cuttingPlanResponse.json()
            document.title = _result.cpName
            setResult(_result)
            setOwnCp(_result.projectUser.id === user_data.id)
            setOwner(_result.projectUser)
            if("backgroundTask" in _result){
                setBackgroundTask(_result.backgroundTask)
            }

            let unitPriceKey = 'project'
            if(_result.projectType === 'metal_profile'){
                unitPriceKey = 'metal_profile_project'
            }


            if (!(_result.activated || [4, 5].includes(user_type))) {
                let purchasableResponse
                try {
                    purchasableResponse = await isProjectPurchasable(_result.projectId)
                }
                catch {
                    var props = {
                        error_code: "3K5JSH",
                        detail: "Sistem Hatası. Sistem yöneticisi ile irtibata geçin.",
                    }
                    navigate(`/error`, { state: { props: props } })
                }
                if (!purchasableResponse.ok) {
                    var props = {
                        error_code: "4K5YRH",
                        detail: "Sistem Hatası. Sistem yöneticisi ile irtibata geçin.",
                    }
                    navigate(`/error`, { state: { props: props } })
                }
                const purchasableData = await purchasableResponse.json()
                setPurchasable(purchasableData.purchasable)

                let priceResponse
                if (user_type === 3) {
                    priceResponse = await getSingleUnitPrice(`${unitPriceKey}_delegate`);
                }
                else if (user_type === 2) {
                    priceResponse = await getSingleUnitPrice(`${unitPriceKey}_with_delegation`);
                }
                else {
                    priceResponse = await getSingleUnitPrice(unitPriceKey);
                }

                if (!priceResponse.ok) {
                    var props = {
                        error_code: "0K228F",
                        detail: `Hata: ${priceResponse.statusText} (${priceResponse.status})`,
                    }
                    navigate(`/error`, { state: { props: props } })
                }
                const priceData = await priceResponse.json()
                setProjectUnitPrice(priceData.unit_price)
                setUnitDiscount(priceData.unit_discount)
                setUnitPriceType(priceData.type)
            }

            setIsLoading(false);
        }

        fetchData();
    }, []);

    return {
        isLoading,
        setIsLoading,
        result,
        projectUnitPrice,
        unitDiscount,
        unitPriceType,
        purchasable,
        userType,
        ownCp,
        owner,
        backgroundTask,
    }
}

export default useCpInfoInitializer;