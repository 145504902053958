import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

import useUnitPrices from '../../../hooks/admin/useUnitPrices';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';

import { Link } from 'react-router-dom';

const PricesTr = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
    const homeURL = process.env.REACT_APP_HOME_URL

    document.title = "Fiyat Bilgileri"

    const {
        isLoading,
        projectPrice,
        metalProfileProjectPrice,
        transferDiscount,
    } = useUnitPrices();

    const exampleWeight = 156.3

    const examplePrice = (projectPrice.unit_price * exampleWeight).toFixed(0)


    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                {isLoading ? LoadingContainer :
                    <Container className={`bg-white my-5 p-5 shadow-sm`}>

                        <h4 className='mb-3'>Tasnifer Güncel Fiyatlandırması</h4>

                        <p>Tasnifer, oluşturacağınız her projeyi ayrı ücretlendirir. Her proje için tek sefer ödeme yapılır. Projelerde oluşturulacak birden fazla kesim planı için ayrı ücret ödemeniz gerekmez.</p>

                        <p><span className='fw-bold'>Donatı</span> ve <span className='fw-bold'>Profil</span> projelerinin ücretlendirme şeması farklıdır. Donatı projeleri <span className='fw-bold'>ton başına</span> birim fiyat ile ücretlendirilirken profil projeleri her <span className='fw-bold'>1000 metre başına</span> birim fiyat ile ücretlendirilir.</p>

                        <p>Güncel fiyatlandırma aşağıdaki gibidir:</p>

                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Proje Tipi</th>
                                    <th>Fiyata Esas Birim</th>
                                    <th>Birim Fiyat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Donatı</td>
                                    <td>1 ton</td>
                                    <td>{projectPrice.unit_price}₺</td>
                                </tr>
                                <tr>
                                    <td>Profil</td>
                                    <td>1000 m</td>
                                    <td>{metalProfileProjectPrice.unit_price}₺</td>
                                </tr>
                            </tbody>
                        </table>

                        {/* <p>Örneğin yüklediğiniz projede {exampleWeight} ton demir varsa {exampleWeight} &times; {projectPrice.unit_price} = {examplePrice} ₺ ücret ödersiniz.</p> */}

                        <p>Havale ile ödemelerde <span className='fw-bold'>%{(transferDiscount.unit_price * 100)}</span> indirim uygulanır.</p>

                        <p>Projemiz T.C. Sanayi ve Teknoloji Bakanlığı tarafından desteklenmektedir ve KDV oranımız <span className='fw-bold'>%0</span>'dır.</p>

                        <p>Satın alım işlemleriyle ilgili detaylara Kullanım Rehberimizdeki <Link to="/tr/user-manual#purchase-manual">Satın Alım Rehberi</Link> üzerinden erişebilirsiniz.</p>

                        <p>Fiyatlandırma ile ilgili tüm sorularınız için {contactEmail} e-posta adresine e-posta yollayabilirsiniz.</p>

                        <p></p>


                    </Container>
                }
            </div>
            <Footer />
        </div>
    );
};

export default PricesTr;