import React from 'react';
import styles from './WasteTable.module.css'
import { useTranslation } from 'react-i18next';

const WasteTable = ({ wastes, minOutageLength, ratio }) => {

    let fontSize1 = `${12 * ratio}pt`
    // let fontSize2 = `${11 * ratio}pt`
    // let fontSize3 = `${10 * ratio}pt`
    // let fontSize4 = `${9 * ratio}pt`

    // let marginSize1 = `${6 * ratio}pt`
    // let marginSize2 = `${2 * ratio}pt`
    // let marginSize3 = `${1 * ratio}pt`

    const { t } = useTranslation();

    return (
        <div className={`mb-3 border ${styles.tableWrap}`} style={{ fontSize: `${fontSize1}` }}>
            <table className={`table table-sm text-center align-middle`}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th scope="col" style={{ width: "20%" }}>{t("Uzunluk")}</th>
                        <th scope="col" style={{ width: "20%" }}>{t("Adet")}</th>
                        <th scope="col" style={{ width: "60%", textAlign: "start" }}>{t("Kesim No.")}</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(wastes).sort((a, b) => b - a).map((waste,i) => {

                        let isColored = waste < minOutageLength
                        
                        if(wastes[waste].nCuts === 0){
                            return <></>
                        }

                        return <tr key={i} style={{
                            backgroundColor: isColored ? '#e9ecef' : ''
                        }}>
                            <td>{waste}</td>
                            <td>{wastes[waste].nCuts}</td>
                            <td style={{ textAlign: "start" }}>{(Object.keys(wastes[waste].patterns).map((item) => `${item}`)).join(", ")}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default WasteTable