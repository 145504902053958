import React, { useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import styles from './RebarTable.module.css';
import { useTranslation } from 'react-i18next';
import { calculateWeight } from '../../other/utilFunctions';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';
import { MRT_Localization_EN } from 'material-react-table/locales/en';

const DetailedRebarTable = (props) => {

    const { i18n, t } = useTranslation();

    const localization = i18n.language === 'tr' ? MRT_Localization_TR : MRT_Localization_EN;

    const data = props.data;

    let floorAvailable = false;

    if (data.length > 0) {
        floorAvailable = "floor" in data[0]
    }

    const columns = useMemo(
        () => [
            {
                header: t('Donatı Kodu'),
                accessorKey: 'code',
                enableGrouping: false, //do not let this column be grouped
                size: 100
            },
            {
                header: t('Çap-Uzunluk'),
                accessorFn: (row) => `Φ${row.diameter} - ${row.length}`,
                size: 125
            },
            {
                header: t('Adet'),
                accessorKey: 'number',
                aggregateFn: 'sum',
                AggregatedCell: ({ cell, row }) => {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box fontWeight="bold">Toplam:</Box>
                            <Box>{cell.getValue()}</Box>
                        </Stack>
                    )
                },
                size: 87
            },
            {
                header: t('Uzunluk'),
                accessorKey: 'length',
                size: 98
            },
            {
                header: t('Çap'),
                accessorKey: 'diameter',
                Cell: ({ cell, row }) => {
                    return (
                        <Box>Φ{cell.getValue()}</Box>
                    )
                },
                size: 80
            },
            {
                header: t('Poz'),
                accessorKey: 'poz',
                size: 80
            },
            {
                header: t('Kat'),
                accessorKey: 'floor',
                size: 100
            },
            {
                header: t('Eleman'),
                accessorKey: 'element',
                size: 100
            },
            {
                header: t('Eleman Kodu'),
                accessorKey: 'elementCode',
                size: 100
            },
            {
                header: t('Açıklama'),
                accessorFn: (row) => row.explanation,
                size: 100
            },
            {
                header: t('Ağırlık (kg)'),
                accessorFn: (row) => calculateWeight(row.diameter, row.length * row.number),
                Cell: ({ cell, row }) => {
                    return (
                        <Box className="fw-bold">{cell.getValue()?.toFixed(2)}</Box>
                    )
                },
                enableGrouping: false,
                aggregateFn: 'sum',
                AggregatedCell: ({ cell, table }) => {
                    return (
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box fontWeight="bold">Toplam:</Box>
                            <Box>{cell.getValue()?.toFixed(2)}</Box>
                        </Stack>
                    )
                },
                size: 100
            },

        ],
        [],
    );

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            enableColumnResizing
            enableGrouping
            enableStickyHeader
            enableStickyFooter
            initialState={{
                density: 'compact',
                expanded: true, //expand all groups by default
                pagination: { pageIndex: 0, pageSize: 100 },
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}
            muiTableContainerProps={{ sx: { maxHeight: 700 } }}
            localization={localization}
        />
    )
}

export default DetailedRebarTable;