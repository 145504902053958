import React from "react";

import { Tab, Nav, Row, Col, Container } from "react-bootstrap";
import RebarInspection from './RebarTabPages/RebarInspection';
import CuttingPlans from './RebarTabPages/CuttingPlans';
import ProjectDetails from './RebarTabPages/ProjectDetails';
import LayoutStats from './RebarTabPages/LayoutStats';

import { AiFillWarning } from 'react-icons/ai';
import { useTranslation } from "react-i18next";


import styles from './ProjectInfo.module.css';

const RebarProjectInfo = (
    {
        activeTab,
        setActiveTab,
        id,
        name,
        projectCreationTime,
        projectTheoreticalWeight,
        projectUser,
        projectUploadedFileType,
        logLength,
        bindirmeOrani,
        floorAvailable,
        projectActivated,
        unitPrice,
        unitDiscount,
        unitPriceType,
        purchasable,
        userType,
        ownProject,
        nodes,
        donatiDict,
        rebarListDict,
        cps,
        layoutPlan,
        explanations,
    }
) => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Nav className="nav-tabs nav-fill flex-row d-none d-md-flex">
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="first"
                                className={activeTab === 'first' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('first')}
                            >
                                {t("Donatı Bilgileri")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="fourth"
                                className={activeTab === 'fourth' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('fourth')}
                            >
                                {t("İstatistikler")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="second"
                                className={activeTab === 'second' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('second')}
                            >
                                {t("Kesim Planları")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="third"
                                className={activeTab === 'third' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('third')}
                            >
                                {t("Proje Bilgileri")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                </Nav>

                <Nav className="nav-tabs nav-fill flex-row d-flex d-md-none">
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="first"
                                className={activeTab === 'first' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('first')}
                            >
                                {t("D. Blg.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="fourth"
                                className={activeTab === 'fourth' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('fourth')}
                            >
                                {t("İstat.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="second"
                                className={activeTab === 'second' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('second')}
                            >
                                {t("K. Pln.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="third"
                                className={activeTab === 'third' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('third')}
                            >
                                {t("P. Blg.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="first" style={{ backgroundColor: 'white' }} transition={false}>
                        <RebarInspection
                            nodes={nodes}
                            donatiDict={donatiDict}
                            rebarListDict={rebarListDict}
                            bindirmeOrani={bindirmeOrani}
                            explanations={explanations}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth" style={{ backgroundColor: 'white' }} transition={false}>
                        <LayoutStats
                            layoutPlan={layoutPlan}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" transition={false}>
                        <CuttingPlans
                            id={id}
                            cps={cps}
                            activated={projectActivated}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third" transition={false}>
                        <ProjectDetails
                            projectCreationTime={projectCreationTime}
                            id={id}
                            name={name}
                            theoreticalWeight={projectTheoreticalWeight}
                            user={projectUser}
                            uploadedFileType={projectUploadedFileType}
                            logLength={logLength}
                            bindirmeOrani={bindirmeOrani}
                            floorAvailable={floorAvailable}
                            activated={projectActivated}
                            unitPrice={unitPrice}
                            unitDiscount={unitDiscount}
                            unitPriceType={unitPriceType}
                            purchasable={purchasable}
                            userType={userType}
                            ownProject={ownProject}
                        />
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
            {activeTab === "first" ?
                <Container fluid className='bg-white mt-3'>
                    <Row className='mb-3 p-2 border border-warning rounded '>
                        <Col sm="1" className='d-none d-sm-flex justify-content-center align-items-center'><AiFillWarning style={{ "fontSize": "2.5rem", "color": "orange" }} /></Col>
                        <Col sm="11" style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ fontWeight: 400, fontSize: '11pt', display: 'flex', alignItems: 'center', minHeight: '50px' }}>
                                {t("projectinfo_donati_bilgileri_uyari")}
                            </div>
                        </Col>
                    </Row>
                </Container> : <></>}
        </>
    )

}

export default RebarProjectInfo;