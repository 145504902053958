import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import { getAdditionalUserInfo } from '../../services/additionalUserInfo';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import DelegationContractText from './DelegationContractText';
import { Button } from 'react-bootstrap';
import { renderToStaticMarkup } from 'react-dom/server'
import { activateDelegation } from '../../services/activateDelegation';
import { FaCheckCircle } from 'react-icons/fa'; // use react-icons for this
import styles from '../Verification/Verification.module.css'

import { useTranslation } from 'react-i18next';

const DelegationContract = (props) => {

    const [userInfo, setUserInfo] = useState({})
    const [pageLoading, setPageLoading] = useState(true)
    const [approveLoading, setApproveLoading] = useState(false)
    const [showApproved, setShowApproved] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const { i18n, t } = useTranslation();

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            let userInfoResponse;
            try {
                userInfoResponse = await getAdditionalUserInfo();
            }
            catch {
                var props = {
                    error_code: "HXQ2OC",
                    detail: t("Hata: Bilgiler yüklenemedi."),
                }

                navigate(`/error`, { state: { props: props } })
            }

            if (!userInfoResponse.ok) {
                var props = {
                    error_code: "3E8VVP",
                    detail: `${t("Hata")}: ${userInfoResponse.statusText} (${userInfoResponse.status})`,
                }
                navigate(`/error`, { state: { props: props } })
            }

            const userInfoJson = await userInfoResponse.json();

            if (userInfoJson.user_type !== 3) {
                navigate(`/${i18n.language}`)
            }
            else if (userInfoJson.delegate_active) {
                navigate(`/${i18n.language}`)
            }
            else {
                setUserInfo(userInfoJson);
                setPageLoading(false);
            }
        }
        fetchData()
    }, [])

    const handleApprove = async (html) => {

        setApproveLoading(true);
        let response;
        try {
            response = await activateDelegation(html);
        }
        catch {
            setApproveLoading(false)
            setErrorMessage(`${t("Hata")} 1`)
        }

        setApproveLoading(false)
        if (response.ok) {
            setShowApproved(true)
        }
        else if (response.status === 400) {
            const info = await response.json()
            setErrorMessage(info.detail)
        }
        else {
            setErrorMessage(`${t("Hata")} 2`)
        }
    }

    document.title = t("Temsilcilik Sözleşmesi")

    let current_date = new Date()
    current_date = current_date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })

    const delegationContractComponent = <DelegationContractText props={{ ...userInfo, date: current_date }} />

    const contractHtml = renderToStaticMarkup(delegationContractComponent)

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                {pageLoading ? LoadingContainer :
                    <Container className={`bg-white my-5 p-5 shadow-sm ${styles.verifyContainer}`}>
                        {showApproved ?
                            <>
                                <FaCheckCircle size={70} color="green" className={styles.checkIcon} />
                                <h2 className={styles.title}>{t("Temsilciliğiniz Başarıyla Aktifleştirilmiştir.")}</h2>
                                <p className={styles.message}>{t("Tasnifer'i Temsilcilik avantajlarıyla kullanmaya başlayabilirsiniz.")}</p>

                            </> :
                            <>
                                <div className='m-2 fw-bold text-center'>{t("Sayın kullanıcımız, Temsilci adayı olarak atanmış bulunmaktasınız.")}</div>
                                <div className='m-2 fw-bold text-center'><u>{t("Sistemi Temsilci olarak kullanmaya devam etmek için aşağıdaki sözleşmeyi okuyup onaylayınız.")}</u></div>
                                <div className='m-2 mb-5 text-center'>{t("delegationcontract_message1")} {process.env.REACT_APP_CONTACT_EMAIL} {t("delegationcontract_message2")}</div>
                                {delegationContractComponent}
                                {errorMessage !== "" ?
                                    <div><p className='text-danger'>{errorMessage}</p></div>
                                    : <></>
                                }
                                <div className='text-center mt-4'>
                                    <Button
                                        size='lg'
                                        onClick={() => handleApprove(contractHtml)}
                                        disabled={approveLoading}
                                    >
                                        {
                                            approveLoading ?
                                                t("YÜKLENİYOR") :
                                                t("OKUDUM, ONAYLIYORUM.")
                                        }
                                    </Button>
                                </div>
                            </>}

                    </Container>
                }
            </div>
            <Footer />
        </div>
    );
};

export default DelegationContract;
