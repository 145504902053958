import React from 'react';

const homeURL = process.env.REACT_APP_HOME_URL

const SaleContractTextTr = (props) => {

    return <>
        <h2 className='mb-4 text-center'>Yazılım Hizmeti Satış Sözleşmesi </h2>

        <ol>
            <h5 className='mb-4 fw-bold'><li>Taraflar ve Tanımlar</li></h5>
            <ol>
                <li className='my-1'>İşbu Hizmet Satış Sözleşmesi  ("Sözleşme"), {props.date} tarihinde, aşağıdaki taraflar arasında akdedilmiştir:
                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Satıcı ("Teber Soft" olarak anılacaktır)</th>
                            </tr>
                            <tr>
                                <th>Unvan</th>
                                <td>Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi</td>
                            </tr>
                            <tr>
                                <th>Adres</th>
                                <td>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3 Şahinbey / Gaziantep</td>
                            </tr>
                            <tr>
                                <th>VKN</th>
                                <td>8331165867</td>
                            </tr>
                            <tr>
                                <th>E-posta Adresi</th>
                                <td>{process.env.REACT_APP_CONTACT_EMAIL}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Müşteri ("Müşteri" olarak anılacaktır)</th>
                            </tr>
                            <tr>
                                <th>Ad-Soyad/Unvan</th>
                                <td>{props.clientName}</td>
                            </tr>
                            <tr>
                                <th>Adres</th>
                                <td>{props.clientAddress}</td>
                            </tr>
                            {props.type === "real_person" ?
                                props.tr_identity_number ? <tr>
                                    <th>TCKN</th>
                                    <td>{props.tr_identity_number}</td>
                                </tr> : <></> :
                                props.clientTaxno ? <tr>
                                    <th>VKN</th>
                                    <td>{props.clientTaxno}</td>
                                </tr> : <></>}
                            <tr>
                                <th>E-posta Adresi</th>
                                <td>{props.clientEmail}</td>
                            </tr>
                            {props.clientTelephone ?
                                <tr>
                                    <th>Telefon</th>
                                    <td>{props.clientTelephone}</td>
                                </tr> : <></>}
                        </tbody>
                    </table> </li>
                <li className='my-1'>Sözleşmeye konu olan <a href={`${homeURL}`}>{homeURL}</a> web adresi üzerinden erişilen Tasnifer isimli inşaat demiri optimizasyon programı bundan sonra Yazılım olarak anılacaktır. </li>
                <li className='my-1'>Yazılım’a yüklenen, belirlenen üçüncü taraf İnşaat uygulamaları tarafından üretilen veya Teber Soft tarafından belirlenen formatta Müşteri tarafından hazırlanan, bir inşaat projesinin detaylı metrajını içeren elektronik dosyalar İnşaat Projesi veya İnşaat Projeleri olarak anılacaktır.</li>
                <li className='my-1'>İnşaat Projeleri’nin Yazılım tarafından optimizasyon algoritmaları ile işlenmesi sonucu üretilen, en az fire ile en optimum demir kesim planını ve bu kesim planı hakkındaki detayları içeren elektronik dokümanların ve web sayfalarının hepsi Kesim Planı veya Kesim Planları olarak anılacaktır.</li>
            </ol>


            <h5 className='my-4 fw-bold'><li>Sözleşmenin Konusu</li></h5>
            <ol>
                <li className='my-1'>Sözleşme konusu hizmetin tanımı, KDV dahil satış fiyatı, ödeme şekli ve temel nitelikleri:
                    <table className='table table-borderless'>
                        <tbody>
                            <tr>
                                <th>Hizmetin tanımı</th>
                                <th>Satış Fiyatı</th>
                                <th>Ödeme Şekli</th>
                            </tr>
                            <tr>
                                <td>{props.serviceDetail}</td>
                                <td>₺{props.price}</td>
                                <td>{props.paymentMethod}</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li className='my-1'>Müşteri, Yazılım’a yüklediği İnşaat Projelerini optimize ederek Kesim Planları üretme ve bu Kesim Planlarını inceleme, kaydetme ve yazdırma hakkını satın alır. Sözleşmenin konusu, Teber Soft ile Müşteri arasındaki bu satın alma işinde tarafların hak ve yükümlülüklerini belirlemektir.  </li>
                <li className='my-1'>Taraflar, Sözleşme’nin yürürlüğe girmesi ile, Yazılım ile ilgili yapılacak bütün işlemlerin bu sözleşmede belirtilen esaslara ve hükümlere göre sürdürüleceğini kabul ve beyan eder.</li>
                <li className='my-1'>Taraflar, bu Satış Sözleşmesi’nin ayrılmaz parçaları olan, <a href={`${homeURL}/privacy-policy`}>{homeURL}/privacy-policy</a> web adresinden erişilebilen Gizlilik Politikası ve <a href={`${homeURL}/terms-and-conditions`}>{homeURL}/terms-and-conditions</a> web adresinden erişilebilen Üye Sözleşmesi’ni okuduklarını, anladıklarını ve bütün hükümleriyle birlikte kabul ettiklerini beyan eder.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Taraflar'ın Hak ve Yükümlülükleri</li></h5>
            <ol>
                <li className='my-1'>Teber Soft ve Müşteri, Yazılım'ı kullanırken tüm yasal mevzuat hükümlerine ve işbu Sözleşme şartlarına uymayı ve söz konusu hükümleri ihlal etmemeyi kabul ve taahhüt eder. Şözleşme şartlarını ihlal etmesi durumda, oluşacak tüm hukuki ve cezai yaptırımlardan ihlali gerçekleştiren Taraf sorumlu olacaktır.</li>

                <li className='my-1'>Yazılım'ın veya Yazılım'ın içeriğindeki ve Yazılım'ın ürettiği her türlü doküman veya elektronik materyalin işbu Sözleşme ile belirlenen şartlara ve yürürlükteki mevzuat hükümlerine aykırı olarak kullanılmasının tespiti durumunda Teber Soft Müşteri'ye dava açma hakkını saklı tutar.</li>

                <li className='my-1'>Müşteri, 6698 Sayılı Kişisel Verilerin Korunması Kanunu'nun 11. maddesine dayanarak, kişisel verileri üzerindeki haklarını kullanabilir ve bu verilerde istediği zaman değişiklik veya güncelleme yapabilir.</li>

                <li className='my-1'>Müşteri, işbu Sözleşme ile ve Yazılım'ı kullanırken elde ettiği hakları ücretli veya üretsiz bir şekilde başka bir kişi veya kurumlara devredemez, kiralayamaz, kullandırtamaz. Aksi durumun tespiti hâlinde Teber Soft, Müşteri'nin aldığı hizmeti haber vermeksizin kalıcı olarak iptal etme ve hukuki yolları kullanma hakkını saklı tutar.</li>

                <li className='my-1'>Müşteri, Yazılım'ı hiçbir şekil ve şartta, Yazılım'a ve Yazılım'ın bileşenlerinin barındırıldığı sunucu ve veritabanlarına teknik bir zarar verecek, Teber Soft'u maddi zarara uğratacak veya fikrî ve sınaî mülkiyet haklarını ihlal edecek şekilde kullanmayacağını taahhüt eder.</li>

                <li className='my-1'>Müşteri Yazılım'ın sunduğu hizmetleri satın alırken Teber Soft'a sağladığı bilgilerin doğru ve güncel olduğunu beyan eder. Yanlış beyanından dolayı oluşacak aksaklıklardan ve banka ile Müşteri arasında oluşacak ihtilaflardan Müşteri sorumludur.</li>

                <li className='my-1'>Teber Soft, İnşaat Projesi üreticisi olan üçüncü taraf inşaat programlarında yapılan versiyon güncellemeleri sonucu Müşteri'nin hizmet alamamasından sorumlu değildir. Teber Soft, Müşteri'nin Yazılım'da işlenebilecek formatlardan farklı bir İnşaat Projesi'ni Yazılım'a yüklemesi sebebiyle hizmet alamamasından sorumlu değildir.</li>

                <li className='my-1'>Teber Soft Yazılım'ın teknik özellikleri ve dokümanları üzerinde zamanlaması ve şartları kendi inisiyatifinde olmak üzere her türlü güncellemeyi ve hata düzeltmesini yapma hakkını saklı tutar.</li>

                <li className='my-1'>Teber Soft, Yazılım tarafından üretilen Kesim Planı'nın yerinde icrasından, lojistiğinden, taşeronların kesim planının uygulamasında yaptığı hatalardan ve bu Kesim Planı'nın icrasından doğacak diğer hatalardan mesul değildir. Yazılım tarafından hesaplanan çıktıların doğruluğu, Kesim Planı'na tam olarak riayet edilmesine bağlıdır. Kesim Planı'na aykırı olarak yapılacak kesim işlemleri Yazılım tarafından yapılmış hesaplarla uyumlu olmayacağından Teber Soft, Müşteri'nin Kesim Planı'na aykırı davranışlarından sorumlu tutulamaz.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Muhtelif Hükümler</li></h5>
            <ol>
                <li className='my-1'>Teber Soft yazılım ürününün işlevlerini en iyi şekilde yerine getirmesi için gereken azami özeni göstermektedir. Ancak Teber Soft bu Yazılım’ın kusursuz ve hatasız olduğu veya Müşteri’nin özel ihtiyaçlarını tamamen karşılayacağı şeklinde bir iddia ve taahhütte bulunmamaktadır. </li>

                <li className='my-1'>Teber Soft, herhangi bir sınırlama ya da Müşteri’nin önceden iznine gerek olmaksızın Müşteri’nin Teber Soft kullanıcısı ve müşterisi olduğunu açıklamaya yetkilidir.</li>

                <li className='my-1'>Teber Soft destek personeli, yetkileri dahilinde Müşteri verisine tam erişim hakkına sahiptir. Teber Soft, sunucuların güvenliği konusunda gerekli hassasiyeti gösterecektir. Müşteri de Teber Soft tarafından kurulan güvenlik sistemine riayet etmek ve gizliliği korumakla yükümlüdür. </li>

                <li className='my-1'>Yazılım, web tabanlı bir şekilde geliştirildiği için internet bağlantısı gerektirmektedir. Müşteri'nin internet bağlantısında oluşabilecek sorunlar sebebiyle hizmet alamamasından Teber Soft sorumlu tutulamaz.</li>

                <li className='my-1'>Müşteri, hizmet satın alımı yapmak istediği Teber Soft yazılımı için programla uyumlu internet tarayıcısı kullanmayı taahhüt eder. Ayrıca Müşteri, Teber Soft’un yazılım-donanım uyuşmazlığı ile ilgili olarak hiçbir maddi ve hukuki sorumluluğunun bulunmadığını kabul eder.</li>

                <li className='my-1'>Teber Soft Yazılım hakkında Müşteri tarafından talep edilebilecek her türlü teknik destek talebine sadece e-posta yoluyla cevap verir. Müşteri, e-posta dışındaki yollardan talep edeceği destek taleplerine Teber Soft tarafından cevap verilmeyeceğini kabul eder. </li>

                <li className='my-1'>Gerektiğinde bu belgeye erişim sağlayabilmek için bu belgeyi saklamak Teber Soft ve Müşteri'nin ortak sorumluluğu altındadır. Müşteri bu belgeyi görmediğini, okumadığını, bu belgeye sahip olmadığını veya bu belgeye sahip olmasına imkan tanınmadığını iddia edemez. </li>

                <li className='my-1'>Bu belge kullanıcının Yazılım'ı kullanmayı seçtiği dilde düzenlenir ve o dilde saklanır. Müşteri, belgenin düzenlendiği dildeki sözleşmeyi onaylar.</li>

            </ol>

            <h5 className='my-4 fw-bold'><li>Ücretlendirme ve Ödeme</li></h5>
            <ol>
                <li className='my-1'>Teber Soft, Yazılım'da sunulan hizmetlerin satışı için ödedikçe kullanma (pay-as-you-go) ve abonelik olmak üzere iki yöntem belirlemiştir. Müşteri, her iki yöntemde de belirlenen ücretleri ödemesini müteakiben ilgili yöntemin sınırları içerisinde Kesim Planı oluşturma hakkına sahip olur.</li>

                <li className='my-1'>Ödedikçe kullanma yönteminde Müşteri, yüklediği İnşaat Projesi'nde bulunan ve Yazılım tarafından hesaplanan demir ağırlığı üzerinden Yazılım tarafından hesaplanan ücreti öder. Müşteri, bu ücreti ödemesinin ardından bu İnşaat Projesi’nin demirlerini optimize ederek Kesim Planları oluşturma hakkına sahip olur. Bu yöntemde Müşteri, başka İnşaat Projeleri’nin Kesim Planları’nı elde etmek için yeni ödeme yapmak zorundadır.</li>

                <li className='my-1'>Abonelik yönteminde ise Müşteri, önceden Teber Soft tarafından belirlenen bir ağırlık hakkı (“Kredi”) satın alır. Teber Soft, birim ağırlık başına ücret belirler. Kesim Planları oluşturduğu her bir İnşaat Projesi için Müşteri'nin Kredi'si, İnşaat Projesinin ağırlığı kadar azaltılır. Müşteri, Kredi satın aldıktan sonra, kendisine tanımlanan bu Kredi'sini tüketene kadar ücret ödemez. Müşteri yüklediği projede kredisi yetersiz kalırsa yeni kredi satın almak zorundadır.</li>

                <li className='my-1'>Ödedikçe kullanma ve abonelik yöntemleri arasında ağırlık başına ücretler farklı olabilir. Ücretin ödenmesi ile Müşteri Kesim Planı oluşturma hakkını elde eder. Teber Soft, ücretler üzerinde değişiklik yapma hakkını saklı tutar. Müşteri, satın alımlarında, Teber Soft tarafından belirlenen işbu Satış Sözleşmesi'nin hüküm ve şartlarının geçerli olduğunu kabul eder.</li>

                <li className='my-1'>Ödemeler Havale / EFT yoluyla veya Kredi / Banka Kartı yoluyla alınır.</li>

                <li className='my-1'>Havale / EFT yöntemiyle yapılan ödemelerde, Müşteri'nin ilgili tutarı Teber Soft'a ait banka hesabına yatırması beklenir. Müşteri'nin bu para transferi sırasında para transferi açıklamasına sipariş numarasını eklemesi gerekmektedir. Müşteri, ödemeyi yaptıktan sonra ödemesinin dekontunu {process.env.REACT_APP_CONTACT_EMAIL} e-posta adresine göndermelidir.</li>

                <li className='my-1'>Havale / EFT yöntemiyle yapılacak ödemelerin 1 hafta içerisinde yapılması beklenmektedir. Teber Soft, 1 hafta içerisinde yapılmayan ödemeleri iptal etme ve Müşteri'ye kesim planı oluşturabilme hizmetini vermeme hakkını saklı tutar.</li>

                <li className='my-1'>Havale / EFT yöntemiyle yapılacak ödemelerin eksik veya hatalı olmasının tespiti hâlinde Müşteri ile e-posta yoluyla iletişime geçilecek ve ilgili eksikliğin veya hatanın giderilmesi istenecektir. Müşteri eksik veya hatalı yaptığı bu ödemelerin iadesini isteyebilir. Bu durumda ödenen meblağ Müşteri'ye iade edilecektir. Bu iade süreci 1 haftayı bulabilir.</li>

            </ol>

            <h5 className='my-4 fw-bold' id="returna"><li>İade</li></h5>
            <ol>
                <li>Yazılım tarafından verilen Kesim Planı oluşturma hizmetinde Kesim Planı'nın ait olduğu Proje'nin oluşturulması esnasında vuku bulmuş ve yazılım güncelleştirmeleriyle telafisi mümkün olmayan hataların tespiti hâlinde Müşteri para iadesi talebinde bulunabilir.</li>
                <li>Yazılım güncelleştirmeleriyle telafisi mümkün olmayan ve iade talebine sebep olabilecek Kesim Planı oluşturma hatalarına şunlar dahildir:
                    <ul>
                        <li>İnşaat Projesi dosyasının okunması esnasında eksik veya fazla demir alınması sebebiyle demir miktarının eksik veya fazla çıkması</li>
                        <li>İnşaat Projesi dosyasının okunması esnasında yerleşim planının yanlış okunması sebebiyle Kesim Planı'ndaki demirlerin yerleştirildiği yerlerin yanlış olması</li>
                    </ul>
                </li>
                <li>Yazılım güncelleştirmeleriyle telafisi mümkün olan ve Yazılım azami iki hafta içerisinde güncelleştirilerek hatanın giderilebildiği takdirde iade talebine sebep olamayacak Kesim Planı oluşturma hatalarına şunlar dahildir:
                    <ul>
                        <li>Kesim Planı oluşturma sürecindeki hatalar (Gerekli güncelleştirmenin ardından aynı konfigürasyonlarda yeni bir kesim planı oluşturularak hata giderilebilir.)</li>
                        <li>Uygulamanın önyüz (frontend) kısmındaki bir görüntü oluşturulmasındaki veya hesaplamalardaki hatalar. (Güncelleştirmenin ardından yeni bir kesim planı oluşturulmasına gerek kalmadan hata giderilmiş olur.)</li>
                    </ul>
                </li>
                <li>Yazılım'daki hatanın varlığının ispatı yükümlülüğü Müşteri'ye aittir. Müşteri göndereceği e-postada hatanın mahiyetini detaylıca anlatmakla mükelleftir. Hatanın güncelleştirme ile giderilip giderilemeyeceğinin kararı Teber Soft'a aittir.</li>
                <li>İade talebi Müşteri'nin <span className='fw-bold'>{process.env.REACT_APP_CONTACT_EMAIL}</span> e-posta adresine atacağı, Yazılım'daki hatanın varlığını ispat eden e-postası sonrası değerlendirmeye alınır, yazılım güncelleştirmesi ile hata telafisi mümkün olmadığı takdirde iade işlemi gerçekleştirilerek Kesim Planı ve Kesim Planı'nın ait olduğu ilgili Proje sistemden silinir.</li>
                <li>Teber Soft yazılım güncelleştirmesi ile telafisi mümkün olan hataların telefisini Müşteri'nin e-posta ile geçerli bir başvuru yapmasından sonraki azami iki hafta içerisinde yapmayı taahhüt eder. Bu güncelleştirmenin belirtilen süre içerisinde yapılmaması halinde Müşteri iade talebinde bulunabilir.</li>
                <li>Bu hâller dışında yazılım üzerinden alınan kesim planı oluşturma hizmeti, kesim planlarına erişim hakkının verildiği an gerçekleşmiş sayıldığından hatasız olarak tamamlanmış bu hizmetin iadesi mümkün olamaz ve Müşteri, kesim planı oluşturma hizmetini satın aldıktan sonra elimine edilmesi mümkün olmayan bir hata tespiti olmadığı takdirde iade talebinde bulunamaz.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Sözleşmenin Feshi</li></h5>
            <ol>
                <li className='my-1'>Ödedikçe kullanma yönteminde Sözleşme, ücretin ödenmesinden 3 ay sonra kendiliğinden fesholur.</li>
                <li className='my-1'>Abonelik yönteminde ise Sözleşme, kullanıcının Kredi’lerinin tükenmesini takip eden 3 ay içinde fesholur. </li>
                <li className='my-1'>Müşteri tarafından ödemelerin yapılmaması durumunda Teber Soft taahhüt ettiği hizmeti erteleme, durdurma veya iptal etme hakkına sahiptir.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Tebligata Esas Posta ve Elektronik Posta Adresi</li></h5>
            <ol>
                <li className='my-1'>Taraflar, sözleşmede yazılı adreslerini yasal tebligat adresi olarak kabul ederler. Taraflar adres değişikliklerini onbeş (15) gün içerisinde web sitesinde ilan etmek veya e-posta yolu ile bildirmekle yükümlüdürler. Aksi halde sözleşmede yazılı adreslere, tebligat kanunun 35. maddesine göre yapılacak tebligatın geçerli olacağını kabul ederler. </li>
                <li className='my-1'>Sözleşme süresi içinde Müşteri’nin elektronik posta adresine mesaj, bilgi, yazı, ihtar, ödeme bildirimi, hesap ekstresi gönderilebilir. Müşteri söz konusu elektronik iletilerin alınmadığı ya da kendisine ulaşmadığı iddiasında bulunamayacağı gibi, söz konusu iletilerin gönderildikleri tarihten itibaren üç (3) gün sonra yasal anlamda tebliğ etmiş sayılacağını beyan, kabul ve taahhüt eder.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü</li></h5>
            <p className='my-1'>İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir. İşbu Sözleşme'den doğan ve taraflarca müzakere edilerek halledilemeyen tüm anlaşmazlıkların hallinden Gaziantep Mahkemeleri ve İcra Müdürlükleri yetkilidir.</p>

        </ol>

        <p>9 maddeden ve bu maddelerin alt maddelerinden oluşan işbu Sözleşme, Müşteri tarafından her bir hükmü okunarak ve bütünüyle anlaşılarak elektronik ortamda onaylanmak suretiyle, satış işleminin başarıyla gerçekleştiği an itibariyle yürürlüğe girmiştir.</p>

        <p>Son Güncellenme Tarihi: {props.date}</p>
    </>
}

export default SaleContractTextTr