import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

import useUnitPrices from '../../../hooks/admin/useUnitPrices';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';

import { Link } from 'react-router-dom';

const PricesEn = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
    const homeURL = process.env.REACT_APP_HOME_URL

    document.title = "Price Information"

    const {
        isLoading,
        projectPrice,
        metalProfileProjectPrice,
        transferDiscount,
    } = useUnitPrices();

    const exampleWeight = 156.3

    const examplePrice = (projectPrice.unit_price * exampleWeight).toFixed(0)

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                {isLoading ? LoadingContainer :
                    <Container className={`bg-white my-5 p-5 shadow-sm`}>

                        <h4 className='mb-3'>Current Pricing of Tasnifer</h4>

                        <p>Tasnifer charges each project you create separately. You pay a one-time fee for each project. You do not need to pay separately for multiple cutting plans created within the projects.</p>

                        <p>The pricing scheme for <span className='fw-bold'>Rebar</span> and <span className='fw-bold'>Profile</span> projects is different. Rebar projects are charged a unit price <span className='fw-bold'>per ton</span>, while profile projects are charged a unit price for every <span className='fw-bold'>1000 meters</span>.</p>

                        <p>The current pricing is as follows:</p>

                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Project Type</th>
                                    <th>Unit of Price</th>
                                    <th>Unit Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Rebar</td>
                                    <td>1 ton</td>
                                    <td>₺{projectPrice.unit_price}</td>
                                </tr>
                                <tr>
                                    <td>Profile</td>
                                    <td>1000 m</td>
                                    <td>₺{metalProfileProjectPrice.unit_price}</td>
                                </tr>
                            </tbody>
                        </table>

                        {/* <p>For example, if your uploaded project has {exampleWeight} tons of iron, you would pay {exampleWeight} &times; {projectPrice.unit_price} = {examplePrice} ₺.</p> */}

                        <p>A <span className='fw-bold'>{(transferDiscount.unit_price * 100)}%</span> discount is applied for payments via bank transfer.</p>

                        <p>Our project is supported by the Republic of Turkey Ministry of Industry and Technology, and our VAT rate is <span className='fw-bold'>0%</span>.</p>

                        <p>For details on purchasing processes, you can refer to the <Link to="/en/user-manual#purchase-manual">Purchase Guide</Link> in our User Guide.</p>

                        <p>For all your questions regarding pricing, you can send an email to {contactEmail}.</p>

                        <p></p>

                    </Container>
                }
            </div>
            <Footer />
        </div>
    );
};

export default PricesEn;
