import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import WeightTable from '../../../components/WeightTable/WeightTable';
import RebarTable from '../../../components/RebarTable/RebarTable';
import LongRebarTable from '../../../components/LongRebarTable/LongRebarTable';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import useRebarInspection from '../../../hooks/useRebarInspection';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {
    theoreticalWeightInfo,
    rebarTableInfo,
    placementTableInfo,
    longRebarTableInfo,
} from '../../../infoContents'
import InfoNote from '../../../components/InfoNote/InfoNote';

import { useTranslation } from 'react-i18next';
import DetailedRebarTable from '../../../components/RebarTable/DetailedRebarTable';
import CheckboxList from '../../../components/CheckboxList/CheckboxList';

const RebarInspection = (props) => {

    const { i18n, t } = useTranslation();

    const {
        rebars,
        weights,
        longRebars,
        checked,
        expanded,
        setExpanded,
        handleCheck,
        handleCheckExplanations,
        showDetailedTable,
        setShowDetailedTable
    } = useRebarInspection(props)

    const longRebarsExist = longRebars.length > 0;

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    return (
        <Container className='border border-top-0 p-3' fluid style={{ backgroundColor: 'white' }}>
            <h3 className="text-center d-block d-md-none">{t("Donatı Bilgileri")}</h3>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Row>
                <Col lg={3} md={4} xs={12}>
                    <Container className="border shadow-sm my-3 py-3">
                        <h5 className="text-center">{t("Filtreler")} </h5>
                        <Container className="border py-3" style={{ maxHeight: "30vh", overflowY: "auto" }}>
                            <CheckboxList
                                items={
                                    props.explanations.map((item) => (
                                        { "label": item, "value": item }
                                    ))}
                                onCheckedItemsChange={handleCheckExplanations}
                                singleSelection={false}
                                emptySelection={true}
                                showAllLabel={false}
                                showDeselectLabel={true}
                            />
                        </Container>
                    </Container>
                    <Container className="border shadow-sm my-3 py-3">
                        <h5 className="text-center">{t("Yerleşim Planı")} <InfoNote content={placementTableInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                        <CheckboxTree
                            nodes={props.nodes}
                            showNodeIcon={false}
                            checked={checked}
                            expanded={expanded}
                            onCheck={handleCheck}
                            onExpand={(expanded) => setExpanded(expanded)}
                        />
                    </Container>
                </Col>

                <Col lg={9} md={8} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <Container className="border shadow-sm mb-3">
                                <h5 className="text-center py-3">{t("Teorik Ağırlıklar (kg)")} <InfoNote content={theoreticalWeightInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                                <WeightTable data={weights} />
                            </Container>
                        </Col>
                        <Col xs={12} >
                            <Container className={`border shadow-sm ${showDetailedTable ? "pb-3" : ""}`} style={{ "display": "flex", "flexDirection": "column", "maxHeight": showDetailedTable ? "" : "100vh" }}>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <h5 className="text-center py-3">{t("Donatılar")} <InfoNote content={rebarTableInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                                    </Col>
                                    <Col className='d-flex align-items-center justify-content-center'>
                                        <Form.Check
                                            type='switch'
                                            label={t("Detaylı Tabloyu Göster")}
                                            value={showDetailedTable}
                                            onChange={() => setShowDetailedTable(!showDetailedTable)}
                                        />
                                    </Col>
                                </Row>

                                {showDetailedTable ?
                                    <DetailedRebarTable data={rebars} /> :
                                    <RebarTable data={rebars} />
                                }
                            </Container>
                        </Col>
                        {
                            longRebarsExist ?
                                <Col xs={12} className="mt-3">
                                    <Container className="border shadow-sm" style={{ "display": "flex", "flexDirection": "column", "maxHeight": "100vh" }}>
                                        <h5 className="text-center py-3">{t("Uzun Donatılar")} <InfoNote content={longRebarTableInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></h5>
                                        <LongRebarTable data={longRebars} bindirmeOrani={props.bindirmeOrani} />
                                    </Container>
                                </Col> : <></>
                        }

                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default RebarInspection;