import apiClient from "./apiClient";

const unsign = async (sign) => {
    const url = `${process.env.REACT_APP_API_URL}/api/unsign/`;

    const response = await apiClient(url, {
        method: 'POST',
        body: JSON.stringify({
            "signed_data": sign
        }),
    })
    return response;
};

export { unsign };
