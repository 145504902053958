import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { tr_lang_dict } from "./locales/tr";
import { en_lang_dict } from "./locales/en";

i18n
  .use(initReactI18next)
  .init({ 
    fallbackLng: "tr",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: en_lang_dict,
      },
      tr: {
        translation: tr_lang_dict
      },
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;

export const supportedLanguages = ['en', 'tr']; 
export const defaultLanguage = 'tr';
