import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import styles from './CheckboxList.module.css';
import { useTranslation } from 'react-i18next';

const CheckboxList = ({ 
    items,
    onCheckedItemsChange,
    singleSelection = false,
    emptySelection = false,
    showAllLabel = true,
    showDeselectLabel = false,
    allSelectedAtFirst = false
}) => {
    const [checkedItems, setCheckedItems] = useState([]);
    const [allChecked, setAllChecked] = useState(false);

    const { t } = useTranslation();

    const showPropertyPresent = items.some((item) => item.hasOwnProperty("show"));

    const showedItems = showPropertyPresent ? items.filter((item) => item.show) : items;

    const firstRun = React.useRef(true);

    useEffect(() => {
        if (allSelectedAtFirst) {
            setCheckedItems(showedItems.map((item) => item.value));
            setAllChecked(true);
            onCheckedItemsChange(items.map((item) => item.value)); // Trigger the callback here
        }
        else if (emptySelection) {
            setCheckedItems([]);
        }
        else {
            setCheckedItems([items[0].value]);
        }
    }, []);

    useEffect(() => {
        const itemsToShow = showPropertyPresent ? items.filter((item) => item.show) : items;
        let _checkedItems;
        if (firstRun.current) {
            firstRun.current = false;
            if (allSelectedAtFirst) {
                _checkedItems = itemsToShow.map((item) => item.value);
            }
            else if (emptySelection) {
                _checkedItems = [];
            }
            else {
                _checkedItems = [items[0].value];
            }
        }
        else{
            _checkedItems = checkedItems.filter((item) => itemsToShow.map((item) => item.value).includes(item));
        }
        const checkedItemsToShow = _checkedItems.filter((item) => itemsToShow.map((item) => item.value).includes(item));
        setCheckedItems(checkedItemsToShow);
        if (checkedItemsToShow.length === itemsToShow.length) {
            setAllChecked(true);
        }
        else {
            setAllChecked(false);
        }
    }, [items]);

    const handleCheckboxChange = (e, item) => {
        // Don't allow unchecking the last checked item

        if (!emptySelection && checkedItems.length === 1 && checkedItems[0] === item.value) {
            return;
        }

        let newCheckedItems;
        if (singleSelection) {
            newCheckedItems = e.target.checked ? [item.value] : [];
        } else {
            if (e.target.checked) {
                newCheckedItems = [...checkedItems, item.value];
                if (newCheckedItems.length === items.length) {
                    setAllChecked(true);
                }
            } else {
                newCheckedItems = checkedItems.filter((i) => i !== item.value);
                setAllChecked(false);
            }
        }
        setCheckedItems(newCheckedItems);
        onCheckedItemsChange(newCheckedItems); // Trigger the callback here
    };

    const handleAllCheckboxChange = (e) => {
        let newCheckedItems;
        if (e.target.checked) {
            newCheckedItems = items.map((item) => item.value);
        } else if (emptySelection) {
            newCheckedItems = [];
        }
        else {
            newCheckedItems = [items[0].value];
        }
        setCheckedItems(newCheckedItems);
        setAllChecked(e.target.checked);
        onCheckedItemsChange(newCheckedItems); // Trigger the callback here
    };

    const handleDeselect = () => {
        if(emptySelection){
            setCheckedItems([]);
            setAllChecked(false);
            onCheckedItemsChange([]); // Trigger the callback here
        }
        else{
            setCheckedItems([items[0].value]);
            setAllChecked(false);
            onCheckedItemsChange([items[0].value]); // Trigger the callback here
        }
    }

    return (
        <div className={styles.checkboxList}>
            {!singleSelection && items.length > 1 && showAllLabel && (
                <div className={styles.checkboxItem}>
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-all`}
                        onChange={handleAllCheckboxChange}
                        checked={allChecked}
                        label={t("Tümü")}
                    />
                </div>
            )}
            {!singleSelection && items.length > 1 && showDeselectLabel && (
                <div className='text-center mb-2'>
                    <Button
                        className="btn-sm btn-light border"
                        onClick={handleDeselect}
                    >{t("Temizle")}</Button>
                </div>
            )}
            {showedItems.map((item, index) => (
                <div key={index} className={styles.checkboxItem}>
                    <Form.Check
                        type="checkbox"
                        id={`checkbox-${index}`}
                        onChange={(e) => handleCheckboxChange(e, item)}
                        checked={checkedItems.includes(item.value)}
                        label={item.label}
                    />
                </div>
            ))}
        </div>
    );
};

export default CheckboxList;
