import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactEn from './localePages/ContactEn';
import ContactTr from './localePages/ContactTr';

const Contact = () => {

    const { i18n, t } = useTranslation();

    if (i18n.language === 'en') {
        return <ContactEn />;
    }
    else {
        return <ContactTr />;
    }
};

export default Contact;
