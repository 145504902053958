import apiClient from "../apiClient";

export const sendAdminAuthCode = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/send-admin-auth-code/`;

    const response = await apiClient(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return response;

}