import React from 'react';

const homeURL = process.env.REACT_APP_HOME_URL

const DelegationContractTextTr = ({ props }) => {

    return <>
        <h2 className='mb-4 text-center'>Temsilcilik Sözleşmesi</h2>

        <ol>
            <h5 className='mb-4 fw-bold'><li>Taraflar ve Tanımlar</li></h5>
            <ol>
                <li className='my-1'>İşbu Temsilcilik Sözleşmesi  ("Sözleşme"), {props.date} tarihinde, aşağıdaki taraflar arasında akdedilmiştir:
                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Satıcı ("Teber Soft" olarak anılacaktır)</th>
                            </tr>
                            <tr>
                                <th>Unvan</th>
                                <td>Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi</td>
                            </tr>
                            <tr>
                                <th>Adres</th>
                                <td>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3  Şahinbey / Gaziantep</td>
                            </tr>
                            <tr>
                                <th>VKN</th>
                                <td>8331165867</td>
                            </tr>
                            <tr>
                                <th>E-posta Adresi</th>
                                <td>{process.env.REACT_APP_CONTACT_EMAIL}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Temsilci ("Temsilci" olarak anılacaktır)</th>
                            </tr>
                            <tr>
                                <th>Ad-Soyad</th>
                                <td>{`${props.name} ${props.surname}`}</td>
                            </tr>
                            <tr>
                                <th>Adres</th>
                                <td>{props.address}</td>
                            </tr>
                            {props.tr_identity_number &&
                                <tr>
                                    <th>TCKN</th>
                                    <td>{props.tr_identity_number}</td>
                                </tr>}
                            {props.taxno &&
                                <tr>
                                    <th>VKN</th>
                                    <td>{props.taxno}</td>
                                </tr>}
                            <tr>
                                <th>E-posta Adresi</th>
                                <td>{props.email}</td>
                            </tr>
                            <tr>
                                <th>Telefon</th>
                                <td>+{props.telephone_country_code}{props.telephone}</td>
                            </tr>
                        </tbody>
                    </table> </li>
                <li className='my-1'>Sözleşmeye konu olan <a href={`${homeURL}`}>{homeURL}</a> web adresi üzerinden erişilen Tasnifer isimli inşaat demiri optimizasyon programı bundan sonra Yazılım olarak anılacaktır. </li>
                <li className='my-1'>Yazılım’a yüklenen, belirlenen üçüncü taraf İnşaat uygulamaları tarafından üretilen veya Teber Soft tarafından belirlenen formatta Temsilci tarafından hazırlanan, bir inşaat projesinin detaylı metrajını içeren elektronik dosyalar İnşaat Projesi veya İnşaat Projeleri olarak anılacaktır.</li>
                <li className='my-1'>İnşaat Projeleri’nin Yazılım tarafından optimizasyon algoritmaları ile işlenmesi sonucu üretilen, en az fire ile en optimum demir kesim planını ve bu kesim planı hakkındaki detayları içeren elektronik dokümanların ve web sayfalarının hepsi Kesim Planı veya Kesim Planları olarak anılacaktır.</li>
            </ol>


            <h5 className='my-4 fw-bold'><li>Sözleşmenin Konusu</li></h5>
            <ol>
                <li className='my-1'>Temsilci, Teber Soft adına Yazılım'ın tanıtılması görevini üstlenir ve Yazılım'ı kullanırken bazı haklar elde eder. Sözleşmenin konusu, Teber Soft ile Temsilci arasında tarafların hak ve yükümlülüklerini belirlemektir.</li>

                <li className='my-1'>Taraflar, Sözleşme’nin yürürlüğe girmesi ile, Yazılım'ın tanıtımı ile ilgili olan ve Temsilcilik haklarından doğan bütün işlemlerin bu sözleşmede belirtilen esaslara ve hükümlere göre sürdürüleceğini kabul ve beyan eder.</li>
                <li className='my-1'>Taraflar, bu Sözleşme’nin ayrılmaz parçaları olan, <a target="_blank" href={`${homeURL}/privacy-policy`}>{homeURL}/privacy-policy</a> web adresinden erişilebilen Gizlilik Politikası ve <a target="_blank" href={`${homeURL}/terms-and-conditions`}>{homeURL}/terms-and-conditions</a> web adresinden erişilebilen Üye Sözleşmesi’ni okuduklarını, anladıklarını ve bütün hükümleriyle birlikte kabul ettiklerini beyan eder.</li>
                <li className='my-1'>Temsilci, Yazılım üzerinde yapacağı satın alım işlemlerinde, satın alım sırasında oluşturulan Satış Sözleşmesi'ne Müşteri sıfatıyla taraf olacaktır. Temsilci, satın alım işlemlerinde ayrıca bu Satış Sözleşmesi'ne aykırı hareket etmeyeceğini ve bu Satış Sözleşmesi'nin hükümlerini okuduğunu ve onayladığını beyan eder. Örnek Satış Sözleşmesi'ne <a target="_blank" href={`${homeURL}/sale-contract`}>{homeURL}/sale-contract</a> web adresi üzerinden erişilebilir.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Taraflar'ın Hak ve Yükümlülükleri</li></h5>
            <ol>
                <li className='my-1'>Temsilci, Teber Soft adına Yazılım'ın tanıtım görevini üstlenir. Tanıtım faaliyetleri, potansiyel kullanıcıların Yazılım'a üye olmasını ve Yazılım'ı kullanma hakkını satın almalarını sağlayacak her türlü aktiviteyi ihtiva eder.</li>
                <li className='my-1'>Temsilci, Yazılım'ı indirimli olarak kullanma hakkına sahip olur. İndirim miktarı Teber Soft tarafından belirlenir. Teber Soft Temsilci'ye yapılacak indirim miktarıyla ilgili değişiklik yapma hakkını saklı tutar.</li>
                <li className='my-1'>Temsilci, temsilciliği devam ettiği sürece tanıtım faaliyetleri sonucu Yazılım'a üye olmasını sağladığı her kullanıcının Yazılım'ı kullanırken ödediği ücretlerden pay elde etme hakkına sahip olur. Teber Soft ücretten alınacak pay ve kullanıcılardan bu payın alınacağı süreyle ilgili değişiklik yapma hakkını saklı tutar.</li>
                <li className='my-1'>Teber Soft, Temsilci'nin üye olmasını sağladığı kişilerin Yazılım'ı kullanırken ödediği ücretlerden alacağı payı, ödemenin Teber Soft'un banka hesabına geçmesini takip eden bir hafta içerisinde Temsilci'ye öder. Yapılan ödemelerin Teber Soft'un banka hesabına geçmesi, kredi/banka kartı ile yapılan ödemelerde Teber Soft'un ödeme altyapısı için kullandığı bankadan kaynaklanan, havale ile yapılan ödemelerde ise ödemeyi yapacak olan kullanıcının parayı geç yatırmasından kaynaklanan ve 40 günü bulabilen gecikmeler yaşanabilmektedir. </li>
                <li className='my-1'>Temsilci, temsilcilik dolayısıyla elde ettiği hakları Teber Soft'un rızası dışında üçüncü kişi ve kurumlarla paylaşamayacağını, bu hakları üçüncü kişi ve kurumlara kullandırtamayacağını ve bu hakları devredemeyeceğini kabul eder.</li>

            </ol>

            <h5 className='my-4 fw-bold'><li>Muhtelif Hükümler</li></h5>
            <ol>
                <li className='my-1'>Temsilci'nin elde ettiği indirimli kesim planı oluşturma hakkını kullanarak üçüncü kişi ve kurumlara yapacağı hizmet satışlarıyla Teber Soft'un hiçbir ilişiği bulunmamaktadır. Bu hizmet satışları sırasında ortaya çıkabilecek sorunlardan Teber Soft sorumlu tutulamaz.</li>
                <li className='my-1'>Her Temsilci'nin bir Temsilci kodu bulunmaktadır. Temsilci'nin Yazılım'a üye yaptığı kişilerin Yazılım'a üye olurken bu temsilci kodunu Üye Olma Formu'nda gerekli alana yazması gerekmektedir. Bu kodu yazan üyeler Yazılım tarafından Temsilci ile ilişkilendirilir. Temsilci, kendisine ait bu kodu üye olurken üye formuna yazmayan üyelerin Yazılım üzerinde ödeyeceği ücretlerde hak iddia edemez.</li>

            </ol>

            <h5 className='my-4 fw-bold'><li>Sözleşmenin Feshi</li></h5>
            <ol>
                <li className='my-1'>Sözleşme, sözleşmenin yapıldığı tarihten itibaren iki (2) yıl boyunca geçerlidir. İki (2) yıl sonunda sözleşme kendiliğinden fesholur.</li>
                <li className='my-1'>Sözleşme süresi bitiminde, tarafların mutabık kalması hâlinde sözleşmenin süresi uzatılabilir veya yeni bir sözleşme yapılabilir.</li>
                <li className='my-1'>Taraflardan birinin sözleşme hükümlerine aykırı hareket etmesi durumunda karşı tarafın sözleşmeyi tek taraflı feshetme hakkı bulunmaktadır.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Tebligata Esas Posta ve Elektronik Posta Adresi</li></h5>
            <ol>
                <li className='my-1'>Taraflar, sözleşmede yazılı adreslerini yasal tebligat adresi olarak kabul ederler. Taraflar adres değişikliklerini onbeş (15) gün içerisinde web sitesinde ilan etmek veya e-posta yolu ile bildirmekle yükümlüdürler. Aksi halde sözleşmede yazılı adreslere, tebligat kanunun 35. maddesine göre yapılacak tebligatın geçerli olacağını kabul ederler. </li>
                <li className='my-1'>Sözleşme süresi içinde Temsilci’nin elektronik posta adresine mesaj, bilgi, yazı, ihtar, ödeme bildirimi, hesap ekstresi gönderilebilir. Temsilci söz konusu elektronik iletilerin alınmadığı ya da kendisine ulaşmadığı iddiasında bulunamayacağı gibi, söz konusu iletilerin gönderildikleri tarihten itibaren üç (3) gün sonra yasal anlamda tebliğ etmiş sayılacağını beyan, kabul ve taahhüt eder.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü</li></h5>
            <p className='my-1'>İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir. İşbu Sözleşme'den doğan ve taraflarca müzakere edilerek halledilemeyen tüm anlaşmazlıkların hallinden Gaziantep Mahkemeleri ve İcra Müdürlükleri yetkilidir.</p>

        </ol>

        <p>7 maddeden ve bu maddelerin alt maddelerinden oluşan işbu Sözleşme, Temsilci tarafından her bir hükmü okunarak ve bütünüyle anlaşılarak elektronik ortamda onaylanmak suretiyle, {props.date} tarihinde yürürlüğe girmiştir.</p>
    </>
}

export default DelegationContractTextTr