import i18n from "../i18n";

export const verifyEmailChange = async (user_id, timestamp, signature) => {
    const url = `${process.env.REACT_APP_API_URL}/api/verify-email-change/`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': i18n.language || 'tr',
        },
        body: JSON.stringify({
            user_id: user_id,
            timestamp: timestamp,
            signature: signature,
        }),
    });

    return response;

}