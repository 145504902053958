import React from 'react';

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Tab from 'react-bootstrap/Tab';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import styles from './Projects.module.css'
import ProjectRow from '../../components/ProjectRow/ProjectRow';

import useProjects from '../../hooks/useProjects/useProjects';

import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import { useTranslation } from "react-i18next";
import { Nav } from 'react-bootstrap';
import RebarProjectsTab from './RebarProjectsTab';
import MetalProfileProjectsTab from './MetalProfileProjectsTab';

const Projects = () => {

    const {
        activeTab,
        setActiveTab,
        projects,
        rebarProjects,
        metalProfileProjects,
        showDeletedToast,
        setShowDeletedToast,
        showInfoErrorToast,
        setShowInfoErrorToast,
        isLoading,
    } = useProjects()

    const { i18n, t } = useTranslation();

    document.title = t("Projelerim")

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />
            <div className={mainStyles.content}>
                {isLoading ? LoadingContainer :

                    <div >
                        <Container className="border mt-4 mb-4 py-4 bg-white d-flex justify-content-center align-items-center" >
                            <Button variant="primary" size="lg" className={styles.customButton} href={`/${i18n.language}/create-project`}>
                                {t("Proje Oluştur")}
                            </Button>
                        </Container>
                        <Container className='p-0'>
                            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
                                <Nav className="nav-tabs nav-fill flex-row">
                                    <Col xs={6}>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="rebar"
                                                className={activeTab === 'rebar' ? styles.activeTab : styles.tabHeader}
                                                onClick={() => {
                                                    localStorage.setItem('ca_projecttab', 'rebar')
                                                    setActiveTab('rebar')
                                                }}
                                            >
                                                {t("Donatı Projeleri")}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col xs={6}>
                                        <Nav.Item>
                                            <Nav.Link
                                                eventKey="metal_profile"
                                                className={activeTab === 'metal_profile' ? styles.activeTab : styles.tabHeader}
                                                onClick={() => {
                                                    localStorage.setItem('ca_projecttab', 'metal_profile')
                                                    setActiveTab('metal_profile')
                                                }}
                                            >
                                                {t("Profil Projeleri")}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="rebar" style={{ backgroundColor: 'white' }} transition={false}>
                                        <RebarProjectsTab
                                            projects={rebarProjects}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="metal_profile" style={{ backgroundColor: 'white' }} transition={false}>
                                        <MetalProfileProjectsTab
                                            projects={metalProfileProjects}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Container>
                    </div>
                }
            </div>
            <Toast
                onClose={() => setShowDeletedToast(false)}
                show={showDeletedToast}
                bg="success"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Bilgi")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{t("Proje başarıyla silindi.")}</Toast.Body>
            </Toast>
            <Toast
                onClose={() => setShowInfoErrorToast(false)}
                show={showInfoErrorToast}
                bg="danger"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{t("Proje açılamadı. Lütfen sistem yöneticisiyle iletişime geçiniz.")}</Toast.Body>
            </Toast>
            <Footer />
        </div >
    );
};

export default Projects;
