import React from 'react';

const homeURL = process.env.REACT_APP_HOME_URL

const DelegationContractTextEn = ({ props }) => {

    return <>
        <h2 className='mb-4 text-center'>Delegation Agreement</h2>

        <ol>
            <h5 className='mb-4 fw-bold'><li>Parties and Definitions</li></h5>
            <ol>
                <li className='my-1'>This Delegation Agreement ("Agreement") is made on {props.date} between the following parties:
                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Seller ("Teber Soft" will be referred to as)</th>
                            </tr>
                            <tr>
                                <th>Title</th>
                                <td>Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3  Şahinbey / Gaziantep / Türkiye</td>
                            </tr>
                            <tr>
                                <th>Tax ID</th>
                                <td>8331165867</td>
                            </tr>
                            <tr>
                                <th>E-mail Address</th>
                                <td>{process.env.REACT_APP_CONTACT_EMAIL}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Delegate ("Delegate" will be referred to as)</th>
                            </tr>
                            <tr>
                                <th>Name-Surname</th>
                                <td>{`${props.name} ${props.surname}`}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{props.address}</td>
                            </tr>
                            {props.tr_identity_number &&
                                <tr>
                                    <th>Turkish ID</th>
                                    <td>{props.tr_identity_number}</td>
                                </tr>}
                            {props.taxno &&
                                <tr>
                                    <th>Tax ID</th>
                                    <td>{props.taxno}</td>
                                </tr>}
                            <tr>
                                <th>E-mail Address</th>
                                <td>{props.email}</td>
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td>
                                +{props.telephone_country_code}{props.telephone}</td>
                            </tr>
                        </tbody>
                    </table> </li>
                <li className='my-1'>The construction reinforcement optimization program called Tasnifer, which is accessed via the web address <a href={`${homeURL}/en`}>{homeURL}</a> that is the subject of this Agreement, will hereinafter be referred to as the Software.</li>
                <li className='my-1'>Electronic files containing the detailed measurements of a construction project that are uploaded to the Software, produced by third-party construction applications determined by Teber Soft or prepared by the Delegate in the format determined by Teber Soft, will be referred to as Construction Project or Construction Projects.</li>
                <li className='my-1'>All electronic documents and web pages containing the most optimal cutting plan with minimal waste and details about this cutting plan, produced as a result of the processing of Construction Projects by the Software's optimization algorithms, will be referred to as Cutting Plan or Cutting Plans.</li>
            </ol>


            <h5 className='my-4 fw-bold'><li>Subject of the Agreement</li></h5>
            <ol>
                <li className='my-1'>The Delegate assumes the responsibility of promoting the Software on behalf of Teber Soft and gains certain rights while using the Software. The subject of the Agreement is to determine the rights and obligations of the parties between Teber Soft and the Delegate.</li>

                <li className='my-1'>The parties acknowledge and declare that with the entry into force of the Agreement, all operations related to the promotion of the Software and the rights arising from the Delegation rights will be carried out in accordance with the principles and provisions set forth in this Agreement.</li>
                <li className='my-1'>The parties declare that they have read, understood and accepted all the provisions of the Privacy Policy, which is an integral part of this Agreement, accessible via the web address <a target="_blank" href={`${homeURL}/en/privacy-policy`}>{homeURL}/privacy-policy</a> and the Membership Agreement accessible via the web address <a target="_blank" href={`${homeURL}/en/terms-and-conditions`}>{homeURL}/terms-and-conditions</a>.</li>
                <li className='my-1'>The Delegate will be a party as the Customer during the purchase transactions he/she will make on the Software. The Delegate declares that he/she will not act against this Sales Agreement during the purchase transactions and that he/she has read and approved the provisions of this Sales Agreement. A sample Sales Agreement can be accessed via the web address <a target="_blank" href={`${homeURL}/en/sale-contract`}>{homeURL}/sale-contract</a>.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Rights and Obligations of the Parties</li></h5>
            <ol>
                <li className='my-1'>The Delegate assumes the responsibility of promoting the Software on behalf of Teber Soft. Promotional activities include any activity that will ensure potential users become members of the Software and purchase the right to use the Software.</li>
                <li className='my-1'>The Delegate has the right to use the Software at a discounted rate. The discount rate is determined by Teber Soft. Teber Soft reserves the right to change the discount rate to be given to the Delegate.</li>
                <li className='my-1'>For as long as the delegation continues, the Delegate has the right to earn a share from the fees paid by each user they have ensured to become a member of the Software as a result of their promotional activities. Teber Soft reserves the right to make changes regarding the share to be taken from the fee and the period during which this share will be received from the users.</li>
                <li className='my-1'>Teber Soft will pay the Delegate's share from the fees paid by the people the Delegate ensured to become members of the Software, within one week following the payment being transferred to Teber Soft's bank account. Delays of up to 40 days may be experienced in the transfer of payments to Teber Soft's bank account, these delays can be caused by the bank used by Teber Soft for its payment infrastructure in credit/debit card payments, and by late deposits by the user making the payment in wire transfer payments.</li>
                <li className='my-1'>The Delegate agrees that they will not share the rights they obtain through delegation with third-party individuals and institutions without Teber Soft's consent, will not allow these rights to be used by third-party individuals and institutions, and will not transfer these rights.</li>

            </ol>

            <h5 className='my-4 fw-bold'><li>Miscellaneous Provisions</li></h5>
            <ol>
                <li className='my-1'>Teber Soft has no connection with any service sales that the Delegate will make to third-party individuals and institutions using the right to create discounted cutting plans they obtained. Teber Soft cannot be held responsible for any problems that may arise during these service sales.</li>
                <li className='my-1'>Each Delegate has a Delegate code. The Delegate needs to ensure that the people they make members of the Software write this delegate code in the required field on the Membership Form while becoming members of the Software. Members who enter this code are associated with the Delegate by the Software. The Delegate cannot claim rights on the fees that will be paid on the Software by members who do not write their own code in the membership form while becoming members.</li>

            </ol>

            <h5 className='my-4 fw-bold'><li>Termination of the Agreement</li></h5>
            <ol>
                <li className='my-1'>The Agreement is valid for two (2) years from the date the Agreement is made. At the end of two (2) years, the Agreement will be automatically terminated.</li>
                <li className='my-1'>At the end of the Agreement period, if the parties agree, the term of the Agreement may be extended or a new Agreement may be made.</li>
                <li className='my-1'>In the event that one of the parties acts in violation of the provisions of the Agreement, the other party has the right to terminate the Agreement unilaterally.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Address for Service of Notice by Mail and Electronic Mail</li></h5>
            <ol>
                <li className='my-1'>The parties accept the addresses stated in the Agreement as their legal service addresses. The parties are obliged to announce address changes on the website within fifteen (15) days or notify them by e-mail. Otherwise, they agree that the notifications made to the addresses stated in the Agreement will be valid in accordance with Article 35 of the Notification Law. </li>
                <li className='my-1'>Messages, information, writings, warnings, payment notifications, and account statements can be sent to the Delegate's electronic mail address during the Agreement period. The Delegate cannot claim that they did not receive or did not reach them regarding the aforementioned electronic messages, and they declare, accept and undertake that the aforementioned messages will be deemed to have been served legally three (3) days after the date they were sent.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Governing Law and Dispute Resolution</li></h5>
            <p className='my-1'>This Agreement is subject to the laws of the Republic of Turkey. All disputes arising from this Agreement and which cannot be settled through negotiation between the parties will be resolved by the Gaziantep Courts and Enforcement Offices.</p>

        </ol>

        <p>This Agreement, consisting of 7 articles and their sub-articles, has entered into force on {props.date} by the electronic approval of the Delegate after each provision has been read and fully understood.</p>
    </>
}

export default DelegationContractTextEn