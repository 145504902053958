import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import useUsersPurchases from '../../hooks/admin/useUsersPurchases';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { iso31661_country_codes } from '../../other/variables';
import { iso31662_state_codes } from '../../other/variables';

import { useTranslation } from 'react-i18next';

const UsersPurchases = () => {

    const {
        isLoading,
        selectedMethod,
        selectedPurchased,

        handleMethodChange,
        handlePurchasedChange,

        tableData,
        tableKeys,

        modalInfo,
        modalOnShow,
        modalOnHide,

        handleDownloadInvoice,
    } = useUsersPurchases()

    const { i18n, t } = useTranslation();

    document.title = t("Satın Alımlar")

    const columns = useMemo(
        () => tableKeys, [tableKeys],
    );

    let modal;
    let transfer = modalInfo.type === "project-transfer"

    if (modalInfo.type.split('-')[0] === "project") {
        let date_created = (new Date(modalInfo.params.payment_detail.date_created)).toDateString()
        let time_created = (new Date(modalInfo.params.payment_detail.date_created)).toTimeString()
        let date_completed = ""
        let time_completed = ""
        if (transfer) {
            if (modalInfo.type === "project-transfer") {
                date_completed = (new Date(modalInfo.params.payment_detail.date_completed)).toDateString()
                time_completed = (new Date(modalInfo.params.payment_detail.date_completed)).toTimeString()
            }
        }
        modal = <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t("Sipariş/Ödeme Detayı")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5>{t("Sipariş/Ödeme Bilgisi")}</h5>
                        <table className='table table-sm'>
                            <tbody>
                                <tr>
                                    <th>
                                        {t("Sipariş Numarası")}
                                    </th>
                                    <td>
                                        {modalInfo.params.order_id}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Sipariş Tarihi")}
                                    </th>
                                    <td>
                                        {`${date_created} ${time_created}`}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Durumu")}
                                    </th>
                                    <td>
                                        {modalInfo.params.status}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Sipariş Veren Kullanıcı")}
                                    </th>
                                    <td>
                                        {`${modalInfo.params.user_email} (${modalInfo.params.user})`}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Sipariş Edilen Proje")}
                                    </th>
                                    <td>
                                        <a href={`/${i18n.language}/project-info/${modalInfo.params.payment_detail.project}`} target='_blank'>{`${modalInfo.params.payment_detail.project_name} (${modalInfo.params.payment_detail.project})`}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Proje Ağırlığı")}
                                    </th>
                                    <td>
                                        {(modalInfo.params.payment_detail.project_weight / 1000).toFixed(3)} ton
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Birim Fiyat")}
                                    </th>
                                    <th>
                                        {`${modalInfo.params.payment_detail.unit_price} ₺/ton`}
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        {modalInfo.params.payment_detail.vat !== 0 && `${t("KDV")} `}
                                        {modalInfo.params.payment_detail.vat !== 0 && modalInfo.params.payment_detail.discount_rate !== 0 && "& "}
                                        {modalInfo.params.payment_detail.discount_rate !== 0 && `${t("İndirim")} `}
                                        {(modalInfo.params.payment_detail.vat !== 0 || modalInfo.params.payment_detail.discount_rate) !== 0 && `${t("Dahil")} `}
                                        {t("Toplam Fiyat")}
                                    </th>
                                    <th>
                                        {`${modalInfo.params.payment_detail.total_price} ₺`}
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        {t("KDV")}
                                    </th>
                                    <th>
                                        {`%${100 * modalInfo.params.payment_detail.vat}`}
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        {t("İndirim Oranı")}
                                    </th>
                                    <th>
                                        %{(100 * modalInfo.params.payment_detail.discount_rate).toFixed(2)}
                                    </th>
                                </tr>
                                {modalInfo.params.payment_detail.vat !== 0 &&
                                    <tr>
                                        <th>
                                            {t("KDV Hariç Toplam Fiyat")}
                                        </th>
                                        <th>
                                            {`${(modalInfo.params.payment_detail.total_price / (1 + modalInfo.params.payment_detail.vat)).toFixed(2)} ₺`}
                                        </th>
                                    </tr>
                                }

                                {transfer ?

                                    <>{modalInfo.params.payment_detail.completed ?
                                        <tr>
                                            <th>
                                                {t("Tamamlanma Tarihi")}
                                            </th>
                                            <td>
                                                {`${date_completed} ${time_completed}`}
                                            </td>
                                        </tr> : <></>}
                                        {modalInfo.params.status === "failed" ?
                                            <tr>
                                                <th>{t("İptal Mesajı")}</th>
                                                <td>{modalInfo.params.payment_detail.cancel_message}</td>
                                            </tr> : <></>}
                                    </>
                                    :
                                    <>
                                        <tr>
                                            <th>{t("Kart")}</th>
                                            <td>**** **** **** {modalInfo.params.payment_detail.card_last_four_no} ({modalInfo.params.payment_detail.card_type})</td>
                                        </tr>
                                        <tr>
                                            <th>{t("Ödeme Mesajı")}</th>
                                            <td>{modalInfo.params.payment_detail.message}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                    </Col>
                    <Col>
                        <h5>{t("Fatura Bilgisi")}</h5>
                        <table className='table table-sm'>
                            <tbody>
                                <tr>
                                    <th>
                                        {t("Fatura Tipi")}
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.type}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Ad")}
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.name ? modalInfo.params.invoice_details.name : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Soyad")}
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.surname ? modalInfo.params.invoice_details.surname : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("TCKN")}
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.tr_identity_number ? modalInfo.params.invoice_details.tr_identity_number : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Telefon")}
                                    </th>
                                    <td>
                                        {`+${modalInfo.params.invoice_details.telephone_country_code}${modalInfo.params.invoice_details.telephone}`}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Şirket Adı")}
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.company_name ? modalInfo.params.invoice_details.company_name : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        {t("Vergi No")}
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.taxno ? modalInfo.params.invoice_details.taxno : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>
                                        {t("Adres")}
                                    </th>
                                </tr>
                                {modalInfo.params.invoice_details.street &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.street}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.address_line1 &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.address_line1}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.address_line2 &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.address_line2}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.zipcode &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.zipcode}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.country &&
                                    <tr><td colSpan={2}>
                                        {`${iso31661_country_codes[modalInfo.params.invoice_details.country]} / ${iso31662_state_codes[modalInfo.params.invoice_details.province]} / ${modalInfo.params.invoice_details.county}`}
                                    </td></tr>
                                }

                                {modalInfo.params.invoice_details.invoice_url !== null ?
                                    <tr>
                                        <th colSpan={2}>
                                            <a href="#" onClick={() => handleDownloadInvoice(modalInfo.params.order_id)}>{t("Faturayı İndir")}</a>
                                        </th>
                                    </tr>

                                    : <></>}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </Modal.Body>
        </>
    }
    else {
        modal = <>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    hey
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{JSON.stringify(modalInfo.params)}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button>hey</Button>
            </Modal.Footer>
        </>
    }

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>

                {isLoading ? LoadingContainer :
                    <Container className='mt-4'>
                        <Row className='mb-4'>
                            <Col>
                                <Form.Select
                                    onChange={(e) => handlePurchasedChange(e.target.value)}
                                    value={selectedPurchased}
                                >
                                    <option value="1">{t("Satın alınan hizmeti seçin")}</option>
                                    <option value="project">{t("Proje")}</option>
                                    <option value="credits" disabled>{t("Kredi")}</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select
                                    onChange={(e) => handleMethodChange(e.target.value)}
                                    value={selectedMethod}
                                >
                                    <option value="1">{t("Ödeme yöntemini seçin")}</option>
                                    <option value="transfer">{t("Havale / EFT")}</option>
                                    <option value="credit_card">{t("Kredi / Banka Kartı")}</option>
                                </Form.Select>
                            </Col>
                        </Row>


                        <MaterialReactTable
                            columns={columns}
                            data={tableData}
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                    modalOnShow(`${selectedPurchased}-${selectedMethod}`, row.original)
                                },
                                sx: {
                                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                            })} />

                        <Modal
                            show={modalInfo.show}
                            onHide={() => modalOnHide()}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            animation={false}
                        >
                            {modal}
                        </Modal>

                    </Container>}

            </div>
            <Footer />
        </div>
    );
};

export default UsersPurchases;
