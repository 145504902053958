import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Footer from '../../components/Footer/Footer';
import styles from '../Pages.module.css';
import useLogin from '../../hooks/useLogin/useLogin';

import Toast from 'react-bootstrap/Toast';

import { useTranslation } from "react-i18next";
import { defaultLanguage } from '../../i18n';

const Login = () => {

    const {
        email,
        setEmail,
        password,
        setPassword,
        handleSubmit,

        errorToast,
        setErrorToast,

        showAgainToast,
        setShowAgainToast,

        showSignUpToast,
        setShowSignUpToast,
        isLoading,
    } = useLogin();

    const { i18n, t } = useTranslation();
    const langPrefix = i18n.language === defaultLanguage ? "" : `/${i18n.language}`;

    document.title = t("Giriş Yap")

    return (
        <div className={styles.flexWrapper}>
            <AppNavbar />

            <div className={styles.content}>
                <Container style={{ marginTop: '10vh' }}>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={4}>
                            <h3 className="text-center mt-5">{t("Giriş Yap")}</h3>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>{t("E-posta")}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formPassword" className="mb-3">
                                    <Form.Label>{t("Şifre")}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100 d-block mb-3"
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spinner size='sm'/> : t("Giriş Yap")}
                                </Button>
                                <Form.Text className="text-muted text-center">
                                    <a href={`${langPrefix}/forgot-password`}>{t("Şifremi unuttum")}</a>
                                </Form.Text>
                            </Form>
                        </Col>
                    </Row>
                </Container>


            </div>
            <Footer />
            <Toast
                onClose={() => setShowAgainToast(false)}
                show={showAgainToast}
                bg="warning"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Bilgi")}</strong>
                </Toast.Header>
                <Toast.Body>{t("Lütfen tekrar giriş yapın.")}</Toast.Body>
            </Toast>

            <Toast
                onClose={() => setErrorToast({show:false, message:""})}
                show={errorToast.show}
                bg="danger"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{errorToast.message}</Toast.Body>
            </Toast>

            <Toast
                onClose={() => setShowSignUpToast(false)}
                show={showSignUpToast}
                bg="success"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Bilgi")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{t("Kayıt olma başarılı.")}</Toast.Body>
            </Toast>
        </div>
    );
};

export default Login;
