import React from 'react';
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap';

import { Bs3Circle, BsCheckCircle } from 'react-icons/bs'

import styles from '../ProjectPurchase.module.css'

import { iso31661_country_codes, iso31662_state_codes } from '../../../other/variables';

import { useTranslation } from 'react-i18next';

const PurchaseEnd = (props) => {

    const id = props.id
    const setStep = props.setStep
    const projectName = props.projectName
    const projectTheoreticalWeight = props.projectTheoreticalWeight
    const unitPrice = props.unitPrice
    const totalPrice = props.totalPrice
    const paymentMethod = props.paymentMethod
    const invoiceInfo = props.invoiceInfo
    const orderLoading = props.orderLoading
    const handleSubmit = props.handleSubmit
    
    const { i18n, t } = useTranslation();

    const clientName = invoiceInfo.type === "real_person" ? `${invoiceInfo.data.name} ${invoiceInfo.data.surname}` : invoiceInfo.data.company_name
    const serviceDetail = <div><a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}/project-info/${id}`} target='_blank'>{projectName}</a> {t("isimli projenin kesim planı oluşturma hakkı")}</div>


    return (
        <>
            <Container className='text-center bg-white align-items-center mt-4 border'>
                <Row>
                    <Col>
                        <div className={`${styles.statusStep}`}>
                            <span className={`${styles.statusCircle} ${styles.completedCircle}  ${styles.clickable}`} onClick={() => setStep(1)}><BsCheckCircle /></span>
                            <span className={`${styles.statusLabel} ${styles.clickable}`} onClick={() => setStep(1)}>{t("Fatura Detayları")}</span>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.statusStep}`}>
                            <span className={`${styles.statusCircle} ${styles.completedCircle}  ${styles.clickable}`} onClick={() => setStep(2)}><BsCheckCircle /></span>
                            <span className={`${styles.statusLabel} ${styles.clickable}`} onClick={() => setStep(2)}>{t("Ödeme Bilgileri")}</span>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${styles.statusStep}`}>
                            <span className={`${styles.statusCircle}`}><Bs3Circle /></span>
                            <span className={styles.statusLabel}>{t("Siparişi Tamamla")}</span>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className='mt-4 bg-white border p-4'>
                <p className='fw-bold'>{t("Fatura ve ödeme bilgilerinizi gözden geçiriniz:")}</p>
                <Row>

                    <Col md={6}>
                        <h5>{t("Fatura Bilgileri")} &nbsp;<a href="#" style={{ fontSize: "10pt", fontWeight: "bold" }} onClick={() => setStep(1)}>{t("Düzenle")}</a></h5>
                        <table className='table table-borderless'>
                            <tbody>
                                <tr>
                                    <th>{t("Ad-Soyad/Unvan")}</th>
                                    <td>{clientName}</td>
                                </tr>
                                <tr>
                                    <th>{t("Adres")}</th>
                                    <td>{iso31661_country_codes[invoiceInfo.data.country]}</td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{iso31662_state_codes[invoiceInfo.data.province]} - {invoiceInfo.data.county}</td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{invoiceInfo.data.street} - {invoiceInfo.data.zipcode}</td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td>{invoiceInfo.data.address_line1}</td>
                                </tr>
                                {
                                    invoiceInfo.data.address_line2 ? <tr>
                                        <th></th>
                                        <td>{invoiceInfo.data.address_line2}</td>
                                    </tr> : <></>
                                }
                                {invoiceInfo.type === "real_person" ?
                                    invoiceInfo.data.tr_identity_number ? <tr>
                                        <th>{t("TCKN")}</th>
                                        <td>{invoiceInfo.data.tr_identity_number}</td>
                                    </tr> : <></> :
                                    invoiceInfo.data.taxno ? <tr>
                                        <th>{t("VKN")}</th>
                                        <td>{invoiceInfo.data.taxno}</td>
                                    </tr> : <></>}
                                <tr>
                                    <th>{t("E-posta Adresi")}</th>
                                    <td>{invoiceInfo.data.email}</td>
                                </tr>
                                {invoiceInfo.data.telephone ?
                                    <tr>
                                        <th>{t("Telefon")}</th>
                                        <td>+{invoiceInfo.data.telephone_country_code}{invoiceInfo.data.telephone}</td>
                                    </tr> : <></>}
                            </tbody>
                        </table>
                    </Col>
                    <Col md={6}>
                        <h5>{t("Ödeme Bilgileri")} &nbsp;<a href="#" style={{ fontSize: "10pt", fontWeight: "bold" }} onClick={() => setStep(2)}>{t("Düzenle")}</a></h5>
                        <table className='table table-borderless'>
                            <tbody>
                                <tr>
                                    <th>{t("Ödenecek Tutar (KDV Dahil)")}</th>
                                    <td>₺{totalPrice}</td>
                                </tr>
                                <tr>
                                    <th>{t("KDV Oranı")}</th>
                                    <td>%{100 * props.vat}</td>
                                </tr>
                                <tr>
                                    <th>{t("Ödeme Yöntemi")}</th>
                                    <td>{paymentMethod === 1 ? t("Havale / EFT") : t("Kredi / Banka Kartı")}</td>
                                </tr>
                                {paymentMethod !== 1 &&
                                    <tr>
                                        <th></th>
                                        <td>**** **** **** {props.cardNumber.substr(id.length - 6)}<img src={props.cardTypeLogo} className="ms-2" width="35px"></img></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </Col>

                </Row>
                <div className='text-center'>
                    <Button size="lg" variant='success' style={{ width: "45%" }} disabled={orderLoading} onClick={() => { handleSubmit() }}>
                        {orderLoading ? <Spinner size='sm' /> : t("SİPARİŞİ TAMAMLA")}
                    </Button>
                </div>
            </Container>

        </>

    );
};

export default PurchaseEnd;
