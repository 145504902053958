import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ThreeDPage = () => {
    const { state } = useLocation();

    if (state === null) {
        return <div></div>
    }
    const { htmlContent } = state

    document.title = "3D Secure"

    return (
        <div>
            <iframe
                title="3D Secure"
                id='3d_iframe'
                srcDoc={htmlContent}
                style={{ width: '100vw', height: '80vh', overflow: "hidden" }}
            />
        </div>)
}

export default ThreeDPage;