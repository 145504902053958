import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

const AdminAuth = ({ props }) => {

    const {
        pageLoading,
        email,
        password,
        setPassword,
        handlePasswordSubmitted,
        passwordLoading,
        passwordNote,
        passwordCorrect,
        authCode,
        setAuthCode,
        handleCodeSubmitted,
        codeLoading,
        codeNote,
        codeFinished,
        authorized,
    } = props


    return (
        pageLoading ? <></> :
            <Container style={{ marginTop: '20vh' }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={4}>
                        <h4 className="text-center mt-5">Şifrenizi Girin</h4>
                        <Form onSubmit={(e)=>{e.preventDefault()}}>
                            <Form.Group controlId="formPassword" className="mb-3">
                                <Form.Label>Şifre</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={password}
                                    disabled={passwordCorrect}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>
                            {passwordCorrect ? <></> : <p className='text-danger'>{passwordNote}</p>}
                            {passwordCorrect ?
                                <Form.Group controlId="formCode" className="mb-3">
                                    <Form.Label>{email} adresine gönderilen kodu girin:</Form.Label>
                                    <Form.Control
                                        value={authCode}
                                        onChange={(e) => setAuthCode(e.target.value)}
                                    />
                                </Form.Group> : <></>}
                            <p className='text-danger'>{codeNote}</p>
                            {passwordCorrect ? <Button
                                variant="primary"
                                className="w-100 d-block mb-3"
                                disabled={codeFinished || codeLoading}
                                onClick={() => handleCodeSubmitted(authCode)}
                            >
                                {codeLoading ? <Spinner className='spinner-border-sm' /> : "Gönder"}
                            </Button> : <Button
                                variant="primary"
                                className="w-100 d-block mb-3"
                                disabled={passwordLoading}
                                onClick={() => handlePasswordSubmitted()}
                            >
                                {passwordLoading ? <Spinner className='spinner-border-sm' /> : "Gönder"}
                            </Button>
                            }
                        </Form>
                    </Col>
                </Row>
            </Container>
    );
};

export default AdminAuth;
