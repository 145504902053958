import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

const PrivacyPolicyEn = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
    const homeURL = process.env.REACT_APP_HOME_URL

    document.title = "Privacy Policy"

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>Privacy Policy</h2>


                    <h5 className='mb-4 fw-bold'>1. Introduction</h5>
                    <p><span className='fw-bold'>Teber Soft Yazılım İnşaat Sanayi ve Ticaret LTD. ŞTİ.</span> ("Company") values your privacy and the security of your data. Your personal data is stored and processed in accordance with the Personal Data Protection Law No. 6698 (KVKK). </p>
                    <p>In this Privacy Policy, you can find information about how and for what purposes we collect your data.</p>
                    <p>The Company reserves the right to make changes to this Privacy Policy. Users are advised to review this text regularly.</p>
                    <p>This Privacy Policy only applies to <a href={`${homeURL}/en`}>{homeURL}</a> ("Application"). Other websites that can be accessed from the Application are not covered by this Privacy Policy. </p>
                    <p>If you do not agree to this Privacy Policy, you should not continue using the Application.</p>


                    <h5 className='my-4 fw-bold'>2. Collected Personal Data</h5>
                    <p>We collect and store the following data while you use the Application:</p>
                    <ul>
                        <li className='mb-1'>Your email address provided when you register for the Application</li>
                        <li className='mb-1'>Your project files that are uploaded to create a project within the Application, generated by third-party construction programs, or created by you</li>
                        <li className='mb-1'>Your name, surname, phone number, address, company name, tax office information, if you wish, for the purpose of automatically filling the invoice information in the in-app purchases</li>
                        <li className='mb-1'>Your IP address, browser, device, visit date and time information (not to be stored for more than 2 years) when you visit the Application</li>
                    </ul>
                    <p>Apart from this data, your credit or bank card information that you provide while purchasing our services is not stored in our system.</p>


                    <h5 className='my-4 fw-bold'>3. Use of Personal Data</h5>
                    <p>We store and process your personal data for the following purposes while you use the Application:</p>
                    <ul>
                        <li className='mb-1'>To perform the membership transactions required for you to use the Application</li>
                        <li className='mb-1'>To provide notifications in cases such as signing up for the Application, email address verification, password change, and to send you emails about updates, new offers and services in the Application</li>
                        <li className='mb-1'>To fulfill our obligations arising from the Law No. 5651 on the Regulation of Publications Made in the Internet Environment and the Fight Against Crimes Committed Through These Publications</li>
                    </ul>

                    <h5 className='my-4 fw-bold'>4. Cookies</h5>
                    <p>We use cookies to improve your experience while using the Application. You can block cookies from your browser settings, but your Application experience may be affected.</p>

                    <h5 className='my-4 fw-bold'>5. Age Limit</h5>
                    <p>The Application is designed for users over the age of 18. If you are not 18 years old, you should not use the Application. If we notice that we have stored personal data belonging to children under the age of 18, we will delete this data.</p>


                    <h5 className='my-4 fw-bold'>6. Your Rights Regarding Your Data</h5>
                    <p>You can use the following rights granted to you by the Turkish Personal Data Protection Law No. 6698 by contacting us at any time:</p>
                    <ul>
                        <li className='mb-1'>The right to learn whether your personal data is being processed or not;</li>
                        <li className='mb-1'>The right to request information and a copy of the relevant data when your personal data is being processed;</li>
                        <li className='mb-1'>The right to determine the processing purpose and whether the data is used in accordance with the purpose;</li>
                        <li className='mb-1'>The right to know to whom your personal data is transferred domestically or abroad;</li>
                        <li className='mb-1'>The right to request rectification of your personal data if it is inaccurate or incomplete;</li>
                        <li className='mb-1'>The right to request the erasure or destruction of your personal data;</li>
                        <li className='mb-1'>The right to request the notification of the rectification, erasure or destruction of your personal data to the third parties to whom it has been transferred;</li>
                        <li className='mb-1'>The right to request compensation for any damage arising from the unlawful processing of your personal data;</li>
                        <li className='mb-1'>The right to request the restriction of the processing of your personal data;</li>
                        <li className='mb-1'>The right to request a copy of your personal data processed electronically and to have this data transferred to another person;</li>
                        <li className='mb-1'>The right to object to the processing of your personal data at any time.</li>
                    </ul>

                    <h5 className='my-4 fw-bold'>7. Contact</h5>
                    <p>You can contact us for any questions and suggestions about the Application and this Privacy Policy. </p>
                    <p><span className='fw-bold'>Our email address:</span> <a href={`mailto:${contactEmail}`}>{contactEmail}</a></p>
                    <p className='mb-1'><span className='fw-bold'>Our postal address:</span> Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi </p>
                    <p>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3  Şahinbey / Gaziantep</p>
                    <p>Last Updated Date: July 21, 2023</p>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyEn;