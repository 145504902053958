import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import styles from './Verification.module.css';

import { FaCheckCircle } from 'react-icons/fa'; // use react-icons for this
import { MdEmail } from 'react-icons/md'; // use react-icons for this
import { Container } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const BeforeVerification = () => {

    const { t } = useTranslation();

    document.title = t("E-posta Doğrulama")

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>

                <Container className={`bg-white mt-5 p-5 shadow-sm ${styles.verifyContainer}`}>
                    <MdEmail size={70} color="gray" className={styles.checkIcon} />
                    <h2 className={styles.title}>{t("E-posta Doğrulama Gerekiyor")}</h2>
                    <p className={styles.message}>{t("E-posta adresinize doğrulama maili gönderilmiştir. Gelen kutunuzu kontrol ediniz. Gelen kutunuzda doğrulama mailini göremezseniz")} <span className='fw-bold'>{t("spam kutunuzu kontrol etmeyi unutmayınız.")}</span></p>
                </Container>

            </div>
            <Footer />
        </div>
    );
};

export default BeforeVerification;
