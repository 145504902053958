import apiClient from "./apiClient";

export const deleteCp = async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/api/delete-cp/${id}`;

    const response = await apiClient(url, {
        method: 'DELETE',
    })

    return response;

}