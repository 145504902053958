import React, { useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import { MaterialReactTable, useMateraialReactTable } from 'material-react-table'
import useLoginAttempts from '../../../hooks/admin/useLoginAttempts'
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';

const LoginAttempts = () => {

    const {
        isLoading,
        tableData,
        pagination,
        setPagination,
        totalAttempts
    } = useLoginAttempts()

    const columns = useMemo(
        () => [
            {
                accessorKey: 'user', //access nested data with dot notation
                header: 'UID',
                size: 50,
            },
            {
                accessorKey: 'email', //access nested data with dot notation
                header: 'Email',
                size: 150,
            },
            {
                accessorKey: 'ip_address',
                header: 'IP Adress',
                size: 150,
            },
            {
                header: 'Şifre Doğru',
                size: 100,
                accessorFn : (row) => {
                    return row.password_true ? "Evet" : "Hayır"
                }
            },
            {
                header: 'Giriş Başarılı',
                size: 100,
                accessorFn: (row) => {
                    return row.status ? "Evet" : "Hayır"
                }
            },
            {
                header: 'Time',
                size: 200,
                accessorFn: (row) => {
                    return new Date(row.time).toLocaleString("tr")
                }
            },
        ],
        []
    )

    return (
        <Container>
            {isLoading ? LoadingContainer
                :
                <MaterialReactTable
                    columns={columns}
                    data={tableData}
                    manualPagination={true} //enable manual pagination
                    onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                    state={{ pagination }} //pass the pagination state to the table
                    rowCount={totalAttempts} //total items for the pagination
                    enablePagination={true} //enable pagination
                />}
        </Container>
    );
};

export default LoginAttempts;
