import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import {
    inactiveCpTotalWeightInfo,
    inactiveCpOutageWeightInfo,
    inactiveCpWasteWeightInfo,
    inactiveCpSubWeightInfo,
    inactiveCpYieldPercentageInfo,
} from '../../../infoContents';
import InfoNote from '../../../components/InfoNote/InfoNote';

import { useTranslation } from 'react-i18next';

const InactiveCpInfo = ({
    result,
    projectUnitPrice,
    unitDiscount,
    purchasable,
    ownCp,
}) => {

    const cpId = result.cpId
    const projectId = result.projectId
    const projectName = result.projectName
    const minOutageLength = result.minOutageLength
    // Inaktif projelere ait kesim planlarinda asagidaki bilgileri
    // gostermemeye karar verdik:

    // const allLogWeight = result.summary.allLogWeight
    // const allOutageWeight = result.summary.allOutageWeight
    // const allWastedWeight = result.summary.allWastedWeight
    // const allSubWeight = result.summary.allSubWeight
    // const percentYield = result.summary.percentYield
    const useSubstitutes = result.useSubstitutes
    const diameters = result.diameters
    const floors = result.floors
    const projectTheoreticalWeight = result.projectTheoreticalWeight

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    const { i18n, t } = useTranslation();

    return (
        <>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <Container>
                {/* <CpInfoHeader
                    projectName={projectName}
                    projectId={projectId}
                    cpName={cpName}
                    userType={userType}
                    activated={false}
                    backgroundTask={false}
                    ownCp={ownCp}
                    owner={owner}
                    result={result}
                /> */}

                <Row>
                    <Col md={4}>
                        <table className="table table-striped-columns border my-3 align-middle bg-white">
                            <tbody>
                                <tr>
                                    <th style={{ width: "75%" }}>{t("Kesim Planı Numarası")}</th>
                                    <td style={{ width: "25%" }}>{cpId}</td>
                                </tr>
                                <tr>
                                    <th>{t("Elde kalacak en kısa fire")}</th>
                                    <td>{minOutageLength}</td>
                                </tr>
                                <tr>
                                    <th>{t("Yerine kullanım")}</th>
                                    <td>{useSubstitutes ? t('Evet') : t('Hayır')}</td>
                                </tr>
                            </tbody>
                        </table>

                    </Col>
                    <Col md={8}>
                        <table className="table table-striped-columns border my-3 align-middle bg-white">
                            <tbody>
                                <tr>
                                    <th style={{ width: "45%" }}>{t("Toplam Ağırlık (kg)")}&nbsp;
                                        <InfoNote
                                            content={inactiveCpTotalWeightInfo[i18n.language]}
                                            setDialogContent={setDialogContent}
                                            setDialogOpen={setDialogOpen}
                                        /></th>
                                    <td style={{ width: "65%" }} rowSpan={5} className='text-center'>{t("Bu istatistikleri ve kat-çap bazında diğer detaylı istatistikleri öğrenmek için ödeme yapınız.")}</td>
                                </tr>
                                <tr>
                                    <th>{t("Elde Kalan Fire Ağırlığı (kg)")}&nbsp;
                                        <InfoNote
                                            content={inactiveCpOutageWeightInfo[i18n.language]}
                                            setDialogContent={setDialogContent}
                                            setDialogOpen={setDialogOpen}
                                        /></th>
                                </tr>
                                <tr>
                                    <th>{t("Teorik Fire Ağırlığı (kg)")}&nbsp;
                                        <InfoNote
                                            content={inactiveCpWasteWeightInfo[i18n.language]}
                                            setDialogContent={setDialogContent}
                                            setDialogOpen={setDialogOpen}
                                        /></th>
                                </tr>
                                <tr>
                                    <th>{t("Yerine Kullanım Ağırlığı (kg)")}&nbsp;
                                        <InfoNote
                                            content={inactiveCpSubWeightInfo[i18n.language]}
                                            setDialogContent={setDialogContent}
                                            setDialogOpen={setDialogOpen}
                                        /></th>
                                </tr>
                                <tr>
                                    <th>{t("Verim Yüzdesi")}&nbsp;
                                        <InfoNote
                                            content={inactiveCpYieldPercentageInfo[i18n.language]}
                                            setDialogContent={setDialogContent}
                                            setDialogOpen={setDialogOpen}
                                        /></th>
                                </tr>
                                <tr>
                                    <th>{t("Çözüme dahil olan çaplar")}</th>
                                    <td>{diameters.map((d, i) => {
                                        if (diameters.indexOf(d) === diameters.length - 1) {
                                            return <span key={i}>Φ{d}</span>
                                        } else {
                                            return <span key={i}>Φ{d}, </span>
                                        }
                                    })}</td>
                                </tr>
                                    <tr>
                                        <th>{t("Çözüme dahil olan katlar")}</th>
                                        <td>{floors.map((d, i) => {
                                            if (floors.indexOf(d) === floors.length - 1) {
                                                return <span key={i}>{d}</span>
                                            } else {
                                                return <span key={i}>{d}, </span>
                                            }
                                        })}</td>
                                    </tr>
                            </tbody>
                        </table>

                    </Col>
                    {ownCp ?
                        purchasable ?
                            <Col md={{ offset: 1, span: 10 }}>
                                <Container className='text-center border p-2 mb-3 bg-white'>
                                    <p>{t("Bu, kesim planının özetidir. Detaylı kesim planını elde etmek için bu proje için kesim planı oluşturma hakkını satın almanız gerekmektedir.")}</p>
                                    <p><span className='fw-bold text-danger'>{t("NOT")}:</span> {t("Satın alım işlemini sadece bu kesim planı için değil, kesim planının ait olduğu projenin bütün kesim planları için yapmış olacaksınız. Ödemenizin onaylanmasının ardından bu kesim planıyla birlikte")} <a href={`/${i18n.language}/project-info/${projectId}`}>{projectName}</a> {t("isimli projenizde oluşturduğunuz ve oluşturacağınız bütün kesim planlarına erişebileceksiniz.")} </p>
                                </Container>
                            </Col>
                            :
                            <Col md={{ offset: 1, span: 10 }}>
                                <Container className='text-center border p-2 mb-3 bg-white'>
                                    {t("Bu, kesim planının özetidir. Detaylı kesim planını elde etmek için bu proje için kesim planı oluşturma hakkını satın almanız gerekmektedir. Daha önceden bu proje için oluşturmuş olduğunuz")} <a href={`/${i18n.language}/project-purchase/${projectId}`}>{t("siparişinizin")}</a> {t("onaylanmasını bekleyiniz.")}
                                </Container>
                            </Col>
                        : <></>
                    }
                </Row>

                {purchasable ?

                    <Row className='text-center'>
                        <Col md={{ offset: 3, span: 6 }}>
                            <Button style={{ backgroundColor: '#318CE7' }} className='p-3 border' href={`/${i18n.language}/project-purchase/${projectId}`}>
                                <h5>{t("inactivecpinfo_paymentmsg1")} ({projectName}) {t("inactivecpinfo_paymentmsg2")}</h5>
                                <div>
                                    {unitDiscount !== 0 ?
                                        <>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <s style={{ fontSize: "17pt", fontWeight: "400", alignItems: "center" }} >{Math.floor(projectTheoreticalWeight * (unitDiscount + projectUnitPrice) * 1e-3)}₺</s>
                                                <s style={{ fontSize: "6pt", marginLeft: "10px" }}> ({(unitDiscount + projectUnitPrice)} ₺/ton)</s>
                                                <span className='ms-2'>{t("inactive_yerine")}</span>
                                            </div>
                                        </>
                                        :
                                        <></>}
                                    <div className='mb-2'>
                                        <span style={{ fontSize: "32pt", fontWeight: "600" }}>{Math.floor(projectTheoreticalWeight * projectUnitPrice * 1e-3)}₺</span>
                                        <div style={{ fontSize: "9pt" }}> ({(projectTheoreticalWeight / 1000).toFixed(3)} ton &times; {projectUnitPrice} ₺/ton)</div>
                                    </div>
                                </div>

                                <p>{t("Ödeme yaptıktan sonra bu proje için istediğiniz kadar kesim planı oluşturabilir ve detaylarını elde edebilirsiniz.")}</p>
                            </Button>
                        </Col>

                        {/* <Col md={{ span: 5 }}>
                        <Button style={{ backgroundColor: '#0073ba', height:"230px" }} className='p-3 border'>
                            <h5>Kredi satın al</h5>
                            <h3>Fiyat bilgisi için tıklayınız</h3>
                            <p>Birim ağırlık başına daha az ücret ödeyerek toplu optimizasyon hakkı (kredi) satın alabilir, kredilerinizi gelecek projelerinizde de kullanabilirsiniz. Detaylı bilgi için tıklayınız.</p>
                        </Button>
                    </Col> */}
                    </Row>

                    : <></>}

            </Container>
        </>
    )
}

export default InactiveCpInfo;
