import React, { useState, }  from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import Pattern from '../../../../components/Pattern/Pattern';

import { useTranslation } from 'react-i18next';

const Patterns = (props) => {

    const diameter = props.diameter
    const patterns = props.currentPatterns
    const showedPatterns = props.showedPatterns
    const floorAvailable = props.floorAvailable
    const donatiDict = props.donatiDict
    const allFloors = props.allFloors
    const checkedFloors = props.checkedFloors
    const bindirmeOrani = props.bindirmeOrani
    const handleShowLowerFloorPatterns = props.handleShowLowerFloorPatterns
    const showLowerFloorCheck = props.showLowerFloorCheck
    const version = props.version

    let ratio = 1.2

    const [lastShowLowerFloorPatterns, setLastShowLowerFloorPatterns] = useState(true)

    const { t } = useTranslation();

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            {showLowerFloorCheck && floorAvailable ?
                <div className='d-flex justify-content-end mb-2'>
                    <Form>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label={t("Alt katlara ait kesimleri de göster.")}
                            defaultChecked={lastShowLowerFloorPatterns}
                            onChange={(e) => {
                                handleShowLowerFloorPatterns(e.target.checked);
                                setLastShowLowerFloorPatterns(e.target.checked);
                            }}
                        />
                    </Form>
                </div>
                : <></>}
            {showedPatterns.map((pattern, i) => (
                <Pattern
                    key={i}
                    diameter={diameter}
                    floorAvailable={floorAvailable}
                    pattern={pattern}
                    allFloors={props.allFloors}
                    donatiDict={donatiDict}
                    checkedFloors={checkedFloors}
                    bindirmeOrani={bindirmeOrani}
                    ratio={ratio}
                    version={version}
                />
            ))}
        </Container>
    )
}

export default Patterns;