import { useState } from 'react';
import { signUpUser } from '../../services/signUpService';
import { checkIfUserActive } from '../../services/isUserActiveService';
import { useTranslation } from 'react-i18next';

const useSignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [delegateCode, setDelegateCode] = useState("")

    const [errorToast, setErrorToast] = useState({ show: false, message: "" });

    const { i18n, t } = useTranslation();

    const validateEmail = (email) => {
        // Regular expression to check if email is valid
        const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return regex.test(email);
    };

    const validatePassword = (password) => {
        // Minimum 6 characters, at least one letter and one number
        const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
        return regex.test(password);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true)

        let isPasswordMismatch = false;
        let isInvalidEmail = false;
        let isInvalidPassword = false;

        if (password !== passwordConfirm) {
            isPasswordMismatch = true;
        }
        if (!validateEmail(email)) {
            isInvalidEmail = true;
        }
        if (!validatePassword(password)) {
            isInvalidPassword = true;
        }

        setPasswordMismatch(isPasswordMismatch);
        setInvalidEmail(isInvalidEmail);
        setInvalidPassword(isInvalidPassword);

        if (!isPasswordMismatch && !isInvalidEmail && !isInvalidPassword) {
            try {
                // check if such a user exists already:
                const userExistsResponse = await checkIfUserActive(email)
                if (userExistsResponse.ok) {
                    setIsLoading(false)
                    setErrorToast({ show: true, message: t("Bu e-posta ile kayıtlı kullanıcı mevcut.") });
                }
                else {
                    try {
                        const response = await signUpUser(email, password, delegateCode);
                        if (response.ok) {
                            window.location = `/${i18n.language}/verify-your-email`;
                        }
                        else {
                            setIsLoading(false)
                            const result = await response.json()
                            const errorMessage = result.username || result.password || result.password1 || result.password2 || result.email || result.non_field_errors || result.detail
                            setErrorToast({ show: true, message: errorMessage });
                        }
                    }
                    catch (error){
                        setIsLoading(false)
                        setErrorToast({ show: true, message: `${t("Sunucu Hatası. Lütfen sistem yöneticisi ile iletişime geçin.")} (${t("Hata Kodu")}: 3G8K)` });
                    }
                }
            } catch (error) {
                setIsLoading(false)
                setErrorToast({ show: true, message: `${t("Sunucu Hatası. Lütfen sistem yöneticisi ile iletişime geçin.")} (${t("Hata Kodu")}: Y6ET)` });
            }
        }
        else{
            setIsLoading(false)
        }
    };

    return {
        handleSubmit,
        email,
        setEmail,
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,
        delegateCode,
        setDelegateCode,
        invalidEmail,
        invalidPassword,
        passwordMismatch,
        errorToast,
        setErrorToast,
        isLoading,
    }

}

export default useSignUp;