import React from 'react';
import Container from 'react-bootstrap/Container';

const Landing = () => {

    return (
        <Container>
            <p>Admin Paneline Hoş Geldiniz.</p>
            <p>Lütfen işlemlerinizi bitirdikten sonra çıkış yapmayı unutmayınız!</p>
        </Container>
    );
};

export default Landing;
