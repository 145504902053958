import apiClient from "./apiClient";

export const getSingleUnitPrice = async (type) => {
    const url = `${process.env.REACT_APP_API_URL}/api/unit-price/?type=${type}`;

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}