import React from 'react';
import { BiChevronRight, } from 'react-icons/bi';

const rebarLengthInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Kesim yapılan demir uzunluğu:</span> Donatıların kesileceği demirin santimetre cinsinden uzunluğu. Nervürlü demirler piyasada standart olarak 12 metre uzunluğunda satılmaktadır. Eğer standart olarak üretilmiş daha uzun veya kısa demirlerden kesim yapmak istiyorsanız bunu ayarlayabilirsiniz.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Cutting length of the iron:</span> The length of the rebar to be cut in centimeters. Ribbed bars are commonly sold in the market with a standard length of 12 meters. If you want to cut rebar from longer or shorter bars that are produced as standard, you can adjust this value.</p>
    </div>
}

const overlapFactorInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Bindirme Katsayısı:</span> Yüklenen proje dosyası, kesim yapılan demir uzunluğundan daha uzun donatılar içeriyorsa, bu demirler belli bir bindirme boyu eklenerek parçalanmaktadır. Bindirme boyu donatı çapı, sınıfı ve beton kalitesine göre değişiklik göstermektedir. Uygulamada pratiklik olması için güvenilir tarafta kalma gerekliliği de hesaba katılarak, S420 çeliği için C25 ve üzeri beton sınıflarında yeterli bindirme mesafesini veren <span style={{ fontWeight: "bold" }}>40 &times; Φ mm</span> değeri kullanılmıştır. Yine de bindirme boyunun yetersiz kalacağı durumlar için bir <span style={{ fontWeight: "bold" }}>k</span> katsayısıyla çarpılarak bu mesafe arttırılabilir. Bindirme boyu bu uygulamada aşağıdaki formüle göre hesaplanmaktadır:</p>
        <p className="text-center"> Bindirme Boyu (cm) &#x3d; 4 &times; Φ &times; k</p>
        <p> Bu formülde <span style={{ fontWeight: "bold" }}>Φ</span>, demirin milimetre cinsinden çapı; <span style={{ fontWeight: "bold" }}>k</span> ise burada girilen <span style={{ fontStyle: "italic" }}>bindirme boyu katsayısı</span>dır. Bindirme katsayısı 0 ile 5 arasında bir ondalık sayıdır. <span style={{ fontWeight: "bold" }}>Manşon kullanılacaksa bindirme katsayısının 0 girilmesi gerekir.</span> Formülde çıkan sonuç yukarı yuvarlanır. </p>
        <p>Örneğin, standart 1200 cm boydan kesilen 14 mm çapında ve 1270 cm uzunluğunda bir donatı için bindirme katsayısı 1.2 alındığında programda bindirme boyu şöyle hesaplanır: </p>
        <p className="text-center"> L &#x3d; 4 &times; 14 &times; 1.2 &#x3d; 67.2 </p>
        <p>Çıkan sonuç yukarı yuvarlanır ve bindirme boyu <span style={{ fontWeight: "bold" }}>68</span> cm olarak elde edilir. Dolayısıyla 1270 cm'lik donatı 1200 cm ve 70 + 68 = 138 cm olacak şekilde iki donatıya ayrılır.</p>
        <p>Parçalanma sonucu oluşan donatı sayısı arttıkça son parçaya bir bindirme boyu daha eklenir. Örneğin 14 mm çapındaki 2540 cm'lik bir donatı 2 adet 1200 cm'lik ve 1 adet 140 + <span style={{ fontWeight: "bold" }}>2</span> &times; L cm'lik parçalara bölünür. L yukarıdaki gibi hesaplanır. Dolayısıyla 2540 cm'lik donatı 2 adet 1200 ve 1 adet 70 + 2 &times; 68 = 206 cm'lik parçalara bölünür.</p>
    </div>,

    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Overlap Factor:</span> If the loaded project file contains rebars longer than the cutting length, these rebars are divided by adding a certain overlap length. The overlap length varies depending on the rebar diameter, class, and concrete quality. For practicality in the application and to ensure reliability, a value of <span style={{ fontWeight: "bold" }}>40 &times; Φ mm</span> is used, which provides sufficient overlap distance for S420 steel in C25 and higher concrete classes. However, this distance can be increased by multiplying it with a factor <span style={{ fontWeight: "bold" }}>k</span> in case the overlap length is insufficient. The overlap length is calculated in this application using the following formula:</p>
        <p className="text-center"> Overlap Length (cm) &#x3d; 4 &times; Φ &times; k</p>
        <p> In this formula, <span style={{ fontWeight: "bold" }}>Φ</span> represents the diameter of the rebar in millimeters, and <span style={{ fontWeight: "bold" }}>k</span> is the <span style={{ fontStyle: "italic" }}>overlap factor</span> entered here. The overlap factor is a decimal number between 0 and 5. <span style={{ fontWeight: "bold" }}>If a coupler is used, the overlap factor should be set to 0.</span> The result obtained from the formula is rounded up. </p>
        <p>For example, for a 14 mm diameter rebar cut from a standard 1200 cm length and having a length of 1270 cm, with an overlap factor of 1.2, the overlap length is calculated as follows in the program: </p>
        <p className="text-center"> L &#x3d; 4 &times; 14 &times; 1.2 &#x3d; 67.2 </p>
        <p>The result is rounded up, and the overlap length is obtained as <span style={{ fontWeight: "bold" }}>68</span> cm. Therefore, the 1270 cm rebar is divided into two rebars of 1200 cm and 70 + 68 = 138 cm. </p>
        <p>As the number of rebars resulting from the division increases, an additional overlap length is added to the last piece. For example, a 14 mm diameter rebar with a length of 2540 cm is divided into 2 pieces of 1200 cm and 1 piece of 140 + <span style={{ fontWeight: "bold" }}>2</span> &times; L cm. L is calculated as described above. Therefore, the 2540 cm rebar is divided into 2 pieces of 1200 cm and 1 piece of 70 + 2 &times; 68 = 206 cm. </p>
    </div>
}

const theoreticalWeightInfo = {
    "tr": <div style={{ color: "black" }}>
        <p>Yerleşim planında seçilen donatıların hiç fire verilmeden kesilebilmesi durumundaki ağırlıklarıdır. Ağırlıklar her bir çap için ayrı ayrı hesaplanır. İnce (Φ8, Φ10, Φ12) ve kalın (Φ14 ve üzeri) donatı toplamları ile bütün çapların ağırlıkları toplamı da hesaplanır.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p>The weights of the selected rebars in the placement plan, if they are cut without any waste. The weights are calculated separately for each diameter. The total weights of thin (Φ8, Φ10, Φ12) and thick (Φ14 and above) rebars are also calculated.</p>
    </div>
}

const rebarTableInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> Yüklenen proje dosyasındaki donatı grupları bu tabloda incelenebilir. Tablodaki sütunlar: </p>
        <p><span style={{ fontWeight: "bold" }}>Donatı Kodu:</span> Program tarafından aynı özelliklere sahip her donatı grubu için bir donatı kodu üretilir. Örneğin 12-583-34 koduna sahip bir donatı 12 mm çapında ve 583 cm uzunluğundaki 34. donatı grubunun kodudur. Bu donatı koduna sahip donatılar, yerleşim planında aynı yere aittir ve aynı işlevi görmektedir.</p>
        <p><span style={{ fontWeight: "bold" }}>Adet:</span> Donatı adedi</p>
        <p><span style={{ fontWeight: "bold" }}>Uzunluk:</span> Donatı uzunluğu</p>
        <p><span style={{ fontWeight: "bold" }}>Çap:</span> Donatı çapı (Φ)</p>
        <p><span style={{ fontWeight: "bold" }}>Poz:</span> Metraj dosyasında verilen poz numarası. Dosyada poz numarası yoksa donatılar dosyadaki sırasıyla 1'den başlayarak numaralandırılır.</p>
        <p><span style={{ fontWeight: "bold" }}>Kat:</span> Donatı grubunun bulunduğu kat</p>
        <p><span style={{ fontWeight: "bold" }}>Eleman:</span> Donatı grubunun bulunduğu yapı elemanı</p>
        <p><span style={{ fontWeight: "bold" }}>Eleman kodu:</span> Donatı grubunun bulunduğu yapı elemanının kodu</p>
        <p><span style={{ fontWeight: "bold" }}>Açıklama:</span> Donatı grubunun kullanım amacı</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> The rebar groups in the loaded project file can be examined in this table. The columns in the table are: </p>
        <p><span style={{ fontWeight: "bold" }}>Rebar Code:</span> A rebar code is generated by the program for each rebar group with the same properties. For example, a rebar with the code 12-583-34 is the code of the 34th rebar group with a diameter of 12 mm and a length of 583 cm. Rebars with this rebar code belong to the same location in the placement plan and serve the same function.</p>
        <p><span style={{ fontWeight: "bold" }}>Quantity:</span> Number of rebars</p>
        <p><span style={{ fontWeight: "bold" }}>Length:</span> Length of the rebar</p>
        <p><span style={{ fontWeight: "bold" }}>Diameter:</span> Diameter of the rebar (Φ)</p>
        <p><span style={{ fontWeight: "bold" }}>Poz:</span> The position number given in the meter file. If there is no position number in the file, the rebars are numbered starting from 1 in the file.</p>
        <p><span style={{ fontWeight: "bold" }}>Floor:</span> The floor where the rebar group is located</p>
        <p><span style={{ fontWeight: "bold" }}>Element:</span> The building element where the rebar group is located</p>
        <p><span style={{ fontWeight: "bold" }}>Element code:</span> The code of the building element where the rebar group is located</p>
        <p><span style={{ fontWeight: "bold" }}>Description:</span> The purpose of the rebar group</p>
    </div>
}

const placementTableInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> Yüklenen projedeki yapının katlara ve elemanlara göre yerleşim planı. Donatılar ve ağırlıkları hakkında bilgi alınmak istenen yapı birimi buradan seçilerek, ilgili bilgiler yandaki tablolardan incelenebilir. Yapı elemanı biriminin yanındaki küçük <BiChevronRight style={{ fontSize: "20pt", border: "1px solid black" }} /> butonuna basılarak daha alt yapı elemanı birimlerine erişilebilir.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> Placement plan of the structure in the loaded project according to floors and elements. The relevant information can be examined from the tables on the side by selecting the building unit for which information about rebars and weights is desired. By pressing the small <BiChevronRight style={{ fontSize: "20pt", border: "1px solid black" }} /> button next to the building element unit, lower building element units can be accessed.</p>
    </div>
}

const longRebarTableInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> Yüklenen projedeki kesim yapılan demirin uzunluğundan daha uzun olan donatıların ve onların parçalanmış hâllerinin listesi.</p>
        <p><span style={{ fontWeight: "bold" }}>Adet:</span> Uzun donatının adedi. Parçalanan donatıların her biri de bu adette üretilecektir.</p>
        <p><span style={{ fontWeight: "bold" }}>Uzunluk:</span> Uzun donatının uzunluğu</p>
        <p><span style={{ fontWeight: "bold" }}>Çap:</span> Uzun donatının çapı (Φ)</p>
        <p><span style={{ fontWeight: "bold" }}>Bindirme Boyu:</span> Hesaplanan bindirme boyu</p>
        <p><span style={{ fontWeight: "bold" }}>Kat:</span> Uzun donatının bulunduğu kat</p>
        <p><span style={{ fontWeight: "bold" }}>Eleman:</span> Uzun donatının bulunduğu yapı elemanı tipi</p>
        <p><span style={{ fontWeight: "bold" }}>Eleman kodu:</span> Uzun donatının bulunduğu yapı elemanının kodu</p>
        <p><span style={{ fontWeight: "bold" }}>Açıklama:</span> Uzun donatı grubunun kullanım amacı</p>
        <p><span style={{ fontWeight: "bold" }}>Donatı Kodları:</span> Uzun donatının parçalanması sonucu oluşan donatıların kodları.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> List of rebars in the loaded project that are longer than the cutting length and their fragmented versions.</p>
        <p><span style={{ fontWeight: "bold" }}>Quantity:</span> Number of long rebars. Each fragmented rebar will be produced in this quantity.</p>
        <p><span style={{ fontWeight: "bold" }}>Length:</span> Length of the long rebar</p>
        <p><span style={{ fontWeight: "bold" }}>Diameter:</span> Diameter of the long rebar (Φ)</p>
        <p><span style={{ fontWeight: "bold" }}>Overlap Length:</span> Calculated overlap length</p>
        <p><span style={{ fontWeight: "bold" }}>Floor:</span> Floor where the long rebar is located</p>
        <p><span style={{ fontWeight: "bold" }}>Structure Element:</span> Structural element type where the long rebar is located</p>
        <p><span style={{ fontWeight: "bold" }}>Element code:</span> Code of the structural element where the long rebar is located</p>
        <p><span style={{ fontWeight: "bold" }}>Description:</span> Purpose of the long rebar group</p>
        <p><span style={{ fontWeight: "bold" }}>Rebar Codes:</span> Codes of the rebars resulting from the fragmentation of the long rebar.</p>
    </div>
}

const subInfo = {
    "tr": <div style={{ color: "black" }}>
        <p><span style={{ fontWeight: "bold" }}>Artıklar bir alt çap için kullanılsın:</span> Optimizasyon yüksek çaplardan başlanarak küçük çaplara doğru yapılmaktadır. Bu seçeneği işaretlerseniz bir çaptaki kesimlerden artan fireler bir alt çapta uygun olan donatıların yerine kullanılacaktır. Örneğin Φ12'den kalan uygun fireler Φ10 donatılarında kullanılacaktır.</p>
        <p><span style={{ fontWeight: "bold" }}>Seçilen katlar kendi arasında çözülsün:</span> Bu seçim sonucu oluşan kesim planında seçilen katlara ait donatılar kendi içerisinde optimize edilir. Kesim planında farklı katlara ait donatılar aynı kesim üzerinde bulunmaz. Donatıların yerleştirilmesinde farklı katlara ait donatıların aynı kesimde olmasına izin verilmediği için verim oranının daha düşük ve kullanılan demir miktarının daha fazla olması muhtemeldir.</p>
        <p><span style={{ fontWeight: "bold" }}>Elde kalan fire için dikkate alınacak en kısa boy:</span> Kesim planı sonucunda elde kalan fire hesaplanırken burada girilen değerden daha kısa uzunluktaki fireler dikkate alınmayacaktır. Yani, bu fireler son parçada bırakılmış kabul edilecektir. Teorik fire hesabında ise bütün fireler dikkate alınır.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p><span style={{ fontWeight: "bold" }}>Use leftovers for the next diameter:</span> Optimization starts from larger diameters and goes towards smaller diameters. If you check this option, the remaining waste from cuts in one diameter will be used for suitable rebars in the next smaller diameter. For example, the remaining waste from Φ12 rebars will be used in Φ10 rebars.</p>
        <p><span style={{ fontWeight: "bold" }}>Resolve selected floors separately:</span> In the resulting cutting plan, rebars from selected floors are optimized separately. Rebars from different floors will not be placed on the same cut. Since rebars from different floors are not allowed to be placed on the same cut, the efficiency rate may be lower and the amount of used steel may be higher.</p>
        <p><span style={{ fontWeight: "bold" }}>Minimum length to consider for remaining waste:</span> When calculating the remaining waste in the cutting plan, waste with a length shorter than the value entered here will not be considered. In other words, these wastes will be considered as left in the last piece. In the theoretical waste calculation, all wastes are considered.</p>
    </div>
}

const orderLogInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Sipariş Boy Sayısı:</span> İçerisinde, seçilen katlardan daha altta bulunan katlara ait donatı içermeyen kesim sayısı. Bu kesimler sadece seçilen katlara veya seçilen katların daha üstünde bulunan katlara ait donatıları içerir.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Number of Order Cuts:</span> The number of cuts that do not contain rebars from floors lower than the selected floors. These cuts only contain rebars from the selected floors or from floors higher than the selected floors.</p>
    </div>
}

const orderWeightInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Sipariş Ağırlığı:</span> İçerisinde, seçilen katlardan daha altta bulunan katlara ait donatı içermeyen kesimlerin ağırlığı.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Order Weight:</span> The weight of the cuts that do not contain rebars from floors lower than the selected floors.</p>
    </div>
}

const totalLogInfoWithFloor = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Toplam Boy Sayısı:</span> İçerisinde, seçilen katlara ait herhangi bir donatı içeren kesimlerin sayısı. Bu kesimler seçilen katın altında veya üstündeki bütün katlara ait donatıları içerebilir.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Total Number of Cuts:</span> The number of cuts that contain rebars from the selected floors. These cuts may contain rebars from all floors below or above the selected floor.</p>
    </div>
}

const totalWeightInfoWithFloor = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Toplam Ağırlık:</span> İçerisinde, seçilen katlara ait herhangi bir donatı içeren kesimlerin ağırlığı.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Total Weight:</span> The weight of the cuts that contain rebars from the selected floors.</p>
    </div>
}

const totalLogInfoWithoutFloor = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Toplam Boy Sayısı:</span> Seçilen çapa ait bütün kesimler.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Total Number of Cuts:</span> The number of cuts for all diameters.</p>
    </div>
}

const totalWeightInfoWithoutFloor = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Toplam Ağırlık:</span> Seçilen çapa ait bütün kesimlerin ağırlığı.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Total Weight:</span> The weight of all cuts for the selected diameter.</p>
    </div>
}

const outageWeightInfoV1 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Elde Kalan Fire Ağırlığı:</span> Kesimler ve yerine yerleştirmeler tamamlandıktan sonra elde kalması gereken fire ağırlığı. Elde kalan fire şöyle hesaplanmaktadır:</p>
        <p className="text-center">E &#61; T &#8722; L &#8722; Y</p>
        <p> <span style={{ fontWeight: "bold" }}>E = </span> Elde kalan fire ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>T = </span> Teorik fire ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>L = </span> Dikkate alınacak en kısa fire boyundan daha kısa olan firelerin toplam ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>Y = </span> Yerine kullanım ağırlığı</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Remaining Waste Weight:</span> The weight of waste that should remain after the cuts and placements are completed. The remaining waste is calculated as follows:</p>
        <p className="text-center">E &#61; T &#8722; L &#8722; Y</p>
        <p> <span style={{ fontWeight: "bold" }}>E = </span> Remaining waste weight</p>
        <p> <span style={{ fontWeight: "bold" }}>T = </span> Theoretical waste weight</p>
        <p> <span style={{ fontWeight: "bold" }}>L = </span> Total weight of waste shorter than the minimum waste length to be considered</p>
        <p> <span style={{ fontWeight: "bold" }}>Y = </span> Weight of used steel</p>
    </div>
}

const outageWeightInfoV2 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Elde Kalan Fire Ağırlığı:</span> Kesimler tamamlandıktan sonra elde kalması gereken fire ağırlığı. Elde kalan fire şöyle hesaplanmaktadır:</p>
        <p className="text-center">E &#61; T &#8722; L</p>
        <p> <span style={{ fontWeight: "bold" }}>E = </span> Elde kalan fire ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>T = </span> Teorik fire ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>L = </span> Dikkate alınacak en kısa fire boyundan daha kısa olan firelerin toplam ağırlığı</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Remaining Waste Weight:</span> The weight of waste that should remain after the cuts and placements are completed. The remaining waste is calculated as follows:</p>
        <p className="text-center">E &#61; T &#8722; L</p>
        <p> <span style={{ fontWeight: "bold" }}>E = </span> Remaining waste weight</p>
        <p> <span style={{ fontWeight: "bold" }}>T = </span> Theoretical waste weight</p>
        <p> <span style={{ fontWeight: "bold" }}>L = </span> Total weight of waste shorter than the minimum waste length to be considered</p>
    </div>
}

const wasteWeightInfoV1 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Teorik Fire Ağırlığı:</span> Mevcut kesimler içerisindeki bütün firelerin ağırlığı. Bu fireler seçilen en kısa fireden daha kısa olabilir. Alt çapta kullanılan fireler (yerine kullanım) de bu hesaba dahildir.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Theoretical Waste Weight:</span> The weight of all the waste in the current cuts. These wastes can be shorter than the selected minimum waste length. The wastes used in the next diameter (substitution) are also included in this calculation.</p>
    </div>
}

const wasteWeightInfoV2 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Teorik Fire Ağırlığı:</span> Mevcut kesimler içerisindeki bütün firelerin ağırlığı. Bu fireler seçilen en kısa fireden daha kısa olabilir.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Theoretical Waste Weight:</span> The weight of all the waste in the current cuts. These wastes can be shorter than the selected minimum waste length.</p>
    </div>
}

const subWeightInfoV1 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Yerine Kullanım Ağırlığı:</span> Mevcut kesimler içerisindeki firelerden bir alt çapta kullanılanların ağırlığı.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Substitution Weight:</span> The weight of the waste used in the next diameter in the current cuts.</p>
    </div>
}

const subWeightInfoV2 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Yerine Kullanım Ağırlığı:</span> Mevcut kesimler içerisinde bir alt çapta kullanılan kesimlerin toplam ağırlığı.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Substitution Weight:</span> The total weight of the cuts used in the next diameter in the current cuts.</p>
    </div>
}

const yieldPercentageInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Verim Yüzdesi:</span> 100 - (Fire Yüzdesi) </p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Efficiency Percentage:</span> 100 - (Waste Percentage) </p>
    </div>
}

const wastePercentageInfoWithFloorV1 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Fire Yüzdesi:</span> 100 x (Teorik Fire Ağırlığı - Yerine Kullanım Ağırlığı) / (Sipariş Ağırlığı)</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Waste Percentage:</span> 100 x (Theoretical Waste Weight - Substitution Weight) / (Order Weight)</p>
    </div>
}

const wastePercentageInfoWithFloorV2 = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Fire Yüzdesi:</span> 100 x (Teorik Fire Ağırlığı) / (Sipariş Ağırlığı)</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Waste Percentage:</span> 100 x (Theoretical Waste Weight) / (Order Weight)</p>
    </div>
}

const wastePercentageInfoWithoutFloor = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Fire Yüzdesi:</span> 100 x (Teorik Fire Uzunluğu Toplamının Ağırlığı - Yerine Kullanım Uzunlukları Toplamının Ağırlığı) / (Toplam Ağırlık)</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Waste Percentage:</span> 100 x (Total Weight of Theoretical Waste Lengths - Total Weight of Substitution Lengths) / (Total Weight)</p>
    </div>
}

// InactiveContent

const inactiveCpTotalWeightInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Toplam Ağırlık:</span> Kesim planının uygulanması için sipariş edilmesi gereken toplam demir ağırlığı.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Total Weight:</span> The total weight of steel that needs to be ordered for the implementation of the cutting plan.</p>
    </div>
}

const inactiveCpOutageWeightInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Elde Kalan Fire Ağırlığı:</span> Kesimler ve yerine yerleştirmeler tamamlandıktan sonra elde kalması gereken fire ağırlığı. Elde kalan fire şöyle hesaplanmaktadır:</p>
        <p className="text-center">E &#61; T &#8722; L &#8722; Y</p>
        <p> <span style={{ fontWeight: "bold" }}>E = </span> Elde kalan fire ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>T = </span> Teorik fire ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>L = </span> Dikkate alınacak en kısa fire boyundan daha kısa olan firelerin toplam ağırlığı</p>
        <p> <span style={{ fontWeight: "bold" }}>Y = </span> Yerine kullanım ağırlığı</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Remaining Waste Weight:</span> The weight of waste that should remain after cuts and placements are completed. The remaining waste is calculated as follows:</p>
        <p className="text-center">E = T - L - Y</p>
        <p> <span style={{ fontWeight: "bold" }}>E = </span> Remaining waste weight</p>
        <p> <span style={{ fontWeight: "bold" }}>T = </span> Theoretical waste weight</p>
        <p> <span style={{ fontWeight: "bold" }}>L = </span> Total weight of wastes shorter than the shortest considered waste length</p>
        <p> <span style={{ fontWeight: "bold" }}>Y = </span> Replacement Weight</p>
    </div>
}

const inactiveCpWasteWeightInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Teorik Fire Ağırlığı:</span> Kesimler içerisindeki bütün firelerin ağırlığı. Bu fireler elde kalacak en kısa fireden daha kısa olabilir.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Theoretical Waste Weight:</span> The weight of all the waste in the cuts. These wastes can be shorter than the shortest waste length to be considered.</p>
    </div>
}

const inactiveCpSubWeightInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Yerine Kullanım Ağırlığı:</span> Bir çaptan artan ve o çapın bir alt çapında kullanılabilen demirlerin toplam ağırlığıdır.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Substitution Weight:</span> The total weight of the rebars that are left from a diameter and can be used in the next diameter.</p>
    </div>
}

const inactiveCpYieldPercentageInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Verim Yüzdesi:</span> 100 - 100 x (Teorik Fire Ağırlığı - Yerine Kullanım Ağırlığı) / (Toplam Ağırlık)</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Efficiency Percentage:</span> 100 - 100 x (Theoretical Waste Weight - Substitution Weight) / (Total Weight)</p>
    </div>
}

const delegateCodeInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Temsilci Kodu:</span> Tasnifer'e üye olmanıza vesile olan bir temsilcimiz varsa, bu temsilcimizin sizinle paylaştığı koddur. Bu kodu bu forma girerek çeşitli avantajlardan faydalanabilir, üyeliğinizi temsilcimizle ilişkilendirmemizi sağlayabilirsiniz.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Delegate Code:</span> If you have a delegate who has helped you to become a Tasnifer member, this is the code shared with you by your delegate. By entering this code into this form, you can benefit from various advantages and associate your membership with your delegate.</p>
    </div>
}

const projectInfoStatsInfo = {
    "tr": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Parça Tipi Sayısı:</span> Her biri ayrı yerde farklı işlevleri gören, ancak aynı uzunluk ve çapta olabilen donatı grubu sayısıdır.</p>
        <p> <span style={{ fontWeight: "bold" }}>Farklı Parça Tipi Sayısı:</span> Uzunluğu ve çapı farklı olan donatı gruplarının sayısıdır. Bu istatistikte farklı yerlerde farklı işlevleri gören, ancak aynı uzunluk ve çapa sahip donatılar ayrı ayrı sayılmaz.</p>
        <p> <span style={{ fontWeight: "bold" }}>Eleman Sayısı:</span> Bir yapı elemanı grubu için farklı yapı elemanı koduna sahip her bir eleman sayısıdır. Örneğin 1. Kattaki Kiriş sayısı, 3. Kattaki Kolon sayısı vb.</p>
    </div>,
    "en": <div style={{ color: "black" }}>
        <p> <span style={{ fontWeight: "bold" }}>Number of Piece Types:</span> The number of rebar groups that perform different functions in different places, but can have the same length and diameter.</p>
        <p> <span style={{ fontWeight: "bold" }}>Number of Unique Piece Types:</span> The number of rebar groups with different lengths and diameters. In this statistic, rebars that perform different functions in different places, but have the same length and diameter, are not counted separately.</p>
        <p> <span style={{ fontWeight: "bold" }}>Number of Elements:</span> The number of each element with a different element code for a structural element group. For example, the number of beams on the 1st floor, the number of columns on the 3rd floor, etc.</p>
    </div>
}


export {
    rebarLengthInfo,
    overlapFactorInfo,

    theoreticalWeightInfo,
    rebarTableInfo,
    placementTableInfo,
    longRebarTableInfo,

    subInfo,

    orderLogInfo,
    orderWeightInfo,
    totalLogInfoWithFloor,
    totalWeightInfoWithFloor,
    totalLogInfoWithoutFloor,
    totalWeightInfoWithoutFloor,
    outageWeightInfoV1,
    outageWeightInfoV2,
    wasteWeightInfoV1,
    wasteWeightInfoV2,
    subWeightInfoV1,
    subWeightInfoV2,
    yieldPercentageInfo,
    wastePercentageInfoWithFloorV1,
    wastePercentageInfoWithFloorV2,
    wastePercentageInfoWithoutFloor,

    inactiveCpTotalWeightInfo,
    inactiveCpOutageWeightInfo,
    inactiveCpWasteWeightInfo,
    inactiveCpSubWeightInfo,
    inactiveCpYieldPercentageInfo,

    delegateCodeInfo,
    projectInfoStatsInfo,
}