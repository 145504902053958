import { useState, useEffect } from "react";
import { getLoginAttempts } from "../../services/adminServices/getLoginAttempts";

const useLoginAttempts = () => {
    const [isLoading, setIsLoading] = useState(true)

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20, //customize the default page size
    });

    const [tableData, setTableData] = useState({ results: [] });

    const [totalAttempts, setTotalAttempts] = useState(0)

    useEffect(() => {
        async function fetch() {
            const response = await getLoginAttempts(pagination.pageIndex + 1)
            const data = await response.json()
            setTotalAttempts(data.count)
            setTableData(data.results)
            setPagination({ ...pagination, pageSize: data.results.length })
        }

        fetch()
        setIsLoading(false)
    }, [])

    useEffect(() => {
        async function fetch() {
            const response = await getLoginAttempts(pagination.pageIndex + 1)
            const data = await response.json()
            setTableData(data.results)
            setPagination({ ...pagination, pageSize: data.results.length })
        }

        fetch()
    }, [pagination.pageIndex])


    return {
        isLoading,
        tableData,
        pagination,
        setPagination,
        totalAttempts
    }
}

export default useLoginAttempts;