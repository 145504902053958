import React from 'react';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';

import { Bs1Circle, Bs2Circle, Bs3Circle } from 'react-icons/bs'

import styles from '../ProjectPurchase.module.css'

import { iso31661 } from '../../../other/iso31661';
import { iso31662 } from '../../../other/iso31662';
import { customList } from 'country-codes-list';

import { useTranslation } from 'react-i18next';

const InvoiceDetails = (props) => {
    const telephoneCodes = customList('countryCallingCode', '{countryCode}');

    const setStep = props.setStep
    const invoiceInfo = props.invoiceInfo
    const setInvoiceInfo = props.setInvoiceInfo
    const invoiceType = invoiceInfo.type
    const saveInvoiceInfo = props.saveInvoiceInfo
    const setSaveInvoiceInfo = props.setSaveInvoiceInfo
    const handleInvoiceInfoStep = props.handleInvoiceInfoStep
    const telephoneError = props.telephoneError
    const countryError = props.countryError
    const provinceError = props.provinceError

    const { t } = useTranslation();

    return (
        <Container>
            <Row className='text-center bg-white align-items-center mt-4 border'>
                <Col>
                    <div className={styles.statusStep}>
                        <span className={styles.statusCircle}><Bs1Circle /></span>
                        <span className={styles.statusLabel}>{t("Fatura Detayları")}</span>
                    </div>
                </Col>
                <Col>
                    <div className={`${styles.statusStep} ${styles.disabled}`}>
                        <span className={`${styles.statusCircle} ${styles.disabledCircle}`}><Bs2Circle /></span>
                        <span className={styles.statusLabel}>{t("Ödeme Bilgileri")}</span>
                    </div>
                </Col>
                <Col>
                    <div className={`${styles.statusStep} ${styles.disabled}`}>
                        <span className={`${styles.statusCircle} ${styles.disabledCircle}`}><Bs3Circle /></span>
                        <span className={styles.statusLabel}>{t("Siparişi Tamamla")}</span>
                    </div>
                </Col>
            </Row>

            <Row className='bg-white border mt-4 py-2'>
                <Form onSubmit={(e) => handleInvoiceInfoStep(e)}>
                    <Form.Group className="mb-3" controlId="type">
                        <Form.Label>{t("Fatura Türü")}</Form.Label>
                        <Form.Select defaultValue={1} onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, type: e.target.value }) }}>
                            <option value={"real_person"}>{t("Bireysel Fatura")}</option>
                            <option value={"legal_person"}>{t("Kurumsal Fatura")}</option>
                        </Form.Select>
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>{t("İsim")} {invoiceType === "real_person" ? "(*)" : ""}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required={invoiceType === "real_person"}
                                    value={invoiceInfo.data.name ? invoiceInfo.data.name : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, name: e.target.value } }) }} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="surname">
                                <Form.Label>{t("Soyisim")} {invoiceType === "real_person" ? "(*)" : ""}</Form.Label>
                                <Form.Control
                                    type="text"
                                    required={invoiceType === "real_person"}
                                    value={invoiceInfo.data.surname ? invoiceInfo.data.surname : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, surname: e.target.value } }) }} />
                            </Form.Group>
                        </Col>
                    </Row>

                    {invoiceType === "real_person" &&

                        <Form.Group className="mb-3" controlId="tr_id">
                            <Form.Label>{t("TC Kimlik No")}</Form.Label>
                            <Form.Control
                                type="number"
                                maxLength={11}
                                min={0}
                                // required={invoiceType === "real_person"}
                                value={invoiceInfo.data.tr_identity_number ? invoiceInfo.data.tr_identity_number : ""}
                                onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, tr_identity_number: e.target.value } }) }} />
                        </Form.Group>
                    }

                    {invoiceType === "legal_person" &&
                        <>

                            <Form.Group className="mb-3" controlId="comp_name">
                                <Form.Label>{t("Şirket Adı")} (*)</Form.Label>
                                <Form.Control
                                    maxLength={20}
                                    type="text"
                                    required={invoiceType === "legal_person"}
                                    value={invoiceInfo.data.company_name ? invoiceInfo.data.company_name : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, company_name: e.target.value } }) }} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tax_no">
                                <Form.Label>{t("Vergi No.")} (*)</Form.Label>
                                <Form.Control
                                    maxLength={20}
                                    type="number"
                                    required={invoiceType === "legal_person"}
                                    value={invoiceInfo.data.taxno ? invoiceInfo.data.taxno : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, taxno: e.target.value } }) }} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="tax_office">
                                <Form.Label>{t("Vergi Dairesi")}</Form.Label>
                                <Form.Control
                                    maxLength={20}
                                    type="text"
                                    value={invoiceInfo.data.tax_office ? invoiceInfo.data.tax_office : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, tax_office: e.target.value } }) }} />
                            </Form.Group>
                        </>
                    }

                    <Form.Group className="mb-3" controlId="telephone">
                        <Form.Label>{t("Tel. No.")} (*)</Form.Label>
                        <Row>
                            <Col md={3}>
                                <Form.Select
                                    defaultValue={invoiceInfo.data.telephone_country_code}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, telephone_country_code: e.target.value } }) }}
                                >
                                    {Object.keys(telephoneCodes).map((option, index) => (
                                        <option key={index} value={option}>({telephoneCodes[option]}) +{option}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={9}>
                                <Form.Control
                                    maxLength={20}
                                    type="number"
                                    value={invoiceInfo.data.telephone ? invoiceInfo.data.telephone : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, telephone: e.target.value } }) }} />
                            </Col>
                        </Row>
                        <p className='text-danger'>{telephoneError}</p>
                    </Form.Group>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label>{t("Ülke")} (*)</Form.Label>
                                <Form.Select
                                    defaultValue={invoiceInfo.data.country}
                                    onChange={(e) => {
                                        setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, country: e.target.value, province: "" } })
                                    }}
                                >
                                    <option value="">Seçiniz</option>
                                    {iso31661.map((option, index) => (
                                        <option key={index} value={option.numeric}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="province">
                                <Form.Label>{t("Şehir / Eyalet")} (*)</Form.Label>
                                <Form.Select
                                    value={invoiceInfo.data.province ? invoiceInfo.data.province : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, province: e.target.value } }) }}
                                    disabled={invoiceInfo.data.country === ""}
                                >
                                    <option value="">Seçiniz</option>
                                    {iso31662.filter((state) => {
                                        let country = iso31661.filter((obj) => obj.numeric === invoiceInfo.data.country)
                                        if (country.length === 0) {
                                            return false;
                                        }
                                        else {
                                            country = country[0]
                                            return state.parent === country.alpha2
                                        }
                                    }).map((option, index) => (
                                        <option key={index} value={option.code}>{option.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="county">
                                <Form.Label>{t("İlçe")} (*)</Form.Label>
                                <Form.Control
                                    maxLength={20}
                                    required
                                    value={invoiceInfo.data.county ? invoiceInfo.data.county : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, county: e.target.value } }) }} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {(countryError !== "") &&
                        <p className='text-danger'>{countryError}</p>
                    }
                    {(provinceError !== "") &&
                        <p className='text-danger'>{provinceError}</p>
                    }
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="street">
                                <Form.Label>{t("Mahalle")} (*)</Form.Label>
                                <Form.Control
                                    maxLength={40}
                                    required
                                    value={invoiceInfo.data.street ? invoiceInfo.data.street : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, street: e.target.value } }) }} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="zipcode">
                                <Form.Label>{t("Posta Kodu")} (*)</Form.Label>
                                <Form.Control
                                    maxLength={10}
                                    required
                                    value={invoiceInfo.data.zipcode ? invoiceInfo.data.zipcode : ""}
                                    onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, zipcode: e.target.value } }) }} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className="mb-3" controlId="address_line1">
                        <Form.Label>{t("Adres Satırı")} 1 (*)</Form.Label>
                        <Form.Control
                            maxLength={100}
                            required
                            value={invoiceInfo.data.address_line1 ? invoiceInfo.data.address_line1 : ""}
                            onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, address_line1: e.target.value } }) }} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="address_line2">
                        <Form.Label>{t("Adres Satırı")} 2</Form.Label>
                        <Form.Control
                            maxLength={100}
                            value={invoiceInfo.data.address_line2 ? invoiceInfo.data.address_line2 : ""}
                            onChange={(e) => { setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, address_line2: e.target.value } }) }} />
                    </Form.Group>

                    <p>{t("Adınıza fatura kesilebilmesi için verdiğiniz bilgilerin doğru olduğundan emin olunuz.")}</p>

                    <Row>
                        <Col>
                            <Form.Check
                                type="checkbox"
                                id="save-info"
                                label={t("Sonraki siparişlerim için fatura bilgilerimi kaydet / güncelle.")}
                                value={saveInvoiceInfo}
                                onChange={(e) => setSaveInvoiceInfo(e.target.checked)} />
                        </Col>
                        <Col className='d-flex justify-content-end m-2' md={3}>
                            <Button size='lg' type='submit'>{t("İLERLE")}</Button>
                        </Col>
                    </Row>
                </Form>

            </Row>

        </Container>

    );
};

export default InvoiceDetails;
