import apiClient from "../apiClient";

export const isProjectPurchasable= async (id) => {
    const url = `${process.env.REACT_APP_API_URL}/api/project-purchasable/${id}`;

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}