import apiClient from "../apiClient";

export const setUnitPrice = async (type, unit_price) => {
    const url = `${process.env.REACT_APP_API_URL}/api/unit-price/`;

    const response = await apiClient(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            type: type,
            unit_price: unit_price
        }),
    });

    return response;

}