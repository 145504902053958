import apiClient from "./apiClient";

export const changePassword = async (email, old_password, new_password) => {
    const url = `${process.env.REACT_APP_API_URL}/api/change-password/`;

    const response = await apiClient(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email: email,
            old_password: old_password,
            new_password: new_password,
        }),
    });

    return response;

}