import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

const PrivacyPolicyTr = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
    const homeURL = process.env.REACT_APP_HOME_URL

    document.title = "Gizlilik Politikası"

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>Gizlilik Politikası</h2>

                    <h5 className='mb-4 fw-bold'>1. Giriş</h5>
                    <p><span className='fw-bold'>Teber Soft Yazılım İnşaat Sanayi ve Ticaret LTD. ŞTİ.</span> ("Şirket") olarak gizliliğinize ve verilerinizin güvenliğine değer vermekteyiz. Kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) kapsamında saklanmakta ve işlenmektedir. </p>
                    <p>Bu Gizlilik Politikası'nda, verilerinizi nasıl ve ne amaçlarla topladığımıza dair bilgiye ulaşabilirsiniz.</p>
                    <p>Şirket, bu Gizlilik Politikası'nda değişiklik yapma hakkını saklı tutar. Kullanıcıların bu metni düzenli aralıklarla gözden geçirmesi tavsiye olunur.</p>
                    <p>Bu Gizlilik Politikası sadece <a href={`${homeURL}/tr`}>{homeURL}</a> ("Uygulama") için geçerlidir. Uygulama'dan erişilebilen diğer internet siteleri bu Gizlilik Politikası kapsamında değerlendirilemez. </p>
                    <p>Bu Gizlilik Politikası'nı kabul etmiyorsanız, Uygulama'yı kullanmaya devam etmemelisiniz.</p>


                    <h5 className='my-4 fw-bold'>2. Toplanan Kişisel Veriler</h5>
                    <p>Uygulama'yı kullandığınız sürece aşağıdaki verileri toplamakta ve saklamaktayız:</p>
                    <ul>
                        <li className='mb-1'>Uygulama'ya üye olurken sağladığınız e-posta adresiniz</li>
                        <li className='mb-1'>Uygulama içerisinde proje oluşturmak için yüklenen, üçüncü taraf inşaat programları tarafından üretilmiş, veya tarafınızca oluşturulmuş proje dosyalarınız</li>
                        <li className='mb-1'>İsterseniz uygulama içerisindeki satın alımlarda fatura bilgilerinin otomatik olarak doldurulması amacıyla ad, soyad, telefon numarası, adres, şirket adı, vergi dairesi bilgileriniz</li>
                        <li className='mb-1'>Uygulama'yı ziyaret ettiğiniz IP adresi, tarayıcı, cihaz, ziyaret tarih ve saati bilgileri (2 yıldan uzun süre saklanmamak kaydıyla)</li>
                    </ul>
                    <p>Bu veriler haricinde, hizmetlerimizi satın alırken sağladığınız kredi veya banka kartı bilgileriniz sistemimizde saklanmamaktadır.</p>


                    <h5 className='my-4 fw-bold'>3. Kişisel Verilerin Kullanımı</h5>
                    <p>Uygulama'yı kullandığınız sürece kişisel verilerinizi şu amaçlarla saklamakta ve işlemekteyiz:</p>
                    <ul>
                        <li className='mb-1'>Uygulama'yı kullanmanız için gereken üyelik işlemlerini gerçekleştirmek</li>
                        <li className='mb-1'>Uygulama'ya üye olma, e-posta adresi doğrulaması, şifre değişikliği gibi durumlarda bildirim sağlamak ve Uygulama'daki güncellemeleri, yeni teklif ve servisleri tarafınıza bildirmek amacıyla e-posta göndermek</li>
                        <li className='mb-1'>5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun'dan doğan yükümlülüklerimizi yerine getirmek</li>
                    </ul>

                    <h5 className='my-4 fw-bold'>4. Çerezler</h5>
                    <p>Uygulama'yı kullanmanız sırasındaki deneyiminizi iyileştirmek adına çerezler kullanmaktayız. Çerezleri tarayıcınızın ayarlarından engelleyebilirsiniz, ancak Uygulama deneyiminiz etkilenebilir.</p>

                    <h5 className='my-4 fw-bold'>5. Yaş Sınırı</h5>
                    <p>Uygulama, 18 yaşından büyük kullanıcılar için geliştirilmiştir. 18 yaşından büyük değilseniz Uygulama'yı kullanmamalısınız. 18 yaşından küçüklere ait kişisel verileri sakladığımızı fark ettiğimiz takdirde bu verileri sileceğiz.</p>


                    <h5 className='my-4 fw-bold'>6. Verileriniz ile ilgili Haklarınız</h5>
                    <p>6698 Sayılı Kişisel Verilerin Korunması Kanunu kapsamında tarafınıza sağlanan şu hakları istediğiniz zaman tarafımıza ulaşarak kullanabilirsiniz:</p>
                    <ul>
                        <li className='mb-1'>Kişisel verilerinizin ne olup olmadığını sorgulama hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin işlendiği durumlarda, ilgili bilgileri ve kopyalarını isteme hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin işlenme amacını ve amaca uygun kullanılıp kullanılmadığını belirleme hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin yerel ya da yurtdışında hangi üçüncü taraflara aktarıldığını bilme hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin yanlış ya da eksik işlenmiş olması durumunda düzeltilmesini talep etme hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin silinmesini ya da yok edilmesini talep etme hakkı</li>
                        <li className='mb-1'>Kişisel verilerin düzeltme, silme veya yok etme işlemlerinin ilgili üçüncü taraflara bildirilmesini isteme hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin hukuka aykırı bir şekilde işlenmesi sonucunda oluşan zararın giderilmesini talep etme hakkı</li>
                        <li className='mb-1'>Kişisel verilerinizin tamamının ya da bir bölümünün işlemesinin durdurulmasını talep etme hakkı</li>
                        <li className='mb-1'>Elektronik ortamda işlenen kişisel verilerinizin bir kopyasını talep etme ve bu verileri başka bir kişiye iletilmesini isteme hakkı</li>
                        <li className='mb-1'>İstediğiniz bir zamanda kişisel verilerinizin işlenmesine itiraz etme hakkı</li>
                    </ul>

                    <h5 className='my-4 fw-bold'>7. İletişim</h5>
                    <p>Bizimle Uygulama ve bu Gizlilik Politikası hakkındaki her türlü soru ve görüşleriniz için iletişime geçebilirsiniz. </p>
                    <p><span className='fw-bold'>E-posta adresimiz:</span> <a href={`mailto:${contactEmail}`}>{contactEmail}</a></p>
                    <p className='mb-1'><span className='fw-bold'>Posta adresimiz:</span> Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi </p>
                    <p>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3  Şahinbey / Gaziantep</p>
                    <p>Son Güncellenme Tarihi: 21 Temmuz 2023</p>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyTr;
