import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import styles from './Footer.module.css';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

import watermark from '../../img/watermark-logosuz.png'

import { useTranslation } from "react-i18next";
import { supportedLanguages, defaultLanguage } from '../../i18n';

const Footer = ({ minimized = true }) => {

  const { i18n, t } = useTranslation();

  const langPrefix = i18n.language === defaultLanguage ? "" : `/${i18n.language}`;

  return (
    <div className='text-center text-lg-start text-muted border-top mt-4' style={{ backgroundColor: "white" }}>
      <>
        <section>
          <Container fluid className='text-center text-md-start mt-3 px-5 d-none d-lg-block'>
            <div className='d-flex justify-content-between'>
              <img src={watermark} style={{ height: '40px' }}></img>

              <div>
                {/* <a href='about-us' className='text-reset text-decoration-none ms-4'>
                  Hakkımızda
                </a> */}

                <a href={`${langPrefix}/contact`} className='text-reset text-decoration-none ms-4'>
                  {t("İletişim")}
                </a>

                <a href={`${langPrefix}/prices`} className='text-reset text-decoration-none ms-4'>
                  {t("Fiyatlandırma")}
                </a>

                <a href={`${langPrefix}/terms-and-conditions`} className='text-reset text-decoration-none ms-4'>
                  {t("Üyelik Sözleşmesi")}
                </a>

                <a href={`${langPrefix}/privacy-policy`} className='text-reset text-decoration-none ms-4'>
                  {t("Gizlilik Politikası")}
                </a>

                <a href={`${langPrefix}/sale-contract`} className='text-reset text-decoration-none ms-4'>
                  {t("Satış Sözleşmesi")}
                </a>
              </div>
            </div>

          </Container>
          <Container fluid className='text-center text-md-start mt-4 px-5 d-none d-md-block d-lg-none'>
            <Row className='mt-3 mb-3'>
              <Col md="4" className='mx-auto d-none d-md-block'>
                <img src={watermark} style={{ height: '40px', marginBottom: '10pt' }}></img>
              </Col>

              <Col xs="6" md="4">
                <p>
                  <a href={`${langPrefix}/prices`} className='text-reset text-decoration-none '>
                    {t("Fiyatlandırma")}
                  </a>
                </p>
                {/* <p>
                  <a href='about-us' className='text-reset text-decoration-none '>
                    Hakkımızda
                  </a>
                </p> */}
                <p>
                  <a href={`${langPrefix}/about-us#cantact-us`} className='text-reset text-decoration-none '>
                    {t("İletişim")}
                  </a>
                </p>
                <p>
                  <a href={`${langPrefix}/privacy-policy`} className='text-reset text-decoration-none '>
                    {t("Gizlilik Politikası")}
                  </a>
                </p>
              </Col>

              <Col xs="6" md="4">
                <p>
                  <a href={`${langPrefix}/terms-and-conditions`} className='text-reset text-decoration-none '>
                    {t("Üyelik Sözleşmesi")}
                  </a>
                </p>
                <p>
                  <a href={`${langPrefix}/sale-contract`} className='text-reset text-decoration-none '>
                    {t("Satış Sözleşmesi")}
                  </a>
                </p>
              </Col>
            </Row>
          </Container>

          <Container fluid className='d-none d-md-block px-5'>
            <hr />
          </Container>

          <Container fluid className='px-5'>
            <div style={{ fontSize: '9pt' }} className='my-2'>
              &copy; {new Date().getFullYear()}  <a className='text-reset fw-bold text-decoration-none' href='https://tebersoft.net/'>
                Teber Soft
              </a>. {t("Tüm Hakları Saklıdır.")}

            </div>
          </Container>
        </section>
      </>
    </div>
  );
};

export default Footer;
