import apiClient from "../apiClient";

export const approveProjectPayment = async (orderId, invoiceFile, bankReceiptFile, lang="tr") => {
    const url = `${process.env.REACT_APP_API_URL}/api/payment/approve-project-payment/`;

    let formData = new FormData();
    formData.append('order_id', orderId);
    formData.append('invoice', invoiceFile);
    formData.append('bank_receipt', bankReceiptFile);

    const response = await apiClient(url, {
        method: 'POST',
        body: formData,
        headers : {
            'Accept-Language': lang,
        }
    })

    return response;

}