import i18n from "../i18n";

export const requestEmailChange = async (user_id, old_email, new_email) => {
    const url = `${process.env.REACT_APP_API_URL}/api/request-email-change/`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Accept-Language': i18n.language || 'tr',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user_id: user_id,
            old_email: old_email,
            new_email: new_email,
        }),
    });

    return response;

}