import { useEffect, useState } from 'react';
import { getUserData } from '../../services/userService';
import { requestEmailChange } from '../../services/requestEmailChangeService';
import { checkIfUserActive } from '../../services/isUserActiveService';
import { changePassword } from '../../services/changePasswordService';
import { getAdditionalUserInfo, setAdditionalUserInfo } from '../../services/additionalUserInfo';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const validatePassword = (password) => {
    // Minimum 6 characters, at least one letter and one number
    const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(password);
};

const useAccountSettings = () => {

    const { i18n, t } = useTranslation();

    const [pageLoading, setPageLoading] = useState(true)

    const [changeEmail, setChangeEmail] = useState(false)
    const [changeInvoiceDetails, setChangeInvoiceDetails] = useState(false)

    const [showEnterPasswordModal, setShowEnterPasswordModal] = useState(false)
    const [passwordForChangeEmail, setPasswordForChangeEmail] = useState("")
    const [emailModalNote, setEmailModalNote] = useState({ state: "dontshow", note: "" })
    const [newEmailRequestDone, setNewEmailRequestDone] = useState(false)

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("")
    const [newPasswordRequestLoading, setNewPasswordRequestLoading] = useState(false)
    const [newPasswordRequestDone, setNewPasswordRequestDone] = useState(false)
    const [passwordModalNote, setPasswordModalNote] = useState({ state: "dontshow", note: "" })

    const [emailButtonLoading, setEmailButtonLoading] = useState(false)
    const [emailButtonDisabled, setEmailButtonDisabled] = useState(false)

    const [userEmail, setUserEmail] = useState()
    const [tempEmail, setTempEmail] = useState()
    const [userId, setUserId] = useState()

    const [userType, setUserType] = useState(0)

    const [invoiceDetails, setInvoiceDetails] = useState({
        name: "",
        surname: "",
        telephone_country_code: "",
        telephone: "",
        company_name: "",
        tax_office: "",
        taxno: "",
        address: "",
    })

    const [savingInvoiceDetails, setSavingInvoiceDetails] = useState(false)
    const [savedInvoiceDetails, setSavedInvoiceDetails] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {

        async function fetchData() {
            try {
                const result = await getUserData();
                if (result.user_type === 3 && !(result.delegation_active)) {
                    navigate(`/${i18n.language}/delegation-contract`)
                }
                setUserEmail(result.email);
                setTempEmail(result.email)
                setUserId(result.id);
                setUserType(result.user_type);
                try {
                    const invoiceResponse = await getAdditionalUserInfo()
                    if (invoiceResponse.ok) {
                        const data = await invoiceResponse.json()
                        setInvoiceDetails(data)
                    }
                    else {
                        var props = {
                            error_code: "EOYGTB",
                            detail: `${t("Hata")}: ${invoiceResponse.statusText} (${invoiceResponse.status})`,
                        }

                        navigate(`/error`, { state: { props: props } })
                    }
                    setPageLoading(false);
                }
                catch {
                    var props = {
                        error_code: "9EITYT",
                        detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                    }

                    navigate(`/error`, { state: { props: props } })
                }

            } catch (error) {
                // getUserError
            }
        }

        fetchData()

    }, [])

    const handleEmailChange = async (e) => {
        e.preventDefault();

        setEmailButtonLoading(true);
        try {
            const passwordResponse = await checkIfUserActive(userEmail, passwordForChangeEmail);

            if (passwordResponse.ok) {
                const response = await requestEmailChange(
                    userId,
                    userEmail,
                    tempEmail,
                )

                if (response.ok) {
                    setEmailButtonLoading(false);
                    setTempEmail(`${tempEmail} ${t("(onay bekleniyor)")}`);
                    setChangeEmail(false);
                    setEmailButtonDisabled(true);
                    setEmailModalNote({ state: "success", note: t("Yeni e-posta adresinize aktivasyon maili gönderilmiştir.") })
                    setNewEmailRequestDone(true)
                }
                else {
                    // show error message
                    setChangeEmail(false);
                    setEmailButtonLoading(false);
                    if (response.status === 409) {
                        setEmailModalNote({ state: "danger", note: t("Bu e-posta adresi Tasnifer'e kayıtlı.") })
                    }
                    if (response.status === 400) {
                        setEmailModalNote({ state: "danger", note: t("Bu e-posta adresi ile değişiklik yapılamıyor.") })
                    }
                    else {
                        setEmailModalNote({ state: "danger", note: t("Sunucu hatası. Sistem yönetici ile iletişime geçin. (Hata Kodu: 8LJO)") })
                    }
                }

                // setShowEnterPasswordModal(false)
            }
            else {
                setEmailButtonLoading(false);
                setEmailModalNote({ state: "danger", note: t("Şifre yanlış.") })
            }
        } catch {
            setEmailButtonLoading(false);
            var props = {
                error_code: "6045HE",
                detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
            }
            navigate(`/error`, { state: { props: props } })
        }
    }

    const handleEmailChangeCancel = () => {
        setShowEnterPasswordModal(false);
        setPasswordForChangeEmail("")
        setTempEmail(userEmail);
        setChangeEmail(false)
        setEmailModalNote({ state: "dontshow", note: "" })
    }

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        setPasswordModalNote({ state: "dontshow", note: "" })

        if (currentPassword.length < 6) {
            setPasswordModalNote({ state: "danger", note: t("Mevcut şifre yanlış.") })
        }
        else {
            setNewPasswordRequestLoading(true);
            try {
                const passwordResponse = await checkIfUserActive(userEmail, currentPassword);

                if (passwordResponse.ok) {

                    if (!validatePassword(newPassword)) {
                        setPasswordModalNote({ state: "danger", note: t("Yeni şifre en az bir rakam ve bir büyük harf içermeli, en az 6 karakterden oluşmalıdır.") })
                        setNewPasswordRequestLoading(false);
                    }
                    else if (newPassword !== newPasswordConfirm) {
                        setPasswordModalNote({ state: "danger", note: t("Yeni şifreler eşleşmiyor.") })
                        setNewPasswordRequestLoading(false);
                    }
                    else if (newPassword === currentPassword) {
                        setPasswordModalNote({ state: "danger", note: t("Yeni şifre ile mevcut şifre aynı.") })
                        setNewPasswordRequestLoading(false);
                    }
                    else {

                        try {
                            const response = await changePassword(
                                userEmail,
                                currentPassword,
                                newPassword,
                            )
                            if (response.ok) {
                                setPasswordModalNote({ state: "success", note: t("Şifre başarıyla değiştirildi.") })
                                setNewPasswordRequestLoading(false);
                                setNewPasswordRequestDone(true)
                            }
                            else {
                                setPasswordModalNote({ state: "danger", note: t("Şifre değiştirilemedi. (Hata Kodu: M13J)") })
                                setNewPasswordRequestLoading(false);
                            }
                        }
                        catch {
                            var props = {
                                error_code: "I2J9XJ",
                                detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                            }
                            navigate(`/error`, { state: { props: props } })
                        }
                    }
                }
                else {
                    setPasswordModalNote({ state: "danger", note: t("Mevcut şifre yanlış.") })
                    setNewPasswordRequestLoading(false);
                }
            } catch {
                var props = {
                    error_code: "EIQWJS",
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }

                navigate(`/error`, { state: { props: props } })
            }
        }
    }

    const handleSaveInvoiceDetails = async () => {
        setSavingInvoiceDetails(true)
        try {
            const response = await setAdditionalUserInfo(invoiceDetails);

            setSavingInvoiceDetails(false);
            if (response.ok) {
                setChangeInvoiceDetails(false)
            }
            else {
                var props = {
                    error_code: "5CU937",
                    detail: `${("Sistem Hatası")}: ${response.statusText} (${response.status})`,
                }
                navigate(`/error`, { state: { props: props } })
            }
        }
        catch {
            var props = {
                error_code: "4E9OJ1",
                detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
            }
            navigate(`/error`, { state: { props: props } })
        }

    }

    return {
        pageLoading,

        userType,

        userEmail,
        setUserEmail,
        tempEmail,
        setTempEmail,

        showEnterPasswordModal,
        setShowEnterPasswordModal,

        passwordForChangeEmail,
        setPasswordForChangeEmail,

        emailModalNote,
        setEmailModalNote,

        newEmailRequestDone,

        emailButtonLoading,
        emailButtonDisabled,

        showChangePasswordModal,
        setShowChangePasswordModal,
        currentPassword,
        setCurrentPassword,
        newPassword,
        setNewPassword,
        newPasswordConfirm,
        setNewPasswordConfirm,
        newPasswordRequestLoading,
        setNewPasswordRequestLoading,
        newPasswordRequestDone,
        setNewPasswordRequestDone,
        passwordModalNote,
        setPasswordModalNote,

        handlePasswordChange,

        userId,
        changeEmail,
        setChangeEmail,
        changeInvoiceDetails,
        setChangeInvoiceDetails,

        handleEmailChange,
        handleEmailChangeCancel,

        invoiceDetails,
        setInvoiceDetails,
        savingInvoiceDetails,
        savedInvoiceDetails,
        handleSaveInvoiceDetails,
    }
}

export default useAccountSettings