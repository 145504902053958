import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import CheckboxList from '../../../../components/CheckboxList/CheckboxList';

import styles from '../CpInfo.module.css';

import { useTranslation } from 'react-i18next';

const Configuration = (props) => {

    const { t } = useTranslation();

    return (
        <Container fluid className='border p-3' style={{ backgroundColor: 'white' }}>
            <h4 className='text-center mb-3'>{t("Seçim")}</h4>

            {/* <Row className='justify-content-center'> */}

            <Row>
                <Col xs={12}>
                    <Container className="border shadow-sm mb-3">
                        <h5 className="text-center py-3">{t("Yerleşim Planı")}</h5>
                        <div className={`${styles.checkboxListContainer} mb-3`}>
                            <CheckboxList items={props.floors} onCheckedItemsChange={props.onFloorsChange} />
                        </div>
                    </Container>
                </Col>
            </Row>


            <Row>
                <Col xs={12}>
                    <Container className="border shadow-sm mb-3">
                        <h5 className="text-center py-3">{t("Çaplar")}</h5>
                        <div className={`${styles.checkboxListContainer} mb-3`}>
                            <CheckboxList items={props.diameters} onCheckedItemsChange={props.onDiametersChange} singleSelection={true} />
                        </div>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Configuration;