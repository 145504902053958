import React, { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Collapse from 'react-bootstrap/Collapse';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import styles from './CpInfo.module.css';

import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';

const BackgroundCpInfo = ({
    cuttingPlanInfo,
    allProfilesInfo,
}) => {

    const nFormat = new Intl.NumberFormat();

    function format(item) {
        return nFormat.format(item);
    }

    const [open, setOpen] = useState(false)

    const cpId = cuttingPlanInfo.cpId
    const cpName = cuttingPlanInfo.cpName
    const projectId = cuttingPlanInfo.projectId
    const projectName = cuttingPlanInfo.projectName
    const creationTime = cuttingPlanInfo.creationTime
    const projectTheoreticalWeight = cuttingPlanInfo.projectTheoreticalWeight

    const { i18n, t } = useTranslation();


    return (
        <Container>

            <Row>
                <Col md={{ offset: 1, span: 10 }}>
                    <table className="table table-striped-columns border my-3 align-middle bg-white">
                        <tbody>
                            <tr>
                                <th style={{ width: "75%" }}>{t("Kesim Planı Numarası")}</th>
                                <td style={{ width: "25%" }}>{cpId}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "75%" }}>{t("Durum")}</th>
                                <td style={{ width: "25%" }}>{cuttingPlanInfo.status}</td>
                            </tr>
                            <tr>
                                <th style={{ width: "45%" }}>{t("Teorik Ağırlık (kg)")}</th>
                                <td style={{ width: "65%" }}>{format(projectTheoreticalWeight)}</td>
                            </tr>
                        </tbody>
                    </table>

                </Col>
            </Row>

            <Container
                style={{
                    marginTop: "2rem",
                    padding: "1rem",
                    fontSize: "1.5rem",
                    textAlign: "center",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    border: "1px solid #e0e0e0",
                }}
            >

                <p className='p-0 m-0'>
                    {t("Bu kesim planı çok sayıda ve tipte parça içerdiği için")} <span className='fw-bold'>{t("çözüm arka planda yapılıyor.")}</span>
                </p>
                <p className='p-0 m-0'>
                    {t("Çözüm tamamlandığında")} <span className='fw-bold'>{t("bu sayfayı yenileyerek")}</span> {t("sonucu görebilirsiniz.")}
                </p>

            </Container>

        </Container>
    )
}

export default BackgroundCpInfo;
