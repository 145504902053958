import styles from './InfoNote.module.css'
import { useTranslation } from "react-i18next";

const InfoNote = ({ content, setDialogOpen, setDialogContent }) => {
    const { i18n, t } = useTranslation();

    return (<><span>&nbsp;</span><span
        className={styles.infoNote}
        onClick={() => {
            setDialogOpen(true);
            setDialogContent(content)
        }} >{t("Bilgi")}</span></>)
}

export default InfoNote;