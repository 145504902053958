import React from 'react';
import Container from 'react-bootstrap/Container';

import StockTable from '../../../../components/CpInfoTables/StockTable/StockTable';

import { useTranslation } from 'react-i18next';

const Stocks = ({ availableStocks }) => {

    const { t } = useTranslation();

    let stockAvailable = availableStocks.length !== 0;

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            <Container fluid style={{ "display": "flex", "flexDirection": "column", "maxHeight": "100vh" }}>
                <h5 className="text-center py-2">{t("Stok Listesi")}</h5>
                {stockAvailable ?
                    <StockTable
                        availableStocks={availableStocks}
                        ratio={1}
                    />
                    :
                    <p className="text-center">{t("Stok mevcut değil.")}</p>
                }
            </Container>
        </Container>
    )
}

export default Stocks;