import { useState, useEffect } from 'react';
import { getUnitPrices } from '../../services/adminServices/getAllUnitPrices';
import { setUnitPrice } from '../../services/adminServices/setUnitPrice';
import { useNavigate } from "react-router-dom";

const useUnitPrices = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true)
    const [projectPrice, setProjectPrice] = useState({})
    const [projectDelegatePrice, setProjectDelegatePrice] = useState({})
    const [projectWithDelegationPrice, setProjectWithDelegationPrice] = useState({})
    const [projectDelegateCut, setProjectDelegateCut] = useState({})
    const [metalProfileProjectPrice, setMetalProfileProjectPrice] = useState({})
    const [metalProfileProjectDelegatePrice, setMetalProfileProjectDelegatePrice] = useState({})
    const [metalProfileProjectWithDelegationPrice, setMetalProfileProjectWithDelegationPrice] = useState({})
    const [metalProfileProjectDelegateCut, setMetalProfileProjectDelegateCut] = useState({})
    const [credits1000Price, setCredits1000Price] = useState({})
    const [credits2000Price, setCredits2000Price] = useState({})
    const [credits3000Price, setCredits3000Price] = useState({})
    const [vat, setVat] = useState({})
    const [transferDiscount, setTransferDiscount] = useState({})

    const [modalInfo, setModalInfo] = useState({
        show: false,
        initialPrice: 0,
        explanation: "",
        type: "",
    });

    const [priceLoading, setPriceLoading] = useState(false)
    const [priceSettingNote, setPriceSettingNote] = useState({ variant: "danger", note: "" })

    const [price, setPrice] = useState(0)

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getUnitPrices()

                if (response.ok) {
                    let data = await response.json()
                    let dict = {}
                    data.map(i => { dict = { ...dict, [i.type]: i } })
                    setProjectPrice(dict['project'])
                    setProjectDelegatePrice(dict['project_delegate'])
                    setProjectWithDelegationPrice(dict['project_with_delegation'])
                    setProjectDelegateCut(dict['project_delegate_cut'])
                    setMetalProfileProjectPrice(dict['metal_profile_project'])
                    setMetalProfileProjectDelegatePrice(dict['metal_profile_project_delegate'])
                    setMetalProfileProjectWithDelegationPrice(dict['metal_profile_project_with_delegation'])
                    setMetalProfileProjectDelegateCut(dict['metal_profile_project_delegate_cut'])
                    setCredits1000Price(dict['1000credits'])
                    setCredits2000Price(dict['2000credits'])
                    setCredits3000Price(dict['3000credits'])
                    setVat(dict['vat'])
                    setTransferDiscount(dict['transfer_discount'])
                    setIsLoading(false)
                }
                else {
                    setIsLoading(false)
                    var props = {
                        error_code: "C6R2OO",
                        detail: "Sistem Hatası. Sistem yöneticisi ile irtibata geçin.",
                    }

                    navigate("/error", { state: { props: props } })
                }
            }
            catch (error) {
                setIsLoading(false)
                var props = {
                    error_code: "RKO4AB",
                    detail: "Sistem Hatası. Sistem yöneticisi ile irtibata geçin.",
                }

                navigate("/error", { state: { props: props } })
            }
        }

        fetchData()

    }, [])

    const handleSetUnitPrice = async (e, type, price) => {
        e.preventDefault()
        setPriceLoading(true)
        let response
        try {
            response = await setUnitPrice(type, price)
        }
        catch {
            setPriceLoading(false)
            setPriceSettingNote({
                variant: "danger",
                note: "Sistem Hatası. (JO55UL)"
            })
        }

        if (response.ok) {
            setPriceLoading(false)
            switch (type) {
                case "project":
                    setProjectPrice({
                        type: "project",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "project_delegate":
                    setProjectDelegatePrice({
                        type: "project_delegate",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "project_with_delegation":
                    setProjectWithDelegationPrice({
                        type: "project_with_delegation",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "project_delegate_cut":
                    setProjectDelegateCut({
                        type: "project_delegate_cut",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "metal_profile_project":
                    setMetalProfileProjectPrice({
                        type: "metal_profile_project",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "metal_profile_project_delegate":
                    setMetalProfileProjectDelegatePrice({
                        type: "metal_profile_project_delegate",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "metal_profile_project_with_delegation":
                    setMetalProfileProjectWithDelegationPrice({
                        type: "metal_profile_project_with_delegation",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "metal_profile_project_delegate_cut":
                    setMetalProfileProjectDelegateCut({
                        type: "metal_profile_project_delegate_cut",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "1000credits":
                    setCredits1000Price({
                        type: "1000credits",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "2000credits":
                    setCredits2000Price({
                        type: "2000credits",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "3000credits":
                    setCredits3000Price({
                        type: "3000credits",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "vat":
                    setVat({
                        type: "vat",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
                case "transfer_discount":
                    setTransferDiscount({
                        type: "transfer_discount",
                        unit_price: price,
                        last_update: Date(),
                    })
                    break
            }
            setPriceSettingNote({ variant: "success", note: "Başarılı" })
        }
        else {
            setPriceLoading(false)
            setPriceSettingNote({
                variant: "danger",
                note: "Sistem Hatası (QCHND4)"
            })
        }
    }

    const modalOnHide = () => {
        setModalInfo({
            ...modalInfo,
            show: false,
        })
        setPriceSettingNote({ variant: "danger", note: "" })
    }

    return {
        isLoading,

        projectPrice,
        projectDelegatePrice,
        projectWithDelegationPrice,
        projectDelegateCut,

        metalProfileProjectPrice,
        metalProfileProjectDelegatePrice,
        metalProfileProjectWithDelegationPrice,
        metalProfileProjectDelegateCut,

        credits1000Price,
        credits2000Price,
        credits3000Price,
        vat,
        transferDiscount,

        modalInfo,

        setModalInfo,
        priceLoading,
        priceSettingNote,
        handleSetUnitPrice,

        price,
        setPrice,

        modalOnHide,
    }
}

export default useUnitPrices