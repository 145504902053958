import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

const calculateWeight = (diameter, length) => {
    const density = 7.85
    const radius = diameter / 2 * 1e-1
    const A = Math.PI * radius ** 2
    const V = A * length
    const mass = V * density
    return mass * 1e-3
}

const AllDiameterResultsTable = (props) => {

    const { t } = useTranslation();

    let version = 1
    if ("version" in props.result) {
        version = props.result.version;
    }

    const floorAvailable = props.floorAvailable
    const floors = props.floors.map((i) => i.value)

    let allFloors
    let orderSummary
    let logLength
    let diameters
    let floorIndexes

    logLength = props.result.logLength
    diameters = Object.keys(props.result.diameterResults)

    diameters = diameters.map(Number)

    const nofThins = diameters.filter(d => d <= 12).length
    const nofThicks = diameters.filter(d => d > 12).length

    const thinDiameters = diameters.slice(0, nofThins)
    const thickDiameters = diameters.slice(nofThins, diameters.length)

    const isThin = nofThins !== 0
    const isThick = nofThicks !== 0

    let nofCols = diameters.length + 2
    let colWidth = 100 / nofCols
    if (isThin) { nofCols += 1 }
    if (isThick) { nofCols += 1 }
    let colWidthWithThinThick = 100 / nofCols

    let totalLogWeights
    let totalOrderLogs
    let totalOrderWeights
    let orderOutageWeights
    let orderWastedWeights
    let substitutionWeights
    let floorInfo = {}
    let wastePercentages
    let thinDenom, thinNumer, thickDenom, thickNumer
    let thinWaste, thinYield
    let thickWaste, thickYield

    if (floorAvailable) {
        allFloors = props.result.allFloors
        orderSummary = props.result.orderSummary
        floorIndexes = Object.keys(orderSummary[diameters[0]]).map(Number)

        totalLogWeights = diameters.map((diameter) => (
            props.result.diameterResults[diameter].totalLogWeight
        ))

        totalLogWeights = diameters.map((diameter) => calculateWeight(diameter, props.result.diameterResults[diameter].totalLogsUsed * logLength))

        totalOrderLogs = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += i.orderLogs, 0))

        totalOrderWeights = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += calculateWeight(diameter, i.orderLogs * logLength), 0))

        orderOutageWeights = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += calculateWeight(diameter, i.orderOutageLength), 0))

        orderWastedWeights = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += calculateWeight(diameter, i.orderWastedLength), 0))

        substitutionWeights = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += calculateWeight(diameter, i.substitutionLength), 0))

        let orderOutageLengths = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += i.orderOutageLength, 0))
        let orderWastedLengths = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += i.orderWastedLength, 0))
        let subLengths = diameters.map((diameter) => Object.values(props.result.orderSummary[diameter]).reduce((sum, i) => sum += i.substitutionLength, 0))
        wastePercentages = diameters.map((diameter, idx) => {
            let numerator;
            if (version === 1) {
                numerator = calculateWeight(diameter,orderWastedLengths[idx] - subLengths[idx])
            }
            else {
                numerator = calculateWeight(diameter,orderWastedLengths[idx])
            }
            let denominator = calculateWeight(diameter,totalOrderLogs[idx] * logLength)
            return totalOrderLogs[idx] !== 0 ? 100 * (numerator / denominator) : -1
        })

        for (let diameter in props.result.orderSummary) {
            diameter = Number(diameter)
            for (const floor in props.result.orderSummary[diameter]) {
                if (!floorInfo[floor]) {
                    floorInfo[floor] = {
                        "totalWeight": 0,
                        "orderWeight": 0,
                        "totalOutageWeight": 0,
                        "totalWastedWeight": 0,
                        "orderOutageWeight": 0,
                        "orderWastedWeight": 0,
                        "substitutionWeight": 0
                    };
                }
                const diametersFloorInfo = props.result.orderSummary[diameter][floor]
                floorInfo[floor].totalWeight += calculateWeight(diameter, diametersFloorInfo.totalLogs * logLength);
                floorInfo[floor].orderWeight += calculateWeight(diameter, diametersFloorInfo.orderLogs * logLength);
                floorInfo[floor].totalOutageWeight += calculateWeight(diameter, diametersFloorInfo.totalOutageLength);
                floorInfo[floor].totalWastedWeight += calculateWeight(diameter, diametersFloorInfo.totalWastedLength);
                floorInfo[floor].orderOutageWeight += calculateWeight(diameter, diametersFloorInfo.orderOutageLength);
                floorInfo[floor].orderWastedWeight += calculateWeight(diameter, diametersFloorInfo.orderWastedLength);
                floorInfo[floor].substitutionWeight += calculateWeight(diameter, diametersFloorInfo.substitutionLength);
            }
        }
        thinDenom = thinDiameters.reduce((acc, diameter) => {
            return acc + floors.reduce((acc2, floor) => acc2 + calculateWeight(diameter, orderSummary[diameter][floor].orderLogs * logLength), 0)
        }, 0)
        thinNumer = thinDiameters.reduce((acc, diameter) => {
            if (version === 1) {
                return acc + floors.reduce((acc3, floor) => acc3 + (calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength)), 0)
            }
            else {
                return acc + floors.reduce((acc3, floor) => acc3 + (calculateWeight(diameter, orderSummary[diameter][floor].orderWastedLength)), 0)
            }
        }, 0)
        thinWaste = thinDenom !== 0 ? `${(100 * (thinNumer / thinDenom)).toFixed(2)}%` : '-'
        thinYield = thinDenom !== 0 ? `${(100 - 100 * (thinNumer / thinDenom)).toFixed(2)}%` : '-'


        thickDenom = thickDiameters.reduce((acc, diameter) => {
            return acc + floors.reduce((acc2, floor) => acc2 + calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength), 0)
        }, 0)
        thickNumer = thickDiameters.reduce((acc, diameter) => {
            if (version === 1) {
                return acc + floors.reduce((acc3, floor) => acc3 + (calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength)), 0)
            }
            else {
                return acc + floors.reduce((acc3, floor) => acc3 + (calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength)), 0)
            }
        }, 0)
        thickWaste = thickDenom !== 0 ? `${(100 * (thickNumer / thickDenom)).toFixed(2)}%` : '-'
        thickYield = thickDenom !== 0 ? `${(100 - 100 * (thickNumer / thickDenom)).toFixed(2)}%` : '-'
    }
    else {
        thinDenom = thinDiameters.reduce((acc, diameter) => {
            return acc + (props.result.diameterResults[diameter].totalLogWeight)
        }, 0)
        thinNumer = thinDiameters.reduce((acc, diameter) => {
            if (version === 2) {
                return acc + (props.result.diameterResults[diameter].wastedWeight)
            }
            else {
                return acc + (props.result.diameterResults[diameter].surplusWeight - props.result.diameterResults[diameter].subWeight)
            }
        }, 0)

        thinWaste = thinDenom !== 0 ? `${(100 * (thinNumer / thinDenom)).toFixed(2)}%` : '-'
        thinYield = thinDenom !== 0 ? `${(100 - 100 * (thinNumer / thinDenom)).toFixed(2)}%` : '-'

        thickDenom = thickDiameters.reduce((acc, diameter) => {
            return acc + (props.result.diameterResults[diameter].totalLogWeight)
        }, 0)
        thickNumer = thickDiameters.reduce((acc, diameter) => {
            if (version === 2) {
                return acc + (props.result.diameterResults[diameter].wastedWeight)
            }
            else {
                return acc + (props.result.diameterResults[diameter].surplusWeight - props.result.diameterResults[diameter].subWeight)
            }
        }, 0)
        thickWaste = thickDenom !== 0 ? `${(100 * (thickNumer / thickDenom)).toFixed(2)}%` : '-'
        thickYield = thickDenom !== 0 ? `${(100 - 100 * (thickNumer / thickDenom)).toFixed(2)}%` : '-'
    }

    return floorAvailable ? (
        <Row>
            <Container className="m-2">
                <h5>{t("Sipariş Boy Sayısı")}</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidth}%` }}></th>
                                {diameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidth}%` }}>{`Φ${diameter}`}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {diameters.map((diameter, index) => (
                                            <td key={index}>
                                                {orderSummary[diameter][floor].orderLogs}
                                            </td>
                                        ))}
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {diameters.map((diameter, index) => (
                                    <th key={index}>
                                        {totalOrderLogs[index]}
                                    </th>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Sipariş Ağırlığı")} (kg)</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderLogs * logLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThin ? <th>
                                            {thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderLogs * logLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderLogs * logLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThick ? <th>
                                            {thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderLogs * logLength), 0).toFixed(0)}
                                        </th> : <></>}

                                        <th>{floorInfo[floor].orderWeight.toFixed(0)}</th>
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {totalOrderWeights[index].toFixed(0)}
                                    </th>
                                ))}
                                {isThin ? <th>
                                    {Object.keys(thinDiameters).map(Number).reduce((acc, index) => acc + totalOrderWeights[index], 0).toFixed(0)}
                                </th> : <></>}
                                {thickDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {totalOrderWeights[nofThins + index].toFixed(0)}
                                    </th>
                                ))}
                                {isThick ? <th>
                                    {Object.keys(thickDiameters).map(Number).reduce((acc, index) => acc + totalOrderWeights[nofThins + index], 0).toFixed(0)}
                                </th> : <></>}
                                <th>{totalOrderWeights.reduce((sum, i) => sum + i, 0).toFixed(0)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Toplam Boy Sayısı")}</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidth}%` }}></th>
                                {diameters.map((diameter, index) =>
                                    <th key={index} scope="col" style={{ width: `${colWidth}%` }}>{`Φ${diameter}`}</th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[eval(floor)]}</th>
                                        {diameters.map((diameter, index) => (
                                            <td key={index}>
                                                {orderSummary[diameter][floor].totalLogs}
                                            </td>
                                        ))}
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {diameters.map((diameter, index) => (
                                    <th key={index}>
                                        {props.result.diameterResults[diameter].totalLogsUsed}
                                    </th>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Toplam Ağırlık")} (kg)</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].totalLogs * logLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThin ? <th>
                                            {thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].totalLogs * logLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].totalLogs * logLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThick ? <th>
                                            {thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].totalLogs * logLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        <th>{floorInfo[floor].totalWeight.toFixed(0)}</th>
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {totalLogWeights[index].toFixed(0)}
                                    </th>
                                ))}
                                {isThin ? <th>
                                    {Object.keys(thinDiameters).map(Number).reduce((acc, index) => acc + totalLogWeights[index], 0).toFixed(0)}
                                </th> : <></>}
                                {thickDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {totalLogWeights[nofThins + index].toFixed(0)}
                                    </th>
                                ))}
                                {isThick ? <th>
                                    {Object.keys(thickDiameters).map(Number).reduce((acc, index) => acc + totalLogWeights[nofThins + index], 0).toFixed(0)}
                                </th> : <></>}
                                <th>{totalLogWeights.reduce((sum, i) => sum + i, 0).toFixed(0)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Elde Kalan Fire Ağırlığı")} (kg)</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderOutageLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThin ? <th>
                                            {thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderOutageLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderOutageLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThick ? <th>
                                            {thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderOutageLength), 0).toFixed(0)}
                                        </th> : <></>}

                                        <th>{floorInfo[floor].orderOutageWeight.toFixed(0)}</th>
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {orderOutageWeights[index].toFixed(0)}
                                    </th>
                                ))}
                                {isThin ? <th>
                                    {Object.keys(thinDiameters).map(Number).reduce((acc, index) => acc + orderOutageWeights[index], 0).toFixed(0)}
                                </th> : <></>}
                                {thickDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {orderOutageWeights[nofThins + index].toFixed(0)}
                                    </th>
                                ))}
                                {isThick ? <th>
                                    {Object.keys(thickDiameters).map(Number).reduce((acc, index) => acc + orderOutageWeights[nofThins + index], 0).toFixed(0)}
                                </th> : <></>}
                                <th>{orderOutageWeights.reduce((sum, i) => sum + i, 0).toFixed(0)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Teorik Fire Ağırlığı")} (kg)</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThin ? <th>
                                            {thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThick ? <th>
                                            {thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        <td style={{ fontWeight: 'bold' }}>{floorInfo[floor].orderWastedWeight.toFixed(0)}</td>
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {orderWastedWeights[index].toFixed(0)}
                                    </th>
                                ))}
                                {isThin ? <th>
                                    {Object.keys(thinDiameters).map(Number).reduce((acc, index) => acc + orderWastedWeights[index], 0).toFixed(0)}
                                </th> : <></>}
                                {thickDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {orderWastedWeights[nofThins + index].toFixed(0)}
                                    </th>
                                ))}
                                {isThick ? <th>
                                    {Object.keys(thickDiameters).map(Number).reduce((acc, index) => acc + orderWastedWeights[nofThins + index], 0).toFixed(0)}
                                </th> : <></>}
                                <th>{orderWastedWeights.reduce((sum, i) => sum + i, 0).toFixed(0)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Yerine Kullanım Ağırlığı")} (kg)</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {
                                if (floors.includes(floor)) {
                                    return <tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].substitutionLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThin ? <th>
                                            {thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].substitutionLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => (
                                            <td key={index}>
                                                {calculateWeight(Number(diameter), orderSummary[diameter][floor].substitutionLength).toFixed(0)}
                                            </td>
                                        ))}
                                        {isThick ? <th>
                                            {thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].substitutionLength), 0).toFixed(0)}
                                        </th> : <></>}
                                        <td style={{ fontWeight: 'bold' }}>{floorInfo[floor].substitutionWeight.toFixed(0)}</td>
                                    </tr>
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {substitutionWeights[index].toFixed(0)}
                                    </th>
                                ))}
                                {isThin ? <th>
                                    {Object.keys(thinDiameters).map(Number).reduce((acc, index) => acc + substitutionWeights[index], 0).toFixed(0)}
                                </th> : <></>}
                                {thickDiameters.map((diameter, index) => (
                                    <th key={index}>
                                        {substitutionWeights[nofThins + index].toFixed(0)}
                                    </th>
                                ))}
                                {isThick ? <th>
                                    {Object.keys(thickDiameters).map(Number).reduce((acc, index) => acc + substitutionWeights[nofThins + index], 0).toFixed(0)}
                                </th> : <></>}
                                <th>{substitutionWeights.reduce((sum, i) => sum + i, 0).toFixed(0)}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>
            {
                version === 1 ?
                    <Container className="m-2">
                        <h5>{t("Verim Hesabında Kullanılan Fire Ağırlığı")} (kg)</h5>
                        <div className='table-responsive'>
                            <table className="table border table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                        {thinDiameters.map((diameter, index) =>
                                            <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                        )}
                                        {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                        {thickDiameters.map((diameter, index) =>
                                            <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                        )}
                                        {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                        <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {floorIndexes.map((floor) => {
                                        if (floors.includes(floor)) {
                                            return <tr key={floor}>
                                                <th>{allFloors[Number(floor)]}</th>
                                                {thinDiameters.map((diameter, index) => {
                                                    if (version === 1) {
                                                        return <td key={index}>
                                                            {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength).toFixed(0)}
                                                        </td>
                                                    }
                                                    else {
                                                        return <td key={index}>
                                                            {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength).toFixed(0)}
                                                        </td>
                                                    }
                                                })}
                                                {isThin ?
                                                    <th>
                                                        {
                                                            version === 1 ?
                                                                thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength), 0).toFixed(0)
                                                                :
                                                                thinDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength), 0).toFixed(0)
                                                        }
                                                    </th>
                                                    :
                                                    <></>
                                                }
                                                {thickDiameters.map((diameter, index) => {
                                                    if (version === 1) {
                                                        return <td key={index}>
                                                            {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength).toFixed(0)}
                                                        </td>
                                                    }
                                                    else {
                                                        return <td key={index}>
                                                            {calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength).toFixed(0)}
                                                        </td>
                                                    }
                                                })}
                                                {isThick ?
                                                    <th>
                                                        {
                                                            version === 1 ?
                                                                thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength), 0).toFixed(0)
                                                                :
                                                                thickDiameters.reduce((acc, diameter) => acc + calculateWeight(Number(diameter), orderSummary[diameter][floor].orderWastedLength), 0).toFixed(0)
                                                        }
                                                    </th>
                                                    :
                                                    <></>
                                                }
                                                <td style={{ fontWeight: 'bold' }}>
                                                    {version === 1 ?
                                                        (floorInfo[floor].orderWastedWeight - floorInfo[floor].substitutionWeight).toFixed(0)
                                                        :
                                                        (floorInfo[floor].orderWastedWeight).toFixed(0)
                                                    }
                                                </td>
                                            </tr>
                                        }
                                    })}
                                    <tr>
                                        <th>{t("TÜMÜ")}</th>
                                        {thinDiameters.map((diameter, index) => {
                                            if (version === 1) {
                                                return <th key={index}>
                                                    {(orderWastedWeights[index] - substitutionWeights[index]).toFixed(0)}
                                                </th>
                                            }
                                            else {
                                                return <th key={index}>
                                                    {(orderWastedWeights[index]).toFixed(0)}
                                                </th>
                                            }
                                        })}
                                        {isThin ? <th>
                                            {Object.keys(thinDiameters).map(Number).reduce((acc, index) => acc + (orderWastedWeights[index] - substitutionWeights[index]), 0).toFixed(0)}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => {
                                            if (version === 1) {
                                                return <th key={index}>
                                                    {(orderWastedWeights[nofThins + index] - substitutionWeights[nofThins + index]).toFixed(0)}
                                                </th>
                                            }
                                            else {
                                                return <th key={index}>
                                                    {(orderWastedWeights[nofThins + index]).toFixed(0)}
                                                </th>
                                            }
                                        })}
                                        {isThick ? <th>
                                            {Object.keys(thickDiameters).map(Number).reduce((acc, index) => acc + (orderWastedWeights[nofThins + index] - substitutionWeights[nofThins + index]), 0).toFixed(0)}
                                        </th> : <></>}
                                        {version === 1 ?
                                            <th>{(orderWastedWeights.reduce((sum, i) => sum + i, 0) - substitutionWeights.reduce((sum, i) => sum + i, 0)).toFixed(0)}</th>
                                            :
                                            <th>{(orderWastedWeights.reduce((sum, i) => sum + i, 0)).toFixed(0)}</th>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Container>

                    : <></>
            }

            <Container className="m-2">
                <h5>{t("Verim Yüzdesi")}</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {

                                let numerator;
                                if (version === 1) {
                                    numerator = floorInfo[floor].orderWastedWeight - floorInfo[floor].substitutionWeight
                                }
                                else {
                                    numerator = floorInfo[floor].orderWastedWeight
                                }
                                let denominator = floorInfo[floor].orderWeight

                                let floorResult = denominator !== 0 ? `${(100 - 100 * (numerator / denominator)).toFixed(2)}%` : '-'

                                if (floors.includes(floor)) {
                                    let thinDenom = thinDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength), 0)
                                    let thinNumer;
                                    if (version === 1) {
                                        thinNumer = thinDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength), 0)
                                    }
                                    else {
                                        thinNumer = thinDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength), 0)
                                    }
                                    let thinResult = thinDenom !== 0 ? `${(100 - 100 * (thinNumer / thinDenom)).toFixed(2)}%` : '-'

                                    let thickDenom = thickDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength), 0)
                                    let thickNumer;
                                    if (version === 1) {
                                        thickNumer = thickDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength), 0)
                                    }
                                    else {
                                        thickNumer = thickDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength), 0)
                                    }
                                    let thickResult = thickDenom !== 0 ? `${(100 - 100 * (thickNumer / thickDenom)).toFixed(2)}%` : '-'

                                    return (<tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => {
                                            let denominator = calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength)
                                            let numerator;
                                            if (version === 1) {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength)
                                            }
                                            else {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength)
                                            }
                                            let result = orderSummary[diameter][floor].orderLogs !== 0 ? `${(100 - 100 * (numerator / denominator)).toFixed(2)}%` : '-'
                                            return (<td key={index}> {result} </td>)
                                        })}
                                        {isThin ? <th>
                                            {thinResult}
                                        </th> : <></>}
                                        {thickDiameters.map((diameter, index) => {
                                            let denominator = calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength)
                                            let numerator;
                                            if (version === 1) {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength)
                                            }
                                            else {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength)
                                            }
                                            let result = orderSummary[diameter][floor].orderLogs !== 0 ? `${(100 - 100 * (numerator / denominator)).toFixed(2)}%` : '-'
                                            return (<td key={index}> {result} </td>)
                                        })}
                                        {isThick ? <th>
                                            {thickResult}
                                        </th> : <></>}
                                        <td style={{ fontWeight: 'bold' }}>{floorResult}</td>
                                    </tr>)
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => {
                                    let yieldPercentage = wastePercentages[index] !== -1 ? `${(100 - wastePercentages[index]).toFixed(2)}%` : '-'
                                    return <th key={index}> {yieldPercentage} </th>
                                })}
                                {isThin ? <th>
                                    {thinYield}
                                </th> : <></>}
                                {thickDiameters.map((diameter, index) => {
                                    let yieldPercentage = wastePercentages[nofThins + index] !== -1 ? `${(100 - wastePercentages[nofThins + index]).toFixed(2)}%` : '-'
                                    return <th key={index}> {yieldPercentage} </th>
                                })}
                                {isThick ? <th> {thickYield} </th> : <></>}
                                {version === 1 ?
                                    <th>{
                                        (100 - 100 * ((Object.values(floorInfo).reduce((sum, i) => sum += i.orderWastedWeight, 0) - Object.values(floorInfo).reduce((sum, i) => sum += i.substitutionWeight, 0))
                                            / Object.values(floorInfo).reduce((sum, i) => sum += i.orderWeight, 0))).toFixed(2)
                                    }%</th>
                                    :
                                    <th>{
                                        (100 - 100 * ((Object.values(floorInfo).reduce((sum, i) => sum += i.orderWastedWeight, 0))
                                            / Object.values(floorInfo).reduce((sum, i) => sum += i.orderWeight, 0))).toFixed(2)
                                    }%</th>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>

            <Container className="m-2">
                <h5>{t("Fire Yüzdesi")}</h5>
                <div className='table-responsive'>
                    <table className="table border table-hover">
                        <thead>
                            <tr>
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                                {thinDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                                {thickDiameters.map((diameter, index) =>
                                    <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                                )}
                                {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                                <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {floorIndexes.map((floor) => {

                                let numerator;
                                if (version === 1) {
                                    numerator = floorInfo[floor].orderWastedWeight - floorInfo[floor].substitutionWeight
                                }
                                else {
                                    numerator = floorInfo[floor].orderWastedWeight
                                }
                                let denominator = floorInfo[floor].orderWeight

                                let floorResult = denominator !== 0 ? `${(100 * (numerator / denominator)).toFixed(2)}%` : '-'

                                if (floors.includes(floor)) {

                                    let thinDenom = thinDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength), 0)
                                    let thinNumer;
                                    if (version === 1) {
                                        thinNumer = thinDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength), 0)
                                    }
                                    else {
                                        thinNumer = thinDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength), 0)
                                    }
                                    let thinResult = thinDenom !== 0 ? `${(100 * (thinNumer / thinDenom)).toFixed(2)}%` : '-'

                                    let thickDenom = thickDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength), 0)
                                    let thickNumer;
                                    if (version === 1) {
                                        thickNumer = thickDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength), 0)
                                    }
                                    else {
                                        thickNumer = thickDiameters.reduce((acc, diameter) => acc + calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength), 0)
                                    }
                                    let thickResult = thickDenom !== 0 ? `${(100 * (thickNumer / thickDenom)).toFixed(2)}%` : '-'

                                    return (<tr key={floor}>
                                        <th>{allFloors[Number(floor)]}</th>
                                        {thinDiameters.map((diameter, index) => {
                                            let denominator = calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength)
                                            let numerator;
                                            if (version === 1) {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength)
                                            }
                                            else {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength)
                                            }
                                            let result = orderSummary[diameter][floor].orderLogs !== 0 ? `${(100 * (numerator / denominator)).toFixed(2)}%` : '-'
                                            return (<td key={index}> {result} </td>)
                                        })}
                                        {isThin ? <th> {thinResult} </th> : <></>}
                                        {thickDiameters.map((diameter, index) => {
                                            let denominator = calculateWeight(diameter,orderSummary[diameter][floor].orderLogs * logLength)
                                            let numerator;
                                            if (version === 1) {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength - orderSummary[diameter][floor].substitutionLength)
                                            }
                                            else {
                                                numerator = calculateWeight(diameter,orderSummary[diameter][floor].orderWastedLength)
                                            }
                                            let result = orderSummary[diameter][floor].orderLogs !== 0 ? `${(100 * (numerator / denominator)).toFixed(2)}%` : '-'
                                            return (<td key={index}> {result} </td>)
                                        })}
                                        {isThick ? <th> {thickResult} </th> : <></>}
                                        <td style={{ fontWeight: 'bold' }}>{floorResult}</td>
                                    </tr>)
                                }
                            })}
                            <tr>
                                <th>{t("TÜMÜ")}</th>
                                {thinDiameters.map((diameter, index) => {
                                    let wastePercentage = wastePercentages[index] !== -1 ? `${wastePercentages[index].toFixed(2)}%` : '-'
                                    return <th key={index}> {wastePercentage} </th>
                                })}
                                {isThin ? <th> {thinWaste} </th> : <></>}
                                {thickDiameters.map((diameter, index) => {
                                    let wastePercentage = wastePercentages[nofThins + index] !== -1 ? `${wastePercentages[nofThins + index].toFixed(2)}%` : '-'
                                    return <th key={index}> {wastePercentage} </th>
                                })}
                                {isThick ? <th> {thickWaste} </th> : <></>}
                                {version === 1 ?
                                    <th>{
                                        (100 * ((Object.values(floorInfo).reduce((sum, i) => sum += i.orderWastedWeight, 0) - Object.values(floorInfo).reduce((sum, i) => sum += i.substitutionWeight, 0))
                                            / Object.values(floorInfo).reduce((sum, i) => sum += i.orderWeight, 0))).toFixed(2)
                                    }%</th>
                                    :
                                    <th>{
                                        (100 * ((Object.values(floorInfo).reduce((sum, i) => sum += i.orderWastedWeight, 0))
                                            / Object.values(floorInfo).reduce((sum, i) => sum += i.orderWeight, 0))).toFixed(2)
                                    }%</th>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Container>
        </Row>

    ) : (
        <Container className='m-2'>
            <div className="table-responsive">
                <table className="table border table-hover">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}></th>
                            {thinDiameters.map((diameter, index) =>
                                <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                            )}
                            {isThin ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("İnce")} </th> : <></>}
                            {thickDiameters.map((diameter, index) =>
                                <th key={diameter} scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{`Φ${diameter}`}</th>
                            )}
                            {isThick ? <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}> {t("Kalın")} </th> : <></>}
                            <th scope="col" style={{ width: `${colWidthWithThinThick}%` }}>{t("TÜMÜ")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>{t("Boy Sayısı")}</th>
                            {thinDiameters.map((diameter, index) =>
                                <td key={index}>{props.result.diameterResults[diameter].totalLogsUsed}</td>
                            )}
                            {isThin ? <th>-</th> : <></>}
                            {thickDiameters.map((diameter, index) =>
                                <td key={index}>{props.result.diameterResults[diameter].totalLogsUsed}</td>
                            )}
                            {isThick ? <th>-</th> : <></>}
                            <th>-</th>
                        </tr>
                        <tr>
                            <th>{t("Toplam Ağırlık")} (kg)</th>
                            {thinDiameters.map((diameter, index) =>
                                <td key={index}>{Number(props.result.diameterResults[diameter].totalLogWeight)}</td>
                            )}
                            {isThin ? <th>{thinDiameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].totalLogWeight), 0)}</th> : <></>}
                            {thickDiameters.map((diameter, index) =>
                                <td key={index}>{Number(props.result.diameterResults[diameter].totalLogWeight)}</td>
                            )}
                            {isThick ? <th>{thickDiameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].totalLogWeight), 0)}</th> : <></>}
                            <th>{diameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].totalLogWeight), 0)}</th>
                        </tr>
                        <tr>
                            <th>{t("Elde Kalan Fire Ağırlığı")} (kg)</th>
                            {thinDiameters.map((diameter, index) => {
                                if (version === 2) {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].outageWeight)}</td>
                                }
                                else {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].outageWeight + props.result.diameterResults[diameter].overproductionWeight - props.result.diameterResults[diameter].subWeight)}</td>
                                }
                            })}
                            {isThin ? <th>{thinDiameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].outageWeight + props.result.diameterResults[diameter].overproductionWeight - props.result.diameterResults[diameter].subWeight), 0)}</th> : <></>}
                            {thickDiameters.map((diameter, index) => {
                                if (version === 2) {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].outageWeight)}</td>
                                }
                                else {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].outageWeight + props.result.diameterResults[diameter].overproductionWeight - props.result.diameterResults[diameter].subWeight)}</td>
                                }
                            })}
                            {isThick ? <th>{thickDiameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].outageWeight + props.result.diameterResults[diameter].overproductionWeight - props.result.diameterResults[diameter].subWeight), 0)}</th> : <></>}
                            <th>{diameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].outageWeight + props.result.diameterResults[diameter].overproductionWeight - props.result.diameterResults[diameter].subWeight), 0)}</th>
                        </tr>
                        <tr>
                            <th>{t("Teorik Fire Ağırlığı")} (kg)</th>
                            {thinDiameters.map((diameter, index) => {
                                if (version === 2) {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].wastedWeight)}</td>
                                }
                                else {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].surplusWeight)}</td>
                                }
                            })}
                            {isThin ? <th>{thinDiameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].surplusWeight), 0)}</th> : <></>}
                            {thickDiameters.map((diameter, index) => {
                                if (version === 2) {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].wastedWeight)}</td>
                                }
                                else {
                                    return <td key={index}>{Number(props.result.diameterResults[diameter].surplusWeight)}</td>
                                }
                            })}
                            {isThick ? <th>{thickDiameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].surplusWeight), 0)}</th> : <></>}
                            <th>{diameters.reduce((acc, diameter) => acc + Number(props.result.diameterResults[diameter].surplusWeight), 0)}</th>
                        </tr>
                        <tr>
                            <th>{t("Verim Yüzdesi")}</th>
                            {thinDiameters.map((diameter, index) => {
                                let numerator
                                if (version === 2) {
                                    numerator = props.result.diameterResults[diameter].wastedWeight
                                }
                                else {
                                    numerator = (props.result.diameterResults[diameter].surplusWeight - props.result.diameterResults[diameter].subWeight)
                                }
                                return <td key={index}>{(100 - 100 * (numerator / props.result.diameterResults[diameter].totalLogWeight)).toFixed(2)}%</td>
                            })}
                            {isThin ? <th>{thinYield}</th> : <></>}
                            {thickDiameters.map((diameter, index) => {
                                let numerator
                                if (version === 2) {
                                    numerator = props.result.diameterResults[diameter].wastedWeight
                                }
                                else {
                                    numerator = (props.result.diameterResults[diameter].surplusWeight - props.result.diameterResults[diameter].subWeight)
                                }
                                return <td key={index}>{(100 - 100 * (numerator / props.result.diameterResults[diameter].totalLogWeight)).toFixed(2)}%</td>
                            })}
                            {isThick ? <th>{thickYield}</th> : <></>}
                            <th>{(100 - 100 * (thinNumer + thickNumer) / (thinDenom + thickDenom)).toFixed(2)}%</th>
                        </tr>
                        <tr>
                            <th>{t("Fire Yüzdesi")}</th>
                            {thinDiameters.map((diameter, index) => {
                                let numerator
                                if (version === 2) {
                                    numerator = props.result.diameterResults[diameter].wastedWeight
                                }
                                else {
                                    numerator = (props.result.diameterResults[diameter].surplusWeight - props.result.diameterResults[diameter].subWeight)
                                }
                                return <td key={index}>{(100 * (numerator / props.result.diameterResults[diameter].totalLogWeight)).toFixed(2)}%</td>
                            })}
                            {isThin ? <th>{thinWaste}</th> : <></>}
                            {thickDiameters.map((diameter, index) => {
                                let numerator
                                if (version === 2) {
                                    numerator = props.result.diameterResults[diameter].wastedWeight
                                }
                                else {
                                    numerator = (props.result.diameterResults[diameter].surplusWeight - props.result.diameterResults[diameter].subWeight)
                                }
                                return <td key={index}>{(100 * (numerator / props.result.diameterResults[diameter].totalLogWeight)).toFixed(2)}%</td>
                            })}
                            {isThick ? <th>{thickWaste}</th> : <></>}
                            <th>{(100 * (thinNumer + thickNumer) / (thinDenom + thickDenom)).toFixed(2)}%</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </Container>
    )
}

export default AllDiameterResultsTable;