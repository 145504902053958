import apiClient from "../apiClient";

export const downloadInvoice = async (order_id) => {
    const url = `${process.env.REACT_APP_API_URL}/api/payment/download-invoice/${order_id}`;

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}