import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { RiPencilFill } from 'react-icons/ri'
import useUnitPrices from '../../../hooks/admin/useUnitPrices';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';



const UnitPrices = () => {

    const {
        isLoading,

        projectPrice,
        projectDelegatePrice,
        projectWithDelegationPrice,
        projectDelegateCut,

        metalProfileProjectPrice,
        metalProfileProjectDelegatePrice,
        metalProfileProjectWithDelegationPrice,
        metalProfileProjectDelegateCut,

        credits1000Price,
        credits2000Price,
        credits3000Price,
        vat,
        transferDiscount,

        modalInfo,

        setModalInfo,
        priceLoading,
        priceSettingNote,
        handleSetUnitPrice,

        price,
        setPrice,

        modalOnHide,
    } = useUnitPrices();

    if (isLoading) {
        return LoadingContainer
    }

    const vatDate = new Date(vat.last_update)
    const transferDiscountDate = new Date(transferDiscount.last_update)
    const projectPriceDate = new Date(projectPrice.last_update)
    const projectDelegatePriceDate = new Date(projectDelegatePrice.last_update)
    const projectWithDelegationPriceDate = new Date(projectWithDelegationPrice.last_update)
    const projectDelegateCutDate = new Date(projectDelegateCut.last_update)
    const metalProfileProjectPriceDate = new Date(metalProfileProjectPrice.last_update)
    const metalProfileProjectDelegatePriceDate = new Date(metalProfileProjectDelegatePrice.last_update)
    const metalProfileProjectWithDelegationPriceDate = new Date(metalProfileProjectWithDelegationPrice.last_update)
    const metalProfileProjectDelegateCutDate = new Date(metalProfileProjectDelegateCut.last_update)
    const credits1000PriceDate = new Date(credits1000Price.last_update)
    const credits2000PriceDate = new Date(credits2000Price.last_update)
    const credits3000PriceDate = new Date(credits3000Price.last_update)

    const vatConstant = 1 + parseFloat(vat.unit_price)

    return (
        <Container className='text-center'>

            <h3 className='mb-4'>KDV Oranı</h3>

            <Table striped="columns" className='border mb-3'>
                <thead>
                    <tr>
                        <th>% Oran (x100)</th>
                        <th>Son Güncelleme</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{vat.unit_price}</th>
                        <td>{vatDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: vat.unit_price,
                                explanation: "KDV Oranı",
                                type: "vat",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                </tbody>

            </Table>

            <h3 className='mb-4'>Havale İndirim Oranı</h3>

            <Table striped="columns" className='border mb-3'>
                <thead>
                    <tr>
                        <th>% Oran (x100)</th>
                        <th>Son Güncelleme</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{transferDiscount.unit_price}</th>
                        <td>{transferDiscountDate.toDateString()}</td>
                        <td onClick={() => {
                            setModalInfo({
                                show: true,
                                initalPrice: transferDiscount.unit_price,
                                explanation: "Havale İndirimi Oranı",
                                type: "transfer_discount",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                </tbody>

            </Table>


            <h3 className='mb-4'>Birim Fiyatlar</h3>
            <Table striped="columns" className='border'>
                <thead>
                    <tr>
                        <th>Açıklama</th>
                        <th>KDV Dahil Birim Fiyat (₺)</th>
                        <th>KDV Hariç Birim Fiyat (₺)</th>
                        <th>Son Güncelleme</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Proje Bazlı Standart Fiyat (Donatı)</td>
                        <th>{projectPrice.unit_price}</th>
                        <th>{(projectPrice.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{projectPriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: projectPrice.unit_price,
                                explanation: "Proje bazlı ödeme KDV Dahil birim fiyatı",
                                type: "project",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Temsilcilik İndirimli Proje Bazlı Fiyat (Donatı)</td>
                        <th>{projectDelegatePrice.unit_price}</th>
                        <th>{(projectDelegatePrice.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{projectDelegatePriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: projectDelegatePrice.unit_price,
                                explanation: "Temsilcilik İndirimli Proje Bazlı KDV Dahil birim fiyatı",
                                type: "project_delegate",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Temsilci Vesileli Satın Alım Fiyatı (Donatı)</td>
                        <th>{projectWithDelegationPrice.unit_price}</th>
                        <th>{(projectWithDelegationPrice.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{projectWithDelegationPriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: projectWithDelegationPrice.unit_price,
                                explanation: "Temsilci Vesileli Satın Alım KDV Dahil birim fiyatı",
                                type: "project_with_delegation",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Temsilci Vesileli Satın Alımlardaki Kesinti Tutarı (Donatı)</td>
                        <th>{projectDelegateCut.unit_price}</th>
                        <th>{(projectDelegateCut.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{projectDelegateCutDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: projectDelegateCut.unit_price,
                                explanation: "Temsilci Vesileli Satın Alımlardaki Kesinti Tutarı",
                                type: "project_delegate_cut",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Proje Bazlı Standart Fiyat (Profil)</td>
                        <th>{metalProfileProjectPrice.unit_price}</th>
                        <th>{(metalProfileProjectPrice.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{metalProfileProjectPriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: metalProfileProjectPrice.unit_price,
                                explanation: "(Profil) Proje bazlı ödeme KDV Dahil birim fiyatı",
                                type: "metal_profile_project",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Temsilcilik İndirimli Proje Bazlı Fiyat (Profil)</td>
                        <th>{metalProfileProjectDelegatePrice.unit_price}</th>
                        <th>{(metalProfileProjectDelegatePrice.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{metalProfileProjectDelegatePriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: metalProfileProjectDelegatePrice.unit_price,
                                explanation: "(Profil) Temsilcilik İndirimli Proje Bazlı KDV Dahil birim fiyatı",
                                type: "metal_profile_project_delegate",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Temsilci Vesileli Satın Alım Fiyatı (Profil)</td>
                        <th>{metalProfileProjectWithDelegationPrice.unit_price}</th>
                        <th>{(metalProfileProjectWithDelegationPrice.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{metalProfileProjectWithDelegationPriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: metalProfileProjectWithDelegationPrice.unit_price,
                                explanation: "(Profil) Temsilci Vesileli Satın Alım KDV Dahil birim fiyatı",
                                type: "metal_profile_project_with_delegation",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>Temsilci Vesileli Satın Alımlardaki Kesinti Tutarı (Profil)</td>
                        <th>{metalProfileProjectDelegateCut.unit_price}</th>
                        <th>{(metalProfileProjectDelegateCut.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{metalProfileProjectDelegateCutDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setProjectPrice(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: metalProfileProjectDelegateCut.unit_price,
                                explanation: "(Profil) Temsilci Vesileli Satın Alımlardaki Kesinti Tutarı",
                                type: "metal_profile_project_delegate_cut",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>1000 Ton Kredisi</td>
                        <th>{credits1000Price.unit_price}</th>
                        <th>{(credits1000Price.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{credits1000PriceDate.toDateString()}</td>
                        <td onClick={() => {
                            // setPriceSettingFunction((x) => { setCredits1000Price(x) })
                            setModalInfo({
                                show: true,
                                initalPrice: credits1000Price.unit_price,
                                explanation: "1000 ton kredi için KDV Dahil birim fiyatı",
                                type: "100credits",
                            })
                        }
                        }><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>2000 Ton Kredisi</td>
                        <th>{credits2000Price.unit_price}</th>
                        <th>{(credits2000Price.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{credits2000PriceDate.toDateString()}</td>
                        <td onClick={() => setModalInfo({
                            show: true,
                            initalPrice: credits2000Price.unit_price,
                            explanation: "2000 ton kredi için KDV Dahil birim fiyatı",
                            type: "2000credits",
                        })}><RiPencilFill /></td>
                    </tr>
                    <tr>
                        <td>3000 Ton Kredisi</td>
                        <th>{credits3000Price.unit_price}</th>
                        <th>{(credits3000Price.unit_price / vatConstant).toFixed(2)}</th>
                        <td>{credits3000PriceDate.toDateString()}</td>
                        <td onClick={() => setModalInfo({
                            show: true,
                            initalPrice: credits3000Price.unit_price,
                            explanation: "3000 ton kredi için KDV Dahil birim fiyatı",
                            type: "3000credits",
                        })}><RiPencilFill /></td>
                    </tr>
                </tbody>
            </Table>

            <Modal
                show={modalInfo.show}
                onHide={() => modalOnHide()}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                animation={false}
            >
                <Form onSubmit={(e) => handleSetUnitPrice(e, modalInfo.type, price)}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {(modalInfo.type === "vat" || modalInfo.type === "transfer_discount") ?
                                "Oranı Belirle" : "KDV Dahil Birim Fiyat Belirle"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4 className='mb-3'>{modalInfo.explanation}</h4>
                        {(modalInfo.type === "vat" || modalInfo.type === "transfer_discount") &&
                            <>
                                <p className='fw-bold text-danger'>Lütfen oranı 0 ile 1 arasında bir ondalıklı sayı olacak şekilde giriniz.</p>
                                <p className='fw-bold text-danger'>Örneğin %20 için 0.2 girmeniz gerekir. %2.5 için 0.025 girmeniz gerekir.</p>
                            </>
                        }
                        <InputGroup className="mb-3">
                            {(modalInfo.type === "vat" || modalInfo.type === "transfer_discount") &&
                                <InputGroup.Text id="basic-addon2">
                                    {"% ("}
                                </InputGroup.Text>}
                            {(modalInfo.type === "vat" || modalInfo.type === "transfer_discount") ?
                                <Form.Control
                                    type='number'
                                    max={1.00}
                                    min={0.00}
                                    step={0.001}
                                    defaultValue={modalInfo.initialPrice}
                                    onChange={(e) => setPrice(Number(e.target.value))}
                                /> :
                                <Form.Control
                                    type='number'
                                    step={0.01}
                                    defaultValue={modalInfo.initialPrice}
                                    onChange={(e) => setPrice(Number(e.target.value))}
                                />
                            }
                            <InputGroup.Text id="basic-addon2">
                                {modalInfo.type === "vat" || modalInfo.type === "transfer_discount" ?  " x 100)" : "₺"}
                            </InputGroup.Text>
                        </InputGroup>
                        <p className={`text-${priceSettingNote.variant}`}>{priceSettingNote.note}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => modalOnHide()}>Close</Button>
                        {priceLoading ? <Button disabled={true}>Yükleniyor...</Button>
                            : <Button variant='primary' type='submit'>Submit</Button>}
                    </Modal.Footer>
                </Form>
            </Modal>
        </Container>
    );
};

export default UnitPrices;
