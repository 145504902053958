// hooks/useLogin.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { verifyUser } from '../../services/verifyService';

import { useTranslation } from 'react-i18next';

const useVerification = () => {
    const [verifyNote, setVerifyNote] = useState({});
    const [isLoading, setIsLoading] = useState(true)

    const location = useLocation();

    const { t } = useTranslation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search.replaceAll("&amp;", "&"));

        if (searchParams.get('user_id') && searchParams.get('timestamp') && searchParams.get('signature')) {

            const user_id = searchParams.get('user_id')
            const timestamp = searchParams.get('timestamp')
            const signature = searchParams.get('signature')

            async function fetchData() {
                try {
                    const verifyResponse = await verifyUser(user_id, timestamp, signature);

                    if (verifyResponse.ok) {
                        const data = await verifyResponse.json()
                        setVerifyNote({
                            "state": "success",
                            "note": t("Doğrulama başarılı. Hesabınıza giriş yapabilirsiniz.")
                        })
                    }
                    else {
                        try{
                            const data = await verifyResponse.json()
                            if (data.detail === 'Signature expired') {
                                setVerifyNote({
                                    "state": "danger",
                                    "note": t("Doğrulama linkinizin süresi geçmiştir. Hesabınıza giriş yapmayı deneyerek yeni doğrulama maili alabilirsiniz.")
                                })
                            }
                            else {
                                setVerifyNote({
                                    "state": "danger",
                                    "note": `${t("Doğrulama başarısız. Tekrar deneyin.")} (${t("Hata Kodu")}: 7V2J)`
                                })
                            }
                        }
                        catch (error) {
                            setVerifyNote({
                                "state": "danger",
                                "note": `${t("Sistem hatası")}. (${t("Hata Kodu")}: 9JRE)`
                            })
                        }
                    }
                } catch (error) {
                    setVerifyNote({
                        "state": "danger",
                        "note": `${t("Sistem hatası")}. (Hata Kodu: 5JY8)`
                    })
                }
                setIsLoading(false)

            }
            fetchData()

        }
        else {
            setVerifyNote({
                "state": "danger",
                "note": `${t("Doğrulama başarısız. Tekrar deneyin.")} 4`
            })
            setIsLoading(false)

        }
    }, [location.search]);

    return {

        isLoading,
        verifyNote,
    };
};

export default useVerification;
