import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import { MdEmail } from 'react-icons/md'; // use react-icons for this

import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import styles from './Reset.module.css'
import { useTranslation } from 'react-i18next';


const ResetLinkSent = () => {

    const { t } = useTranslation();
    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white mt-5 p-5 shadow-sm ${styles.verifyContainer}`}>
                    <MdEmail size={70} color="gray" className={styles.checkIcon} />
                    <h2 className={styles.title}>{t("Şifre Sıfırlama E-postası Gönderildi")}</h2>
                    <p className={styles.message}>{t("E-posta adresinize sıfırlama e-postası gönderilmiştir. Gelen kutunuzu kontrol ediniz.")}</p>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default ResetLinkSent;
