import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import SaleContractText from './SaleContractText';

import { useTranslation } from 'react-i18next';

const SaleContract = () => {

    const { t } = useTranslation();

    document.title = t("Satış Sözleşmesi")

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <p><span className='fw-bold'>{t("NOT")} 1:</span> {t("Bu satış sözleşmesi örnek bir sözleşmedir. Yapılacak satış işlemlerine ait satış sözleşmesi buradaki ilgili kısımların otomatik doldurulmasıyla oluşturulur, satış öncesinde müşteriye onaylatılır ve kaydedilir.")}</p>
                    <p className='mb-5'><span className='fw-bold'>{t("NOT")} 2:</span> {t("Bu satış sözleşmesi iade koşullarını da içermektedir.")} </p>
                    <SaleContractText date="....." clientName="....." clientAddress="....."/>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default SaleContract;
