import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';

import { useTranslation } from "react-i18next";

const AssignedUsers = ({ assignedUsers }) => {
    const homeURL = process.env.REACT_APP_HOME_URL

    const [selectedProjects, setSelectedProjects] = useState([])
    const [selectedUser, setSelectedUser] = useState({})

    const { i18n, t } = useTranslation();

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Container className="border-start border-end border-bottom">
            <Row className="py-3">
                <Col>
                    <h3>{t("Kullanıcılar")}</h3>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{t("E-posta")}</th>
                                <th>{t("İsim")}</th>
                                <th>{t("Soyisim")}</th>
                                <th>{t("Telefon")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                assignedUsers.map((item, idx) => (
                                    <tr key={idx} onClick={() => {
                                        setSelectedProjects(assignedUsers[idx].projects)
                                        setSelectedUser(assignedUsers[idx])
                                    }}
                                    style={{ backgroundColor: assignedUsers[idx].id === selectedUser.id ? '#d8dadd' : '' }}
                                    >
                                        <td>{item.id}</td>
                                        <td>{item.email}</td>
                                        <td>{item.name}</td>
                                        <td>{item.surname}</td>
                                        <td>{item.telephone}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
                <Col>
                    <h3>{t("Projeler")}</h3>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>{t("İsim")}</th>
                                <th>{t("Aktif")}</th>
                                <th>{t("Ağırlık")}</th>
                                <th>{t("Tarih")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectedProjects.map((item, idx) => (
                                    <tr key={idx} onClick={() => {
                                        openInNewTab(`${homeURL}/${i18n.language}/project-info/${item.id}`)
                                    }}>
                                        <td>{item.id}</td>
                                        <td>{item.name}</td>
                                        <td>{item.activated ? t("Evet") : t("Hayır")}</td>
                                        <td>{(item.theoreticalWeight * 1e-3).toFixed(2)}</td>
                                        <td>{new Date(item.creationTime).toLocaleDateString(`${i18n.language}-${i18n.language.toUpperCase()}`)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    )
}

export default AssignedUsers;