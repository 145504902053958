import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Footer from '../../components/Footer/Footer';
import styles from '../Pages.module.css';
import useResetPassword from '../../hooks/useResetPassword/useResetPassword';

import { useTranslation } from 'react-i18next';

const ResetPassword = () => {

    const {
        password,
        setPassword,
        passwordConfirm,
        setPasswordConfirm,

        invalidPassword,
        passwordMismatch,

        handleSubmit,

        resetNote,

        isLoading,
    } = useResetPassword();

    const { t } = useTranslation();

    return (
        <div className={styles.flexWrapper}>
            <AppNavbar />

            <div className={styles.content}>
                <Container style={{ marginTop: '10vh' }}>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={4}>
                            <h3 className="text-center mt-5">{t("Şifre Sıfırlama")}</h3>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="password" className="mb-3">
                                    <Form.Label>{t("Şifre")}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group><Form.Group controlId="passwordConfirm" className="mb-3">
                                    <Form.Label>{t("Şifrenizi Yeniden Girin")}</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={passwordConfirm}
                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                    />
                                </Form.Group>
                                {invalidPassword && (
                                    <p className="text-danger">{t("Şifreniz en az 6 karakter olmalı, harf ve sayılardan oluşmalıdır.")}</p>
                                )}
                                {passwordMismatch && (
                                    <p className="text-danger">{t("Şifreler eşleşmiyor.")}</p>
                                )}
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100 d-block mb-3"
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spinner size='sm'/> : t("Şifremi Sıfırla")}
                                </Button>
                                {resetNote.state !== "dontshow" ? 
                                <p className={`text-${resetNote.state}`}>{resetNote.note}</p>
                                : <></>}
                            </Form>
                        </Col>
                    </Row>
                </Container>

            </div>
            <Footer />

        </div>
    );
};

export default ResetPassword;
