import apiClient from "../apiClient";

export const getPurchases = async (user_id = -1) => {
    let url
    if (user_id === -1) {
        url = `${process.env.REACT_APP_API_URL}/api/payment/`;
    }
    else {
        url = `${process.env.REACT_APP_API_URL}/api/payment/?user_id=${user_id}`;
    }

    const response = await apiClient(url, {
        method: 'GET',
    })

    return response;

}