import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

import Footer from '../../components/Footer/Footer';
import styles from '../Pages.module.css';
import useSendResetPwLink from '../../hooks/useSendResetPwLink/useSendResetPwLink';

import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';


const ForgotPassword = () => {

    const {
        email,
        setEmail,
        handleSubmit,

        errorToast,
        setErrorToast,

        isLoading,
    } = useSendResetPwLink();

    const { t } = useTranslation();

    document.title = t("Şifremi Unuttum")

    return (
        <div className={styles.flexWrapper}>
            <AppNavbar />

            <div className={styles.content}>
                <Container style={{ marginTop: '10vh' }}>
                    <Row className="justify-content-md-center">
                        <Col xs={12} md={4}>
                            <h3 className="text-center mt-5">{t("Şifremi Unuttum")}</h3>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>{t("E-posta")}</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="w-100 d-block mb-3"
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spinner size='sm'/> : t("Şifremi Unuttum")}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container>

            </div>
            <Footer />

            <Toast
                onClose={() => setErrorToast({ show: false, message: "" })}
                show={errorToast.show}
                bg="danger"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{errorToast.message}</Toast.Body>
            </Toast>

        </div>
    );
};

export default ForgotPassword;
