import apiClient from "./apiClient";

const activateDelegation = async (contract) => {
    const url = `${process.env.REACT_APP_API_URL}/api/activate-delegation/`;

    const response = await apiClient(url, {
        method: 'POST',
        body: JSON.stringify({
            "contract": contract,
        }),
    })
    return response;

};

export { activateDelegation };
