// hooks/useLogin.js
import { useState, useEffect } from 'react';
import { getUserData } from '../services/userService'

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const useUserData = () => {

  const [email, setEmail] = useState('');
  const [id, setId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(0)

  const [name, setName] = useState("")

  useEffect(() => {

    const ca_a = localStorage.getItem('ca_a');

    if (ca_a) {
      const ca_a_payload = parseJwt(ca_a)

      const ca_r = localStorage.getItem('ca_r');
      const ca_r_payload = parseJwt(ca_r)

      const payload_email = ca_a_payload.email
      const payload_id = ca_a_payload.user_id
      const payload_user_type = ca_a_payload.user_type
      const ca_a_exp = ca_a_payload.exp
      const ca_r_exp = ca_r_payload.exp

      let payload_name = "";
      if([3,4,5].includes(ca_a_payload.user_type)){
        payload_name = `${ca_a_payload.name} ${ca_a_payload.surname}`
      }

      const currentTime = Date.now() / 1000

      async function fetchData() {
        try {
          const result = await getUserData();
          setEmail(result.email);
          setId(result.id);
          setUserType(result.user_type);
          if([3,4,5].includes(result.user_type)){
            if(result.name != null && result.surname != null){
              setName(`${result.name} ${result.surname}`);
            }
          }

          setIsLoading(false);

        } catch (error) {
          // getUserError
        }
      }

      if (ca_a_exp > currentTime) {
        setEmail(payload_email)
        setId(payload_id)
        setUserType(payload_user_type)
        setName(payload_name)
        setIsLoading(false);
      }
      else if (ca_r_exp > currentTime) {
        setEmail(payload_email)
        setId(payload_id)
        setUserType(payload_user_type)
        setName(payload_name)
        setIsLoading(false)
        fetchData()
      }
      else {
        fetchData();
      }
    } else {
      setIsLoading(false)
    }

  }, []);

  return {
    email,
    id,
    userType,
    name,
    isLoading
  }
};

export default useUserData;
