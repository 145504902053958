import apiClient from "../apiClient";

export const setUserInfo = async (id, changedInfo) => {
    const url = `${process.env.REACT_APP_API_URL}/api/set-user-info/${id}/`;
    const response = await apiClient(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(changedInfo),
    });

    return response;

}