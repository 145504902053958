import i18n from "../../i18n";

export const getUnitPrices = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/unit-price/`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept-Language': i18n.language || 'tr',
        },
    })

    return response;

}