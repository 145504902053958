import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import Footer from '../../components/Footer/Footer';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import { RiPencilFill } from 'react-icons/ri'
import { BsCheckLg } from 'react-icons/bs'
import { FiX } from 'react-icons/fi'

import useAccountSettings from '../../hooks/useAccountSettings/useAccountSettings';

import styles from '../Pages.module.css'
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import { iso31661 } from '../../other/iso31661'
import { iso31662 } from '../../other/iso31662'
import { customList } from 'country-codes-list';

import { useTranslation } from 'react-i18next';

const telephoneCodes = customList('countryCallingCode', '{countryCode}');

const LoadingButton = <Button
    disabled
    className="btn-secondary rounded-0"
    style={{ width: "8rem", border: "0", fontSize: "10px" }}
>
    <Spinner className='spinner-border-sm' />
</Button>

const validateEmail = (email) => {
    // Regular expression to check if email is valid
    const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
};

function EmailChangeEnterPasswordModal(props) {

    const { t } = useTranslation();

    const passwordForChangeEmail = props.passwordForChangeEmail;
    const setPasswordForChangeEmail = props.setPasswordForChangeEmail;
    const setShowEnterPasswordModal = props.setShowEnterPasswordModal;
    const setChangeEmail = props.setChangeEmail;
    const handleEmailChange = props.handleEmailChange;
    const emailButtonLoading = props.emailButtonLoading;
    const emailModalNote = props.emailModalNote;
    const newEmailRequestDone = props.newEmailRequestDone;
    const handleEmailChangeCancel = props.handleEmailChangeCancel;

    return (
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
        >
            <Modal.Body>
                <h5 className="mb-3">{t("Şifrenizi Giriniz:")}</h5>
                <Form.Control
                    id='passwordForEmailChange'
                    type="password"
                    placeholder="Şifre"
                    value={passwordForChangeEmail}
                    disabled={newEmailRequestDone}
                    onChange={(e) => { setPasswordForChangeEmail(e.target.value) }}
                />
                {emailModalNote.state === "dontshow" ? <></> :
                    <p className={`mt-3 mb-0 text-center text-${emailModalNote.state}`}>{emailModalNote.note}</p>}
            </Modal.Body>
            <Modal.Footer>
                {newEmailRequestDone ?
                    <Button
                        variant="success"
                        onClick={(e) => {
                            setChangeEmail(false);
                            setShowEnterPasswordModal(false);
                        }}>
                        {t("Çıkış")}
                    </Button>
                    :
                    <>
                        <Button
                            variant="secondary"
                            disabled={emailButtonLoading}
                            onClick={() => {
                                handleEmailChangeCancel()
                            }}>{t("Vazgeç")}</Button>
                        <Button
                            variant="success"
                            disabled={emailButtonLoading || newEmailRequestDone}
                            onClick={(e) => {
                                handleEmailChange(e)
                            }}>
                            {emailButtonLoading ? <Spinner className='spinner-border-sm' /> : t("Onayla")}
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}

function PasswordModal(props) {

    const { t } = useTranslation();

    const setShowChangePasswordModal = props.setShowChangePasswordModal
    const currentPassword = props.currentPassword
    const setCurrentPassword = props.setCurrentPassword
    const newPassword = props.newPassword
    const setNewPassword = props.setNewPassword
    const newPasswordConfirm = props.newPasswordConfirm
    const setNewPasswordConfirm = props.setNewPasswordConfirm
    const newPasswordRequestLoading = props.newPasswordRequestLoading
    const setNewPasswordRequestLoading = props.setNewPasswordRequestLoading
    const newPasswordRequestDone = props.newPasswordRequestDone
    const setNewPasswordRequestDone = props.setNewPasswordRequestDone
    const passwordModalNote = props.passwordModalNote
    const setPasswordModalNote = props.setPasswordModalNote
    const handlePasswordChange = props.handlePasswordChange

    return (
        <Modal
            show={props.show}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
        >
            <Modal.Body>
                <p style={{ fontWeight: "bold" }}>{t("Mevcut Şifrenizi Giriniz:")}</p>
                <Form.Control
                    disabled={newPasswordRequestDone}
                    id='currentPassword'
                    type="password"
                    placeholder={t("Mevcut Şifre")}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <hr className="hr"></hr>
                <p style={{ fontWeight: "bold" }}>{t("Yeni Şifrenizi Giriniz:")}</p>
                <Form.Control
                    disabled={newPasswordRequestDone}
                    id='newPassword'
                    type="password"
                    placeholder={t("Yeni Şifre")}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <p className="mt-3" style={{ fontWeight: "bold" }}>{t("Yeni Şifrenizi Tekrar Giriniz:")}</p>
                <Form.Control
                    disabled={newPasswordRequestDone}
                    id='newPasswordConfirm'
                    type="password"
                    placeholder={t("Yeni Şifre")}
                    value={newPasswordConfirm}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                />
                {passwordModalNote.state === "dontshow" ? <></> :
                    <p className={`mt-3 mb-0 text-center text-${passwordModalNote.state}`}>{passwordModalNote.note}</p>}
            </Modal.Body>
            <Modal.Footer>
                {newPasswordRequestDone ?
                    <Button
                        variant="success"
                        onClick={(e) => {
                            setShowChangePasswordModal(false);
                        }}>
                        {t("Çıkış")}
                    </Button>
                    :
                    <>
                        <Button
                            variant="secondary"
                            disabled={newPasswordRequestLoading}
                            onClick={() => {
                                setShowChangePasswordModal(false)
                                setCurrentPassword("")
                                setNewPassword("")
                                setNewPasswordConfirm("")
                                setPasswordModalNote({ state: "dontshow", note: "" })
                                setNewPasswordRequestDone(false)
                            }}>{t("Vazgeç")}</Button>
                        <Button
                            variant="success"
                            type='submit'
                            disabled={newPasswordRequestLoading || newPasswordRequestDone}
                            onClick={(e) => {
                                handlePasswordChange(e)
                            }}>
                            {newPasswordRequestLoading ? <Spinner className='spinner-border-sm' /> : t("Onayla")}
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>

    );
}


const Account = () => {

    const { i18n, t } = useTranslation();

    const {
        pageLoading,

        userType,

        userEmail,
        setUserEmail,
        tempEmail,
        setTempEmail,

        showEnterPasswordModal,
        setShowEnterPasswordModal,

        passwordForChangeEmail,
        setPasswordForChangeEmail,

        emailModalNote,
        setEmailModalNote,

        newEmailRequestDone,

        emailButtonLoading,
        emailButtonDisabled,

        showChangePasswordModal,
        setShowChangePasswordModal,
        currentPassword,
        setCurrentPassword,
        newPassword,
        setNewPassword,
        newPasswordConfirm,
        setNewPasswordConfirm,
        newPasswordRequestLoading,
        setNewPasswordRequestLoading,
        newPasswordRequestDone,
        setNewPasswordRequestDone,
        passwordModalNote,
        setPasswordModalNote,

        handlePasswordChange,

        userId,
        changeEmail,
        setChangeEmail,
        changeInvoiceDetails,
        setChangeInvoiceDetails,

        handleEmailChange,
        handleEmailChangeCancel,

        invoiceDetails,
        setInvoiceDetails,
        savingInvoiceDetails,
        savedInvoiceDetails,
        handleSaveInvoiceDetails,
    } = useAccountSettings()

    document.title = t("Hesap Ayarları")

    let userTypeName

    switch (userType) {
        case 0:
            userTypeName = t("Askıda (0)")
            break;
        case 1:
            userTypeName = t("Standart (1)")
            break;
        case 2:
            userTypeName = t("Standart (2)")
            break;
        case 3:
            userTypeName = t("Temsilci (3)")
            break;
        default:
            userTypeName = t("Diğer")
            break;
    }

    const nameSurnameDisabled = (!changeInvoiceDetails || savingInvoiceDetails) || userType === 3;

    const delegateAssigned = "assigned_delegate_name" in invoiceDetails

    return (
        <div className={styles.flexWrapper}>

            <AppNavbar />

            <div className={styles.content}>

                {pageLoading ? LoadingContainer :

                    <Container className='border bg-white mt-5 p-4'>
                        <h3 className='mb-3'>{t("Hesabım")}</h3>

                        <Row>
                            <Col md={4} xs={12}>
                                <InputGroup className="mb-3 shadow-sm">
                                    <InputGroup.Text className="rounded-0" style={{ width: "8rem" }}>{t("Kullanıcı No.")}</InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        value={userId}
                                        className="rounded-0"
                                    />
                                </InputGroup>
                            </Col>

                            <Col md={8} xs={12}>
                                <InputGroup className="mb-3 shadow-sm">
                                    <InputGroup.Text className="rounded-0" style={{ width: "5rem" }}>{t("E-posta")}</InputGroup.Text>
                                    <Form.Control
                                        disabled={!changeEmail}
                                        value={tempEmail}
                                        type="email"
                                        onChange={(e) => setTempEmail(e.target.value)}
                                    />
                                    {emailButtonLoading ?

                                        LoadingButton :


                                        !changeEmail ?
                                            <Button
                                                disabled={emailButtonDisabled}
                                                className="btn-secondary rounded-0"
                                                style={{ width: "8rem", border: "0" }}
                                                onClick={() => { setChangeEmail(true) }}
                                            >
                                                <RiPencilFill /> &nbsp;{t("Değiştir")}
                                            </Button> :
                                            <>
                                                <Button
                                                    className="btn-success rounded-0"
                                                    style={{ width: "4rem", border: "0" }}
                                                    type='submit'
                                                    onClick={() => {
                                                        if (validateEmail(tempEmail) && tempEmail !== userEmail) {
                                                            setShowEnterPasswordModal(true)
                                                        }
                                                    }}
                                                >
                                                    <BsCheckLg />
                                                </Button>
                                                <Button
                                                    className="btn-danger rounded-0"
                                                    style={{ width: "4rem", border: "0" }}
                                                    onClick={() => { setTempEmail(userEmail); setChangeEmail(false) }}
                                                >
                                                    <FiX />
                                                </Button>
                                            </>
                                    }
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <InputGroup className="mb-3 shadow-sm">
                                    <InputGroup.Text className="rounded-0" style={{ width: "8rem" }}>{t("Kullanıcı Tipi")}</InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        value={userTypeName}
                                        className="rounded-0"
                                    />
                                </InputGroup>
                            </Col>

                            {userType === 3 &&
                                <Col>
                                    <InputGroup className="mb-3 shadow-sm">
                                        <InputGroup.Text className="rounded-0" style={{ width: "8rem" }}>{t("Temsilci Kodu")}</InputGroup.Text>
                                        <Form.Control
                                            disabled
                                            value={invoiceDetails.delegate_code}
                                            className="rounded-0"
                                        />
                                    </InputGroup>
                                </Col>
                            }
                        </Row>

                        <InputGroup className="mb-3 shadow-sm">
                            <InputGroup.Text className="rounded-0" style={{ width: "6rem" }}>{t("Şifre")}</InputGroup.Text>
                            <Form.Control
                                disabled
                                type="password"
                                defaultValue={"********"}
                            />
                            <Button
                                className="btn-secondary rounded-0"
                                onClick={() => {
                                    setShowChangePasswordModal(true)
                                    setCurrentPassword("")
                                    setNewPassword("")
                                    setNewPasswordConfirm("")
                                    setPasswordModalNote({ state: "dontshow", note: "" })
                                    setNewPasswordRequestDone(false)
                                }}
                                style={{ width: "8rem", border: "0" }}
                            >
                                <RiPencilFill /> &nbsp;{t("Değiştir")}
                            </Button>
                        </InputGroup>



                        <Container className='border shadow-sm'>
                            <Row className='border-bottom'>
                                <Col className='d-flex justify-content-start align-items-center' sm={10} xs={9} style={{ backgroundColor: '#e9ecef' }}><div>{t("Diğer Bilgiler")}</div></Col>
                                <Col className='d-flex justify-content-end align-items-center p-0' sm={2} xs={3} style={{ backgroundColor: '#e9ecef' }}>
                                    {!changeInvoiceDetails ?
                                        <Button
                                            className="btn-secondary rounded-0"
                                            style={{ width: "8rem", border: "0" }}
                                            onClick={() => setChangeInvoiceDetails(true)}>
                                            <RiPencilFill /> &nbsp;{t("Değiştir")}
                                        </Button> :
                                        <>
                                            <Button
                                                className="btn-success rounded-0"
                                                style={{ width: "4rem", border: "0" }}
                                                onClick={() => handleSaveInvoiceDetails()}
                                                disabled={savingInvoiceDetails}
                                            >
                                                {savingInvoiceDetails ? <Spinner size='sm' /> : <BsCheckLg />}
                                            </Button>
                                            <Button
                                                className="btn-danger rounded-0"
                                                style={{ width: "4rem", border: "0" }}
                                                onClick={() => setChangeInvoiceDetails(false)}
                                                disabled={savingInvoiceDetails}
                                            >
                                                <FiX />
                                            </Button>
                                        </>}
                                </Col>
                            </Row>
                            <Row className='p-3'>
                                <Col>
                                    <Row>
                                        <Col md={6} xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("İsim")}</InputGroup.Text>
                                                <Form.Control
                                                    className='rounded-0'
                                                    disabled={nameSurnameDisabled}
                                                    value={invoiceDetails.name ? invoiceDetails.name : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, name: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Soyisim")}</InputGroup.Text>
                                                <Form.Control
                                                    className='rounded-0'
                                                    disabled={nameSurnameDisabled}
                                                    value={invoiceDetails.surname ? invoiceDetails.surname : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, surname: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("TCKN")}</InputGroup.Text>
                                                <Form.Control
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.tr_identity_number ? invoiceDetails.tr_identity_number : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, tr_identity_number: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Telefon")}</InputGroup.Text>
                                                <div>
                                                    <Form.Select
                                                        className='rounded-0'
                                                        defaultValue={invoiceDetails.telephone_country_code === null || invoiceDetails.telephone_country_code === "" ?
                                                            90 : invoiceDetails.telephone_country_code
                                                        }
                                                        disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                        onChange={(e) => { setInvoiceDetails({ ...invoiceDetails, telephone_country_code: e.target.value }) }}
                                                    >
                                                        {Object.keys(telephoneCodes).map((option, index) => (

                                                            <option key={index} value={option}>({telephoneCodes[option]}) +{option}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                                <Form.Control
                                                    maxLength={20}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.telephone ? invoiceDetails.telephone : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, telephone: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Şirket İsmi")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={20}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.company_name ? invoiceDetails.company_name : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, company_name: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Vergi Dairesi")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={20}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.tax_office ? invoiceDetails.tax_office : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, tax_office: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} xs={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Vergi No.")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={20}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.taxno ? invoiceDetails.taxno : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, taxno: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Ülke")}</InputGroup.Text>
                                                <Form.Select
                                                    defaultValue={invoiceDetails.country}
                                                    onChange={(e) => {
                                                        setInvoiceDetails({ ...invoiceDetails, country: e.target.value, province: "" })
                                                    }}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                >
                                                    <option value="">{t("Seçiniz")}</option>
                                                    {iso31661.map((option, index) => (
                                                        <option key={index} value={option.numeric}>{option.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                        <Col md={4} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Şehir / Eyalet")}</InputGroup.Text>
                                                <Form.Select
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails || invoiceDetails.country === ""}
                                                    value={invoiceDetails.province ? invoiceDetails.province : ""}
                                                    onChange={(e) => { setInvoiceDetails({ ...invoiceDetails, province: e.target.value }) }}
                                                >
                                                    <option value="">{t("Seçiniz")}</option>
                                                    {iso31662.filter((state) => {
                                                        let country = iso31661.filter((obj) => obj.numeric === invoiceDetails.country)
                                                        if (country.length === 0) {
                                                            return false;
                                                        }
                                                        else {
                                                            country = country[0]
                                                            return state.parent === country.alpha2
                                                        }
                                                    }).map((option, index) => (
                                                        <option key={index} value={option.code}>{option.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </InputGroup>
                                        </Col>
                                        <Col md={4} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("İlçe")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={20}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.county ? invoiceDetails.county : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, county: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Mahalle")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={40}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.street ? invoiceDetails.street : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, street: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col md={6} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Posta Kodu")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={10}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.zipcode ? invoiceDetails.zipcode : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, zipcode: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Adres Satırı 1")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={100}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.address_line1 ? invoiceDetails.address_line1 : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, address_line1: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className='mb-3'>
                                            <InputGroup>
                                                <InputGroup.Text className='rounded-0'>{t("Adres Satırı 2")}</InputGroup.Text>
                                                <Form.Control
                                                    maxLength={100}
                                                    className='rounded-0'
                                                    disabled={!changeInvoiceDetails || savingInvoiceDetails}
                                                    value={invoiceDetails.address_line2 ? invoiceDetails.address_line2 : ""}
                                                    onChange={(e) => setInvoiceDetails({ ...invoiceDetails, address_line2: e.target.value })}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                        {delegateAssigned &&

                            <Container className='border shadow-sm my-3'>
                                <Row className='border-bottom'>
                                    <Col className='d-flex justify-content-start align-items-center' style={{ backgroundColor: '#e9ecef', height: "2.5rem" }}><div>{t("Temsilci Bilgileri")}</div></Col>
                                </Row>
                                <Row className='p-3'>
                                    <Col>
                                        <Row>
                                            <Col md={6} xs={12} className='mb-3'>
                                                <InputGroup>
                                                    <InputGroup.Text className='rounded-0' style={{ width: "5rem" }}>{t("İsim")}</InputGroup.Text>
                                                    <Form.Control
                                                        className='rounded-0'
                                                        disabled
                                                        value={`${invoiceDetails.assigned_delegate_name} ${invoiceDetails.assigned_delegate_surname}`}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col md={6} xs={12} className='mb-3'>
                                                <InputGroup>
                                                    <InputGroup.Text className='rounded-0' style={{ width: "5rem" }}>{t("Kod")}</InputGroup.Text>
                                                    <Form.Control
                                                        className='rounded-0'
                                                        disabled
                                                        value={invoiceDetails.assigned_delegate_code}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6} xs={12} className='mb-3'>
                                                <InputGroup>
                                                    <InputGroup.Text className='rounded-0' style={{ width: "5rem" }}>{t("Telefon")}</InputGroup.Text>
                                                    <Form.Control
                                                        className='rounded-0'
                                                        disabled
                                                        value={invoiceDetails.assigned_delegate_telephone}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col md={6} xs={12} className='mb-3'>
                                                <InputGroup>
                                                    <InputGroup.Text className='rounded-0' style={{ width: "5rem" }}>{t("E-posta")}</InputGroup.Text>
                                                    <Form.Control
                                                        className='rounded-0'
                                                        disabled
                                                        value={invoiceDetails.assigned_delegate_email}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>

                        }

                        <Container className='border mt-3 p-2 shadow-sm' style={{ backgroundColor: '#e9ecef' }}>
                            <a href={`/${i18n.language}/purchases`}>{t("Satın Almalar")}</a>
                        </Container>

                        {userType === 3 ?
                            <Container className='border mt-3 p-2 shadow-sm' style={{ backgroundColor: '#e9ecef' }}>
                                <a href={`/${i18n.language}/delegate-panel`}>{t("Temsilci Paneli")}</a>
                            </Container> : <></>}

                        {userType === 5 ?
                            <Container className='border mt-3 p-2 shadow-sm' style={{ backgroundColor: '#e9ecef' }}>
                                <a href={`/${i18n.language}/admin-panel`}>{t("Yönetici Paneli")}</a>
                            </Container> : <></>}

                    </Container>
                }

                <EmailChangeEnterPasswordModal
                    show={showEnterPasswordModal}
                    passwordForChangeEmail={passwordForChangeEmail}
                    setPasswordForChangeEmail={setPasswordForChangeEmail}
                    setShowEnterPasswordModal={setShowEnterPasswordModal}
                    setChangeEmail={setChangeEmail}
                    handleEmailChange={handleEmailChange}
                    emailButtonLoading={emailButtonLoading}
                    emailModalNote={emailModalNote}
                    newEmailRequestDone={newEmailRequestDone}
                    handleEmailChangeCancel={handleEmailChangeCancel}
                />

                <PasswordModal
                    show={showChangePasswordModal}
                    setShowChangePasswordModal={setShowChangePasswordModal}
                    currentPassword={currentPassword}
                    setCurrentPassword={setCurrentPassword}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    newPasswordConfirm={newPasswordConfirm}
                    setNewPasswordConfirm={setNewPasswordConfirm}
                    newPasswordRequestLoading={newPasswordRequestLoading}
                    setNewPasswordRequestLoading={setNewPasswordRequestLoading}
                    newPasswordRequestDone={newPasswordRequestDone}
                    setNewPasswordRequestDone={setNewPasswordRequestDone}
                    passwordModalNote={passwordModalNote}
                    setPasswordModalNote={setPasswordModalNote}
                    handlePasswordChange={handlePasswordChange}
                />

            </div>
            <Footer />
        </div>
    );
};

export default Account;
