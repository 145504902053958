import { Outlet, Navigate } from 'react-router-dom';
import i18n from '../../i18n';

function PrivateRoutes({ children, ...rest }) {
    let isAuthenticated = localStorage.getItem("ca_a");
    
    return isAuthenticated ? <Outlet /> : <Navigate to={`/${i18n.language}/login`} replace />;
  }

  
export default PrivateRoutes;