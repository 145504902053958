import React from 'react';

const homeURL = process.env.REACT_APP_HOME_URL

const SaleContractTextEn = (props) => {
    return <>
        <h2 className='mb-4 text-center'>Software Service Sales Agreement </h2>

        <ol>
            <h5 className='mb-4 fw-bold'><li>Parties and Definitions</li></h5>
            <ol>
                <li className='my-1'>This Service Sales Agreement ('Agreement') is entered into on {props.date} by and between the following parties:
                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Seller (referred to as 'Teber Soft')</th>
                            </tr>
                            <tr>
                                <th>Title</th>
                                <td>Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3 Şahinbey / Gaziantep / Türkiye</td>
                            </tr>
                            <tr>
                                <th>Tax No.</th>
                                <td>8331165867</td>
                            </tr>
                            <tr>
                                <th>Email Address</th>
                                <td>{process.env.REACT_APP_CONTACT_EMAIL}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className='table table-borderless'>
                        <tbody>
                            <tr className='text-center'>
                                <th colSpan={2}>Customer (referred to as 'Customer')</th>
                            </tr>
                            <tr>
                                <th>Name-Surname/Title</th>
                                <td>{props.clientName}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{props.clientAddress}</td>
                            </tr>
                            {props.type === "real_person" ?
                                props.tr_identity_number ? <tr>
                                    <th>TR ID Number</th>
                                    <td>{props.tr_identity_number}</td>
                                </tr> : <></> :
                                props.clientTaxno ? <tr>
                                    <th>Tax No.</th>
                                    <td>{props.clientTaxno}</td>
                                </tr> : <></>}
                            <tr>
                                <th>Email Address</th>
                                <td>{props.clientEmail}</td>
                            </tr>
                            {props.clientTelephone ?
                                <tr>
                                    <th>Phone</th>
                                    <td>{props.clientTelephone}</td>
                                </tr> : <></>}
                        </tbody>
                    </table> </li>
                <li className='my-1'>The software optimization program for construction rebars named Tasnifer, accessed via the web address <a href={`${homeURL}/en`}>{homeURL}</a>, which is the subject of this agreement, will hereinafter be referred to as the Software.</li>
                <li className='my-1'>Electronic files containing detailed quantities of a construction project prepared by the Customer in the format determined by third-party construction applications or Teber Soft, and uploaded to the Software, shall be referred to as Construction Project or Construction Projects.</li>
                <li className='my-1'>Electronic documents and web pages containing the most optimal iron cutting plan with minimum wastage, produced as a result of processing Construction Projects by optimization algorithms within the Software, along with details about this cutting plan, shall collectively be referred to as Cutting Plan or Cutting Plans.</li>
            </ol>


            <h5 className='my-4 fw-bold'><li>Subject of the Agreement</li></h5>
            <ol>
                <li className='my-1'>Description of the service subject to the agreement, inclusive of VAT sales price, payment method, and fundamental characteristics:
                    <table className='table table-borderless'>
                        <tbody>
                            <tr>
                                <th>Description of the service</th>
                                <th>Sale Price</th>
                                <th>Payment Method</th>
                            </tr>
                            <tr>
                                <td>{props.serviceDetail}</td>
                                <td>₺{props.price}</td>
                                <td>{props.paymentMethod}</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
                <li className='my-1'>The Customer purchases the right to optimize Construction Projects uploaded to the Software, produce Cutting Plans, and review, save, and print these Cutting Plans. The subject of the agreement is to determine the rights and obligations of the parties in this purchase transaction between Teber Soft and the Customer.</li>
                <li className='my-1'>Upon the entry into force of the Agreement, the Parties agree and declare that all transactions related to the Software will be conducted in accordance with the principles and provisions specified in this agreement.</li>
                <li className='my-1'>The Parties declare that they have read, understood, and accepted the Privacy Policy accessible at <a href={`${homeURL}/en/privacy-policy`}>{homeURL}/privacy-policy</a> and the Membership Agreement accessible at <a href={`${homeURL}/en/terms-and-conditions`}>{homeURL}/terms-and-conditions</a>, which are inseparable parts of this Sales Agreement, along with all its provisions.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Parties' Rights and Obligations</li></h5>
            <ol>
                <li className='my-1'>Teber Soft and the Customer agree and undertake to comply with all legal regulations and the terms of this Agreement while using the Software, and not to violate the said provisions. In the event of a breach of the terms of the Agreement, the Party breaching the terms shall be liable for all legal and punitive sanctions that may arise.</li>

                <li className='my-1'>In the event of determining any unauthorized use of the Software or any document or electronic material produced by the Software, contrary to the terms specified in this Agreement and the provisions of the current legislation, Teber Soft reserves the right to take legal action against the Customer.</li>

                <li className='my-1'>The Customer may exercise their rights over personal data and make changes or updates to this data at any time based on Article 11 of the Law No. 6698 on the Protection of Personal Data.</li>

                <li className='my-1'>The Customer cannot transfer, lease, or allow the use of the rights acquired with this Agreement and the use of the Software to another person or entity, whether for a fee or free of charge. In case of the determination of the contrary, Teber Soft reserves the right to permanently cancel the service received by the Customer without notice and to use legal means.</li>

                <li className='my-1'>The Customer undertakes not to use the Software in any way that may cause technical harm to the Software, the server, and databases hosting the components of the Software, cause financial damage to Teber Soft, or violate intellectual and industrial property rights.</li>

                <li className='my-1'>The Customer declares that the information provided to Teber Soft when purchasing the services offered by the Software is accurate and up-to-date. The Customer is responsible for any discrepancies arising from incorrect statements and disputes between the bank and the Customer.</li>

                <li className='my-1'>Teber Soft is not responsible for any inability of the Customer to receive services due to version updates made by third-party construction programs that produce Construction Projects. Teber Soft is not responsible for any inability of the Customer to receive services due to uploading a Construction Project to the Software in a format different from those that can be processed by the Software.</li>

                <li className='my-1'>Teber Soft reserves the right to make any updates and error corrections to the technical specifications and documents of the Software at its discretion, including timing and conditions.</li>

                <li className='my-1'>Teber Soft is not liable for the on-site execution, logistics, errors made by subcontractors in implementing the cutting plan, and other errors arising from the execution of this Cutting Plan produced by the Software. The accuracy of the outputs calculated by the Software depends on full compliance with the Cutting Plan. Since cutting operations carried out contrary to the Cutting Plan will not be compatible with the calculations made by the Software, Teber Soft cannot be held responsible for the Customer's actions contrary to the Cutting Plan.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Miscellaneous Provisions</li></h5>
            <ol>
                <li className='my-1'>Teber Soft takes the utmost care necessary for the software product to perform its functions to the best of its ability. However, Teber Soft does not claim or guarantee that this Software is flawless and error-free or that it will fully meet the Customer's specific needs.</li>
                <li className='my-1'>Teber Soft is authorized to disclose that the Customer is a user and customer of Teber Soft without any limitation or requirement for the Customer's prior consent.</li>
                <li className='my-1'>Teber Soft support staff have full access to Customer data within their authority. Teber Soft will take necessary precautions regarding the security of servers. The Customer is also obliged to comply with the security system established by Teber Soft and to protect confidentiality.</li>

                <li className='my-1'>Since the Software is developed in a web-based manner, it requires an internet connection. Teber Soft cannot be held responsible for any inability of the Customer to receive services due to problems with the Customer's internet connection.</li>

                <li className='my-1'>The Customer undertakes to use an internet browser compatible with the program for the Teber Soft software for which they intend to purchase services. Additionally, the Customer acknowledges that Teber Soft has no material or legal responsibility regarding software-hardware incompatibility.</li>

                <li className='my-1'>Teber Soft responds to any technical support requests related to the Software that may be requested by the Customer only via email. The Customer agrees that Teber Soft will not respond to support requests made through channels other than email.</li>

                <li className='my-1'>It is the joint responsibility of Teber Soft and the Customer to retain this document for access when needed. The Customer cannot claim not to have seen, read, possessed, or had access to this document.</li>

                <li className='my-1'>This document is prepared and stored in the language chosen by the user to use the Software. The Customer approves the contract in the language in which the document is prepared.</li>

            </ol>

            <h5 className='my-4 fw-bold'><li>Pricing and Payment</li></h5>
            <ol>
                <li className='my-1'>Teber Soft has established two methods for the sale of services offered in the Software: pay-as-you-go and subscription. The Customer, upon payment of the specified fees in both methods, will have the right to create Cutting Plans within the limits of the respective method.</li>

                <li className='my-1'>In the pay-as-you-go method, the Customer pays the fee calculated by the Software based on the iron weight calculated by the Software in the Construction Project uploaded by the Customer. After paying this fee, the Customer has the right to optimize the irons of this Construction Project and create Cutting Plans. In this method, the Customer must make new payments to obtain Cutting Plans for other Construction Projects.</li>

                <li className='my-1'>In the subscription method, the Customer purchases a predetermined weight allowance ("Credit") from Teber Soft in advance. Teber Soft determines the fee per unit weight. For each Construction Project for which Cutting Plans are created, the Customer's Credit is reduced by the weight of the Construction Project. The Customer does not pay any fees until they have consumed this Credit allocated to them after purchasing Credit. If the Customer's Credit is insufficient for the uploaded project, they must purchase new Credit.</li>

                <li className='my-1'>The fees per unit weight may differ between the pay-as-you-go and subscription methods. Upon payment of the fee, the Customer obtains the right to create Cutting Plans. Teber Soft reserves the right to change the fees. The Customer acknowledges that the terms and conditions of this Sales Agreement determined by Teber Soft are valid for their purchases.</li>

                <li className='my-1'>Payments are accepted via Wire Transfer / EFT or Credit / Debit Card.</li>

                <li className='my-1'>For payments made via Wire Transfer / EFT, the Customer is expected to transfer the relevant amount to Teber Soft's bank account. The Customer must add their order number to the payment description during this money transfer. After making the payment, the Customer must send the payment receipt to {process.env.REACT_APP_CONTACT_EMAIL}.</li>

                <li className='my-1'>Payments made via Wire Transfer / EFT are expected to be completed within 1 week. Teber Soft reserves the right to cancel payments not made within 1 week and to withhold the service of creating cutting plans for the Customer.</li>

                <li className='my-1'>If any deficiencies or errors are detected in payments made via Wire Transfer / EFT, the Customer will be contacted via email, and they will be requested to rectify the relevant deficiency or error. The Customer may request a refund for any incomplete or erroneous payments made. In this case, the paid amount will be refunded to the Customer. This refund process may take up to 1 week.</li>

            </ol>


            <h5 className='my-4 fw-bold' id="returna"><li>Refund</li></h5>
            <ol>
                <li>If irreparable errors occur during the creation of the Cutting Plan for the Project to which the Cutting Plan belongs and cannot be remedied with software updates, the Customer may request a refund.</li>
                <li>Errors in creating Cutting Plans that cannot be remedied with software updates and may lead to a refund request include:
                    <ul>
                        <li>Incorrect calculation of rebar quantity due to missing or excess rebar during the reading of the Construction Project file</li>
                        <li>Incorrect placement of rebars in the Cutting Plan due to misreading of the layout plan during the reading of the Construction Project file</li>
                    </ul>
                </li>
                <li>Errors in creating Cutting Plans that can be remedied with software updates and do not lead to a refund request if the error can be rectified by updating the Software within a maximum of two weeks include:
                    <ul>
                        <li>Errors during the Cutting Plan creation process (After the necessary update, a new Cutting Plan can be created with the same configurations to rectify the error.)</li>
                        <li>Errors in generating an image or calculations in the frontend of the application (After the update, the error is rectified without the need to create a new Cutting Plan.)</li>
                    </ul>
                </li>
                <li>The burden of proof of the error in the Software lies with the Customer. The Customer is obliged to thoroughly describe the nature of the error in the email they send. The decision as to whether the error can be rectified by an update rests with Teber Soft.</li>
                <li>Refund requests are considered after the Customer sends an email to <span className='fw-bold'>{process.env.REACT_APP_CONTACT_EMAIL}</span> providing evidence of the error in the Software. If it is determined that the error cannot be rectified by a software update, the refund process is initiated, and the Cutting Plan and the relevant Project are deleted from the system.</li>
                <li>Teber Soft commits to rectifying errors that can be remedied with software updates within a maximum of two weeks after the Customer submits a valid application via email. If this update is not completed within the specified period, the Customer may request a refund.</li>
                <li>Except in these cases, once the Cutting Plan creation service is accessed through the Software, it is deemed to have been completed error-free from the moment access to the cutting plans is granted. Therefore, refunds for this service, which is considered to have been completed without errors, cannot be issued, and the Customer cannot request a refund unless an irremediable error is identified after purchasing the Cutting Plan creation service.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Termination of the Contract</li></h5>
            <ol>
                <li className='my-1'>In the Pay-as-You-Go method, the Contract automatically terminates 3 months after payment.</li>
                <li className='my-1'>In the Subscription method, the Contract terminates within 3 months following the depletion of the user's Credits.</li>
                <li className='my-1'>In case of non-payment by the Customer, Teber Soft reserves the right to postpone, suspend, or cancel the committed service.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Address for Notifications and Email</li></h5>
            <ol>
                <li className='my-1'>The Parties agree to consider their addresses written in the contract as their legal notification addresses. The Parties are obliged to announce any address changes on the website or via email within fifteen (15) days. Otherwise, they accept that notifications sent to the addresses written in the contract will be valid according to Article 35 of the notification law.</li>
                <li className='my-1'>During the term of the Contract, messages, information, letters, warnings, payment notifications, and account statements may be sent to the Customer's email address. The Customer declares, accepts, and undertakes that they cannot claim that they did not receive such electronic communications or that they did not reach them. Also, the Customer acknowledges that such communications will be considered legally delivered three (3) days after the date of sending.</li>
            </ol>

            <h5 className='my-4 fw-bold'><li>Applicable Law and Dispute Resolution</li></h5>
            <p className='my-1'>This Agreement is subject to the laws of the Republic of Turkey. Gaziantep Courts and Execution Offices have jurisdiction over all disputes arising from this Agreement that cannot be resolved by negotiation between the parties.</p>

        </ol>

        <p>This Agreement, consisting of 9 articles and their sub-articles, enters into force as of the moment the sales transaction is successfully completed by electronically confirming each provision read and fully understood by the Customer.</p>

        <p>Last Updated: {props.date}</p>

    </>
}

export default SaleContractTextEn