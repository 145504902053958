import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import {
    orderLogInfo,
    orderWeightInfo,
    totalLogInfoWithFloor,
    totalWeightInfoWithFloor,
    totalLogInfoWithoutFloor,
    totalWeightInfoWithoutFloor,
    outageWeightInfoV1,
    outageWeightInfoV2,
    wasteWeightInfoV1,
    wasteWeightInfoV2,
    subWeightInfoV1,
    subWeightInfoV2,
    yieldPercentageInfo,
    wastePercentageInfoWithFloorV1,
    wastePercentageInfoWithFloorV2,
    wastePercentageInfoWithoutFloor,
} from '../../../infoContents';
import InfoNote from '../../InfoNote/InfoNote';

import { useTranslation } from 'react-i18next';

const CurrentResultTable = ({ data, floorAvailable, result, checkedDiameter }) => {

    const { i18n, t } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    const version = result.version

    let outageWeightInfo, wasteWeightInfo, subWeightInfo, wastePercentageInfoWithFloor
    if(version === 1){
        outageWeightInfo = outageWeightInfoV1
        wasteWeightInfo = wasteWeightInfoV1
        subWeightInfo = subWeightInfoV1
        wastePercentageInfoWithFloor = wastePercentageInfoWithFloorV1
    }
    else{
        outageWeightInfo = outageWeightInfoV2
        wasteWeightInfo = wasteWeightInfoV2
        subWeightInfo = subWeightInfoV2
        wastePercentageInfoWithFloor = wastePercentageInfoWithFloorV2
    }

    return (
        <>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <table className={`table table-striped-columns border my-3 text-center align-middle`}>
                <tbody>
                    {floorAvailable ? <>
                        <tr>
                            <th scope="row">{t("Sipariş Boy Sayısı")} <InfoNote content={orderLogInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                            <td>{data.orderLogs}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Sipariş Ağırlığı")} (kg) <InfoNote content={orderWeightInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                            <td>{Number(data.orderWeight)}</td>
                        </tr>
                    </> : <></>}
                    <tr>
                        <th scope="row">{t("Toplam Boy Sayısı")} {floorAvailable ? <InfoNote content={totalLogInfoWithFloor[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /> : <InfoNote content={totalLogInfoWithoutFloor[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} />}</th>
                        <td>{data.totalLogs}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t("Toplam Ağırlık")} (kg) {floorAvailable ? <InfoNote content={totalWeightInfoWithFloor[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /> : <InfoNote content={totalWeightInfoWithoutFloor[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} />}</th>
                        <td>{Number(data.totalWeight)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t("Elde Kalan Fire Ağırlığı")} (kg) <InfoNote content={outageWeightInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                        <td>{Number(data.outageWeight)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t("Teorik Fire Ağırlığı")} (kg) <InfoNote content={wasteWeightInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                        <td>{Number(data.wastedWeight)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t("Yerine Kullanım Ağırlığı")} (kg) <InfoNote content={subWeightInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                        <td>{Number(data.subWeight)}</td>
                    </tr>
                    <tr>
                        <th scope="row">{t("Verim Yüzdesi")} <InfoNote content={yieldPercentageInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /></th>
                        <td>{data.yieldPercentage}%</td>
                    </tr>
                    <tr>
                        <th scope="row">{t("Fire Yüzdesi")} {floorAvailable ? <InfoNote content={wastePercentageInfoWithFloor[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} /> : <InfoNote content={wastePercentageInfoWithoutFloor[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} />}</th>
                        <td>{data.wastePercentage}%</td>
                    </tr>
                </tbody>
            </table>
        </>

    )
}

export default CurrentResultTable;