import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import { VscError } from "react-icons/vsc";
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const Error = () => {

    const { t } = useTranslation();

    const { state } = useLocation();

    if (state === null) {
        return <div></div>
    }
    const { props } = state 

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={`${mainStyles.content} d-flex justify-content-center align-items-center`}>
                <Container className='bg-white border text-center p-5'>
                    <VscError size={50} style={{ color: "#dc3545", height: "20%" }} />
                    <h1 className='text-center fw-bold my-3'>{t("HATA")}</h1>
                    <p>{t("İsteğiniz gerçekleştirilirken bir hatayla karşılaşıldı.")}</p>
                    <table className='table table-responsive border mt-3'>
                        <tbody>
                            <tr>
                                <th scope="row">{t("Hata Kodu")}</th>
                                <td>{props.error_code}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Hata Detayı")}</th>
                                <td>{props.detail}</td>
                            </tr>
                        </tbody>

                    </table>
                </Container>
            </div>

            <Footer />
        </div>
    );
};

export default Error;
