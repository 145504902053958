import React from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';

const ReturnContract = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
    const homeURL = process.env.REACT_APP_HOME_URL

    document.title = "İade Sözleşmesi"

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>İade Sözleşmesi</h2>
                    <ol>
                        <h5 className='mb-4 fw-bold'><li>Taraflar ve Tanımlar</li></h5>
                        <ol>
                            <li className='my-1'>İşbu İade Sözleşmesi ("Sözleşme"), Tasnifer isimli web tabanlı yazılımın ("Yazılım") sahibi olan ve Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3 Şahinbey / Gaziantep adresinde bulunan Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi ("Teber Soft") ile, Yazılım üzerinden, bu Sözleşme'deki ve ilgili Satış Sözleşmesi'ndeki şartları kabul ederek hizmet satın alan müşteri ("Müşteri") arasında, Müşteri'nin Yazılım'ı kullanırken tabi olduğu koşulların belirlenmesi için akdedilmiş, Müşteri'nin Yazılım üzerinden hizmet satın alması ile yürürlüğe girmiştir. Sözleşme'de Teber Soft ve Müşteri ayrı ayrı "Taraf" ve birlikte "Taraflar" olarak anılacaktır.</li>
                            <li className='my-1'>Sözleşmeye konu olan <a href={`${homeURL}`}>{homeURL}</a> web adresi üzerinden erişilen Tasnifer isimli inşaat demiri optimizasyon programı bundan sonra Yazılım olarak anılacaktır. </li>
                            <li className='my-1'>Yazılım’a yüklenen, belirlenen üçüncü taraf İnşaat uygulamaları tarafından üretilen veya Teber Soft tarafından belirlenen formatta Müşteri tarafından hazırlanan, bir inşaat projesinin detaylı metrajını içeren elektronik dosyalar İnşaat Projesi veya İnşaat Projeleri olarak anılacaktır.</li>
                            <li className='my-1'>İnşaat Projeleri’nin Yazılım tarafından optimizasyon algoritmaları ile işlenmesi sonucu üretilen, en az fire ile en optimum demir kesim planını ve bu kesim planı hakkındaki detayları içeren elektronik dokümanların ve web sayfalarının hepsi Kesim Planı veya Kesim Planları olarak anılacaktır.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Sözleşme Konusu Hizmetler</li></h5>
                        <ol>
                            <li className='my-1'>Yazılım, İnşaat Projeleri'ni optimizasyon algoritmaları ile işleyerek Kesim Planları üretir. </li>
                            <li className='my-1'>Kesim Planları'nda Üye, inşaat projesinde ne kadar demir kullanacağını, bu demirlerden ne kadar fire elde edeceğini önceden öğrenebilecektir.</li>
                            <li className='my-1'>Üye, İnşaat Projesi'ni yüklemesinin ardından interaktif bir şekilde İnşaat Projesi'ndeki donatı metrajını inceleyebilecektir.</li>
                            <li className='my-1'>Kesim Planları'na erişim hakkı Teber Soft tarafından Üye'ye satılır.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>İade Koşulları</li></h5>

                        <ol>
                            <li>Yazılım tarafından verilen Kesim Planı oluşturma hizmetinde Kesim Planı'nın ait olduğu Proje'nin oluşturulması esnasında vuku bulmuş ve yazılım güncelleştirmeleriyle telafisi mümkün olmayan hataların tespiti hâlinde Müşteri para iadesi talebinde bulunabilir.</li>
                            <li>Yazılım güncelleştirmeleriyle telafisi mümkün olmayan ve iade talebine sebep olabilecek Kesim Planı oluşturma hatalarına şunlar dahildir:
                                <ul>
                                    <li>İnşaat Projesi dosyasının okunması esnasında eksik veya fazla demir alınması sebebiyle demir miktarının eksik veya fazla çıkması</li>
                                    <li>İnşaat Projesi dosyasının okunması esnasında yerleşim planının yanlış okunması sebebiyle Kesim Planı'ndaki demirlerin yerleştirildiği yerlerin yanlış olması</li>
                                </ul>
                            </li>
                            <li>Yazılım güncelleştirmeleriyle telafisi mümkün olan ve Yazılım azami iki hafta içerisinde güncelleştirilerek hatanın giderilebildiği takdirde iade talebine sebep olamayacak Kesim Planı oluşturma hatalarına şunlar dahildir:
                                <ul>
                                    <li>Kesim Planı oluşturma sürecindeki hatalar (Gerekli güncelleştirmenin ardından aynı konfigürasyonlarda yeni bir kesim planı oluşturularak hata giderilebilir.)</li>
                                    <li>Uygulamanın önyüz (frontend) kısmındaki bir görüntü oluşturulmasındaki veya hesaplamalardaki hatalar. (Güncelleştirmenin ardından yeni bir kesim planı oluşturulmasına gerek kalmadan hata giderilmiş olur.)</li>
                                </ul>
                            </li>
                            <li>Yazılım'daki hatanın varlığının ispatı yükümlülüğü Müşteri'ye aittir. Müşteri göndereceği e-postada hatanın mahiyetini detaylıca anlatmakla mükelleftir. Hatanın güncelleştirme ile giderilip giderilemeyeceğinin kararı Teber Soft'a aittir.</li>
                            <li>İade talebi Müşteri'nin <span className='fw-bold'>{process.env.REACT_APP_CONTACT_EMAIL}</span> e-posta adresine atacağı, Yazılım'daki hatanın varlığını ispat eden e-postası sonrası değerlendirmeye alınır, yazılım güncelleştirmesi ile hata telafisi mümkün olmadığı takdirde iade işlemi gerçekleştirilerek Kesim Planı ve Kesim Planı'nın ait olduğu ilgili Proje sistemden silinir.</li>
                            <li>Teber Soft yazılım güncelleştirmesi ile telafisi mümkün olan hataların telefisini Müşteri'nin e-posta ile geçerli bir başvuru yapmasından sonraki azami iki hafta içerisinde yapmayı taahhüt eder. Bu güncelleştirmenin belirtilen süre içerisinde yapılmaması halinde Müşteri iade talebinde bulunabilir.</li>
                            <li>Bu hâller dışında yazılım üzerinden alınan kesim planı oluşturma hizmeti, kesim planlarına erişim hakkının verildiği an gerçekleşmiş sayıldığından hatasız olarak tamamlanmış bu hizmetin iadesi mümkün olamaz ve Müşteri, kesim planı oluşturma hizmetini satın aldıktan sonra elimine edilmesi mümkün olmayan bir hata tespiti olmadığı takdirde iade talebinde bulunamaz.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü</li></h5>
                        <p className='my-1'>İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir. İşbu Sözleşme'den doğan ve taraflarca müzakere edilerek halledilemeyen tüm anlaşmazlıkların hallinden Gaziantep Mahkemeleri ve İcra Müdürlükleri yetkilidir.</p>

                    </ol>

                    <p>4 maddeden ve bu maddelerin alt maddelerinden oluşan işbu Sözleşme, Müşteri tarafından her bir hükmü okunarak ve bütünüyle anlaşılarak elektronik ortamda onaylanmak suretiyle, satış işleminin başarıyla gerçekleştiği an itibariyle yürürlüğe girmiştir.</p>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default ReturnContract;
