import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

const homeURL = process.env.REACT_APP_HOME_URL
const contactEmail = process.env.REACT_APP_CONTACT_EMAIL

const TermsAndConditionsTr = () => {
    document.title = "Şartlar ve Koşullar"

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                <Container className={`bg-white my-5 p-5 shadow-sm`}>
                    <h2 className='mb-4 text-center'>Üyelik Sözleşmesi</h2>


                    <ol>
                        <h5 className='mb-4 fw-bold'><li>Taraflar ve Tanımlar</li></h5>
                        <ol>
                            <li className='my-1'>İşbu Üyelik Sözleşmesi ("Sözleşme"), Tasnifer isimli web tabanlı yazılımın ("Yazılım") sahibi olan ve Çamtepe Mahallesi Mahmut Tevfik Atay Bulvarı Gaziantep Teknopark No: 4/A İç Kapı No:3 Şahinbey / Gaziantep adresinde bulunan Teber Soft Yazılım İnşaat Sanayi ve Ticaret Limited Şirketi ("Teber Soft") ile, Yazılım'a, bu Sözleşme'deki şartları kabul ederek üye olan kullanıcı ("Üye") arasında, Üye'nin Yazılım'ı kullanırken tabi olduğu koşulların belirlenmesi için akdedilmiş, Üye'nin Yazılım'a üye olurken bu Sözleşme'ye elektronik ortamdan onay vermesiyle yürürlüğe girmiştir. Sözleşme'de Teber Soft ve Üye ayrı ayrı "Taraf" ve birlikte "Taraflar" olarak anılacaktır.</li>
                            <li className='my-1'>Sözleşmeye konu olan <a href={`${homeURL}/tr`}>{homeURL}</a> web adresi üzerinden erişilen Tasnifer isimli inşaat demiri optimizasyon programı bundan sonra Yazılım olarak anılacaktır.</li>
                            <li className='my-1'>Yazılım’a yüklenen, belirlenen üçüncü taraf İnşaat uygulamaları tarafından üretilen veya Teber Soft tarafından belirlenen formatta Müşteri tarafından hazırlanan, bir inşaat projesinin detaylı metrajını içeren elektronik dosyalar İnşaat Projesi veya İnşaat Projeleri olarak anılacaktır.</li>
                            <li  className='my-1'>İnşaat Projeleri’nin Yazılım tarafından optimizasyon algoritmaları ile işlenmesi sonucu üretilen, en az fire ile en optimum demir kesim planını ve bu kesim planı hakkındaki detayları içeren elektronik dokümanların ve web sayfalarının hepsi Kesim Planı veya Kesim Planları olarak anılacaktır.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Sözleşme Konusu Hizmetler</li></h5>
                        <ol>
                            <li className='my-1'>Yazılım, İnşaat Projeleri'ni optimizasyon algoritmaları ile işleyerek Kesim Planları üretir. </li>
                            <li className='my-1'>Kesim Planları'nda Üye, inşaat projesinde ne kadar demir kullanacağını, bu demirlerden ne kadar fire elde edeceğini önceden öğrenebilecektir.</li>
                            <li className='my-1'>Üye, İnşaat Projesi'ni yüklemesinin ardından interaktif bir şekilde İnşaat Projesi'ndeki donatı metrajını inceleyebilecektir.</li>
                            <li className='my-1'>Kesim Planları'na erişim hakkı Teber Soft tarafından Üye'ye satılır.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Taraflar'ın Hak ve Yükümlülükleri</li></h5>
                        <ol>
                            <li className='my-1'>Üye, Yazılım'ı kullanırken tüm yasal mevzuat hükümlerine ve işbu Sözleşme şartlarına uymayı ve söz konusu hükümleri ihlal etmemeyi kabul ve taahhüt eder. Aksi durumda, oluşacak tüm hukuki ve cezai yaptırımlardan tamamen Üye sorumlu olacaktır.</li>
                            <li className='my-1'>Yazılım'ın veya Yazılım'ın içeriğindeki ve  Yazılım'ın ürettiği her türlü doküman veya elektronik materyalin işbu Sözleşme ile belirlenen şartlara ve yürürlükteki mevzuat hükümlerine aykırı olarak kullanılması hukuka aykırıdır ve Teber Soft bu durumların tespiti halinde dava açma hakkını saklı tutar. </li>
                            <li className='my-1'><a href={`${homeURL}/tr/privacy-policy`}>{homeURL}/privacy-policy</a> web adresinde bulunan Gizlilik Politikası, Üye'nin Yazılım'ı kullanırken Teber Soft ile paylaştığı kişisel veriler üzerinde Teber Soft ve Üyenin haklarını ve sorumluluklarını düzenler. Gizlilik Politikası işbu Sözleşme'nin ayrılmaz bir parçasıdır. Üye, Yazılım'ı kullandığı sürece Gizlilik Politikası'nı okuduğunu, anladığını ve onayladığını kabul eder.</li>
                            <li className='my-1'>Üye, Yazılım'ın kaynak koduna veya sunucu, veritabanı ve diğer birimlerine erişim talebinde bulunamaz.</li>
                            <li className='my-1'>Üye, üyelik bilgilerini korumakla yükümlüdür. Üye, Yazılım'a kaydolurken belirlediği e-posta ve şifre bilgilerini korumak ve saklamakla yükümlüdür. Bu bilgilerin Üye'nin zaafiyeti sonucu üçüncü şahısların eline geçmesi sebebiyle doğacak zararlardan hiçbir şekilde Teber Soft sorumlu tutulamaz.</li>
                            <li className='my-1'>Üye, işbu Sözleşme ile ve Yazılım'ı kullanırken elde ettiği hakları ücretli veya üretsiz bir şekilde başka bir kişi veya kuruma devredemez, kiralayamaz, kullandırtamaz. Aksi durumun tespiti hâlinde Teber Soft, Üye'nin aldığı hizmeti haber vermeksizin kalıcı olarak iptal etme hakkını saklı tutar.</li>
                            <li className='my-1'>Yazılım, web tabanlı bir şekilde geliştirildiği için internet bağlantısı gerektirmektedir. Üye'nin internet bağlantısında oluşabilecek sorunlar sebebiyle hizmet alamamasından Teber Soft sorumlu tutulamaz.</li>
                            <li className='my-1'>Üye, Yazılım'ı hiçbir şekil ve şartta, Yazılım'a ve Yazılım'ın bileşenlerinin barındırıldığı sunucu ve veritabanlarına teknik bir zarar verecek, Teber Soft'u maddi zarara uğratacak veya fikrî ve sınaî mülkiyet haklarını ihlal edecek şekilde kullanmayacağını taahhüt eder. </li>
                            <li className='my-1'>Üye'nin Yazılım'ı yanlış veya yasa dışı kullanımından Teber Soft sorumlu tutulamaz.</li>
                            <li className='my-1'>Üye Yazılım'ın sunduğu hizmetleri satın alırken Teber Soft'a sağladığı bilgilerin doğru ve güncel olduğunu beyan eder. </li>
                            <li className='my-1'>Teber Soft, İnşaat Projesi üreticisi olan üçüncü taraf  inşaat programlarında yapılan versiyon güncellemeleri sonucu Üye'nin hizmet alamamasından sorumlu değildir.</li>
                            <li className='my-1'>Teber Soft, Üye'nin Yazılım'da işlenebilecek formatlardan farklı bir İnşaat Projesi'ni Yazılım'a yüklemesi sebebiyle hizmet alamamasından sorumlu değildir.</li>
                            <li className='my-1'>Teber Soft Yazılım'ın teknik özellikleri ve dokümanları üzerinde zamanlaması ve şartları kendi inisiyatifinde olmak üzere her türlü güncellemeyi ve hata düzeltmesini yapma hakkını saklı tutar.</li>
                            <li className='my-1'>Teber Soft, Yazılım tarafından üretilen Kesim Planı'nın yerinde icrasından, lojistiğinden, taşeronların kesim planının uygulamasında yaptığı hatalardan ve bu Kesim Planı'nın icrasından doğacak diğer hatalardan mesul değildir. Yazılım tarafından hesaplanan çıktıların doğruluğu, Kesim Planı'na tam olarak riayet edilmesine bağlıdır. Kesim Planı'na aykırı olarak yapılacak kesim işlemleri Yazılım tarafından yapılmış hesaplarla uyumlu olmayacağından Teber Soft, Müşteri'nin Kesim Planı'na aykırı davranışlarından sorumlu tutulamaz.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Gizlilik</li></h5>
                        <ol>
                            <li className='my-1'>Teber Soft, Üye tarafından Yazılım'ın kullanımı amacıyla kendisine verilen kişisel bilgilerin işlenmesi, güvenliği ve saklanması konusunda, 6698 Sayılı Kişisel Verilerin Korunması Kanunu dahil olmak üzere tüm yasal düzenlemelere uygun hareket etmeyi taahhüt eder. Bu doğrultuda, Teber Soft, Üye'nin kişisel verilerini <a href={`${homeURL}/tr/privacy-policy`}>{homeURL}/privacy-policy</a> web adresinde belirtilen Gizlilik Politikası'na uygun olarak toplayabilir, kullanabilir, aktarabilir ve çeşitli şekillerde işleyebilir. Gizlilik Politikası, bu Üyelik Sözleşmesi'nin ayrılmaz bir unsuru olarak kabul edilir.</li>
                            <li className='my-1'>Üye, Yazılım'ı kullanımı aracılığıyla, Gizlilik Politikası'nda belirtildiği üzere kişisel verilerinin toplanmasını, kullanılmasını, paylaşılmasını ve diğer işlemlere tabi tutulmasını bilinçli ve özgür bir şekilde kabul etmektedir. Kişisel verilerin kullanılmasına ilişkin detaylar ve bu konuda sahip olduğunuz haklar hakkında daha fazla bilgi almak için Gizlilik Politikamızı gözden geçirebilirsiniz. Ayrıca, yasal haklarınızı kullanmak için <a href={`mailto:${contactEmail}`}>{contactEmail}</a> e-posta adresine bir e-posta gönderebilirsiniz.</li>
                            <li className='my-1'>Üye, kişisel veri bilgilerinin Teber Soft tarafından bu şekilde işlenmesine ve korunmasına onay verdiğini kabul ve ifade eder. Teber Soft, bahsi geçen kişisel verilerin 6698 Sayılı Kişisel Verilerin Korunması Kanunu'nun 12. maddesine uygun olarak güvenli bir şekilde muhafaza edilmesi, izinsiz erişimlerin ve yasadışı veri işlemlerinin engellenmesi için gerekli tüm önlemleri almayı taahhüt eder. Üye, 6698 Sayılı Kişisel Verilerin Korunması Kanunu'nun 11. maddesine dayanarak, kişisel verileri üzerindeki haklarını kullanabilir ve bu verilerde istediği zaman değişiklik veya güncelleme yapabilir.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Fikrî ve Sınaî Mülkiyet Hakları</li></h5>
                        <ol>
                            <li className='my-1'>Yazılım'ın tasarımı, yazılımı, ve Yazılım'ın üretimi olan her türlü doküman ve elektronik materyale ait tüm fikrî mülkiyet hakları Teber Soft'a ait olup, bunlar Üye tarafından Teber Soft'un yazılı izni olmaksızın kullanılamaz. Yazılım'ın üretimi olan hiçbir materyal satılamaz ve ticari kazanaç sağlamak için dağıtılamaz, değiştirileemz ve başka hiçbir çalışma, yayın, uygulama ve web sitesine dahil edilemez.</li>
                            <li className='my-1'>Üye'nin doğrudan Teber Soft'un fikrî mülkiyet haklarını ihlal etmesi veya Üye'nin zaafiyeti sebebiyle Teber Soft'e ait fikrî mülkiyet haklarının ihlal edilmesi durumunda Üye, tüm doğrudan ve dolaylı zararları tazmin ile mükelleftir. </li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Ücretlendirme ve Ödeme</li></h5>
                        <ol>
                            <li className='my-1'>Teber Soft, Yazılım'da sunulan hizmetlerin satışı için ödedikçe kullanma (pay-as-you-go) ve abonelik olmak üzere iki yöntem belirlemiştir. Müşteri, her iki yöntemde de belirlenen ücretleri ödemesini müteakiben ilgili yöntemin sınırları içerisinde Kesim Planı oluşturma hakkına sahip olur.</li>

                            <li className='my-1'>Ödedikçe kullanma yönteminde Müşteri, yüklediği İnşaat Projesi'nde bulunan ve Yazılım tarafından hesaplanan demir ağırlığı üzerinden Yazılım tarafından hesaplanan ücreti öder. Müşteri, bu ücreti ödemesinin ardından bu İnşaat Projesi’nin demirlerini optimize ederek Kesim Planları oluşturma hakkına sahip olur. Bu yöntemde Müşteri, başka İnşaat Projeleri’nin Kesim Planları’nı elde etmek için yeni ödeme yapmak zorundadır.</li>

                            <li className='my-1'>Abonelik yönteminde ise Müşteri, önceden Teber Soft tarafından belirlenen bir ağırlık hakkı (“Kredi”) satın alır. Teber Soft, birim ağırlık başına ücret belirler. Kesim Planları oluşturduğu her bir İnşaat Projesi için Müşteri'nin Kredi'si, İnşaat Projesinin ağırlığı kadar azaltılır. Müşteri, Kredi satın aldıktan sonra, kendisine tanımlanan bu Kredi'sini tüketene kadar ücret ödemez. Müşteri yüklediği projede kredisi yetersiz kalırsa yeni kredi satın almak zorundadır.</li>

                            <li className='my-1'>Ödedikçe kullanma ve abonelik yöntemleri arasında ağırlık başına ücretler farklı olabilir. Ücretin ödenmesi ile Müşteri Kesim Planı oluşturma hakkını elde eder. Teber Soft, ücretler üzerinde değişiklik yapma hakkını saklı tutar. Müşteri, satın alımlarında, Teber Soft tarafından belirlenen işbu Satış Sözleşmesi'nin hüküm ve şartlarının geçerli olduğunu kabul eder.</li>

                            <li className='my-1'>Üye, satın alımlarında, Teber Soft tarafından belirlenen Satış Sözleşmesi'nin hüküm ve şartlarının geçerli olduğunu kabul eder.</li>
                        </ol>

                        <h5 className='my-4 fw-bold'><li>Sözleşmelerdeki Değişiklikler</li></h5>
                        <p>Teber Soft, kendi inisiyatifine bağlı olarak, bu Üyelik Sözleşmesi ve Yazılım'daki Gizlilik Politikası dahil olmak üzere tüm politika, hüküm ve koşulları, geçerli yasal düzenlemelere aykırı olmamak koşuluyla, Yazılım üzerinde duyuru yaparak tek taraflı bir şekilde değiştirme hakkına sahiptir. Bu Üyelik Sözleşmesi'nin değiştirilen maddeleri, Yazılım'da ilan edildikleri tarihte yürürlüğe girerken, geriye kalan maddeler aynı şekilde geçerliliğini korur ve etkilerini sürdürür. </p>

                        <h5 className='my-4 fw-bold'><li>Sözleşmenin Feshi</li></h5>
                        <p>Teber Soft haklı bir nedenin varlığı halinde işbu Sözleşme’yi feshedebilir. Üye ise dilediği zamanda <a href={`mailto:${contactEmail}`}>{contactEmail}</a> adresine yazılı olarak bildirmek kaydıyla işbu Sözleşme’yi feshedebilir. Yazılı bildirimin Teber Soft'a ulaştığı tarihte Sözleşme sona erer ve Teber Soft Üye’nin kaydını veritabanından siler.</p>

                        <h5 className='my-4 fw-bold'><li>Mücbir Sebep</li></h5>
                        <p>Eğer ayaklanma, ambargo, devlet müdahalesi, isyan, işgal, savaş, seferberlik, grev, lokavt, işçi-işveren çatışmaları, siber saldırılar, iletişim aksaklıkları, altyapı ve internet problemleri, sistem geliştirme veya yenileme faaliyetlerinden kaynaklanan aksaklıklar, elektrik kesintisi, yangın, patlama, fırtına, sel, deprem, toprak kayması, salgınlar veya diğer doğal afetler veya Teber Soft'in kontrolü dışında, Teber Soft'in hatasından kaynaklanmayan ve makul şekilde öngörülemeyen diğer olaylar ("Mücbir Sebep") Teber Soft'in bu Sözleşme'den kaynaklanan yükümlülüklerini yerine getirmesini engeller veya geciktirirse, Teber Soft, Mücbir Sebep sonucunda yerine getirilemeyen veya geciken yükümlülüklerinden ötürü sorumlu tutulamaz ve bu durum bu Sözleşme'nin ihlali olarak kabul edilmez.</p>

                        <h5 className='my-4 fw-bold'><li>Uygulanacak Hukuk ve Uyuşmazlıkların Çözümü</li></h5>
                        <p>İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir. İşbu Sözleşme'den doğan ve taraflarca müzakere edilerek halledilemeyen tüm anlaşmazlıkların hallinden Gaziantep Mahkemeleri ve İcra Müdürlükleri yetkilidir.</p>

                    </ol>

                    <p>10 maddeden oluşan işbu Sözleşme, Üye tarafından her bir hükmü okunarak ve bütünüyle anlaşılarak elektronik ortamda onaylanmak suretiyle, onaylandığı an itibariyle yürürlüğe girmiştir. </p>

                    <p>Son Güncellenme Tarihi: 23 Temmuz 2023</p>
                </Container>
            </div>
            <Footer />
        </div>
    );
};

export default TermsAndConditionsTr;
