import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { unsign } from '../services/unsignService';

import { useTranslation } from 'react-i18next';

export const useCreditCardPaymentSuccess = () => {
    
    const { i18n, t } = useTranslation();

    const [showPage, setShowPage] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [data, setData] = useState({})

    const location = useLocation();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search.replaceAll("&amp;", "&"));


        if (!searchParams.has("redirect")) {
            window.parent.window.location = `/${i18n.language}/`
            return
        }

        const redirect = searchParams.get("redirect")

        if (!(redirect === "0" || redirect === "1")){
            window.parent.window.location = `/${i18n.language}/`
            return
        }

        if (!searchParams.has("key")) {
            window.parent.window.location = `/${i18n.language}/`
            return
        }

        const sign = searchParams.get("key")

        if(redirect === "1"){
            window.parent.window.location = `/${i18n.language}/payment-complete?key=${sign}&redirect=0`
            return
        }

        setShowPage(true)

        async function fetchData() {
            try {
                const response = await unsign(sign)

                if (response.ok) {

                    const response_data = await response.json()

                    let error = "error_code" in response_data.unsigned_data

                    if (error) {
                        setError(true)
                    }
                    
                    setData(response_data.unsigned_data)
                    setIsLoading(false)

                }
                else {
                    setError(true)

                    if (response.status === 400) {
                        const error_data = await response.json()
                        setData({ ...error_data, status: 400 })
                    }
                    else {
                        setData({
                            error_type: "response_error",
                            detail: `Response error: ${response.status}`,
                            error_code: "YNY8HN"
                        })
                    }

                    setIsLoading(false)

                }

            } catch (error) {
                setError(true)
                setData({
                    error_type: "fatal_error",
                    detail: "Server error",
                    error_code: "OO0VQA"
                })
                setIsLoading(false)
            }
        }

        fetchData();
        

    }, [location.search]);

    return {
        showPage,
        isLoading,
        error,
        data,
    }

}